import React from "react";
// import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ClearIcon from "@material-ui/icons/Clear";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import DatePicker from "material-ui-pickers/DatePicker";
import MenuItem from "@material-ui/core/MenuItem";
import NativeSelect from "@material-ui/core/NativeSelect";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import AjoutSocieteDialog from "../../common/AjoutSocieteDialog";
import AjoutBanqueDialog from "../../common/AjoutBanqueDialog";
import SimulateurEmpruntDialog from "./SimulateurEmpruntDialog";
import NumberFormatCustom from "../../common/NumberFormatCustom";
import NumberFormat from "react-number-format";
import withGlobals from "../withGlobals";
import M2FormatCustom from "../../common/M2FormatCustom";

const styles = theme => {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    prefix: {
      marginLeft: theme.spacing.unit,
    },
    textFieldz: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      width: "100%",
    },
    dateField: {
      paddingTop: 0,
      width: 250,
    },
    societeAjoutee: {
      fontSize: "1.2rem",
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
    },
    societeAjouteeLabel: {
      color: "rgba(0, 0, 0, 0.54)",
      padding: 0,
      fontSize: "0.8rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: 1.2,
      marginBottom: theme.spacing.unit,
    },
    textFieldLarge: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      marginBottom: theme.spacing.unit * 2,
      width: 400,
    },
    textFieldSmall: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
    textFieldBig: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 600,
    },
    button: {
      marginTop: "0px",
    },
    submitButton: {
      marginTop: theme.spacing.unit * 2,
      padding: theme.spacing.unit * 2,
    },
    buttonHidden: {
      marginTop: theme.spacing.unit * 4,
      visibility: "hidden",
    },
    prixAcquisition: {
      padding: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 12,
      borderTop: "solid 1px gray",
      fontWeight: "bold",
    },
    bloc: {
      border: "solid 1px gray",
      borderRadius: "3px 3px 3px",
      padding: theme.spacing.unit,
      minHeight: "550px",
    },
    pA: {
      padding: theme.spacing.unit,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      border: "solid 1px #ececec",
      textAlign: "center",
    },
    invisibleFieldset: {
      margin: 0,
      padding: 0,
      border: 0,
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    }
  };
};

class ActifForm extends React.Component {
  state = {
    modeFinancement: "fonds_propres",
    societeAjoutee: null,
    societesAjoutees: [],
    banqueAjoutee: null,
    banquesAjoutees: [],
    dialogSocieteOpen: false,
    dialogBanqueOpen: false,
    simulateurEmpruntOpen: false,
  };

  handleChange = e => {
    this.setState({ modeFinancement: e.target.value });
  };

  handleSocieteAjoutee = societeAjoutee => {
    const { setFieldValue, setFormDataChanged } = this.props;
    setFieldValue("idsociete", societeAjoutee.idsociete, false);
    setFormDataChanged();
    return this.setState({
      societesAjoutees: this.state.societesAjoutees.concat([societeAjoutee]),
      societeAjoutee,
    });
  };

  handleCancelSocieteAjoutee = () => {
    return this.setState({ societeAjoutee: null });
  };

  handleBanqueAjoutee = banqueAjoutee => {
    const { setFieldValue, setFormDataChanged } = this.props;
    setFieldValue("idbanque", banqueAjoutee.idbanque, false);
    setFormDataChanged();
    return this.setState({
      societesAjoutees: this.state.societesAjoutees.concat([banqueAjoutee]),
      banqueAjoutee,
    });
  };

  handleCancelBanqueAjoutee = () => {
    return this.setState({ banqueAjoutee: null });
  };

  handleOpenSimulator = () => {
    this.setState({ simulateurEmpruntOpen: true });
  };

  handleCloseSimulator = () => {
    this.setState({ simulateurEmpruntOpen: false });
  };

  changeValue = (field, value) => {
    const { setFieldValue, setFormDataChanged, changed } = this.props;
    if (!changed) setFormDataChanged();
    setFieldValue(field, value, false);
  };

  handleOpenSocieteDialog = () => this.setState({ dialogSocieteOpen: true });

  handleCloseSocieteDialog = () => this.setState({ dialogSocieteOpen: false });

  handleOpenBanqueDialog = () => this.setState({ dialogBanqueOpen: true });

  handleCloseBanqueDialog = () => this.setState({ dialogBanqueOpen: false });

  handleCancel = () => {
    const { history } = this.props;
    history.push("/actifs");
  };

  handleDateChange = date => {
 
    const { setFieldValue, setFormDataChanged, changed } = this.props;
    if (!changed) setFormDataChanged();
   this.props.setFieldValue("date_acquisition", date ? date.format("YYYY-MM-DD") : null);
}
  render() {
    const {
      handleSubmit,
      touched,
      handleBlur,
      values,
      errors,
      isSubmitting,
      classes,
      changed,
      societes,
      banques,
      typesFinancement,
      readOnly,
      user,
    } = this.props;
    if (values.capital_reste_du_tmp === 0 && values.charge_emprunt_tmp === 0) {
      values.idbanque = 0;
    }
    const { dialogSocieteOpen, dialogBanqueOpen, simulateurEmpruntOpen } = this.state;
    return (
      <form onSubmit={handleSubmit} className={classes.container}>
        <fieldset className={classes.invisibleFieldset} disabled={!user.modifier}>
          <AjoutSocieteDialog
            open={dialogSocieteOpen}
            onSocieteAjoutee={this.handleSocieteAjoutee}
            handleClose={this.handleCloseSocieteDialog}
            societes={societes}
          />
          <AjoutBanqueDialog
            open={dialogBanqueOpen}
            onBanqueAjoutee={this.handleBanqueAjoutee}
            handleClose={this.handleCloseBanqueDialog}
          />
          <SimulateurEmpruntDialog open={simulateurEmpruntOpen} handleClose={this.handleCloseSimulator} />
          <Grid container justify="space-evenly" alignItems="center" direction="row" spacing={16}>

            {!user.modifier && (
              <Grid item xs={12}>
                <Typography variant="subheading">Lecture seule pour l'utilisateur courant</Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography variant="title">Actif</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="title">Financement</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                direction="row"
                spacing={16}
                className={classes.bloc}
              >
                {!this.state.societeAjoutee ? (
                  <Grid item xs={12}>
                    <Grid container justify="flex-start" direction="row" spacing={1}>
                      <Grid item>
                        <FormControl>
                          <InputLabel htmlFor="societe-native-helper">Société</InputLabel>
                          <NativeSelect
                            value={values.idsociete}
                            onChange={(event, key) =>
                              this.changeValue("idsociete", parseInt(event.target.value, 10))
                            }
                            input={<Input name="idsociete" id="societe-native-helper" />}
                            autoFocus
                          >
                            <option value="" key={"a"} />
                            <option value={1} key={1}>
                              PARDES PATRIMOINE
                            </option>
                            <option value={2} key={2}>
                              FORUM PATRIMOINE
                            </option>
                            {societes
                              .concat(this.state.societesAjoutees)
                              .filter(s => s.idsociete > 2)
                              .sort((s2, s1) => {
                                if (s1.nom_societe < s2.nom_societe) return 1;
                                if (s1.nom_societe > s2.nom_societe) return -1;
                                return 0;
                              })
                              .map(s => (
                                <option value={s.idsociete} key={s.idsociete}>
                                  {s.nom_societe.toUpperCase()}
                                </option>
                              ))}
                          </NativeSelect>
                          <FormHelperText id="name-helper-text"> </FormHelperText>
                        </FormControl>
                      </Grid>
                      {user.modifier && (
                        <Grid item>
                          <IconButton
                            onClick={this.handleOpenSocieteDialog}
                            style={{ marginTop: 8 }}
                            aria-label="Ajouter société"
                            title="Créer une Société"
                          >
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                    <Grid item xs={12}>
                      <Grid container direction="row" alignItems="space-between">
                        <Grid item>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.societeAjouteeLabel}>
                              Société
                        </Grid>
                            <Grid item className={classes.societeAjoutee}>
                              {this.state.societeAjoutee.nom_societe}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={this.handleCancelSocieteAjoutee}
                            style={{ marginTop: 0 }}
                            aria-label="Changer société"
                            title="Changer société"
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <DatePicker
                    value={values.date_acquisition || null}
                    onChange={this.handleDateChange}
                    format="DD/MM/YYYY"
                    autoOk
		                required
                    fullWidth
                    clearable
                    label="Date d'acquisition"
                    emptyLabel="Non acquis"
                    clearLabel="Non acquis"
                    cancelLabel="Annuler"
                    invalidDateMessage="Date non valide"
                    keyboard
                    mask={value => (value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [])}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="adresse"
                    value={values.adresse}
                    label="Adresse"
                    name="adresse"
                    type="text"
                    required
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.adresse && touched.adresse ? errors.adresse : null}
                    error={touched.adresse && errors.adresse}
                    onChange={e => this.changeValue("adresse", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="code_postal"
                    value={values.code_postal}
                    label="Code postal"
                    name="code_postal"
                    type="text"
                    required
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.code_postal && touched.code_postal ? errors.code_postal : null}
                    error={touched.code_postal && errors.code_postal}
                    onChange={e => this.changeValue("code_postal", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="ville"
                    value={values.ville}
                    label="Ville"
                    name="ville"
                    type="text"
                    required
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.ville && touched.ville ? errors.ville : null}
                    error={touched.ville && errors.ville}
                    onChange={e => this.changeValue("ville", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="surface_totale_actif"
                    value={values.surface_totale_actif}
                    label="Surface totale actif"
                    name="surface_totale_actif"
                    type="text"
                    required
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.surface_totale_actif && touched.surface_totale_actif
                        ? errors.surface_totale_actif
                        : null
                    }
                    error={touched.surface_totale_actif && errors.surface_totale_actif}
                    InputProps={{
                      inputComponent: M2FormatCustom,
                    }}
                    onChange={e => this.changeValue("surface_totale_actif", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="nombre_parking_ext"
                    value={values.nombre_parking_ext}
                    label="Nbre parking extérieur"
                    name="nombre_parking_ext"
                    type="text"
                    required
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.nombre_parking_ext && touched.nombre_parking_ext ? errors.nombre_parking_ext : null
                    }
                    error={touched.nombre_parking_ext && errors.nombre_parking_ext}
                    onChange={e => this.changeValue("nombre_parking_ext", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="nombre_parking_int"
                    value={values.nombre_parking_int}
                    label="Nbre parking intérieur"
                    name="nombre_parking_int"
                    type="text"
                    required
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.nombre_parking_int && touched.nombre_parking_int ? errors.nombre_parking_int : null
                    }
                    error={touched.nombre_parking_int && errors.nombre_parking_int}
                    onChange={e => this.changeValue("nombre_parking_int", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="nom_expert"
                    value={values.nom_expert}
                    label="Nom de l'expert"
                    name="nom_expert"
                    type="text"
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.nom_expert && touched.nom_expert ? errors.nom_expert : null}
                    error={touched.nom_expert && errors.nom_expert}
                    onChange={e => this.changeValue("nom_expert", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="valeur_expertise"
                    value={values.valeur_expertise}
                    label="Valeur expertise"
                    name="valeur_expertise"
                    type="text"
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.valeur_expertise && touched.valeur_expertise ? errors.valeur_expertise : null
                    }
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    error={touched.valeur_expertise && errors.valeur_expertise}
                    onChange={e => this.changeValue("valeur_expertise", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="valeur_venale"
                    value={values.valeur_venale}
                    label="Valeur vénale"
                    name="valeur_venale"
                    type="text"
                    required
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    helperText={errors.valeur_venale && touched.valeur_venale ? errors.valeur_venale : null}
                    error={touched.valeur_venale && errors.valeur_venale}
                    onChange={e => this.changeValue("valeur_venale", parseInt(e.target.value, 10), false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="travaux"
                    value={values.travaux}
                    label="Travaux"
                    name="travaux"
                    type="text"
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.travaux && touched.travaux ? errors.travaux : null}
                    error={touched.travaux && errors.travaux}
                    onChange={e => this.changeValue("travaux", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="commentaire"
                    value={values.commentaire}
                    label="Commentaire"
                    name="commentaire"
                    type="text"
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.commentaire && touched.commentaire ? errors.commentaire : null}
                    error={touched.commentaire && errors.commentaire}
                    onChange={e => this.changeValue("commentaire", e.target.value, false)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justify="space-around"
                alignItems="center"
                direction="row"
                className={classes.bloc}
              >
                <Grid item>
                  <TextField
                    id="prix_net_vendeur"
                    value={values.prix_net_vendeur}
                    name="prix_net_vendeur"
                    label="Prix net vendeur"
                    type="text"
                    margin="dense"
                    required
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.prix_net_vendeur && touched.prix_net_vendeur ? errors.prix_net_vendeur : null
                    }
                    error={touched.prix_net_vendeur && errors.prix_net_vendeur}
                    className={classes.textField}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("prix_net_vendeur", e.target.value, false)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="commissions_intermediaire"
                    value={values.commissions_intermediaire}
                    name="commissions_intermediaire"
                    label="Com. d'intermédiaire"
                    type="text"
                    margin="dense"
                    required
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.commissions_intermediaire && touched.commissions_intermediaire
                        ? errors.commissions_intermediaire
                        : null
                    }
                    error={touched.commissions_intermediaire && errors.commissions_intermediaire}
                    className={classes.textField}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("commissions_intermediaire", e.target.value, false)}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    id="frais_de_notaire"
                    value={values.frais_de_notaire}
                    name="frais_de_notaire"
                    label="Frais de notaire"
                    type="text"
                    margin="dense"
                    required
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.frais_de_notaire && touched.frais_de_notaire ? errors.frais_de_notaire : null
                    }
                    error={touched.frais_de_notaire && errors.frais_de_notaire}
                    className={classes.textField}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("frais_de_notaire", e.target.value, false)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="travaux_bailleur"
                    value={values.travaux_bailleur}
                    name="travaux_bailleur"
                    label="Travaux bailleur"
                    type="text"
                    margin="dense"
                    required
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.travaux_bailleur && touched.travaux_bailleur ? errors.travaux_bailleur : null
                    }
                    error={touched.travaux_bailleur && errors.travaux_bailleur}
                    className={classes.textField}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("travaux_bailleur", e.target.value, false)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="indemnite_deviction"
                    value={values.indemnite_deviction}
                    name="indemnite_deviction"
                    label="Indemnité d'éviction"
                    type="text"
                    margin="dense"
                    required
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.indemnite_deviction && touched.indemnite_deviction
                        ? errors.indemnite_deviction
                        : null
                    }
                    error={touched.indemnite_deviction && errors.indemnite_deviction}
                    className={classes.textField}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("indemnite_deviction", e.target.value, false)}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    id="fond_commerce"
                    value={values.fond_commerce}
                    name="fond_commerce"
                    label="Fonds de commerce"
                    type="text"
                    margin="dense"
                    required
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.fond_commerce && touched.fond_commerce ? errors.fond_commerce : null}
                    error={touched.fond_commerce && errors.fond_commerce}
                    className={classes.textField}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("fond_commerce", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.pA}>
                    <NumberFormat
                      value={[
                        "prix_net_vendeur",
                        "frais_de_notaire",
                        "commissions_intermediaire",
                        "travaux_bailleur",
                        "indemnite_deviction",
                        "fond_commerce",
                      ].reduce((m, k) => m + parseFloat(values[k]), 0)}
                      displayType={"text"}
                      thousandSeparator=" "
                      prefix={"Prix de revient :"}
                      suffix={" €"}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
                    <Grid item>
                      <TextField
                        id="charge_emprunt_tmp"
                        value={values.charge_emprunt_tmp}
                        name="charge_emprunt_tmp"
                        label="Charge d'emprunt"
                        type="text"
                        margin="dense"
                        required
                        style={{ width: "150px" }}
                        fullWidth
                        onBlur={handleBlur}
                        helperText={
                          errors.charge_emprunt_tmp && touched.charge_emprunt_tmp
                            ? errors.charge_emprunt_tmp
                            : null
                        }
                        error={touched.charge_emprunt_tmp && errors.charge_emprunt_tmp}
                        className={classes.textFieldi}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={e => this.changeValue("charge_emprunt_tmp", e.target.value, false)}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={this.handleOpenSimulator}
                        style={{ marginTop: 12 }}
                        aria-label="Simuler un emprunt"
                        title="Simuler un emprunt"
                      >
                        <AssessmentIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    id="capital_reste_du_tmp"
                    value={values.capital_reste_du_tmp}
                    name="capital_reste_du_tmp"
                    label="Capital restant dû"
                    type="text"
                    margin="dense"
                    required
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.capital_reste_du_tmp && touched.capital_reste_du_tmp
                        ? errors.capital_reste_du_tmp
                        : null
                    }
                    error={touched.capital_reste_du_tmp && errors.capital_reste_du_tmp}
                    className={classes.textField}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("capital_reste_du_tmp", e.target.value, false)}
                  />
                </Grid>
                {!this.state.banqueAjoutee ? (
                  <Grid item xs={12}>
                    <Grid container justify="center" direction="row" spacing={1}>
                      <Grid item>
                        <FormControl>
                          <InputLabel htmlFor="societe-native-helper">Banque</InputLabel>
                          <Select
                            value={values.idbanque}
                            onChange={(event, key) =>
                              this.changeValue("idbanque", parseInt(event.target.value, 10))
                            }
                            input={<Input name="idbanque" id="banque-native-helper" />}
                            autoFocus
                          >
                            <MenuItem value="non_affectee" key={"a"}>
                            {"--- VIDE ---"}
</MenuItem>
                            {banques
                              .concat(this.state.banquesAjoutees)
                              .sort((b2, b1) => {
                                if (b1.nom_banque > b2.nom_banque) return -1;
                                if (b1.nom_banque < b2.nom_banque) return 1;
                                return 0;
                              })
                              .map(s => (
                                 <MenuItem value={s.idbanque} key={s.idbanque}>
                                    <img src={"data:image/jpeg;" + s.image64}  style={{height: 25, paddingTop: 5}}/>
                                    { s.nom_banque.toUpperCase()} { (values.financement === "financement" || s.idbanque === 38)? "": " en cours de sollicitation"}
                                 </MenuItem>
                              ))}
                          </Select>
                          <FormHelperText id="name-helper-text"> </FormHelperText>
                        </FormControl>
                      </Grid>
                      {user.modifier && ( 
                        <Grid item>
                          <IconButton
                            onClick={this.handleOpenBanqueDialog}
                            style={{ marginTop: 8 }}
                            aria-label="Ajouter banque"
                            title="Créer une banque"
                          >
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                    <Grid item xs={12}>
                      <Grid container direction="row" alignItems="space-between" justify="center">
                        <Grid item>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.societeAjouteeLabel}>
                              Banque
                        </Grid>
                            <Grid item className={classes.societeAjoutee}>
                              {this.state.banqueAjoutee.nom_banque}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={this.handleCancelBanqueAjoutee}
                            style={{ marginTop: 0 }}
                            aria-label="Changer banque"
                            title="Changer banque"
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
          {changed &&
            !readOnly && (
              <Grid container justify="flex-end" alignItems="center" direction="row" spacing={8}>
                <Grid item xs={2} className={classes.submitButton}>
                  <Button
                    variant="outlined"
                    disabled={isSubmitting || (values.charge_emprunt_tmp == 0 && values.capital_reste_du_tmp == 0 && values.idbanque > 0) ||  (((values.charge_emprunt_tmp > 0) || (values.capital_reste_du_tmp >0)) && (isNaN(values.idbanque) || values.idbanque == 0) ) }
                    color="textSecondary"
                    fullWidth
                    onClick={this.handleCancel}
                  >
                    Annuler
                </Button>
                </Grid>
                <Grid item xs={2} className={classes.submitButton}>
                  <Button disabled={isSubmitting || (values.charge_emprunt_tmp == 0 && values.capital_reste_du_tmp == 0 && values.idbanque >0)  ||  (((values.charge_emprunt_tmp > 0) || (values.capital_reste_du_tmp >0)) && (isNaN(values.idbanque) || values.idbanque == 0) )  } variant="contained" color="primary" fullWidth type="submit">
                    Sauvegarder
                </Button>
                </Grid>
              </Grid>
            )}
        </fieldset>
      </form>
    );
  }
}

export default withStyles(styles)(withRouter(withGlobals(ActifForm)));
