import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ActifsEmpruntMobile from "./ActifsEmpruntMobile";
import TranchesEmpruntMobile from "./TranchesEmpruntMobile";

const styles = theme => ({
  emprunt: {
    backgroundColor: "silver",
  },
  button: {
    backgroundColor: "silver",
  },
});

class EmpruntMobile extends Component {
  handleActifsClick = () => {
    const { history, emprunt, banques } = this.props;
    history.push(`/emprunts_mobile/${emprunt.idinvestissement}?view=actifs`);
  };
  render() {
    const { emprunt, banques } = this.props;
    console.log("emprunt", emprunt.idinvestissement);
    return (
      <Grid container direction="column" justify="flex-start" alignItems="stretch">
        <Grid item>
          <ActifsEmpruntMobile
            actifs={emprunt.portefeuille.actifs}
            nomPorteufeuille={emprunt.nom_portefeuille}
          />
        </Grid>
        <Grid item>
          <TranchesEmpruntMobile
            idInvestissement={emprunt.idinvestissement}
            tranches={emprunt.portefeuille.emprunts}
            banques={banques}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(EmpruntMobile));
