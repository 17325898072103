import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DownloadIcon from "@material-ui/icons/GetApp";
import withGlobals from "../withGlobals";
import { components } from "react-select";
import MySelect from "./MySelect.js";
import makeAnimated from "react-select/animated";

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: "Select all",
  value: ""
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const animatedComponents = makeAnimated();

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
    // maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  progressMsg: {
    fontSize: "1.3em",
    fontWeight: "bold",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

class FinancementCorporate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generating: false,
      generated: false,
      banques: [],
      societes: [{"idsociete":1,"filiale":false,"nom_societe":"Pardes Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null},{"idsociete":2,"filiale":false,"nom_societe":"Forum Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null}],
      selectionSociete: "",
      selectionBanque: [],
      globale: false,
      queryFilters: "",
    };
  }

  componentDidMount = () => {
    this.props.fetch({ url: `/banques/` }).then(banques => {
      this.setState({ banques });
      });
  };

  handleSubmit = () => {
    this.setState({ generating: true });
    this.props.fetch({url: `${this.props.rendererHost}/rapport_financement_corporate.pdf?action=dnld${this.state.queryFilters}`})
      .then(() => {
        this.setState({ generating: false, generated: true });
      }).catch(() => {
        this.setState({ generating: false, generated: true }); 
      });
  };

  handleReset = () => {
    this.setState({
      generating: false,
      generated: false,
      queryFilters: "",
      selectionSociete: "",
      selectionBanque: [],
    });
  };

  handleChangeBanques = e =>{
    this.setState({ selectionBanque: e, generating: false, generated: false });
   this.state.selectionBanque = e;
   }
   
  handleChangeSocietes = e =>{
    this.setState({ selectionSociete: e.value, generating: false, generated: false });
    this.state.selectionSociete = e.value;  
  }

  handleChangeCB = name => event => {
    this.setState({ [name]: event.target.checked, generating: false, generated: false });
    if (event.target.checked) {
      this.handleReset();
    }
  };



generate = () => {
  var temp = "";
  const isSelectAll =  this.state.selectionBanque.find(b => b.value === "");
  if (this.state.selectionBanque.length > 0 && isSelectAll === undefined) {
    temp += "&idbanque=(";
    for(var i =0; i < this.state.selectionBanque.length; i++){
      const banqueSelected = this.state.banques.find(b => b.nom_banque === this.state.selectionBanque[i].value);
      temp += banqueSelected.idbanque + ",";
    }
    temp += "-1)"; 
  }
  var temp2 = "";
  if (this.state.selectionSociete) {
    temp2 += "&idsociete=" + this.state.selectionSociete;
  }
  const query = temp + temp2;
  this.setState({ queryFilters: query});
  this.state.queryFilters = query;
  this.handleSubmit();
};

  generateRapporUrl = () => {
    return `${this.props.rendererHost}/rapport_vacants.pdf?action=dnld}`;
  };

  render() {
    const { classes } = this.props;
    const { societes, globale, generating, generated, selectionBanque} = this.state;
     const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export";
    return [
      <Grid container alignItems="flex-start" justify="flex-start" direction="column">
	</Grid>,
      generating ? <Grid item style={{ visibility: generating ? "visible" : "hidden" }}>
        <Grid container alignItems="center" justify="center" direction="row">
          <Grid item className={classes.progressMsg}>
            Generation du rapport...
          </Grid>
          <Grid item>
            <CircularProgress className={classes.progress} size={50} />
          </Grid>
        </Grid>
      </Grid> : null,
      generated ? (
        <Grid item>
          <Button color="default" variant="raised" href={`${rapportsUrl}/financement_corporate.pdf`} target="__blank">
            <DownloadIcon />Télécharger le rapport
          </Button>
        </Grid>
      ) : null,
      generated ? (
        <Grid item style={{ marginTop: "4em" }}>
          <Button fullWidth color="primary" variant="raised" onClick={this.handleReset}>
            Nouveau rapport
          </Button>
        </Grid>
      ) : null,
!generated && !generating ? (
  <Grid container alignItems="right" justify="right" direction="row">
          <Grid item style={{left: "8px", position: "relative"}}>
            <FormControlLabel
                control={
                <Checkbox
                  checked={this.state.globale}
                  onChange={this.handleChangeCB("globale")}
                  value="globale"
                  color="primary"
                />
              }
              label="Globale"
            />
          </Grid>
          </Grid>
        ) : null,
!generated && !generating ? (
  <Grid>
  <Grid container alignItems="right" justify="right" direction="row">
    <Grid item xs={3}>
      <Grid item>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-banques">Société</InputLabel>
          <Select
              placeholder="Sociétés"
              value={societes.map((x) =>  { return { value: x.idsociete, label: x.nom_societe }; }).label}
              name="selectionSociete"
              onChange={this.handleChangeSocietes}
              options={societes.map((x) =>  { return { value: x.idsociete, label: x.nom_societe }; })}                
            />
        </FormControl>
      </Grid>
    </Grid>
    </Grid>
    <Grid container alignItems="right" justify="right" direction="row">
    <Grid item xs={3}>
      <Grid item>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="select-multiple-banques">Données de la banque</InputLabel>
          <MySelect
            placeholder="Banques"
            options={this.state.banques.map((x) =>  { return { value: x.nom_banque, label: x.nom_banque }; })}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
              MultiValue,
              ValueContainer,
              animatedComponents
            }}
            onChange={this.handleChangeBanques}
            allowSelectAll={true}
            value={selectionBanque}
          />
        </FormControl>
      </Grid>
    </Grid>
    </Grid>
    </Grid>
        ) : null,
        !generated && !generating ? (
          <Grid item style={{ marginTop: "4em" }}>
            <Button color="primary" variant="raised" onClick={this.generate}>
              Générer le rapport
            </Button>
          </Grid>
        ) : null
    ];
  }
}

export default withStyles(styles, { withTheme: true })(withGlobals(FinancementCorporate));
