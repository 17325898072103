import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../../pages/withGlobals";
import headerStyle from "../../common/tableHeaderStyle";
import ReactTable from "react-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseLogo from "./close.png";


class DialogControleEmpruntsFinanceActive extends React.Component {
  state = {
    dialogOpen: true,
    empruntsFinanceActive: [],
    empruntsFinanceActiveTemp: [],
    madarDatas: [],
    controlesUpdated: true
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
    window.location = "/";
  };


  componentDidMount = () => {
     this.getFinanceActivData();
  };

  getFinanceActivData() {
    this.props
    .fetch({
      url: `https://madar-patrimoine.2m-advisory.fr/pdf/finance-active`,
      method: "GET"
    })
    .then(data => {
      this.setState({empruntsFinanceActiveTemp: data._embedded.transactions});
      this.getData();
    })
    .catch(err => this.getData());
  }

  getData(afterUpdate) {
    this.props
    .fetch({
      url: "/banques/",
      method: "POST",
      body: {
        correspondance_finance_active_madar: "GET"
      }
    })
    .then(parametrage => {
      this.setState({madarDatas: parametrage});
      const datass = this.state.empruntsFinanceActiveTemp.filter(emp => !this.state.madarDatas.some(mData => mData.id_finance_active === emp.object_id));
      this.setState({empruntsFinanceActive: datass});
      if (afterUpdate) {
        this.setState({controlesUpdated: true});
        this.props.openNotification("success", "Correspndances manquantes modifiées avec succès !", false)
      }
    })
    .catch(err => console.log(err));
  }

  handleChangeIdTrancheMadar = (e, index) => {
    this.state.empruntsFinanceActive[index]['id_tranche_madar'] = parseInt(e.target.value.trim());
  };

  handleSave = () => {
    this.setState({controlesUpdated: false});
    this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          empruntsFinanceActiveDatas: this.state.empruntsFinanceActive,
          empruntsFinanceActive: "oui"
        }
      })
      .then(res => {
        this.getData(true);
      })
      .catch(err =>  {
        this.getData(true);
      });
  };

  render() {
    let closeImg = {cursor:'pointer', float:'right', marginTop: '5px', width: '20px'};
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">Contrôle des emprunts Finance Active <img onClick={this.handleCloseDialog} src={CloseLogo} style={closeImg}/></DialogTitle>
          <DialogContent>
          <Grid item xs={12}>
          <h4>Liste des emprunts Finance Active manquants dans MADAR Patrimoine</h4>
          
			</Grid>
          <Grid item xs={12}>
      <ReactTable
        data={this.state.empruntsFinanceActive}
        columns={[
          {
            Header: "ID TRANSACTION FINANCE ACTIVE",
            accessor: "object_id",
            Cell: row => <span style={{ lineHeight: "40px", position:"relative" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          },
          {
            Header: "ID Tranche MADAR",
            accessor: "id_tranche_madar",
            headerStyle: { ...headerStyle, textAlign: "center" },
            Cell: row => { return ( <span>
             <TextField
              type="number"
              key={'id_tranche_madar' + row.index} 
              defaultValue={row.original.code_immeuble_altaix}
              style={{ lineHeight: "40px" , border: "5px solid transparent"}}
              name={'id_tranche_madar' + row.index}
              onChange={e => this.handleChangeIdTrancheMadar(e, row.index)}
               />
            </span>
            );
            }
          },
          {
            Header: "Libellé emprunt",
            accessor: "party.label",
            Cell: row => <span style={{ lineHeight: "40px", position:"relative" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          },
          {
            Header: "Banque",
            accessor: "counterparty.label",
            Cell: row => <span style={{ lineHeight: "40px", position:"relative" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          }
          
        ]}
        noDataText="Aucune donnée"
        showPagination={true}
        defaultPageSize={this.state.empruntsFinanceActive.length > 9 ? 10 : this.state.empruntsFinanceActive.length}
        showPageSizeOptions={false}
        className="-striped -highlight noScroll"
        key={this.state.empruntsFinanceActive.length > 9 ? 10 : this.state.empruntsFinanceActive.length}
      />
		</Grid>

            <DialogActions>
            {this.state.controlesUpdated && <Button onClick={this.handleCloseDialog}>
                Annuler
              </Button> }
              {this.state.controlesUpdated && <Button
                onClick={this.handleSave}
                variant="raised"
                color="primary"
              >
                Valider ce paramètrage
              </Button> }
              {!this.state.controlesUpdated &&
              <Grid container alignItems="center" justify="center" direction="row">
              <Grid item >
                Mise à jour encours...
              </Grid>
              <Grid item>
                <CircularProgress size={50} />
              </Grid>
            </Grid>
              }
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withGlobals(DialogControleEmpruntsFinanceActive);

