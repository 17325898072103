import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import withGlobals from "../withGlobals";

class SurfacesTotales extends React.Component {
  state = {
    local: null,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.localId !== nextProps.localId) {
      this.loadDatas(nextProps.localId);
    }
  }

  componentDidMount = () => {
    this.loadDatas(this.props.localId);
  };

  loadDatas = localId => {
    this.props.fetch({ url: `/locaux/${localId}` }).then(local => {
      if (local.surface_totale <= local.surface_louee_totale && this.props.edit==false)  {
          alert("Toute la surface du local à été louée. \nImpossible de créer un nouveau bail");
          this.props.history.push('/baux');
      } 

      this.props
        .fetch({ url: `/actifs/${local.idactifpatrimonial}` })
        .then(actif => this.setState({ local, actif }));
    });
  };

  calculeSurfaceOccuppeLocal = ({ surface_rdc, surface_etage, surface_sous_sol }) => {
    const total = parseInt(surface_rdc, 10) + parseInt(surface_etage, 10) + parseInt(surface_sous_sol, 10);
    return total;
  };

  render() {
    const { local, actif } = this.state;
    if (!local && !actif) return null;

    const { adresse, code_postal, ville } = actif;

    return (
      <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={16}>
        <Grid item>
          <Grid container direction="column" alignItems="flex-start" justify="center" spacing={0}>
            <Grid item>
              <Typography color="primary">{this.props.localId}</Typography>
            </Grid>
            <Grid item>
              <Typography color="primary">
                {adresse} {code_postal} {ville}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="flex-start" justify="center" spacing={0}>
            <Grid item>
              <Typography color="primary">
                {`Surface de l'actif : ${this.state.actif.surface_totale_actif} m²`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="primary">
                {`Surface du local : ${this.calculeSurfaceOccuppeLocal(local)} m²`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withGlobals(SurfacesTotales);
