import moment from "moment";

export default (template, data) => {
    switch (template) {
        case 'actif':
            return {
                nom_societe: ((societes) => {
                    return societes.find(s => s.idsociete === data.idsociete).nom_societe.toUpperCase()
                })(data.societes),
                date_acquisition: (date => moment(date).format("DD/MM/YYYY"))(data.date_acquisition),
                adresse: data.adresse,
                code_postal: data.code_postal,
                ville: data.ville,
                surface_totale_actif: data.surface_totale_actif,
                nombre_parking_ext: data.nombre_parking_ext,
                nombre_parking_int: data.nombre_parking_int,
                nom_expert: data.nom_expert,
                valeur_expertise: data.valeur_expertise,
                valeur_venale: data.valeur_venale || 0,
                travaux: data.travaux,
                commentaire: data.commentaire,
                prix_net_vendeur: data.prix_net_vendeur,
                commissions_intermediaire: data.commissions_intermediaire,
                frais_de_notaire: data.frais_de_notaire,
                travaux_bailleur: data.travaux_bailleur,
                indemnite_deviction: data.indemnite_deviction,
                fond_commerce: data.fond_commerce,
                prix_de_revient: (
                    ({
                        prix_net_vendeur,
                        frais_de_notaire,
                        commissions_intermediaire,
                        travaux_bailleur,
                        indemnite_deviction,
                        fond_commerce
                    }) => {
                        return [
                            prix_net_vendeur,
                            frais_de_notaire,
                            commissions_intermediaire,
                            travaux_bailleur,
                            indemnite_deviction,
                            fond_commerce
                        ].reduce((m, k) => m + parseFloat(k));
                    }
                )(data),
                charge_emprunt_tmp: data.charge_emprunt_tmp,
                capital_reste_du_tmp: data.capital_reste_du_tmp,
                nom_banque: data.banque,
            };
        case 'bail':
            return {
                adresse: (({ adresse, code_postal, ville }) => {
                    return `${adresse}, ${code_postal} ${ville}`;
                })(data),
                surface_totale_actif: data.surface_totale_actif,
                id: data.idbail,
                etage: data.etage,
                nom_du_locataire: data.nom_du_locataire,
                nom_commercial: data.nom_commercial,
                activite: data.activite,
                destination_bail: data.destination_bail,
                duree: data.type_de_bail,
                type: (({ duree_bail }) => {
                    switch (duree_bail) {
                        case 0:
                            return 'Commercial'
                        case 1:
                            return 'Professionnel'
                        case 2:
                            return 'Dérogatoire'
                        case 3:
                            return 'Vacant'
						case 3:
							return 'Habitation'
                        default:
                            return '-'
                    }
                })(data),
                date_signature_bail: (date => date ? moment(date).format("DD/MM/YYYY") : '-')(data.date_signature_bail),
                date_dentree_du_locataire: (date => date ? moment(date).format("DD/MM/YYYY") : '-')(data.date_dentree_du_locataire),
                date_effet_du_bail: (date => date ? moment(date).format("DD/MM/YYYY") : '-')(data.date_effet_du_bail),
                duree_preavis: data.duree_preavis,
                date_effective_depart: (date => date ? moment(date).format("DD/MM/YYYY") : '-')(data.date_effective_depart),
                date_fin_bail: (date => date ? moment(date).format("DD/MM/YYYY") : '-')(data.date_fin_bail),
                loyer_contractuel: data.loyer_contractuel,
                loyer_actuel: data.loyer_contractuel,
                valeur_locative: data.valeur_locative,
                frais_gestion: data.frais_gestion,
                charges_refacturees: data.charges_refacturees,
                depot_de_garantie: data.depot_de_garantie,
            };
        case 'local':
            return {
                adresse: (({ adresse, code_postal, ville }) => {
                    return `${adresse}, ${code_postal} ${ville}`;
                })(data),
                surface_totale_actif: data.surface_totale_actif,
                id: data.idlocal,
                surface_rdc: data.surface_rdc,
                surface_sous_sol: data.surface_sous_sol,
                surface_etage: data.surface_etage,
                etage: data.etage,
                nombre_parking_ext: data.nombre_parking_ext,
                nombre_parking_int: data.nombre_parking_int,
                surface_totale_local: (({ surface_etage, surface_sous_sol, surface_rdc }) => {
                    return parseInt(surface_etage, 10) + parseInt(surface_sous_sol, 10) + parseInt(surface_rdc, 10);
                })(data),
                licence4: data.licence4 ? 'Oui' : 'Non',
                extraction: data.extraction ? 'Oui' : 'Non',
                restauration: data.restauration ? 'Oui' : 'Non',
                forbidden: data.activite_interdite || 'Non',
            };
        default:
            console.error(`Invalid type ${data.type} for email formatter`);
    }
}
