import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import NumberFormat from "react-number-format";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import withGlobals from "../withGlobals";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LogoArrow from "../arrowleft.png";


const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 1,
      paddingBottom: theme.spacing.unit * 2
    },
    info: {
      textAlign: "left",
      fontSize: "0.9em"
    },
    pA: {
      textAlign: "center",
      borderTop: "solid 1px silver",
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit
    },
    commentaire: {
      textAlign: "center",
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      backgroundColor: "silver"
    },
    textR: {
      textAlign: "right"
    }
  };
};

const durees = ["Commercial", "Professionnel", "Dérogatoire", "Vacant"];

class Bail extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    bail: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      oneItem: false,
      bail: props.bail,
      societe: null,
      actif: null,
      typefinancements: []
    };
  }

  componentWillReceiveProps = nextProps => {
    if (!this.props.bailId !== nextProps.bailId && nextProps.bailId) {
      var idForSearch = nextProps.bailId;
    if (nextProps.bailId.includes('+')) {
      idForSearch = idForSearch.substr(1);
      this.state.oneItem = true;
     }  else {
      this.state.oneItem = false;
}

      this.props
        .fetch({ url: `/baux/${idForSearch}?bailactif=true&polou=lom` })
        .then(bail => {
this.setState({ bail });
                 this.props.fetch({ url: `/actifs/${bail.idactifpatrimonial}` }).then(actif => {
                        this.setState({ actif });
                });
        }
);
    }
  };

  handleFinancer = index => {
    const { history } = this.props;
    const {actif} = this.state;
    if(!actif.financement){
    history.push(`/emprunts/nouveau?actifId=${actif.idactifpatrimonial}`);
} else {
    history.push(`/emprunts_mobile/${actif.idinvestissement}`);
}
  };


  navigateToLocaux = () => {
    const { bail, history } = this.props;
    history.push(`/locaux_mobile?bailId=${bail.idbail}`);
  };

  render() {
    const { classes } = this.props;
    const { bail } = this.state;

    if (!this.state.bail) {
      return (
        <Grid container alignItems="center" direction="row" justify="center">
          <Grid item>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      );
    }

    const paliers = JSON.parse(bail.paliers);

    return (
      <Grid container alignItems="stretch" direction="column" justify="center">
        <Paper elevation={3} className={classes.root}>
          <Grid item xs={12}>
            <div style={{ marginBottom: "8px" }}>
<div className={classes.info}  style={{marginTop: "-8px", float:"left"}} >
<Button color="primary" title="Précédent" onClick={() => { if (this.props.localId) { this.props.history.push(`/locaux_mobile/${this.props.localId}`);} else if (this.state.oneItem == true) { this.props.history.push(`/baux_mobile`); } else { this.props.history.goBack(); } }}>
        <img src={LogoArrow} alt="Précédent" width="30px" />
</Button>
</div>
              <Typography variant="body1">
                {bail.adresse}
                <br />
                {bail.code_postal} {bail.ville}
              </Typography>
            </div>
            <div className={classes.info}>
              Id local {" : "}
              <strong> {bail.idlocal}</strong>
              <br />
              Étage {" : "}
              <strong>{bail.etage}</strong>
              <br />
              <br />
              <div className={classes.commentaire}>Locataire</div>
              <br />
              Nom du locataire {" : "}
              <strong>{bail.nom_du_locataire}</strong>
              <br />
              Nom commercial {" : "}
              <strong>{bail.nom_commercial}</strong>
              <br />
              Activite {" : "}
              <strong>{bail.activite}</strong>
              <br />
              Destination bail {" : "}
              <strong>{bail.destination_bail}</strong>
              <br />
              <br />
              <div className={classes.commentaire}>Bail</div>
              <br />
              Durée {" : "}
              <strong>{bail.type_de_bail}</strong>
              <br />
              Type de bail {" : "}
              <strong>{durees[bail.duree_bail]}</strong>
              <br />
              Date de signature {" : "}
              <strong>
                {bail.date_signature_bail
                  ? moment(bail.date_signature_bail).format("DD/MM/YYYY")
                  : ""}
              </strong>
              <br />
              Date d'entrée {" : "}
              <strong>
                {bail.date_dentree_du_locataire
                  ? moment(bail.date_dentree_du_locataire).format("DD/MM/YYYY")
                  : ""}
              </strong>
              <br />
              Date d'effet' {" : "}
              <strong>
                {bail.date_dentree_du_locataire
                  ? moment(bail.date_effet_du_bail).format("DD/MM/YYYY")
                  : ""}
              </strong>
              <br />
              Durée préavis {" : "}
              <strong>{bail.duree_preavis} mois</strong>
              <br />
              Date d effective de départ {" : "}
              <strong>
                {bail.date_effective_depart
                  ? moment().format("DD/MM/YYYY")
                  : ""}
              </strong>
              <br />
              Date de fin {" : "}
              <strong>
                {bail.date_fin_bail
                  ? moment(bail.date_fin_bail).format("DD/MM/YYYY")
                  : ""}
              </strong>
              <br />
              <br />
              <div className={classes.commentaire}>Local</div>
              <br />
              Loyer contractuel{" : "}
              <strong>
                {" "}
                <NumberFormat
                  value={bail.loyer_contractuel}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Loyer actuel{" : "}
              <strong>
                {" "}
                <NumberFormat
                  value={bail.loyer_actuel}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Valeur locative{" : "}
              <strong>
                <NumberFormat
                  value={bail.valeur_locative}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Frais de gestion{" : "}
              <strong>{bail.frais_gestion} %</strong>
              <br />
              Charges refacturées...{" : "}
              <strong>
			   {bail.charges_refacturees}
              </strong>
              <br />
              Dépot de garantie{" : "}
              <strong>
                {" "}
                <NumberFormat
                  value={bail.depot_de_garantie}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Indice de référence{" : "}
              <strong>{bail.indice_reference}</strong>
              <br />
              Trimestre{" : "}
              <strong>{bail.trimestre}</strong>
              <br />
              Année{" : "}
              <strong>{bail.annee}</strong>
              <br />
              Trimestre <strong>{bail.trimestre}</strong>
              <br />
              Tunnel d'indexation{" : "}
              <strong>{bail.tunnel_indexation}</strong>
              <br />
              {bail.caution_garantie_premiere_demande !== 0 && [
                "Caution garantie premiere demande : ",
                <strong>
                  <NumberFormat
                    value={bail.caution_garantie_premiere_demande}
                    displayType={"text"}
                    thousandSeparator=" "
                    suffix={" €"}
                  />
                </strong>,
                <br />
              ]}
              {bail.caution_garantie_solvabilite !== 0 && [
                "Caution garantie solvabilité : ",
                <strong>
                  <NumberFormat
                    value={bail.caution_garantie_solvabilite}
                    displayType={"text"}
                    thousandSeparator=" "
                    suffix={" €"}
                  />
                </strong>,
                <br />
              ]}
              {bail.caution_societe !== 0 && [
                "Caution société : ",
                <strong>
                  <NumberFormat
                    value={bail.caution_societe}
                    displayType={"text"}
                    thousandSeparator=" "
                    suffix={" €"}
                  />
                </strong>,
                <br />
              ]}
              {bail.caution_perso !== 0 && [
                "Caution perso : ",
                <strong>
                  <NumberFormat
                    value={bail.caution_perso}
                    displayType={"text"}
                    thousandSeparator=" "
                    suffix={" €"}
                  />
                </strong>,
                <br />
              ]}
              {bail.caution_bancaire !== 0 && [
                "Caution garantie premiere demande : ",
                <strong>
                  <NumberFormat
                    value={bail.caution_bancaire}
                    displayType={"text"}
                    thousandSeparator=" "
                    suffix={" €"}
                  />
                </strong>,
                <br />
              ]}
              <br />
              <div className={classes.commentaire}>Affectations</div>
              <br />
              Commerce <strong>{bail.affectation_commerce} m²</strong>
              <br />
              Réserve <strong>{bail.affectation_reserve} m²</strong>
              <br />
              Bureau <strong>{bail.affectation_bureau} m²</strong>
              <br />
              Stockage <strong>{bail.affectation_stockage} m²</strong>
              <br />
              Réserve sous-sol{" "}
              <strong>{bail.affectation_res_sous_sol} m²</strong> <br />
              Commerce sous-sol{" "}
              <strong>{bail.affectation_comm_sous_sol} m²</strong>
              <br />
              {paliers.length > 0 && [
                <br />,
                <div className={classes.commentaire}>Paliers</div>,
                <br />,
                paliers.map(p => [
                  moment(p.date).format("DD/MM/YYYY"),
                  " : ",
                  p.montant,
                  " €"
                ])
              ]}
            </div>
          </Grid>
<br/>
<br/>
          <Grid item>
                <Button
                variant="raised"
                color="primary"
                onClick={this.handleFinancer}>
              Financement
            </Button>

          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Bail)));
