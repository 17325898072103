import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Bail from "./Bail";
import CenteredMobile from "../../layout/CenteredMobile";
import RechercheForm from "../../common/RechercheForm";
import ListItemsMobile from "../../common/ListItemsMobile";
import SwipeableViews from "react-swipeable-views";

class BauxMobile extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      index: this.findSwipeableViewsIndex(props)
    };
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps = nextProps => {
    const {
      match: {
        params: { bailId, localId }
      },
      location: { search }
    } = this.props;
    if (
      search !== nextProps.location.search ||
      bailId !== nextProps.match.params.bailId
    ) {
      this.setState({ index: this.findSwipeableViewsIndex(nextProps) });
    }
  };

  findSwipeableViewsIndex = props => {
    if (props.location.search === "" && props.match.params.bailId) {
      return 1;
    }
    if (props.location.search !== "" && !props.match.params.bailId) {
      return 0;
    }
    if (props.location.search === "" && !props.match.params.bailId) {
      return 0;
    }
  };

  render() {
    const {
      match: {
        params: { bailId, localId }
      },
      user
    } = this.props;
    if (!user || !user.login) return <h1>Acces denied</h1>;

    const { index } = this.state;
    return (
      <CenteredMobile alignItems="center" justify="center">
        <Grid item xs={12}>
          <SwipeableViews index={index} animateHeight={false}>
            <ListItemsMobile
              sameFormSearch={true}
              idField="idbail"
              idFieldName="Id du bail"
              buildPrimaryText={a => `${a.idbail} - ${a.adresse}`}
              buildSecondaryText={a =>
                `${a.code_postal} ${a.ville} - ${a.surface_totale_actif} m²`
              }
              moduleRoute="baux_mobile"
              queryPathname="/baux/"
              lastFiltre="&bailactif=true"
            />
            <div style={{ padding: "0 8px" }}>
              <Bail bailId={bailId} localId={localId} />
            </div>
          </SwipeableViews>
        </Grid>
      </CenteredMobile>
    );
  }
}

export default withRouter(BauxMobile);
