import React, { Component } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import withGlobals from "../withGlobals";

class DatasLoader extends Component {
  static propTypes = {
    empruntId: PropTypes.number.isRequired,
    children: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      emprunt: null,
      actifs: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    this.props.fetch({ url: `/portefeuilles/${this.props.empruntId}` }).then(res => {
      const { actifs, emprunts } = JSON.parse(res.investissements);
      this.props.fetch({ url: "/actifs/" }).then(tousLesActifs => {
        this.setState({
          emprunt: {
            ...res,
            nom_portefeuille: res.nom_portefeuille || "",
            tranches: emprunts.map(e => ({ ...e })),
            actifs: actifs.map(a => {
              return tousLesActifs.find(ac => {
                return ac.idactifpatrimonial === a.idactifpatrimonial;
              });
            }),
          },
          loading: false,
        });
      });
    });
  };

  findLocal = () => this.state.locaux.find(l => l.idlocal === this.props.localId);

  render() {
    const { emprunt, loading } = this.state;
    if (loading) {
      return <CircularProgress size={50} />;
    }
    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        emprunt,
      }),
    );
  }
}

export default withGlobals(DatasLoader);
