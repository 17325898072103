import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from '@material-ui/icons/History';
import DeleteIcon from "@material-ui/icons/Delete";
import NotificationIcon from "@material-ui/icons/Notifications";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import withGlobals from "./withGlobals";
import GroupBy from "lodash/groupBy";
import ConfirmWrapper from "../common/ConfirmWrapper";
import Logo from "./logo.jpg";
import Button from "@material-ui/core/Button";
import LogoForum from "./forum.png";
import LogoPardes from "./pardes.png";
import LogoPdf from "./pdf.png";
import LogoExcel from "./excel.png";
import LogoRefresh from "./refresh.png";
import LogoAssistance from "./assistance.png";
import LogoClose from "./close.png";
import { Card, Col, Row, Modal } from "antd";
import { Space } from 'antd';
import Dialog from "@material-ui/core/Dialog";
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Element from "./UtilsElement/ElementDashboard";
import ElementSynthese from "./UtilsElement/EelementSynthese";


const styles = theme => ({
title: {
marginTop: theme.spacing.unit * 4,
marginBottom: theme.spacing.unit * 4
},
container: {
height: "calc(100vh - 150px)",
overflowY: "hidden"
},
notificationContainer: {
overflowY: "auto"
},
notification: {
padding: theme.spacing.unit * 1,
backgroundColor: "#eaeaea",
boxShadow: "3px 3px 3px gray",
textAlign: "left",
marginBottom: theme.spacing.unit * 2,
minWidth: "600px;"
},
link: {
"&:hover": {
textDecoration: "none"
},
color: "black"
},
palier: {
backgroundColor: "blue"
},
taux: {
backgroundColor: "black"
},
baux: {
backgroundColor: "green"
},
preavis: {
backgroundColor: "red"
},
emprunts: {
backgroundColor: "green"
},
palierS: {
borderLeft: "4px solid blue"
},
tauxS: {
borderLeft: "4px solid black"
},
bauxS: {
borderLeft: "4px solid green"
},
preavisS: {
borderLeft: "4px solid red"
},
empruntsS: {
borderLeft: "4px solid green"
},
infoS: {
borderLeft: "4px solid brown"
},
euribor: {
border: "solid 2px black",
padding: theme.spacing.unit * 1,
marginTop: theme.spacing.unit * 2
},
excelLink: {
lineHeight: "25px",
color: "green"
},
pdfLink: {
lineHeight: "25px",
color: "red"
},
rapportLinks: {
listStyleType: "none",
padding: theme.spacing.unit * 1
},
titreHisto: {
textAlign: "center",
paddingTop: theme.spacing.unit * 1
},
table: {
color: "#333",
fontFamily: "Roboto, Helvetica, Arial, 'sans-serif'",
borderCollapse: "collapse",
borderSpacing: 0,
marginBottom: "3em",
},
td: {
border: "1px solid #CCC",
height: "30px",
background: "#FAFAFA",
textAlign: "center",
minWidth: "78px",
},
th: {
border: "1px solid #CCC",
height: "30px",
background: "#C2C2C2",
fontWeight: "bold",
textAlign: "left",
minWidth: "120px",
},
});

const colors = {
palier: "blue",
taux: "black",
baux: "green",
preavis: "red",
info: "brown"
};

/**
* Find the most recent xlsx rapport coming from /rapports/excel
* @param {[]} rapports 
*/
const getAsideRapportLink = rapports => {
return rapports.filter(rapport => rapport.file.includes('.xlsx'))
.map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
.sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
.shift();
}

const getAsideRapportSyntheseGlobalePardesLink = rapports => {
return rapports.filter(rapport => rapport.file.includes('-1.pdf'))
.map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
.sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
.shift();
}

const getAsideRapportSyntheseGlobaleGlobaleLink = rapports => {
return rapports.filter(rapport => rapport.file.includes('-.pdf'))
.map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
.sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
.shift();
}



const getAsideRapportSyntheseGlobaleForumLink = rapports => {
return rapports.filter(rapport => rapport.file.includes('2.pdf'))
.map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
.sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
.shift();
}


const getAsideRapportSyntheseCourteLink = rapports => {
return rapports.filter(rapport => rapport.file.includes('+.pdf'))
.map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
.sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
.shift();
}

const getAsideRapportSyntheseVacantsLink = rapports => {
return rapports.filter(rapport => rapport.file.includes('-SV.pdf'))
.map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
.sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
.shift();
}

const getAsideRapportCRDLink = rapports => {
return rapports.filter(rapport => rapport.file.includes('-CRD.pdf'))
.map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
.sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
.shift();
}




const titres = {
palier: "PALIERS",
baux: "BAUX",
preavis: "PRÉAVIS",
emprunts: "EMPRUNTS",
};

class ListeNotificationsMadar extends React.Component {
state = {
notifications: [],
rapports: [],
loaded: false,
historiqueOpen: false,
assistance: false,
historiquePDFSynthesePardesOpen: false,
historiquePDFSyntheseForumOpen: false,
historiquePDFSyntheseGlobaleGlobaleOpen: false,
historiquePDFSyntheseCourteOpen: false,
historiquePDFCRDOpen: false,
historiquePDFSyntheseVacantOpen: false,
texteBouton: "NOUVEAU",
texteBoutonFA: "NOUVEAU",
texteBouton2: "NOUVEAU",
refreshText: "",
dscr_actuel: "Loading...",
dscr_facial: "Loading...",
dscr_potentiel: "Loading...",
ltv: "Loading",
NetInitialYield: "Loading...",
NetPotentielYield: "Loading...",
InitialDebtYield: "Loading...",
PotentielDebtYield: "Loading...",
ICR: "Loading...",
TO_PHYSIQUE: "Loading...",
TO_FINANCIER: "Loading...",
dscr_actuelF: "Loading...",
dscr_facialF: "Loading...",
dscr_potentielF: "Loading...",
ltvF: "Loading...",
NetInitialYieldF: "Loading...",
NetPotentielYieldF: "Loading...",
InitialDebtYieldF: "Loading...",
PotentielDebtYieldF: "Loading...",
ICRF: "Loading...",
TO_PHYSIQUE_F: "Loading...",
TO_FINANCIER_F: "Loading...",
refreshStatus:false,
refreshStatusSG:false,
refreshStatusFA:false,
refreshStatusSV:false,
};

componentDidMount() {


const date_dashboard = new Date().toJSON().slice(0,10);
const saved_date = localStorage.getItem("date_dashboard");

if (date_dashboard == saved_date && false) {
this.setState({
  dscr_actuel: localStorage.getItem("dscr_actuel"),
  dscr_facial: localStorage.getItem("dscr_facial"),
  dscr_potentiel: localStorage.getItem("dscr_potentiel"),
  ltv: localStorage.getItem("ltv"),
  dscr_actuelF: localStorage.getItem("dscr_actuelF"),
  dscr_facialF: localStorage.getItem("dscr_facialF"),
  dscr_potentielF: localStorage.getItem("dscr_potentielF"),
  ltvF: localStorage.getItem("ltvF"),
NetInitialYield: localStorage.getItem("NetInitialYield"),
NetPotentielYield: localStorage.getItem("NetPotentielYield"),
InitialDebtYield: localStorage.getItem("InitialDebtYield"),
PotentielDebtYield: localStorage.getItem("PotentielDebtYield"),
ICR: localStorage.getItem("ICR"),
NetInitialYieldF: localStorage.getItem("NetInitialYieldF"),
NetPotentielYieldF: localStorage.getItem("NetPotentielYieldF"),
InitialDebtYieldF: localStorage.getItem("InitialDebtYieldF"),
PotentielDebtYieldF: localStorage.getItem("PotentielDebtYieldF"),
ICRF: localStorage.getItem("ICRF"),
TO_PHYSIQUE_F: localStorage.getItem("TO_PHYSIQUE_F"),
TO_FINANCIER_F: localStorage.getItem("TO_FINANCIER_F"),
TO_PHYSIQUE: localStorage.getItem("TO_PHYSIQUE"),
TO_FINANCIER: localStorage.getItem("TO_FINANCIER")

});
} else {

this.props.fetch({ url: "/rapports/total?idsociete=1&dashboard=1" }).then(rapports => {
this.setState({
  dscr_actuel: rapports.dscr_actuel,
  dscr_facial: rapports.dscr_facial,
  dscr_potentiel: rapports.dscr_potentiel,
  ltv: rapports.ltv,
NetInitialYield: rapports.NetInitialYield,
NetPotentielYield: rapports.NetPotentielYield,
InitialDebtYield: rapports.InitialDebtYield,
PotentielDebtYield: rapports.PotentielDebtYield,
ICR: rapports.ICR,
TO_PHYSIQUE: rapports.TO_PHYSIQUE,
TO_FINANCIER: rapports.TO_FINANCIER
});
localStorage.setItem("date_dashboard", date_dashboard);
localStorage.setItem("dscr_actuel", rapports.dscr_actuel);
localStorage.setItem("dscr_facial", rapports.dscr_facial);
localStorage.setItem("dscr_potentiel", rapports.dscr_potentiel);
localStorage.setItem("ltv", rapports.ltv);
localStorage.setItem("NetInitialYield", rapports.NetInitialYield);
localStorage.setItem("NetPotentielYield", rapports.NetPotentielYield);
localStorage.setItem("InitialDebtYield", rapports.InitialDebtYield);
localStorage.setItem("PotentielDebtYield", rapports.PotentielDebtYield);
localStorage.setItem("ICR", rapports.ICR);
localStorage.setItem("TO_PHYSIQUE", rapports.TO_PHYSIQUE);
localStorage.setItem("TO_FINANCIER", rapports.TO_FINANCIER);
});

this.props.fetch({ url: "/rapports/total?idsociete=2&dashboard=1" }).then(rapports => {
this.setState({
  dscr_actuelF: rapports.dscr_actuel,
  dscr_facialF: rapports.dscr_facial,
  dscr_potentielF: rapports.dscr_potentiel,
  ltvF: rapports.ltv,
NetInitialYieldF: rapports.NetInitialYield,
NetPotentielYieldF: rapports.NetPotentielYield,
InitialDebtYieldF: rapports.InitialDebtYield,
PotentielDebtYieldF: rapports.PotentielDebtYield,
ICRF: rapports.ICR,
TO_PHYSIQUE_F: rapports.TO_PHYSIQUE,
TO_FINANCIER_F: rapports.TO_FINANCIER

});
localStorage.setItem("dscr_actuelF", rapports.dscr_actuel);
localStorage.setItem("dscr_facialF", rapports.dscr_facial);
localStorage.setItem("dscr_potentielF", rapports.dscr_potentiel);
localStorage.setItem("ltvF", rapports.ltv);
localStorage.setItem("NetInitialYieldF", rapports.NetInitialYield);
localStorage.setItem("NetPotentielYieldF", rapports.NetPotentielYield);
localStorage.setItem("InitialDebtYieldF", rapports.InitialDebtYield);
localStorage.setItem("PotentielDebtYieldF", rapports.PotentielDebtYield);
localStorage.setItem("ICRF", rapports.ICR);
localStorage.setItem("TO_PHYSIQUE_F", rapports.TO_PHYSIQUE);
localStorage.setItem("TO_FINANCIER_F", rapports.TO_FINANCIER);

});

}

this.props.fetch({ url: "/rapports/excel" }).then(rapports => {
this.setState({
rapports: rapports.sort((r1, r2) => {
  if (r1.file > r2.file) return -1;
  if (r1.file < r2.file) return 1;
  return 0;
}),
asideRapportLink: getAsideRapportLink(rapports),
asideRapportSyntheseGlobalePardesLink: getAsideRapportSyntheseGlobalePardesLink(rapports),
asideRapportSyntheseGlobaleForumLink: getAsideRapportSyntheseGlobaleForumLink(rapports),
asideRapportSyntheseCourteLink: getAsideRapportSyntheseCourteLink(rapports),
asideRapportSyntheseVacantsLink: getAsideRapportSyntheseVacantsLink(rapports),
asideRapportCRDLink: getAsideRapportCRDLink(rapports),
asideRapportSyntheseGlobaleGlobaleLink: getAsideRapportSyntheseGlobaleGlobaleLink(rapports)
});

this.props.fetch({ url: "/notifications/" }).then(notifications => {
  
const hardcodedNotif = notifications && notifications.length && notifications.length > 0 ? notifications : [];
const notificationsGrouped = GroupBy(hardcodedNotif, "type");
const types = Object.keys(notificationsGrouped)
  .filter(k => k !== "Euribor")
  .map(k => ({
    k,
    qte: notificationsGrouped[k].length
  }))
  .sort((t2, t1) => {
    if (t1.qte > t2.qte) return 1;
    if (t1.qte < t2.qte) return -1;
    return 0;
  });
 
this.setState({
  loaded: true,
  notifications: hardcodedNotif,
  types,
  typeSelected: types && types.length && types.length > 0 && types[0].k
});
});
});
}

handleDelete = idnotification => {
const { notifications } = this.state;
this.props
.fetch({
url: `/notifications/${idnotification}`,
method: "DELETE"
})
.then(() =>
this.setState({
  notifications: notifications.filter(
    n => n.idnotification !== idnotification
  )
})
);
};

handleGenerateNewExcel = () => {
this.setState({ texteBouton: "Génération en cours...", refreshStatusSG:true });
this.props
.fetch({
url: `/banques/`,
method: "POST",
body: {
  "generateExcel": "OUI"
}
})
.then(() =>
this.setState({ texteBouton: "NOUVEAU", refreshStatusSG:false })
);
};

handlMajFinanceActive = () => {
  this.setState({ texteBoutonFA: "MAJ Finance Active en cours...", refreshStatusFA:true });
  this.props
  .fetch({
  url: `/banques/`,
  method: "POST",
  body: {
    "majAllFinActive": "majAllFinActive"
  }
  })
  .then(() =>
  this.setState({ texteBoutonFA: "NOUVEAU", refreshStatusFA:false })
  );
  };

handleGenerateNewSV = () => {
this.setState({ texteBouton2: "Génération en cours...", refreshStatusSV:true });
this.props
.fetch({
url: `/pdfs/rapport_vacants_s.pdf?action=dnld`,
method: "GET"
})
.then(() =>
this.setState({ texteBouton2: "NOUVEAU", refreshStatusSV:false })
);
};



handleGenerateNewExcelWithRefresh = () => {
  this.setState({ refreshText: "Calcul en cours...", refreshStatus:true });
  setTimeout(function(){window.location.reload();}, 180000);
  
    
    this.props.fetch({ url: "/rapports/total?idsociete=2" }).then(rapports => {
    this.setState({
      dscr_actuelF: rapports.dscr_actuel,
      dscr_facialF: rapports.dscr_facial,
      dscr_potentielF: rapports.dscr_potentiel,
      ltvF: rapports.ltv,
    NetInitialYieldF: rapports.NetInitialYield,
    NetPotentielYieldF: rapports.NetPotentielYield,
    InitialDebtYieldF: rapports.InitialDebtYield,
    PotentielDebtYieldF: rapports.PotentielDebtYield,
    ICRF: rapports.ICR,
    TO_PHYSIQUE_F: rapports.TO_PHYSIQUE,
    TO_FINANCIER_F: rapports.TO_FINANCIER
    });

    this.props.fetch({ url: "/rapports/total?idsociete=1" }).then(rapports => {
      this.setState({
        dscr_actuel: rapports.dscr_actuel,
        dscr_facial: rapports.dscr_facial,
        dscr_potentiel: rapports.dscr_potentiel,
        ltv: rapports.ltv,
      NetInitialYield: rapports.NetInitialYield,
      NetPotentielYield: rapports.NetPotentielYield,
      InitialDebtYield: rapports.InitialDebtYield,
      PotentielDebtYield: rapports.PotentielDebtYield,
      ICR: rapports.ICR,
      TO_PHYSIQUE: rapports.TO_PHYSIQUE,
      TO_FINANCIER: rapports.TO_FINANCIER
      });
      // window.location.reload();
  
      this.setState({ refreshText: "", refreshStatus:false });
      });
    
    });
  };
  

buildRapportSyntheseGlobalePardesLink = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-1.pdf")){
	  return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-1.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
  <>
<Col className="dashboard-synthese-style-one">
<a
className={this.props.classes.pdfLink} href={`${rapportsUrl}/${rapport.file}`}
>
<span  className="dashboard-synthese-grid"> Synthèse Globale Pardes  {displayDate?  moment(split[2]).format("DD/MM/YYYY") : ''}</span>
</a>
</Col>
</>
);
};

buildRapportSyntheseGlobaleGlobaleLink = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-.pdf")){
          return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
  <>
<Col className="dashboard-synthese-style-one">
<a className={this.props.classes.pdfLink}
href={`${rapportsUrl}/${rapport.file}`}
>
  <span className="dashboard-synthese-grid"> Synthèse Globale  {displayDate?  moment(split[2]).format("DD/MM/YYYY") : ''}</span>
</a>
</Col>

</>
);
};



buildRapportSyntheseVacantsLink = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-SV.pdf")){
	  return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-SV.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
  <>
<Col  className="dashboard-synthese-style-one">
<a className={this.props.classes.pdfLink} href={`${rapportsUrl}/${rapport.file}`}
>
<span className="dashboard-synthese-grid"> Synthèse des Vacants  {displayDate? moment(split[2]).format("DD/MM/YYYY") : ''}</span>
</a>
</Col>

</>
);
};

buildRapportCRDLink = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-CRD.pdf")){
	  return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-CRD.pdf", "")
.replace("_", " ")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
  <>
<Col className="dashboard-synthese-style-one">
<a className={this.props.classes.pdfLink} href={`${rapportsUrl}/${rapport.file}`} >
<span className="dashboard-synthese-grid"> CRD  {displayDate? moment(split[3]).format("DD/MM/YYYY") : ''}</span>
</a>
</Col>
</>
);
};



buildRapportSyntheseGlobaleForumLink = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-2.pdf")){
	  return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-2.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
  <>
<Col className="dashboard-synthese-style-one">
<a className={this.props.classes.pdfLink} href={`${rapportsUrl}/${rapport.file}`} >
<span className="dashboard-synthese-grid"> Synthèse Globale Forum  {displayDate?  moment(split[2]).format("DD/MM/YYYY") : ''}</span>
</a>
</Col>
</>
);
};



buildRapportSyntheseCourteLink = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("+.pdf")){
	  return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("+.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
<>
<Col className="dashboard-synthese-style-one">
<a className={this.props.classes.pdfLink} href={`${rapportsUrl}/${rapport.file}`}>
  <span className="dashboard-synthese-grid"> Synthèse Courte {displayDate? moment(split[2]).format("DD/MM/YYYY") : ''}</span>
</a>
</Col>
</>
);
};


buildRapportLink = (rapport, displayPhoto, displayDate) => {
  if(rapport.file.includes(".pdf") || rapport.file.includes(".log")){
	  return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace(".xlsx", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
<>
<Row >
<Col className="dashboard-synthese-style-one">
  <a href={`${rapportsUrl}/${rapport.file}`}>
    <span className="dashboard-synthese-grid">Synthèse Globale  {displayDate? moment(split[2]).format("DD/MM/YYYY") : ''}</span>
  </a>
</Col>
<Col className="dashboard-synthese-style-two">
  <span>{displayPhoto? <img src={LogoExcel} alt="Synthèse globale" width="20px" /> : ''}</span> 
</Col>
</Row>

</>

);
};



buildRapportSyntheseGlobalePardesLink2 = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-1.pdf")){
          return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-1.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
<div align="center"  style={{width:"240px"}}>
<a
className={this.props.classes.pdfLink}
// config url du site madar
href={`${rapportsUrl}/${rapport.file}`}
>
{displayPhoto? <img src={LogoPdf} alt="Synthèse globale" width="30px" /> : ''}
Synthèse Globale Pardes  {displayDate?  moment(split[2]).format("DD/MM/YYYY") : ''}
</a>
</div>
);
};

buildRapportSyntheseGlobaleGlobaleLink2 = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-.pdf")){
          return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
<div align="center"  style={{width:"240px"}}>
<a
className={this.props.classes.pdfLink}
// config url du site madar
href={`${rapportsUrl}/${rapport.file}`}
>
{displayPhoto? <img src={LogoPdf} alt="Synthèse globale" width="30px" /> : ''}
Synthèse Globale  {displayDate?  moment(split[2]).format("DD/MM/YYYY") : ''}
</a>
</div>
);
};



buildRapportSyntheseVacantsLink2 = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-SV.pdf")){
          return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-SV.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
<div align="center"  style={{width:"240px"}}>
<a
className={this.props.classes.pdfLink}
// config url du site madar
href={`${rapportsUrl}/${rapport.file}`}
>
{displayPhoto? <img src={LogoPdf} alt="Synthèse globale" width="30px" /> : ''}
 Synthèse des Vacants  {displayDate? moment(split[2]).format("DD/MM/YYYY") : ''}
</a>
</div>
);
};

buildRapportCRDLink2 = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-CRD.pdf")){
          return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-CRD.pdf", "")
.replace("_", " ")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
<div align="center" style={{width:"240px"}}>
<a
className={this.props.classes.pdfLink}
// config url du site madar
href={`${rapportsUrl}/${rapport.file}`}
>
{displayPhoto? <img src={LogoPdf} alt="Synthèse globale" width="30px" /> : ''}
CRD  {displayDate? moment(split[3]).format("DD/MM/YYYY") : ''}
</a>
</div>
);
};



buildRapportSyntheseGlobaleForumLink2 = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("-2.pdf")){
          return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("-2.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
<div align="center" style={{width:"240px"}}>
<a
className={this.props.classes.pdfLink}
// config url du site madar
href={`${rapportsUrl}/${rapport.file}`}
>
{displayPhoto? <img src={LogoPdf} alt="Synthèse globale" width="30px" /> : ''}
Synthèse Globale Forum  {displayDate?  moment(split[2]).format("DD/MM/YYYY") : ''}
</a>
</div>
);
};



buildRapportSyntheseCourteLink2 = (rapport, displayPhoto, displayDate) => {
  if(!rapport.file.includes("+.pdf")){
          return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace("+.pdf", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
<div align="center" style={{width:"240px"}}>
<a
className={this.props.classes.pdfLink}
// config url du site madar
href={`${rapportsUrl}/${rapport.file}`}
>
 {displayPhoto? <img src={LogoPdf} alt="Synthèse globale" width="30px" /> : ''}
 Synthèse Courte {displayDate? moment(split[2]).format("DD/MM/YYYY") : ''}
</a>
</div>
);
};


buildRapportLink2 = (rapport, displayPhoto, displayDate) => {
  if(rapport.file.includes(".pdf") || rapport.file.includes(".log")){
          return;
  }
const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
const split = rapport.file
.replace(".xlsx", "")
.replace("_", " ")
.replace("_", " ")
.split(" ");


return (
<div align="center" style={{width:"240px"}}>
<a
className={this.props.classes.excelLink}
// config url du site madar
href={`${rapportsUrl}/${rapport.file}`}
>
{displayPhoto? <img src={LogoExcel} alt="Synthèse globale" width="30px" /> : ''}
Synthèse Globale  {displayDate? moment(split[2]).format("DD/MM/YYYY") : ''}
</a>
</div>
);
};
             

handleSelectType = typeSelected => this.setState({ typeSelected });

openDrawer = () => this.setState({ historiqueOpen: true });

openAssistance = () => this.setState({ assistance: !this.state.assistance });

closeDrawer = () => this.setState({ historiqueOpen: false });

closeDrawerCRD = () => this.setState({ historiquePDFCRDOpen: false});

openDrawerSyntheseGlobalePardes = () => this.setState({ historiquePDFSynthesePardesOpen: true });

closeDrawerSyntheseGlobalePardes = () => this.setState({ historiquePDFSynthesePardesOpen: false });

openDrawerSyntheseGlobaleForum = () => this.setState({ historiquePDFSyntheseForumOpen: true });

openDrawerSyntheseGlobaleGlobale = () => this.setState({ historiquePDFSyntheseGlobaleGlobaleOpen: true });

closeDrawerSyntheseGlobaleGlobale = () => this.setState({ historiquePDFSyntheseGlobaleGlobaleOpen: false });

closeDrawerSyntheseGlobaleForum = () => this.setState({ historiquePDFSyntheseForumOpen: false });

openDrawerSyntheseCourte = () => this.setState({ historiquePDFSyntheseCourteOpen: true });

closeDrawerSyntheseCourte = () => this.setState({ historiquePDFSyntheseCourteOpen: false });

openDrawerSyntheseVacants = () => this.setState({ historiquePDFSyntheseVacantOpen: true });

closeDrawerSyntheseVacants = () => this.setState({ historiquePDFSyntheseVacantOpen: false });

openDrawerCRD = () => this.setState({ historiquePDFCRDOpen: true });

closeDrawerSyntheseCRD = () => this.setState({ historiquePDFCRDOpen: false });



render() {
const { classes, readOnly } = this.props;
const {
types,
typeSelected,
notifications,
rapports,
rapportsPDFGlobal,
loaded,
historiqueOpen,
assistance,
historiquePDFSynthesePardesOpen,
historiquePDFSyntheseForumOpen,
historiquePDFSyntheseCourteOpen,
historiquePDFCRDOpen,
historiquePDFSyntheseVacantOpen,
historiquePDFCourteOpen,
historiquePDFSyntheseGlobaleGlobaleOpen,
asideRapportLink,
asideRapportSyntheseGlobaleForumLink,
asideRapportSyntheseGlobalePardesLink,
asideRapportCRDLink,
asideRapportSyntheseGlobaleGlobaleLink,
asideRapportSyntheseVacantsLink,
asideRapportSyntheseCourteLink,
} = this.state;

if (!loaded) {
return (
<Grid
  container
  direction="column"
  alignItems="center"
  justify="center"
  spacing={0}
>
  <Grid item className={classes.title}>
    <Typography variant="headline">Synthèse au {moment().format("DD/MM/YYYY")}</Typography>
    <br />
    <CircularProgress size={50} />
  </Grid>
</Grid>
);
}
const majTaux = notifications
.filter(n => n.type === "Euribor")
.sort((n1, n2) => {
if (n1.idnotification > n2.idnotification) return -1;
if (n1.idnotification < n2.idnotification) return 1;
return 0;
});
if (majTaux && majTaux.length > 0) {

}
const euribor = majTaux.length > 0 ? JSON.parse(majTaux[0].message) : null;
euribor.date_notification = moment(majTaux[0].date_notification).format("DD/MM/YYYY");
const selectedNotifications = notifications
.filter(n => n.type !== "Euribor")
.filter(n => n.type === typeSelected);
console.log("notification", typeSelected);
return (
  <>  


<div className="container-acceuil">
  
  <Row>
    <Col className="card-data-pardes-dashboard">
        <Row className="card-title-style-dashboard"><span className="Pardes-title-dashboard">Pardes</span>
          {this.state.refreshStatus == false ?
          <Button className="dashboard-refresh-button" onClick={this.handleGenerateNewExcelWithRefresh }><SyncOutlined style={{fontSize:"22px"}} /> </Button>
          : <Button className="dashboard-refresh-button" disabled={true}><SyncOutlined spin style={{fontSize:"22px"}}  /> </Button>} 
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"DSCR Actuel"}
          valuePardes={this.state.dscr_actuel}
            />
          </Col>
          <Col span={12}>
          <Element
            title={"DSCR Facial"}
            valuePardes={this.state.dscr_facial}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"ICR"}
          valuePardes={this.state.ICR}
            />
          </Col>
          <Col span={12}>
          <Element
            title={"LTV"}
            valuePardes={this.state.ltv}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"TO Financier"}
          valuePardes={this.state.TO_FINANCIER}
            />
          </Col>
          <Col span={12}>
          <Element
            title={"TO Physique"}
            valuePardes={this.state.TO_PHYSIQUE}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"Net Initial Yield"}
          valuePardes={this.state.NetInitialYield}
            />
          </Col>
          <Col span={12}>
          <Element
            title={"Net Potential Yield"}
            valuePardes={this.state.NetPotentielYield}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"DSCR Potentiel"}
          valuePardes={this.state.dscr_potentiel}
            />
          </Col>
          <Col span={12}>
          <Element
          title={"Initial Debt Yield"}
          valuePardes={this.state.InitialDebtYield}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"Potential Debt Yield"}
          valuePardes={this.state.PotentielDebtYield}
            />
          </Col>
        </Row>
         
      
    </Col>
    <Col className="card-data-forum-dashboard">
        <Row className="card-title-style-dashboard"><span className="Forum-title-dashboard">Forum</span>
          {this.state.refreshStatus == false ?
          <Button className="dashboard-refresh-button" onClick={this.handleGenerateNewExcelWithRefresh }><SyncOutlined style={{fontSize:"22px"}} /> </Button>
          : <Button className="dashboard-refresh-button" disabled={true}><SyncOutlined spin style={{fontSize:"22px"}}  /> </Button>} 
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"DSCR Actuel"}
          valueForum={this.state.dscr_actuelF}
            />
          </Col>
          <Col span={12}>
          <Element
            title={"DSCR Facial"}
            valueForum={this.state.dscr_facialF}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"ICR"}
          valueForum={this.state.ICRF}
            />
          </Col>
          <Col span={12}>
          <Element
            title={"LTV"}
            valueForum={this.state.ltvF}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"TO Financier"}
          valueForum={this.state.TO_FINANCIER_F}
            />
          </Col>
          <Col span={12}>
          <Element
            title={"TO Physique"}
            valueForum={this.state.TO_PHYSIQUE_F}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"Net Initial Yield"}
          valueForum={this.state.NetInitialYieldF}
            />
          </Col>
          <Col span={12}>
          <Element
            title={"Net Potential Yield"}
            valueForum={this.state.NetPotentielYieldF}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"DSCR Potentiel"}
          valueForum={this.state.dscr_potentielF}
            />
          </Col>
          <Col span={12}>
          <Element
          title={"Initial Debt Yield"}
          valueForum={this.state.InitialDebtYieldF}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
          <Element
          title={"Potential Debt Yield"}
          valueForum={this.state.PotentielDebtYieldF}
            />
          </Col>
        </Row> 
      
    </Col>
    <Col style={{width:"30%"}}>
    <ElementSynthese
        styledCard="2px solid #A3BA93"
        paddingCard="0%"
        title={rapports.length > 0 && (
          <Row>
            <Col span={4}>
              {this.state.refreshStatusSG == false ?
            <Button className="dashboard-refresh-button" onClick={this.handleGenerateNewExcel }><SyncOutlined style={{fontSize:"19px"}} /> </Button>
            : <Button className="dashboard-refresh-button" disabled={true}><SyncOutlined spin style={{fontSize:"19px"}}  /> </Button>} 
              
          </Col>
            
            <Col span={17} style={{display:"flex",alignItems:"center"}}>
              {this.buildRapportLink(asideRapportLink, true, false)}
            </Col>
            <Col span={3}>
            <IconButton
              onClick={this.openDrawer}
              title="Afficher l'historique"
              >
              <HistoryIcon />
              </IconButton>
            </Col>
          </Row>
          )}
      />
       { (<ElementSynthese
        styledCard="2px solid #A3BA93"
        paddingCard="0%"
        title={rapports.length > 0 && (
          <Row>
            <Col span={4}>
              {this.state.refreshStatusFA == false ?
            <Button className="dashboard-refresh-button" onClick={this.handlMajFinanceActive }><SyncOutlined style={{fontSize:"19px"}} /> </Button>
            : <Button className="dashboard-refresh-button" disabled={true}><SyncOutlined spin style={{fontSize:"19px"}}  /> </Button>} 
              
          </Col>
          <Col span={17} style={{display:"flex",alignItems:"center"}}>
              MAJ Finance Active
            </Col>
          </Row>
          )}
      />)}
      <ElementSynthese
        backgroundCard="#F4F6F9"
        styledCard="#F4F6F9"
        paddingCard="3%"
      />
      <ElementSynthese
        styledCard="1px solid #800000"
        title={rapports.length > 0 && (
          <Row>
            <Col span={4}>
            </Col>
            <Col span={17} style={{display:"flex",alignItems:"center"}}>
            {this.buildRapportSyntheseGlobaleGlobaleLink(asideRapportSyntheseGlobaleGlobaleLink, true, false)}
            </Col>
            
            <Col span={3}>
              <IconButton
              onClick={this.openDrawerSyntheseGlobaleGlobale}
              title="Afficher l'historique"
              >
              <HistoryIcon />
              </IconButton>
            </Col>
          </Row>)}
      />

      <ElementSynthese
        styledCard="1px solid #800000"
        title={rapports.length > 0 && (
          <Row>
            <Col span={4}></Col>
            <Col span={17} style={{display:"flex",alignItems:"center"}}>
            {this.buildRapportSyntheseGlobalePardesLink(asideRapportSyntheseGlobalePardesLink, true, false)}
            </Col>
            
            <Col span={3}>
            <IconButton
          onClick={this.openDrawerSyntheseGlobalePardes}
          title="Afficher l'historique"
          >
          <HistoryIcon />
          </IconButton>
            </Col>
          </Row>
          )}
      />
      <ElementSynthese
        styledCard="1px solid #800000"
        title={rapports.length > 0 && (
          <Row>
            <Col span={4}></Col>
            <Col span={17} style={{display:"flex",alignItems:"center"}}>
            {this.buildRapportSyntheseGlobaleForumLink(asideRapportSyntheseGlobaleForumLink, true, false)}
            </Col>
          
          <Col span={3}>
          <IconButton
          onClick={this.openDrawerSyntheseGlobaleForum}
          title="Afficher l'historique"
          >
          <HistoryIcon />
          </IconButton>
          </Col>
          
          </Row>
          )}
      />
      <ElementSynthese
        styledCard="1px solid #800000"
        title={rapports.length > 0 && (
          <Row>
            <Col span={4}></Col>
            <Col span={17} style={{display:"flex",alignItems:"center"}}>
            {this.buildRapportSyntheseCourteLink(asideRapportSyntheseCourteLink, true, false)}
            </Col>
          
          <Col span={3}>
          <IconButton
          onClick={this.openDrawerSyntheseCourte}
          title="Afficher l'historique"
          >
          <HistoryIcon />
          </IconButton>
          </Col>
          
          </Row>
          )}
      />
      <ElementSynthese
        styledCard="1px solid #800000"
        title={rapports.length > 0 && (
          <Row>
            <Col span={4}>
            {this.state.refreshStatusSV == false ?
            <Button className="dashboard-refresh-button" onClick={this.handleGenerateNewSV }><SyncOutlined style={{fontSize:"19px"}} /> </Button>
            : <Button className="dashboard-refresh-button" disabled={true}><SyncOutlined spin style={{fontSize:"19px"}}  /> </Button>}
            </Col>
            <Col span={17} style={{display:"flex",alignItems:"center"}}>
              {this.buildRapportSyntheseVacantsLink(asideRapportSyntheseVacantsLink, true, false)}
            </Col>
              
            <Col span={3}>
             
              <IconButton
                        onClick={this.openDrawerSyntheseVacants}
                        title="Afficher l'historique"
                        >
                        <HistoryIcon />
              </IconButton>
            </Col>
          </Row>
          )}
      />
      <ElementSynthese
        styledCard="1px solid #800000"
        title={rapports.length > 0 && (
          <Row>
            <Col span={4}></Col>
            <Col span={17} style={{display:"flex",alignItems:"center"}}>
              {this.buildRapportCRDLink(asideRapportCRDLink, true, false)}
            </Col>
              
              <Col span={3}>
              <IconButton
          onClick={this.openDrawerCRD}
          title="Afficher l'historique"
          >
          <HistoryIcon />
          </IconButton>
              </Col>
          </Row>
      
          )}
      />
      
    </Col>
  </Row>
      <Row className="dashboard-synthese-style-euribod-one">
        <Col span={5} className="dashboard-synthese-grid-euribor" style={{paddingLeft:"0px !important"}}><span style={{fontWeight:"bold"}}>Euribor 3M </span>{euribor.date_notification} </Col>
        <Col span={7} className="dashboard-synthese-grid" style={{color:"black", fontWeight:"bold"}}>{euribor.taux_euribor +"%"} </Col>
      </Row>
</div>



<div>
<Grid container alignItems="flex-start" justify="flex-start" direction="row" spacing={0} style={{ marginTop: "1rem", marginBottom:"1rem"}}>



</Grid>


</div>
<Grid container alignItems="flex-start" justify="flex-start" direction="row" spacing={0}>



</Grid>
<Drawer anchor="right" open={historiqueOpen} onClose={this.closeDrawer}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="column"
            spacing={0}
          >
            <Grid item className={classes.titreHisto}>
              <img src={Logo} alt="logo madar" width="150px" />
              <Typography variant="subheading">
                Historique des synthèses
              </Typography>
            </Grid>
            <Grid item>
              <ul className={classes.rapportLinks}>
                {rapports.map(r => <li>{this.buildRapportLink2(r, false, true)}</li>)}
              </ul>
            </Grid>
          </Grid>
        </Drawer>

        <Drawer anchor="right" open={historiquePDFSynthesePardesOpen} onClose={this.closeDrawerSyntheseGlobalePardes}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="column"
            spacing={0}
          >
            <Grid item className={classes.titreHisto}>
              <img src={Logo} alt="logo madar" width="150px" />
              <Typography variant="subheading">
                Historique des synthèses globales Pardes
              </Typography>
            </Grid>
            <Grid item>
              <ul className={classes.rapportLinks}>
                {rapports.map(r => <li>{this.buildRapportSyntheseGlobalePardesLink2(r, false, true)}</li>)}
              </ul>
            </Grid>
          </Grid>
        </Drawer>


<Drawer anchor="right" open={historiquePDFSyntheseGlobaleGlobaleOpen} onClose={this.closeDrawerSyntheseGlobaleGlobale}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="column"
            spacing={0}
          >
            <Grid item className={classes.titreHisto}>
              <img src={Logo} alt="logo madar" width="150px" />
              <Typography variant="subheading">
                Historique des synthèses globales
              </Typography>
            </Grid>
            <Grid item>
              <ul className={classes.rapportLinks}>
                {rapports.map(r => <li>{this.buildRapportSyntheseGlobaleGlobaleLink2(r, false, true)}</li>)}
              </ul>
            </Grid>
          </Grid>
        </Drawer>

        <Drawer anchor="right" open={historiquePDFSyntheseForumOpen} onClose={this.closeDrawerSyntheseGlobaleForum}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="column"
            spacing={0}
          >
            <Grid item className={classes.titreHisto}>
              <img src={Logo} alt="logo madar" width="150px" />
              <Typography variant="subheading">
                Historique des synthèses globales Forum
              </Typography>
            </Grid>
            <Grid item>
              <ul className={classes.rapportLinks}>
                {rapports.map(r => <li>{this.buildRapportSyntheseGlobaleForumLink2(r, false, true)}</li>)}
              </ul>
            </Grid>
          </Grid>
        </Drawer>


        <Drawer anchor="right" open={historiquePDFSyntheseCourteOpen} onClose={this.closeDrawerSyntheseCourte}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="column"
            spacing={0}
          >
            <Grid item className={classes.titreHisto}>
              <img src={Logo} alt="logo madar" width="150px" />
              <Typography variant="subheading">
                Historique des synthèses courtes
              </Typography>
            </Grid>
            <Grid item>
              <ul className={classes.rapportLinks}>
                {rapports.map(r => <li>{this.buildRapportSyntheseCourteLink2(r, false, true)}</li>)}
              </ul>
            </Grid>
          </Grid>
        </Drawer>

        <Drawer anchor="right" open={historiquePDFSyntheseVacantOpen} onClose={this.closeDrawerSyntheseVacants}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="column"
            spacing={0}
          >
            <Grid item className={classes.titreHisto}>
              <img src={Logo} alt="logo madar" width="150px" />
              <Typography variant="subheading">
                Historique des synthèses vacants
              </Typography>
            </Grid>
            <Grid item>
              <ul className={classes.rapportLinks}>
                {rapports.map(r => <li>{this.buildRapportSyntheseVacantsLink2(r, false, true)}</li>)}
              </ul>
            </Grid>
          </Grid>
        </Drawer>

        <Drawer anchor="right" open={historiquePDFCRDOpen} onClose={this.closeDrawerCRD}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="column"
            spacing={0}
          >
            <Grid item className={classes.titreHisto}>
              <img src={Logo} alt="logo madar" width="150px" />
              <Typography variant="subheading">
                Historique des CRDs
              </Typography>
            </Grid>
            <Grid item>
              <ul className={classes.rapportLinks}>
                {rapports.map(r => <li>{this.buildRapportCRDLink2(r, false, true)}</li>)}
              </ul>
            </Grid>
          </Grid>
        </Drawer>

               
      
      </>
    );
  }
}

export default withStyles(styles)(withGlobals(ListeNotificationsMadar));
