export const paddedTableHeaderStyle = {
  backgroundColor: "#a60000",
  color: "white",
  minHeight: "50px",
  lineHeight: "40px",
  fontWeight: "boled",
  textAlign: "right",
  paddingRight: "20px",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
};

export default paddedTableHeaderStyle;
