import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./Style/Style.css"
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import registerServiceWorker from "./registerServiceWorker";
import Index from "./pages/index";
import "moment/locale/fr";
import "typeface-roboto";
import Typography from "@material-ui/core/Typography";
import 'antd/dist/antd.css';

moment.locale("fr");

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Typography component="div">
      <Index />
    </Typography>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);
registerServiceWorker();
