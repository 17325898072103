const filter = (props, state) => {
  const { datas } = props;
  const {
    banque,
    societe,
    idbail,
    idactifpatrimonial,
    codePostal,
    ville,
    adresse,
    surfaceTotale,
    nom_du_locataire,
    biensNonAcquis,
  } = state;

  const datasBanque = banque? datas.filter(b => b.idbanque === banque) : datas;

  const datasSociete = societe ? datasBanque.filter(b => b.idsociete === societe) : datasBanque;
  const datasSocieteF = biensNonAcquis
    ? datasSociete.filter(b => {
        return b.date_acquisition === null;
      })
    : datasSociete;


  const filter =
    idbail !== "" ||
    idactifpatrimonial !== "" ||
    codePostal !== "" ||
    ville !== "" ||
    adresse !== "" ||
    nom_du_locataire  !== "" ||
    surfaceTotale !== "";
  const datasFiltred = filter
    ? datasSocieteF.filter(b => {
        let show = true;
        if (surfaceTotale !== "") {
          const [sC, value] = surfaceTotale.split(" ");
          const valueNum = parseInt(value, 10);
          if (Number.isNaN(valueNum) || (sC !== ">" && sC !== "<")) show = true;
          const val = parseInt(b.surface_totale || b.surface_totale_actif, 10);
          if (sC === ">") show = val > valueNum;
          if (sC === "<") show = val < valueNum;
        }
        if (`${b.idbail}` === idbail) {
          console.log("test", show, `${b.idbail}` === idbail, `${b.idbail}`, idbail);
        }
        if (idbail !== "" && show) show = `${b.idbail}` === idbail;
        if (idactifpatrimonial !== "" && show) show = `${b["idactifpatrimonial"]}` === idactifpatrimonial;
        if (codePostal !== "" && show) show = `${b.code_postal}`.toUpperCase().indexOf(codePostal) !== -1;
        if (adresse !== "" && show) show = `${b.adresse.replaceAll('è', 'e').replaceAll('é', 'e').toLowerCase()}`.indexOf(adresse.replaceAll('è', 'e').replaceAll('é', 'e').toLowerCase()) !== -1;
        if (ville !== "" && show) show = `${b.ville}`.toUpperCase().indexOf(ville.toUpperCase()) !== -1;
        if (nom_du_locataire !== "" && show)
           show = `${b.nom_du_locataire}`.toLowerCase().indexOf(nom_du_locataire.toLowerCase()) !== -1;

        return show;
      })
    : datasSocieteF;

  return { datasFiltred, filter };
};

export default filter;
