import React from "react";
import GlobalContext from "./GlobalContext";

const withGlobals = Component => props => (
  <GlobalContext.Consumer>
    {({ user, fetch }) => (
      <Component
        {...props}
        user={user}
        fetch={fetch}
        readOnly={!user || (!user.superviseur && !user.modifier)}
      />
    )}
  </GlobalContext.Consumer>
);

export default withGlobals;
