import React, { Component } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import withGlobals from "../withGlobals";

class DatasLoader extends Component {
  static propTypes = {
    baux: PropTypes.array.isRequired,
    bailId: PropTypes.number.isRequired,
    children: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      baux: props.baux
    };
  }

  componentDidMount = () => {
    if (!this.findBail()) {
      this.props
        .fetch({ url: `/baux/${this.props.bailId}` })
        .then(baux => this.setState({ baux: this.state.baux.concat([baux]) }));
    }
  };

  findBail = () => this.state.baux.find(b => b.idbail === this.props.bailId);

  render() {
    const bail = this.findBail();
    if (!bail) {
      return <CircularProgress size={50} />;
    }
    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        bail: {
          ...bail,
          paliers:
            typeof bail.paliers === "string"
              ? JSON.parse(bail.paliers)
              : bail.paliers
        },
        baux: this.state.baux
      })
    );
  }
}

export default withGlobals(DatasLoader);
