import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import debounce from "lodash/debounce";
import withGlobals from "../pages/withGlobals";
import ZoneRechercheTable from "./ZoneRechercheTable";
import "./customTable.css";
import defaultFilter from "./defaultFilter";

const styles = theme => {
  return {
    list: {
      paddingBottom: theme.spacing.unit,
      minHeight: "calc(100vh - 155px)",
      overflowY: "auto",
      marginLeft: theme.spacing.unit * 2,
      width: 750,
    },
    table: {
      height: "calc(100vh - 155px)",
    },
    listDialog: {
      paddingTop: 0,
      paddingBottom: theme.spacing.unit,
      maxHeight: "calc(100vh - 200px)",
      minHeight: "calc(100vh - 200px)",
      overflowY: "hidden",
      marginLeft: theme.spacing.unit * 2,
      width: 800,
    },
    search: {
      marginLeft: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
    rowEnhanced: {
      fontWeight: "bold",
    },
    total: {
      padding: theme.spacing.unit,
      textAlign: "center",
    },
  };
};

class ListeActifs extends React.Component {
  static propTypes = {
    inDialog: PropTypes.bool.isRequired,
    showIdActif: PropTypes.bool.isRequired,
    hideSocietes: PropTypes.bool.isRequired,
    datas: PropTypes.array.isRequired,
    datasName: PropTypes.string.isRequired,
    addButtonTitle: PropTypes.string.isRequired,
    apiPathname: PropTypes.string.isRequired,
    idField: PropTypes.string.isRequired,
    onSelectRow: PropTypes.func.isRequired,
    onDatasLoaded: PropTypes.func.isRequired,
    onDatasFiltred: PropTypes.func.isRequired,
    onSocietesLoaded: PropTypes.func,
    onSocieteAdded: PropTypes.func,
    onFiltreAcquisition: PropTypes.func,
    onAddClicked: PropTypes.func.isRequired,
    SubComponent: PropTypes.object,
    searchFields: PropTypes.array,
  };

  static defaultProps = {
    inDialog: false,
  };

  state = {
    societe: null,
    banque: null,
    id: "",
    idlocal: "",
    idbail: "",
    idactifpatrimonial: "",
    codePostal: "",
    surfaceTotale: "",
    nom_du_locataire: "",
    nom_portefeuille: "",
    idemprunt: "",
    ville: "",
    adresse: "",
    loading: false,
    datasFiltred: [],
    biensNonAcquis: false,
  };

  componentDidMount = () => {
    if (this.props.datas.length === 0) {
      this.loadDatas(this.props.apiPathname);
    } else {
      this.filter();
    }
    this.delayedCallback = debounce(e => {
      this.setState({
        [e.target.name]: e.target.value,
        justSaved: false,
      });
      setTimeout(() => this.filter(), 50);
    }, 250);
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.apiPathname !== nextProps.apiPathname) {
      this.loadDatas(nextProps.apiPathname);
    }
  };

  loadDatas = url => {
    this.setState({ loading: true });
    this.props.fetch({ url }).then(res => {
      this.props.onDatasLoaded(res);
      this.setState({ loading: false });
      this.filter();
    });
  };

  getItemFromPathname = () => this.props.location.pathname.split("/")[2];

  handleChangeSearch = e => {
    e.persist();
    this.delayedCallback(e);
  };

  handleSocietesLoaded = societes => this.setState({ societes });

  handleSocieteAjoutee = societe => {
    this.props.onSocieteAdded(societe);
    this.setState({ societe });
  };

  handleChangeSociete = e => {
    this.setState({ societe: parseInt(e.target.value, 10) });
    setTimeout(() => this.filter(), 50);
  };

  handleChangeBanque = e => {
    this.setState({ banque: parseInt(e.target.value, 10) });
    setTimeout(() => this.filter(), 50);
  };

  handleChangeFiltreAcquisition = () => {
    this.setState({ biensNonAcquis: !this.state.biensNonAcquis });
    setTimeout(() => this.filter(), 50);
  };

  handleChangeFiltreBail = () => {
    this.setState({ locauxSansBail: !this.state.locauxSansBail });
    setTimeout(() => this.filter(), 50);
  };

  filter = () => {
    const { datasFiltred, filter } = this.props.filter
      ? this.props.filter(this.props, this.state)
      : defaultFilter(this.props, this.state);

    this.setState({ datasFiltred });

    // on renvoie au parent la liste filtrée et le booleen true si tri selon texte
    if (this.props.onDatasFiltred) {
      this.props.onDatasFiltred(datasFiltred, filter);
    }
  };

  render() {
    const {
      classes,
      onSelectRow,
      onSocietesLoaded,
      inDialog,
      hideSocietes,
      datasName,
      idField,
      onAddClicked,
      tableauAmortissements,
      addButtonTitle,
      SubComponent,
      columnData,
      searchFields,
      showIdActif,
      addDownloadAmortissements,
      disabledDownloadAmortissements,
    } = this.props;
    const { texteSearch, loading, datasFiltred, biensNonAcquis, locauxSansBail } = this.state;

    if (loading) {
      return (
        <Grid container alignItems="center" justify="center" className={classes.list}>
          <Grid item>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={8} direction="row" justify="center">
        <Grid item className={classes.search}>
          {
            <ZoneRechercheTable
              onSearchChange={this.handleChangeSearch}
              onSocieteChange={this.handleChangeSociete}
              onSocieteAjoutee={this.handleSocieteAjoutee}
              onBanqueChange={this.handleChangeBanque}
              onBanqueAjoutee={this.handleBanqueAjoutee}
              onLoaded={onSocietesLoaded}
              uniquementBiensAcquis={biensNonAcquis}
              uniquementLocauxSansBail={locauxSansBail}
              onChangeFiltreAcquisition={this.handleChangeFiltreAcquisition}
              onChangeFiltreBail={this.handleChangeFiltreBail}
              texteSearch={texteSearch}
              onAddClicked={onAddClicked}
              tableauAmortissements={tableauAmortissements}
              addButtonTitle={addButtonTitle}
              inDialog={inDialog}
              hideSocietes={hideSocietes}
              hideBanques={false}
              showIdActif={showIdActif}
              searchFields={searchFields}
              addDownloadAmortissements={addDownloadAmortissements}
              disabledDownloadAmortissements={disabledDownloadAmortissements}
            />
          }
        </Grid>
        <Grid item>
          <Paper elevation={3} className={classes[!inDialog ? "list" : "listDialog"]}>
            <ReactTable
              data={datasFiltred}
              columns={columnData}
              noDataText="Aucune donnée"
              showPagination={false}
              pageSize={datasFiltred.length}
              className={`-striped -highlight withMaxHeight ${classes.table}`}
              SubComponent={SubComponent}
              getTdProps={(state, rowInfo, column, instance) => ({
                onClick: (e, handleOriginal) => {
                  if (e.currentTarget.className.indexOf("rt-expandable") === -1) {
                    onSelectRow(e, rowInfo.original[idField]);
                  }
                  if (handleOriginal) {
                    handleOriginal();
                  }
                },
              })}
            />
          </Paper>
          {!inDialog && (
            <div className={classes.total}>
              <Typography color="primary" variant="title">
                {datasFiltred.length > 0 ? datasFiltred.length : "aucun"}{" "}
                {datasName === "baux" && datasFiltred.length === 1 ? "bail" : datasName}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withGlobals(ListeActifs)));
