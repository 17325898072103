import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
// import PropTypes from 'prop-types';
import { FieldArray } from "formik";
import DatePicker from "material-ui-pickers/DatePicker";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ListIcon from "@material-ui/icons/List";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import ListeActifs from "../../common/ListeActifs";
import CustomDialogTitle from "../../common/CustomDialogTitle";
import NumberFormatCustom from "../../common/NumberFormatCustom";
import MoisFormatCustom from "../../common/MoisFormatCustom";
import PrctFormatCustom from "../../common/PrctFormatCustom";
import M2FormatCustom from "../../common/M2FormatCustom";
import withGlobals from "../withGlobals";
import columnData from "../Local/columnData";
import InfoBulleDialog from "./DialogInfoBulle";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      width: 200,
    },
    localSelected: {
      fontSize: "1.2rem",
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
    },
    localSelectedLabel: {
      color: "rgba(0, 0, 0, 0.54)",
      padding: 0,
      fontSize: "0.8rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: 1.2,
      marginBottom: theme.spacing.unit,
    },
    button: {
      marginTop: theme.spacing.unit * 4,
    },
    paliersBtn: {
      marginBottom: theme.spacing.unit * 2,
    },
    paliers: {
      marginBottom: theme.spacing.unit,
    },
    buttonHidden: {
      marginTop: theme.spacing.unit * 4,
      visibility: "hidden",
    },
    select: {
      width: 200,
      marginBottom: theme.spacing.unit * 2.5,
    },
    affectations: {
      textAlign: "center",
      padding: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
      marginBottom: theme.spacing.unit * 2,
    },
    submitButton: {
      padding: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 4,
    },
    titreSection: {
      padding: theme.spacing.unit,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      textAlign: "center",
    },
    bloc: {
      border: "solid 1px gray",
      borderRadius: "3px 3px 3px",
      padding: theme.spacing.unit,
      width: "900px",
    },
    dateP: {
      marginBottom: theme.spacing.unit * 2,
    },
    invisibleFieldset: {
      margin: 0,
      padding: 0,
      border: 0,
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    }
  };
};

const types_cautions = [
  "caution_garantie_premiere_demande",
  "caution_garantie_solvabilite",
  "caution_societe",
  "caution_perso",
  "caution_bancaire",
];

const name_types_cautions = {
  "caution_garantie_premiere_demande": "Garantie Première Demande",
  "caution_garantie_solvabilite": "Garantie Solvabilité",
  "caution_societe": "Caution Société",
  "caution_perso": "Caution Personnelle",
  "caution_bancaire": "Caution bancaire"
};



class BailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indice: props.bail.variation_indice !== null,
      locaux: [],
      dialogOpen: false,
      localSelected: null,
      index: 0,
      type_caution: [],
      dialogInfoBulleOpen: false,
      financement_corporate: false,
      banques: []
    };
  }

 componentDidMount = () => {
  this.props.fetch({method: "GET", url: `/banques/` }).then(banques => {
    var arrayOfBanque = banques;
    arrayOfBanque.sort(function(a, b){
        if(a.nom_banque < b.nom_banque) { return -1; }
        if(a.nom_banque > b.nom_banque) { return 1; }
        return 0;
    })
            this.setState({banques :arrayOfBanque});
          });
 if (this.props.values.idlocal) {
	
 }

};



handleOpenInfoBulle = () => this.setState({ dialogInfoBulleOpen: true });
handleCloseInfoBulle = () => this.setState({ dialogInfoBulleOpen: false });

  handleChangeTab = (event, index) => {
    this.setState({ index });
  };

  handleLocauxLoaded = locaux => this.setState({ locaux });

  handleAnnuleSelection = () => {
    this.props.setFieldValue("localSelected", this.props.bail.idlocal, false);
    this.setState({ localSelected: null });
  };

  handleSelectLocal = (e, localSelected) => {
    const { setFieldValue, setFormDataChanged, changed, onLocalSelected, history } = this.props;
    if (!changed) setFormDataChanged();
    setFieldValue("idlocal", localSelected, false);
    this.setState({ localSelected, dialogOpen: false });
    console.log("onLocalSelected", onLocalSelected);
    history.push(`/baux/nouveau?localId=${localSelected}`);
  };

  handleOpenDialog = () => this.setState({ dialogOpen: true });

  handleCloseDialog = () => this.setState({ dialogOpen: false });

  changeValue = (field, value) => {
    if (field === "financement_corporate") {
      this.setState({ financement_corporate: (value === "true") });
      value = (value === "true");
    }
    const { setFieldValue, setFormDataChanged, changed } = this.props;
    if (!changed) setFormDataChanged();
    setFieldValue(field, value, false);
  };

  handleChangeCheckbox = e => {
    this.changeValue(e.target.name, e.target.checked ? 0 : " ");
  };

  handleIndiceChange = e => {
    this.changeValue("indice_reference", e.target.value);
if (this.props.values.date_effet_du_bail != null){
try{
this.props.values.date_effet_du_bail = this.props.values.date_effet_du_bail.format("YYYY-MM-DD");
} catch(e){

}
    this.props
      .fetch({
        url: `/indices/?type=${e.target.value}&date=${this.props.values.date_effet_du_bail}`,
      })
      .then(datas => {
        this.changeValue("annee", datas.annee);
        this.changeValue("trimestre", parseInt(datas.trimestre.replace("T", ""), 10));
      });
}
  };

  handleDateChange = (field, date) => {
const { setFieldValue, setFormDataChanged, changed } = this.props;
    if (!changed) setFormDataChanged();

    this.props.setFieldValue(field, date ? date.format("YYYY-MM-DD") : null);
    if (field === "date_effective_depart" || field === "date_effet_du_bail") {
      setTimeout(() => this.calculeDateFin(), 50);
     }


if( field === "date_effet_du_bail") {
if (date != null){
try{
	date = date.format("YYYY-MM-DD");
} catch(e){

}
    this.props
      .fetch({
        url: `/indices/?type=${this.props.values.indice_reference}&date=${date}`,
      })
      .then(datas => {
        this.changeValue("annee", datas.annee);
        this.changeValue("trimestre", parseInt(datas.trimestre.replace("T", ""), 10));
      });
}
}
  };
  
  isCircular = (d) => {
  try {JSON.stringify(d);}
  catch (e) {
	  
	  this.props.values.date_fin_bail = new Date(this.props.values.date_fin_bail).setMonth(new Date(this.props.values.date_effet_du_bail).getMonth() + this.props.values.duree_bail_7);
	  return true;}
  return false;
}
  
  handleEffectiveDateChange = (field, date) => {
	if ((field === "date_effective_depart") && (this.isCircular(date))){
		this.props.setFieldValue(field, null);
	}
	if (field === "date_effective_depart" ) {
      setTimeout(() => this.calculeDateFin(), 50);
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/baux");
  };

  calculeDateFin = () => {
    const { values } = this.props;
    let annees = null;
    let i = 6;
    do {
      if (values[`duree_bail_${i}`] !== "") {
        annees = parseInt(values[`duree_bail_${i}`], 10);
      }
      i--;
    } while (!annees && i > 0);
    if (!annees) annees = 0;

    const mois = values.duree_bail_7 !== "" ? parseInt(values.duree_bail_7, 10) : 0;


    if (!values.date_effective_depart && values.date_effet_du_bail) {
      this.props.setFieldValue(
        "date_fin_bail",
        moment(values.date_effet_du_bail)
          .add(annees, "year")
          .add(mois, "month")
	  .add(-1, "day"),
      );
    }

    if (values.date_effective_depart) {
      this.props.setFieldValue("date_fin_bail", values.date_effective_depart);
    }
  };

  render() {
    const {
      handleSubmit,
      handleBlur,
      touched,
      values,
      errors,
      isSubmitting,
      classes,
      typesActivites,
      changed,
      setFormDataChanged,
      readOnly,
      user,
    } = this.props;

const isVacant =  ((this.props.values.duree_bail == 3) && (this.props.values.idbail > 0));
const isVacantDuree = (this.props.values.duree_bail != 3)? "*": "";
    const { localSelected, locaux, dialogInfoBulleOpen } = this.state;
	
if( !this.props.values.idlocal ){
        this.props.values.duree_bail_1 = "";
        this.props.values.duree_bail_2 = "";
        this.props.values.duree_bail_3 = "";
        this.props.values.duree_bail_4 = "";
        this.props.values.duree_bail_5 = "";
        this.props.values.duree_bail_6 = "";
	this.props.values.duree_preavis = 6;
}
	

    console.log("errors", errors);
    console.log("values", values.duree_bail);

    return (
      <form onSubmit={handleSubmit} className={classes.container}>
        <fieldset className={classes.invisibleFieldset} disabled={!user.modifier}>
          <Dialog
            open={this.state.dialogOpen}
            onClose={this.handleCloseDialog}
            onDatasLoaded={this.handleLocauxLoaded}
            aria-labelledby="form-dialog-title"
            fullScreen
            TransitionComponent={Transition}
          >


            <CustomDialogTitle title="Sélectionner un local" onClose={this.handleCloseDialog} />
            <DialogContent>
              <ListeActifs
                onSelectRow={this.handleSelectLocal}
                onDatasLoaded={this.handleLocauxLoaded}
                datas={locaux}
                apiPathname="/locaux/"
                datasName="locaux"
                idField="idlocal"
                inDialog
                columnData={columnData}
              />
            </DialogContent>
          </Dialog>
<InfoBulleDialog
open={dialogInfoBulleOpen}
idbail={this.props.values.idbail}
handleClose={this.handleCloseInfoBulle}
/>

          <Grid container justify="center" alignItems="center" direction="column" spacing={8}>
            {!user.modifier && (
              <Grid item xs={12}>
                <Typography variant="subheading">Lecture seule pour l'utilisateur courant</Typography>
              </Grid>
            )}
            <Grid item>
              <div className={classes.titreSection}>
                <Typography variant="title">Local</Typography>
              </div>
            </Grid>
            <Grid item>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                direction="row"
                spacing={16}
                className={classes.bloc}
              >
                <Grid item>
                  {!localSelected ? (
                    <Grid container justify="space-between" alignItems="center" direction="row">
                      <Grid item>
                        <TextField
                          id="idlocal"
                          value={values.idlocal}
                          label="ID Local"
                          name="idlocal"
                          type="text"
                          disabled
                          required 
                          margin="dense"
                          fullWidth
                          onBlur={handleBlur}
                          helperText={errors.idlocal && touched.idlocal ? errors.idlocal : null}
                          error={touched.idlocal && errors.idlocal}
                          onChange={e => this.changeValue("idlocal", e.target.value, false)}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={this.handleOpenDialog} style={{ marginTop: 0 }} aria-label="list">
                          <ListIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                      <Grid container direction="row" alignItems="space-between">
                        <Grid item>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.localSelectedLabel}>
                              Local Id
                        </Grid>
                            <Grid item className={classes.localSelected}>
                              {localSelected}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={this.handleAnnuleSelection}
                            style={{ marginTop: 0 }}
                            aria-label="clear"
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
                <Grid item>
                  <TextField
                    id="etage"
                    disabled = {isVacant}
                    value={values.etage}
                    label="Étage"
                    name="etage"
                    type="text"
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.etage && touched.etage ? errors.etage : null}
                    error={touched.etage && errors.etage}
                    onChange={e => this.changeValue("etage", e.target.value, false)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.titreSection}>
                <Typography variant="title">Locataire</Typography>
              </div>
            </Grid>
            <Grid item>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                direction="row"
                spacing={16}
                className={classes.bloc}
              >
                <Grid item>
                  <TextField
                    id="nom_du_locataire"
                    value={values.nom_du_locataire}
                    label="Nom du locataire"
                    name="nom_du_locataire"
                    type="text"
                    required  =  {values.duree_bail != 3}
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.nom_du_locataire && touched.nom_du_locataire ? errors.nom_du_locataire : null
                    }
                    error={touched.nom_du_locataire && errors.nom_du_locataire}
                    onChange={e => this.changeValue("nom_du_locataire", e.target.value, false)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    disabled = {isVacant}
                    id="nom_commercial"
                    value={values.nom_commercial}
                    label="Nom commercial"
                    name="nom_commercial"
                    type="text"
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.nom_commercial && touched.nom_commercial ? errors.nom_commercial : null}
                    error={touched.nom_commercial && errors.nom_commercial}
                    onChange={e => this.changeValue("nom_commercial", e.target.value, false)}
                  />
                </Grid>
                <Grid item>
                  <FormControl className={classes.select} required =  {values.duree_bail != 3} margin="dense">
                    <InputLabel htmlFor="idtype_bail-native-helper">Activité</InputLabel>
                    <NativeSelect
                    disabled = {isVacant} 
                      value={values.idactivite}
                      onChange={(event, key) => this.changeValue("idactivite", event.target.value)}
                      input={<Input name="idactivite" id="idtype_bail-native-helper" />}
                    >
                      <option value="" key="aze" />
                      {typesActivites.map(ta => (
                        <option value={ta.idactivite} key={ta.idactivite}>
                          {ta.nom_activite}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    id="destination_bail"
                    value={values.destination_bail}
                    label="Destination bail"
                    name="destination_bail"
                    type="text"
                    margin="dense"
                    fullWidth
                    disabled = {isVacant} 
                    onBlur={handleBlur}
                    helperText={
                      errors.destination_bail && touched.destination_bail ? errors.destination_bail : null
                    }
                    error={touched.destination_bail && errors.destination_bail}
                    onChange={e => this.changeValue("destination_bail", e.target.value, false)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.titreSection}>
                <Typography variant="title">Bail</Typography>
              </div>
            </Grid>
            <Grid item>
              <Grid
                container
                justify="space-between"
                alignItems="flex-start"
                direction="row"
                spacing={16}
                className={classes.bloc}
              >
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <Grid item>
                      <Grid item>
                        <FormControl className={classes.select} required =  {values.duree_bail != 3} margin="dense">
                          <InputLabel htmlFor="duree_bail-native-helper">Type de bail</InputLabel>
                          <NativeSelect
                            value={values.duree_bail}
                            onChange={(event, key) => this.changeValue("duree_bail", event.target.value)}
                            input={<Input name="duree_bail" id="duree_bail-native-helper" />}
                          >
                            <option value="" />
                            <option value="0">Commercial</option>
                            <option value="1">Professionnel</option>
                            <option value="2">Dérogatoire</option>
                            <option value="3">Vacant</option>
							<option value="4">Habitation</option>
                          </NativeSelect>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item>
                  <TextField
                    id="code_immeuble"
                    disabled = {isVacant}
                    value={values.code_immeuble}
                    label="Code immeuble ALTAIX"
                    name="code_immeuble"
                    type="text"
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.code_immeuble && touched.code_immeuble ? errors.code_immeuble : null}
                    error={touched.code_immeuble && errors.code_immeuble}
                    onChange={e => this.changeValue("code_immeuble", e.target.value, false)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="code_bail_altaix"
                    disabled = {isVacant}
                    value={values.code_bail_altaix}
                    label="Code bail ALTAIX"
                    name="code_bail_altaix"
                    type="text"
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.code_bail_altaix && touched.code_bail_altaix ? errors.code_bail_altaix : null}
                    error={touched.code_bail_altaix && errors.code_bail_altaix}
                    onChange={e => this.changeValue("code_bail_altaix", e.target.value, false)}
                  />
                </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <Grid item>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="flex-start"
                        direction="row"
                        spacing={8}
                      >
                        <Grid item>
                          <div style={{ width: "80px", marginTop: "30px" }}>
                            <label>
                              Durée {isVacantDuree}  
                            </label>
                          </div>
                        </Grid>
                        <Grid item>
                          <input
                    disabled = {isVacant} 
                            id="duree_bail_1"
                            name="duree_bail_1"
			    required  =  {values.duree_bail != 3}
                            value={values.duree_bail_1}
                            type="text"
                            onChange={e => this.changeValue("duree_bail_1", e.target.value, false)}
                            style={{ width: "40px", marginTop: "20px" }}
                          />
                        </Grid>
                        <Grid item>
                          <input
                    disabled = {isVacant}
                            id="duree_bail_2"
                            name="duree_bail_2"
                            value={values.duree_bail_2}
                            type="text"
                            onChange={e => this.changeValue("duree_bail_2", e.target.value, false)}
                            style={{ width: "40px", marginTop: "20px" }}
                          />
                        </Grid>
                        <Grid item>
                          <input
                    disabled = {isVacant}
                            id="duree_bail_3"
                            name="duree_bail_3"
                            value={values.duree_bail_3}
                            type="text"
                            onChange={e => this.changeValue("duree_bail_3", e.target.value, false)}
                            style={{ width: "40px", marginTop: "20px" }}
                          />
                        </Grid>
                        <Grid item>
                          <input
                    disabled = {isVacant}
                            id="duree_bail_4"
                            name="duree_bail_4"
                            value={values.duree_bail_4}
                            type="text"
                            onChange={e => this.changeValue("duree_bail_4", e.target.value, false)}
                            style={{ width: "40px", marginTop: "20px" }}
                          />
                        </Grid>
                        <Grid item>
                          <input
                    disabled = {isVacant}
                            id="duree_bail_5"
                            name="duree_bail_5"
                            value={values.duree_bail_5}
                            type="text"
                            onChange={e => this.changeValue("duree_bail_5", e.target.value, false)}
                            style={{ width: "40px", marginTop: "20px" }}
                          />
                        </Grid>
                        <Grid item>
                          <input
                    disabled = {isVacant} 
                            id="duree_bail_6"
                            name="duree_bail_6"
                            value={values.duree_bail_6}
                            type="text"
                            onChange={e => this.changeValue("duree_bail_6", e.target.value, false)}
                            style={{ width: "40px", marginTop: "20px" }}
                          />
                        </Grid>
                        <Grid item>
                          <div style={{ width: "70px", marginTop: "30px" }}>{" annees  /  "}</div>
                        </Grid>
                        <Grid item>
                          <input
                    disabled = {isVacant} 
                            id="duree_bail_7"
                            name="duree_bail_7"
                            value={values.duree_bail_7}
                            type="text"
                            onChange={e => this.changeValue("duree_bail_7", e.target.value, false)}
                            style={{ width: "40px", marginTop: "20px" }}
                          />
                        </Grid>
                        <Grid item>
                          <div style={{ width: "20px", marginTop: "30px" }}>{" mois"}</div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <DatePicker
                    disabled = {isVacant}
                    className={classes.dateP}
                    value={values.date_signature_bail || null}
                    onChange={date => this.handleDateChange("date_signature_bail", date)}
                    format="DD/MM/YYYY"
                    autoOk
                    required  =  {values.duree_bail != 3}
                    label="Date de signature"
                    emptyLabel="JJ/MM/AAAA"
                    cancelLabel="Annuler"
                    invalidDateMessage="Date non valide"
                    keyboard
                    mask={value => (value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [])}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    disabled = {isVacant} 
                    className={classes.dateP}
                    value={values.date_dentree_du_locataire || null}
                    onChange={date => this.handleDateChange("date_dentree_du_locataire", date)}
                    format="DD/MM/YYYY"
                    autoOk
                    required  =  {values.duree_bail != 3}
                    label="Date d'entrée"
                    emptyLabel="JJ/MM/AAAA"
                    cancelLabel="Annuler"
                    invalidDateMessage="Date non valide"
                    keyboard
                    mask={value => (value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [])}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    disabled = {isVacant} 
                    className={classes.dateP}
                    value={values.date_effet_du_bail || null}
                    onChange={date => this.handleDateChange("date_effet_du_bail", date)}
                    format="DD/MM/YYYY"
                    autoOk
                    required  =  {values.duree_bail != 3}
                    label="Date d'effet"
                    emptyLabel="JJ/MM/AAAA"
                    cancelLabel="Annuler"
                    invalidDateMessage="Date non valide"
                    keyboard
                    mask={value => (value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [])}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    disabled = {isVacant} 
                    className={classes.dateP}
                    id="duree_preavis"
                    value={values.duree_preavis}
                    label="Durée du préavis"
                    name="duree_preavis"
                    type="text"
                    margin="dense"
					style = {{width: 235}}
                    InputProps={{
                      inputComponent: MoisFormatCustom,
                    }}
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.duree_preavis && touched.duree_preavis ? errors.duree_preavis : null}
                    error={touched.duree_preavis && errors.duree_preavis}
                    onChange={e => this.changeValue("duree_preavis", e.target.value, false)}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    className={classes.dateP}
                    value={values.date_effective_depart || null}
                    onChange={date => this.handleDateChange("date_effective_depart", date)}
                    onChangeRaw={date => this.handleDateChange("date_effective_depart", date)}
                    onBlur={date => this.handleEffectiveDateChange("date_effective_depart", date)}
                    format="DD/MM/YYYY"
                    autoOk
                    disabled = {isVacant} 
                    label="Date effective de départ"
                    emptyLabel="JJ/MM/AAAA"
                    cancelLabel="Annuler"
                    invalidDateMessage="Date non valide"
                    keyboard
                    mask={value => (value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [])}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    disabled = {isVacant} 
                    className={classes.dateP}
                    value={values.date_fin_bail || null}
                    onChange={date => this.handleDateChange("date_fin_bail", date)}
                    format="DD/MM/YYYY"
                    autoOk
                    label="Date de fin"
                    emptyLabel="JJ/MM/AAAA"
                    cancelLabel="Annuler"
                    invalidDateMessage="Date non valide"
                    keyboard
                    mask={value => (value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [])}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.titreSection}>
                <Typography variant="title">Loyer</Typography>
              </div>
            </Grid>
            <Grid item>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                direction="row"
                spacing={16}
                className={classes.bloc}
              >
                <Grid item xs={4}>
                  <TextField
                    disabled = {isVacant} 
                    id="loyer_contractuel"
                    value={values.loyer_contractuel}
                    name="loyer_contractuel"
                    label="Loyer contractuel"
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.loyer_contractuel && touched.loyer_contractuel ? errors.loyer_contractuel : null
                    }
                    error={touched.loyer_contractuel && errors.loyer_contractuel}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("loyer_contractuel", e.target.value, false)}
                  />
                  <TextField
                    disabled = {isVacant} 
                    id="loyer_actuel"
                    value={values.loyer_actuel}
                    name="loyer_actuel"
                    label="Loyer actuel"
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={errors.loyer_actuel && touched.loyer_actuel ? errors.loyer_actuel : null}
                    error={touched.loyer_actuel && errors.loyer_actuel}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("loyer_actuel", e.target.value, false)}
                  />

<Button
  variant="contained"
  color="primary"
  fullWidth
   onClick={this.handleOpenInfoBulle}
>
Historique Indexation
</Button>

                  <TextField
                    id="valeur_locative"
                    value={values.valeur_locative}
                    name="valeur_locative"
                    label="Valeur locative"
                    type="text"
                    margin="dense"
                    required 
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.valeur_locative && touched.valeur_locative ? errors.valeur_locative : null
                    }
                    error={touched.valeur_locative && errors.valeur_locative}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.changeValue("valeur_locative", e.target.value, false)}
                  />
                  <TextField
                    id="frais_gestion"
                    value={values.frais_gestion}
                    name="frais_gestion"
                    label="Frais de gestion"
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    disabled = {isVacant} 
                    onBlur={handleBlur}
                    helperText={errors.frais_gestion && touched.frais_gestion ? errors.frais_gestion : null}
                    error={touched.frais_gestion && errors.frais_gestion}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: PrctFormatCustom,
                    }}
                    onChange={e => this.changeValue("frais_gestion", e.target.value, false)}
                  />
                  <TextField
                    id="charges_refacturees"
                    value={values.charges_refacturees}
                    label="Charge refacturées"
                    name="charges_refacturees"
                    type="text"
                    margin="dense"
                    disabled = {isVacant}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.charges_refacturees && touched.charges_refacturees
                        ? errors.charges_refacturees
                        : null
                    }
                    error={touched.charges_refacturees && errors.charges_refacturees}
                    onChange={e => this.changeValue("charges_refacturees", e.target.value, false)}
                  />
                  <TextField
                    id="depot_de_garantie"
                    value={values.depot_de_garantie}
                    label="Dépot de garantie"
                    name="depot_de_garantie"
                    type="text"
                    required  =  {values.duree_bail != 3}
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    disabled = {isVacant} 
                    helperText={
                      errors.depot_de_garantie && touched.depot_de_garantie ? errors.depot_de_garantie : null
                    }
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    error={touched.depot_de_garantie && errors.depot_de_garantie}
                    onChange={e => this.changeValue("depot_de_garantie", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid item lg={12}>
                    <div className={classes.affectations}>Paliers</div>
                  </Grid>
                  <FieldArray
                    name="paliers"
                    render={arrayHelpers => (
                      <Grid item xs={12} className={classes.paliers}>
                        <Button
margin="dense"
                          disabled = {isVacant}
                          fullWidth
                          variant="raised"
                          color="primary"
                          onClick={() => {
                            if (!changed) setFormDataChanged();
                            arrayHelpers.push({
                              date: moment(new Date()).format("YYYY-MM-DD"),
                              montant: "",
                            });
                          }}
                          className={classes.paliersBtn}
                        >
                          + nouveau palier
                      </Button>
                        {values.paliers.map((palier, index) => (
                          <Grid
                            container
                            alignItems="center"
                            justify="center"
                            direction="column"
                            spacing={16}
                            key={index}
                            className={classes.paliers}
                          >
                            <Grid container
                              alignItems="center"
                              justify="center"
                              direction="column">
                              <DatePicker
                                disabled = {isVacant}  
                                value={palier.date_debut || null}
							                  defaultValue={(index == 0)? new Date(values.date_effet_du_bail) : new Date(values.paliers[index-1].date_fin)}
                                onChange={date => {
                                  if (!changed) setFormDataChanged();
                                  this.changeValue(
                                    `paliers.${index}.date_debut`,
                                    date ? date.format("YYYY-MM-DD") : null,
                                  );
                                }}
                                format="DD/MM/YYYY"
                                autoOk
                                required  =  {values.duree_bail != 3}
                                label="Date de début"
                                emptyLabel="JJ/MM/AAAA"
                                cancelLabel="Annuler"
                                invalidDateMessage="Date non valide"
                                mask={value =>
                                  value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                                }
                              />
                              <DatePicker
                                disabled = {isVacant}
                                value={palier.date_fin || null}
								                defaultValue={new Date(palier.date_debut)}
                                onChange={date => {
                                  if (!changed) setFormDataChanged();
                                  this.changeValue(
                                    `paliers.${index}.date_fin`,
                                    date ? date.format("YYYY-MM-DD") : null,
                                  );
                                }}
                                format="DD/MM/YYYY"
                                autoOk
                                required  =  {values.duree_bail != 3}
                                label="Date de fin"
                                emptyLabel="JJ/MM/AAAA"
                                cancelLabel="Annuler"
                                invalidDateMessage="Date non valide"
                                mask={value =>
                                  value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                                }
                              />
                              <FormControl className={classes.formControl} aria-describedby="name-helper-text">
                                <InputLabel htmlFor="name-helper">Montant réduction loyer</InputLabel>
                                <Input
                    disabled = {isVacant}
                                  id="name-helper"
                                  value={palier.montant}
                                  className={classes.textFieldo}
                                  name={`paliers.${index}.montant`}
                                  onBlur={handleBlur}
				  InputProps={{
                		        inputComponent: NumberFormatCustom,
		                    }}
                                  type="text"
                                  endAdornment={<InputAdornment position="start">€</InputAdornment>}
                                  onChange={(event, key) => {
                                    if (!changed) setFormDataChanged();
                                    this.changeValue(`paliers.${index}.montant`, event.target.value);
                                  }}
                                />
                              </FormControl>
                              <IconButton
                    disabled = {isVacant} 
                                type="button"
                                onClick={() => {
                                  if (!changed) setFormDataChanged();
                                  arrayHelpers.remove(index);
                                }} // remove a friend from the list
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid item>
                    <FormControl fullWidth required =  {values.duree_bail != 3} margin="normal">
                      <InputLabel htmlFor="indice_reference-native-helper">Indice de référence</InputLabel>
                      <NativeSelect
                    disabled = {isVacant}
                        value={values.indice_reference}
                        onChange={this.handleIndiceChange}
                        input={<Input name="indice_reference" id="indice_reference-native-helper" />}
                      >
                        <option value="" />
                        <option value="ICC">ICC</option>
                        <option value="ILAT">ILAT</option>
                        <option value="ILC">ILC</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth required =  {values.duree_bail != 3} margin="normal">
                      <InputLabel htmlFor="trimestre-native-helper">Trimestre</InputLabel>
                      <NativeSelect
                    disabled = {isVacant}
                        value={values.trimestre}
                        onChange={(event, key) => this.changeValue("trimestre", event.target.value)}
                        input={<Input name="trimestre" id="trimestre-native-helper" />}
                      >
                        <option value="" />
                        <option value={1}>T1</option>
                        <option value={2}>T2</option>
                        <option value={3}>T3</option>
                        <option value={4}>T4</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="annee"
                      value={values.annee}
                      label="Annee"
                      name="annee"
                      type="text"
                      required  =  {values.duree_bail != 3}
                      margin="dense"
                    disabled = {isVacant} 
                      fullWidth
                      onBlur={handleBlur}
                      helperText={errors.annee && touched.annee ? errors.annee : null}
                      error={touched.annee && errors.annee}
                      onChange={e => this.changeValue("annee", e.target.value, false)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="tunnel_indexation"
                      value={values.tunnel_indexation}
                      label="Tunnel d'indexation"
                      name="tunnel_indexation"
                      type="text"
                      margin="dense"
                      fullWidth
                    disabled = {isVacant} 
                      onBlur={handleBlur}
                      helperText={errors.tunnel_indexation ? errors.tunnel_indexation : null}
                      error={errors.tunnel_indexation}
                      onChange={e => this.changeValue("tunnel_indexation", e.target.value, false)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.affectations}>Type de caution</div>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup column>
                    <FormControlLabel
                      control={
                        <Checkbox
                    disabled = {isVacant}
                          name="caution_garantie_solvabilite"
                          checked={values.caution_garantie_solvabilite}
                          onChange={this.handleChangeCheckbox}
                          value={this.state.caution_garantie_solvabilite}
                        />
                      }
                      label="Garantite solvabilité"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                    disabled = {isVacant} 
                          name="caution_garantie_premiere_demande"
                          checked={values.caution_garantie_premiere_demande}
                          onChange={this.handleChangeCheckbox}
                          value={this.state.caution_garantie_premiere_demande}
                        />
                      }
                      label="Garantie 1ère demande"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                    disabled = {isVacant} 
                          name="caution_perso"
                          checked={values.caution_perso}
                          onChange={this.handleChangeCheckbox}
                          value={this.state.caution_perso}
                        />
                      }
                      label="Caution personnelle"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup column>
                    <FormControlLabel
                      control={
                        <Checkbox
                    disabled = {isVacant}
                          name="caution_societe"
                          checked={values.caution_societe}
                          onChange={this.handleChangeCheckbox}
                          value={this.state.caution_societe}
                        />
                      }
                      label="Caution société"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                    disabled = {isVacant}
                          name="caution_bancaire"
                          checked={values.caution_bancaire}
                          onChange={this.handleChangeCheckbox}
                          value={this.state.caution_bancaire}
                        />
                      }
                      label="Caution bancaire"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <Grid container justify="center" alignItems="flex-end" direction="column" spacing={8} />
                  {types_cautions.filter(tC => values[tC] >= 0 && values[tC] !== " ").map(type_caution => (
                    <Grid item>
                      <TextField
                    disabled = {isVacant} 
                        id={type_caution}
                        value={values[type_caution]}
                        label={name_types_cautions[type_caution]}
                        name={type_caution}
                        type="text"
                        required  =  {values.duree_bail != 3}
                        margin="dense"
                        fullWidth
                        onBlur={handleBlur}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        helperText={errors[type_caution] && touched[type_caution] ? errors[type_caution] : null}
                        error={touched[type_caution] && errors[type_caution]}
                        onChange={e => this.changeValue(type_caution, e.target.value, false)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

{(isVacant || values.date_effective_depart) && ( <Grid>
<Grid item>
  <div className={classes.titreSection}>
	<Typography variant="title">Commercialisation locative</Typography>
  </div>
</Grid>
<Grid item>
  <Grid
	container
	justify="flex-start"
	alignItems="flex-start"
	direction="row"
	spacing={16}
	className={classes.bloc}
  >
	<Grid item xs={12}>
	  <TextField
		id="offre_en_cours"
		value={values.offre_en_cours}
		name="offre_en_cours"
		label="Offre en cours"
		type="text"
		margin="dense"
		required  =  {false}
		fullWidth
		onBlur={handleBlur}
		helperText={
		  errors.offre_en_cours && touched.offre_en_cours ? errors.offre_en_cours : null
		}
		error={touched.offre_en_cours && errors.offre_en_cours}
		className={classes.textFieldo}
		InputProps={{
		  inputComponent: NumberFormatCustom,
		}}
		onChange={e => this.changeValue("offre_en_cours", e.target.value, false)}
	  />
	</Grid>
	<Grid item xs={12}>
	  <TextField
		id="commentaire_offre"
		value={values.commentaire_offre}
		name="commentaire_offre"
		label="Commentaire"
		type="text"
		margin="dense"
		required  =  {false}
		fullWidth
		onBlur={handleBlur}
		helperText={
		  errors.commentaire_offre && touched.commentaire_offre
			? errors.commentaire_offre
			: null
		}
		error={touched.commentaire_offre && errors.commentaire_offre}
		className={classes.textFieldo}
		onChange={e => this.changeValue("commentaire_offre", e.target.value, false)}
	  />
</Grid>
	</Grid>
	</Grid>
	</Grid>
	)}

            <Grid item>
              <div className={classes.titreSection}>
                <Typography variant="title">Affectation</Typography>
              </div>
            </Grid>
            <Grid item>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                direction="row"
                spacing={16}
                className={classes.bloc}
              >
                <Grid item xs={6}>
                  <TextField
                    disabled = {isVacant} 
                    id="affectation_commerce"
                    value={values.affectation_commerce}
                    name="affectation_commerce"
                    label="Commerce"
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.affectation_commerce && touched.affectation_commerce
                        ? errors.affectation_commerce
                        : null
                    }
                    error={touched.affectation_commerce && errors.affectation_commerce}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: M2FormatCustom,
                    }}
                    onChange={e => this.changeValue("affectation_commerce", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled = {isVacant}
                    id="affectation_habitation"
                    value={values.affectation_habitation}
                    name="affectation_habitation"
                    label="Habitation"
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.affectation_habitation && touched.affectation_habitation
                        ? errors.affectation_habitation
                        : null
                    }
                    error={touched.affectation_habitation && errors.affectation_habitation}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: M2FormatCustom,
                    }}
                    onChange={e => this.changeValue("affectation_habitation", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled = {isVacant}
                    id="affectation_reserve"
                    value={values.affectation_reserve}
                    name="affectation_reserve"
                    label="Réserve"
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.affectation_reserve && touched.affectation_reserve
                        ? errors.affectation_reserve
                        : null
                    }
                    error={touched.affectation_reserve && errors.affectation_reserve}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: M2FormatCustom,
                    }}
                    onChange={e => this.changeValue("affectation_reserve", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled =  {isVacant}
                    id="affectation_bureau"
                    value={values.affectation_bureau}
                    name="affectation_bureau"
                    label="Bureau"
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.affectation_bureau && touched.affectation_bureau ? errors.affectation_bureau : null
                    }
                    error={touched.affectation_bureau && errors.affectation_bureau}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: M2FormatCustom,
                    }}
                    onChange={e => this.changeValue("affectation_bureau", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled = {isVacant} 
                    id="affectation_stockage"
                    value={values.affectation_stockage}
                    name="affectation_stockage"
                    label="Stockage"
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.affectation_stockage && touched.affectation_stockage
                        ? errors.affectation_stockage
                        : null
                    }
                    error={touched.affectation_stockage && errors.affectation_stockage}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: M2FormatCustom,
                    }}
                    onChange={e => this.changeValue("affectation_stockage", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="affectation_res_sous_sol"
                    value={values.affectation_res_sous_sol}
                    name="affectation_res_sous_sol"
                    label="Réserve sous-sol"
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    disabled = {isVacant} 
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.affectation_res_sous_sol && touched.affectation_res_sous_sol
                        ? errors.affectation_res_sous_sol
                        : null
                    }
                    error={touched.affectation_res_sous_sol && errors.affectation_res_sous_sol}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: M2FormatCustom,
                    }}
                    onChange={e => this.changeValue("affectation_res_sous_sol", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="affectation_comm_sous_sol"
                    value={values.affectation_comm_sous_sol}
                    name="affectation_comm_sous_sol"
                    label="Commerce sous-sol"
                    disabled = {isVacant} 
                    type="text"
                    margin="dense"
                    required  =  {values.duree_bail != 3}
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.affectation_comm_sous_sol && touched.affectation_comm_sous_sol
                        ? errors.affectation_comm_sous_sol
                        : null
                    }
                    error={touched.affectation_comm_sous_sol && errors.affectation_comm_sous_sol}
                    className={classes.textFieldo}
                    InputProps={{
                      inputComponent: M2FormatCustom,
                    }}
                    onChange={e => this.changeValue("affectation_comm_sous_sol", e.target.value, false)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h2>
                    Affectation totale{" "}
                    {parseFloat(values.affectation_bureau) +
                      parseFloat(values.affectation_reserve) +
                      parseFloat(values.affectation_commerce) +
                      parseFloat(values.affectation_stockage) +
                      parseFloat(values.affectation_habitation) +
                      parseFloat(values.affectation_res_sous_sol) +
                      parseFloat(values.affectation_comm_sous_sol)}{" "}
                    m²
                </h2>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <div className={classes.titreSection}>
                <Typography variant="title">Financement Corporate</Typography>
              </div>
            </Grid>
            <Grid item>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                direction="row"
                spacing={16}
                className={classes.bloc}
              >
                <Grid item xs={6}>
                <FormControl className={classes.select} margin="dense">
                    <InputLabel htmlFor="idtype_bail-native-helper">Financement Corporate</InputLabel>
                    <NativeSelect
                      value={values.financement_corporate}
                      onChange={(event, key) => this.changeValue("financement_corporate", event.target.value)}
                    >
                      <option value={true} key="true">
                        OUI
                      </option>
                      <option value={false} key="false">
                        NON
                      </option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                {(this.state.financement_corporate || values.financement_corporate) && ( 
                <Grid>
                <Grid item xs={6}>
                <FormControl>
                  <InputLabel htmlFor="societe-native-helper">Banque</InputLabel>
                  <Select
                    value={values.id_banque_financement_corporate}
                    onChange={(event, key) =>
                      this.changeValue("id_banque_financement_corporate", parseInt(event.target.value, 10))
                    }
                    input={<Input name="id_banque_financement_corporate" id="banque-native-helper" />}
                    autoFocus
                  >
                    {this.state.banques
                      .sort((b2, b1) => {
                        if (b1.nom_banque > b2.nom_banque) return -1;
                        if (b1.nom_banque < b2.nom_banque) return 1;
                        return 0;
                      })
                      .map(s => (
                          <MenuItem value={s.idbanque} key={s.idbanque}>
                            <img src={"data:image/jpeg;" + s.image64}  style={{height: 25, paddingTop: 5}}/>
                            { s.nom_banque.toUpperCase()} 
                          </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText id="name-helper-text"> </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6}>
                <DatePicker
                    className={classes.dateP}
                    value={values.fin_de_financement || null}
                    onChange={date => this.handleDateChange("fin_de_financement", date)}
                    format="DD/MM/YYYY"
                    autoOk
                    label="Fin de financement"
                    emptyLabel="JJ/MM/AAAA"
                    cancelLabel="Annuler"
                    invalidDateMessage="Date non valide"
                    keyboard
                    mask={value => (value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [])}
                  />
                </Grid>
                </Grid>
                )}
                </Grid>
                 
            </Grid>
            {changed &&
              !readOnly && (
                <Grid container justify="center" alignItems="center" direction="row" spacing={8}>
                  {Object.keys(errors).length > 0 && (
                    <Grid item xs={12} style={{ textAlign: "center", color: "red", marginTop: "1em" }}>
                      Le formulaire comporte des erreurs.
                  </Grid>
                  )}
                  <Grid item xs={3} className={classes.submitButton}>
                    <Button
                      variant="outlined"
                      disabled={isSubmitting || {isVacant}}
                      color="textSecondary"
                      fullWidth
                      onClick={this.handleCancel}
                    >
                      Annuler
                  </Button>
                  </Grid>
                  <Grid item xs={3} className={classes.submitButton}>
                    <Button
                      disabled={isSubmitting && isVacant}
                      variant="contained"
                      color="primary"
                      fullWidth
                      // className={isSubmitting ? classes.buttonHidden : classes.button}
                      type="submit"
                    >
                      {!isSubmitting ? "Sauvegarder" : "Sauvegarde en cours..."}
                    </Button>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </fieldset>
      </form>
    );
  }
}
export default withStyles(styles)(withRouter(withGlobals(BailForm)));
