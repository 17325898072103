import React from "react";
import headerStyle from "../../common/tableHeaderStyle";
import paddedTableHeaderStyle from "../../common/paddedTableHeaderStyle";
const columnData = [
  {
    Header: "Id local",
    accessor: "idlocal",
    width: 75,
    headerStyle,
    Cell: row => <span>{row.value}</span>
  },
  {
    Header: "Code postal",
    accessor: "code_postal",
    width: 120,
    headerStyle,
    Cell: row => <span>{row.value}</span>
  },
  {
    Header: "Ville",
    accessor: "ville",
    width: 150,
    headerStyle,
    Cell: row => <span>{row.value}</span>
  },
  {
    Header: "Adresse",
    accessor: "adresse",
    headerStyle: paddedTableHeaderStyle,
    Cell: row => <span>{row.value}</span>
  },
  {
    Header: "Surface",
    accessor: "surface_totale",
    width: 120,
    Cell: props => (
      <div style={{ paddingRight: "25px" }}>
        {props.value} {"m²"}
      </div>
    ),
    headerStyle: { ...paddedTableHeaderStyle, paddingRight: "45px" }
  },
{ Header: "Société", accessor: "entite", width: 135, headerStyle }
];

export default columnData;
