
import React, { Component } from "react";
import { withRouter } from 'react-router-dom'



class Headers extends Component {
    constructor(props) {
        super(props);
        this.state = {
          lang: 'fr',
          visible: true,
          topScroll: 0
        };
      }
      handleLogout = () => {
        localStorage.removeItem("madar");
        window.location = "/";
      
      };
render() {
    return ( 
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
   
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    
    <li class="nav-item">
      <a class="nav-link" data-widget="fullscreen" href="#" role="button">
        V 1.22.10.09
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-widget="fullscreen" href="#" role="button">
        <i class="fas fa-expand-arrows-alt"></i>
      </a>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        {/* <i class="far fa-bell"></i> */}
        <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
        
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header" style={{cursor:"pointer"}} onClick={() => (window.location = "/profil")}>Profil</span>
        <div class="dropdown-divider"></div>
    
        <span class="dropdown-item dropdown-header" style={{cursor:"pointer"}} onClick={this.handleLogout}>Déconnexion</span>
    
   
        
        
       
      </div>
    </li>
  </ul>
</nav>

);
}
}

export default (withRouter(Headers))