import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Centered from "../layout/Centered";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import DoneIcon from "@material-ui/icons/Done";
import UserIcon from "@material-ui/icons/Person";
import { withStyles } from "@material-ui/core/styles";
import withGlobals from "./withGlobals";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  titre: {
    padding: theme.spacing.unit * 2,
    backgroundColor: "#ececec",
    border: `solid 1px ${theme.palette.primary.dark}`,
  },
  registred: {
    padding: theme.spacing.unit * 2,
    backgroundColor: "#ececec",
    border: `solid 1px green`,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  button: {
    marginTop: theme.spacing.unit * 4,
  },
  icon: {
    height: "8em",
    width: "8em",
    fill: theme.palette.primary.dark,
  },
  iconR: {
    height: "2em",
    width: "2em",
    fill: "green",
  },
  messageR: {
    fontSize: "1.2em",
    color: "green",
  },
});

class Register extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onLoginSuccess: PropTypes.func.isRequired,
  };

  state = {
    error: null,
    submiting: false,
    login: undefined,
    password: undefined,
    passwordConfirm: undefined,
    nom: undefined,
    prenom: undefined,
    registred: false,
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    const { login, password, nom, prenom } = this.state;
    this.props
      .fetch({
        url: "/users/",
        method: "POST",
        body: { login, password, nom, prenom, id_droits: 1, id_groupe: 0, cloture: false },
      })
      .then(user => {
        this.setState({ registred: true });
      });
  };

  render() {
    const { classes } = this.props;
    const { registred } = this.state;
    return (
      <Centered spacing={8} alignItems="center" justify="center">
        <Grid item xs={6}>
          <Paper elevation={registred ? 0 : 10} className={classes.root}>
            <form className={classes.container} onSubmit={this.handleSubmit}>
              <Grid item xs={12} className={registred ? classes.registred : classes.titre}>
                <Grid container direction="row" alignItems="center" justify="space-between" />
                {this.state.registred && [
                  <Grid item xs={12}>
                    <DoneIcon className={classes.iconR} />
                  </Grid>,
                  <Grid item xs={12}>
                    <div className={classes.messageR}>
                      {"Le compte a été créé."}
                      <br />
                      {"Il est en attente d'activation"}
                    </div>
                  </Grid>,
                ]}
                {!this.state.registred && <Grid item>{"Création d'un compte sur le site"}</Grid>}
              </Grid>
              {!registred && (
                <Grid container justify="center" alignItems="center" direction="row" spacing={8}>
                  <Grid item xs={6}>
                    <Grid container direction="column" alignItems="center" justify="center" spacing={8}>
                      <Grid item>
                        <UserIcon className={classes.icon} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container direction="column" alignItems="center" justify="center" spacing={8}>
                      <Grid item>
                        <TextField
                          label="Nom"
                          className={classes.textField}
                          name="nom"
                          onChange={this.handleChange}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Prénom"
                          className={classes.textField}
                          name="prenom"
                          onChange={this.handleChange}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Identifiant (email)"
                          name="login"
                          required
                          type="email"
                          className={classes.textField}
                          onChange={this.handleChange}
                          autoFocus
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Mot de passe"
                          type="password"
                          required
                          className={classes.textField}
                          name="password"
                          onChange={this.handleChange}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Mot de passe (confirm.)"
                          type="password"
                          required
                          className={classes.textField}
                          name="passwordConfirm"
                          onChange={this.handleChange}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.button}
                      type="submit"
                    >
                      Créer cet utilisateur
                    </Button>
                  </Grid>
                </Grid>
              )}
            </form>
          </Paper>
        </Grid>
      </Centered>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Register)));
