import React from "react";
// import PropTypes from 'prop-types';
import { withRouter, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DialogContent from "@material-ui/core/DialogContent";
import CustomDialogTitle from "../../common/CustomDialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import NumberFormat from "react-number-format";
import ListeActifs from "../../common/ListeActifs";
import columnDataActif from "../Actif/columnData";
import FicheActifs from "./FicheActifs";
import TrancheForm from "./TrancheForm";
import TranchesTable from "./TranchesTable";
import FicheEmprunt from "./FicheEmprunt.js";
import AmortissementCouverture from "./AmortissementCouverture";
import withGlobals from "../withGlobals";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    root: {
      padding: theme.spacing.unit,
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      width: 200,
    },
    actifSelected: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
    },
    actifSelectedLabel: {
      color: "rgba(0, 0, 0, 0.54)",
      padding: 0,
      fontSize: "0.8rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: 1.2,
      marginBottom: theme.spacing.unit,
    },
    textFieldLong: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 300,
    },
    button: {
      margin: 0,
      height: 40,
      minHeight: 40,
      width: 40,
      minWidth: 40,
    },
    buttonHidden: {
      marginTop: theme.spacing.unit * 4,
      visibility: "hidden",
    },
    label: {
      paddingLeft: theme.spacing.unit,
    },
  };
};

class EmpruntForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actifs: props.actifs || [],
      banques: [],
      correspondancesFinanceActiveMadar: [],
      amortissements: [],
      actifsPortefeuille: props.emprunt ? props.emprunt.actifs : [],
      nom_portefeuille: props.emprunt ? props.emprunt.nom_portefeuille : "",
      trancheIdxSelected: null,
      actifIdxSelected: 0,
      dialogOpen: !props.emprunt && !props.empruntId,
      actifSelected: null,
      tranches: props.emprunt ? props.emprunt.tranches : [],
      changed: false,
      forceReload: false,
      editMode: props.emprunt && props.emprunt.tranches.length > 0 ? false : true,
      loadingAmortissements: false,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.location.search !== nextProps.location.search) {
      this.loadActif(nextProps.actifId);
    }
  };

  componentDidMount = () => {
    this.loadBanques();
    this.loadCorrespondanceFinanceActiveMadar();
console.log('Load Banques Emprunt');
    if (this.props.actifId) {
      this.loadActif(this.props.actifId);
    }
  };

  handleActifsLoaded = actifs => this.setState({ actifs: actifs.filter((e) => e.financement == false ) });

  handleSelectActif = (e, actifSelected) => {
    if (!this.state.actifsPortefeuille.find(a => a.idactifpatrimonial === actifSelected)) {
      if (this.props.emprunt && this.props.emprunt.idinvestissement) {
        this.loadActif(actifSelected);
      } else {
        this.props.history.push(`/emprunts/nouveau?actifId=${actifSelected}`);
      }
    }
  };

  handleDeleteActif = idactifpatrimonial => {
    this.setState({
      actifsPortefeuille: this.state.actifsPortefeuille.filter(
        a => a.idactifpatrimonial !== idactifpatrimonial,
      ),
    });
    if (this.state.tranches.length > 0) {
      this.handleSauvegarde(this.state.tranches[0].idemprunt);
    }
  };

  handleDeleteCurrentTranche = () => {
    this.handleDeleteTrancheIdx(this.state.trancheIdxSelected);
  };

  handleDeleteTrancheIdx = idxTranche => {
    const idemprunt = this.props.emprunt ? this.state.tranches[0].idemprunt : null;
    const tranches = this.state.tranches.filter((a, idx) => idx !== idxTranche);
    this.setState({
      tranches,
      trancheIdxSelected: null,
    });
    setTimeout(() => this.handleSauvegarde(idemprunt), 100);
  };

  handleSelectTranche = idx => {
    if (this.state.trancheIdxSelected !== idx) {
      this.loadAmortissements(idx);
    }
    this.setState({
      trancheIdxSelected: this.state.trancheIdxSelected !== idx ? idx : null,
      forceReload: true,
      editMode: false,
      amortissements: [],
      couverture: [],
    });
    setTimeout(() => this.setState({ forceReload: false }), 10);
  };

  handleSetActif = actifIdxSelected => this.setState({ actifIdxSelected });

  loadAmortissements = idx => {
    this.setState({ loadingAmortissements: true });
    this.props
      .fetch({
        url: `/amortissements/${this.state.tranches[idx].idamortissement}`,
      })
      .then(res => {
        const amortissements = JSON.parse(res.amortissements);
        const amortissements_finance_active = JSON.parse(res.amortissements_finance_active);
        const couverture = res.couverture ? JSON.parse(res.couverture) : [];
        if (amortissements_finance_active && amortissements_finance_active.length > 0) {
          this.setState({
            loadingAmortissements: false,
            amortissements: amortissements_finance_active,
            couverture,
          });
        } else {
          this.setState({
            loadingAmortissements: false,
            amortissements,
            couverture,
          });
        }
        
      })
      .catch(e => {
        console.log("error", e);
        this.setState({
          loadingAmortissements: false,
          amortissements: [],
          couverture: [],
        });
      });
  };

  handleOpenDialog = () => this.setState({ dialogOpen: true });

  handleCloseDialog = () => {
    const { actifsPortefeuille, tranches } = this.state;
    if (actifsPortefeuille.length === 0 && tranches.length === 0) {
      return this.props.history.push("/emprunts");
    }
    this.setState({ dialogOpen: false });
  };

  handleToggleEditMode = (idx = null) =>
    idx !== null
      ? this.setState({
          editMode: !this.state.editMode,
          trancheIdxSelected: idx,
        })
      : this.setState({
          editMode: !this.state.editMode,
          trancheIdxSelected: null,
        });

  loadActif = id =>
    this.props.fetch({ url: `/actifs/${id}` }).then(actif => {
      console.log("load", this.props.emprunt);
      this.setState({
        actifsPortefeuille: this.state.actifsPortefeuille.concat([actif]),
        actifIdxSelected: this.state.actifsPortefeuille.length,
        dialogOpen: false,
      });
      // if (this.props.emprunt) {
      //   const { tranches } = this.props.emprunt;
      //   this.handleSauvegarde(tranches[0].idemprunt);
      // }
    });


  loadBanques = () => this.props.fetch({method: "POST", url: `/banques/`, body: { ALL: "ALL" } }).then(banques => this.setState({ banques }));
  loadCorrespondanceFinanceActiveMadar = () => {
    this.setState({ loadingAmortissements: true });
    this.props.fetch({method: "POST", url: `/banques/`, body: { correspondance_finance_active_madar_v2: "GET" } }).then(correspondancesFinanceActiveMadar => {
    
    this.setState({ correspondancesFinanceActiveMadar });
    this.setState({ loadingAmortissements: false });
  })};
  handleSubmit = values => {
    const {
      montant_financement,
      duree_financement,
      idbanque,
      date_premiere_echeance,
      decalage_amortissement,
      date_debut_cap,
      date_debut_swap,
	  capital_restant,
    } = values;
	
    const { trancheIdxSelected, tranches } = this.state;
    const idemprunt =
      this.props.emprunt || (this.state.tranches && this.state.tranches.length > 0)
        ? this.state.tranches[0].idemprunt
        : null;
    const tranche = {
      ...values,
      idemprunt,
      montant_financement: parseInt(montant_financement, 10),
      duree_financement: parseInt(duree_financement, 10),
      idbanque: parseInt(idbanque, 10),
      date_premiere_echeance: moment(date_premiere_echeance)
        .add(4, "hours")
        .toISOString(),
      decalage_amortissement: decalage_amortissement
        ? moment(decalage_amortissement)
            .add(4, "hours")
            .toISOString()
        : null,
      date_debut_cap: date_debut_cap
        ? moment(date_debut_cap)
            .add(4, "hours")
            .toISOString()
        : null,
      date_debut_swap: date_debut_swap
        ? moment(date_debut_swap)
            .add(4, "hours")
            .toISOString()
        : null,
	  capital_restant: parseInt(montant_financement, 10),
    };

    const tranchesM =
      trancheIdxSelected !== null
        ? tranches.map((t, idx) => (idx === trancheIdxSelected ? tranche : t))
        : tranches.concat([tranche]);

    this.setState({
      tranches: tranchesM,
      forceReload: true,
      editMode: false,
    });

    setTimeout(() => this.setState({ forceReload: false }), 10);

    this.handleSauvegarde(idemprunt);
console.log(idemprunt);
if (idemprunt != null){
setTimeout(() =>    
window.location.reload() , 600);
}


  };

  handleSauvegarde = idemprunt => {
    const body = {
      actifs: this.state.actifsPortefeuille.map(a => a.idactifpatrimonial),
      tranches: this.state.tranches,
      nom_portefeuille: this.state.nom_portefeuille,
    };

    this.props
      .fetch({
        url: `/emprunts/${idemprunt ? idemprunt : ""}`,
        method: idemprunt ? "PUT" : "POST",
        body,
      })
      .then(datas => {
        if (body.tranches.length === 0) {
          this.props.history.push("/emprunts");
        }

        const { emprunts } = JSON.parse(datas.investissements);

        var trancheIdxSelected = this.state.trancheIdxSelected || emprunts.length - 1;
        if (!this.state.trancheIdxSelected){
		trancheIdxSelected = 0;
	}
        const { idactifpatrimonial } = this.state.actifsPortefeuille[this.state.actifIdxSelected];

	 this.state.actifsPortefeuille[this.state.actifIdxSelected].nom_portefeuille =  this.state.nom_portefeuille;
	 this.state.actifs[this.state.actifIdxSelected].nom_portefeuille =  this.state.nom_portefeuille;

/*        const newState = {
          tranches: emprunts.map(e => ({ ...e, idactifpatrimonial })),
          editMode: false,
          trancheIdxSelected,
        };

        this.setState(newState);
*/
        // if (trancheIdxSelected !== null) {

        //   this.loadAmortissements(trancheIdxSelected);
        // }
console.log("Here");
let now = new Date();
console.log(now);
//         window.location="/emprunts/"+datas.idinvestissement+"?date="+now;
//      window.location.reload();

if(!this.props.emprunt){
 window.location="/emprunts/"+datas.idinvestissement+"?date="+now;
}
        this.props.openNotification(
          "success",
          `Emprunt ${this.props.emprunt ? "modifié" : "créé"}`,
          undefined,
          4000,
        );
//window.location.reload();

        const newState = {
          tranches: emprunts.map(e => ({ ...e, idactifpatrimonial })),
          editMode: false,
          trancheIdxSelected,
        };
        this.setState(newState);

      }).catch(e => {
        if (body.tranches.length === 0) {
          this.props.history.push("/emprunts");
       
        this.props.openNotification(
          "success",
          "Emprunt supprimé",
          undefined,
          2000,
        );

setTimeout(
    function() {
      window.location.reload();
    }, 2000);


 }

 
      });
  };

  handleChangeNomPortefeuille = nom_portefeuille => {
    this.setState({ nom_portefeuille });
    const idemprunt =
      this.props.emprunt || (this.state.tranches && this.state.tranches.length > 0)
        ? this.state.tranches[0].idemprunt
        : null;
    setTimeout(() => this.handleSauvegarde(idemprunt), 100);
  };

  render() {
    const { classes, readOnly } = this.props;
    const {
      actifs,
      actifsPortefeuille,
      nom_portefeuille,
      banques,
      amortissements,
      couverture,
      tranches,
      trancheIdxSelected,
      actifIdxSelected,
      forceReload,
      loadingAmortissements,
      editMode,
      correspondancesFinanceActiveMadar,
    } = this.state;
	
	

    var idactifpatrimonial =
      actifsPortefeuille &&
      actifsPortefeuille[actifIdxSelected] &&
      actifsPortefeuille[actifIdxSelected].idactifpatrimonial
        ? actifsPortefeuille[actifIdxSelected].idactifpatrimonial
        : undefined;
      idactifpatrimonial = (idactifpatrimonial==undefined && actifsPortefeuille &&  actifsPortefeuille[0] &&  actifsPortefeuille[0].idactifpatrimonial)? actifsPortefeuille[0].idactifpatrimonial : undefined;

    const montant_financement = actifsPortefeuille
      .filter(a => a.idactifpatrimonial === idactifpatrimonial)
      .reduce(
        (m, a) =>
          m +
          a.prix_net_vendeur +
          a.frais_de_notaire +
          a.commissions_intermediaire +
          a.travaux_bailleur +
          a.indemnite_deviction +
          a.fond_commerce,
        0,
      );

    console.log("trancheIdxSelected", trancheIdxSelected, actifsPortefeuille);

    const emprunt =
      trancheIdxSelected === null
        ? {
            montant_financement,
            idactifpatrimonial,
            date_premiere_echeance: null,
            duree_financement: 0,
            frequence: "trimestrielle",
            valeur_residuelle_emprunt: 0,
            type_emprunt: "taux_fixe",
            couverture_taux: null,
            type_amortissement: "constant",
            montant_prime_lisse: 0,
            prime_cap: null,
            coefprogressivites: [],
            decalage_amortissement: null,
            duree_cap: 0,
            duree_swap: 0,
            date_differee: null,
            strike_cap: 0,
            taux_cap: 0,
            idbanque: null,
            date_debut_swap: null,
            montant_swap: 0,
            taux_swap: 0,
            montant_cap: 0,
            date_debut_cap: null,
            taux: 0,
            marge_banque: 0,
          }
        : {
            ...tranches[trancheIdxSelected],
            date_premiere_echeance: moment(tranches[trancheIdxSelected].date_premiere_echeance).format(
              "YYYY-MM-DD",
            ),
            decalage_amortissement: tranches[trancheIdxSelected].decalage_amortissement
              ? moment(tranches[trancheIdxSelected].decalage_amortissement).format("YYYY-MM-DD")
              : null,
            date_debut_swap: tranches[trancheIdxSelected].date_debut_swap
              ? moment(tranches[trancheIdxSelected].date_debut_swap).format("YYYY-MM-DD")
              : null,
            date_debut_cap: tranches[trancheIdxSelected].date_debut_cap
              ? moment(tranches[trancheIdxSelected].date_debut_cap).format("YYYY-MM-DD")
              : null,
          };

    const tranchesActif = tranches.filter(t => {
      return (
        !t.idactifpatrimonial ||
        (actifsPortefeuille[actifIdxSelected] &&
          t.idactifpatrimonial === actifsPortefeuille[actifIdxSelected].idactifpatrimonial)
      );
    });

    const showAmortissements = amortissements.length > 0 && trancheIdxSelected !== null && !editMode;
    return (
      <Grid container justify="space-between" alignItems="space-between" direction="row" spacing={8}>
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="flex-start" justify="flex-start">
            <Grid item>
              <IconButton color="primary" title="Liste des emprunts" component={Link} to={`/emprunts`}>
                <ArrowBackIcon className={classes.rightIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.dialogOpen}
          fullScreen
          TransitionComponent={Transition}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <CustomDialogTitle title="Sélectionnez un actif" onClose={this.handleCloseDialog} />
          <DialogContent>
            <ListeActifs
              onSelectRow={this.handleSelectActif}
              datas={actifs}
              onDatasLoaded={this.handleActifsLoaded}
              apiPathname="/actifs/"
              datasName="actifs"
              idField="idactifpatrimonial"
              inDialog
              columnData={columnDataActif}
	      showIdActif
            />
          </DialogContent>
        </Dialog>

        {actifsPortefeuille &&
          actifsPortefeuille.length > 0 && [
            <Grid item xs={showAmortissements ? 3 : 4}>
              <Grid container direction="column" alignItems="stretch" spacing={16}>
                <Grid item>
                  {/* <Paper elevation={3}> */}
                  {trancheIdxSelected === null && (
                    <FicheActifs
                      actifs={actifsPortefeuille}
                      onChangeAcfif={this.handleSetActif}
                      actifIdxSelected={actifIdxSelected}
                      onOpenActifsDialog={this.handleOpenDialog}
                      onDeleteActif={this.handleDeleteActif}
                      nom_portefeuille={nom_portefeuille}
                      changeNomPortefeuille={this.handleChangeNomPortefeuille}
                      readOnly={readOnly}
                    />
                  )}
                  {trancheIdxSelected !== null && (
                    <FicheEmprunt
                      actifs={actifsPortefeuille}
                      emprunt={emprunt}
                      onEditClick={this.handleToggleEditMode}
                      onDeleteClick={this.handleDeleteCurrentTranche}
                      banque={banques.find(b => b.idbanque === emprunt.idbanque)}
                      nombreTranches={tranches.length}
                      noTranche={emprunt.idamortissement}
                      readOnly={readOnly}
                    />
                  )}
                  {/* </Paper> */}
                </Grid>
                {this.state.tranches.length > 0 && (
                  <Grid item>
                    {trancheIdxSelected !== null && (
                      <Button
                        style={{ marginTop: "1em" }}
                        variant="raised"
                        onClick={() => this.handleSelectTranche(trancheIdxSelected)}
                      >
                        <ArrowBackIcon />
                        {"  "}Retour à la liste des tranches
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>,
            <Grid item xs={showAmortissements ? 9 : 8}>
              {loadingAmortissements && <CircularProgress size={50} />}
              {!loadingAmortissements &&
                !forceReload &&
                // trancheIdxSelected !== null &&
                editMode && (
                  <Paper elevation={3} className={classes.rooti}>
                    <TrancheForm
                      emprunt={emprunt}
                      banques={banques}
                      onSubmit={this.handleSubmit}
                      onCancel={this.handleToggleEditMode}
                      editMode={trancheIdxSelected !== null}
                      identifiant_transaction_finance_active={trancheIdxSelected !== null && correspondancesFinanceActiveMadar.filter(elm => elm.idamortissement == emprunt.idamortissement).length > 0 ? correspondancesFinanceActiveMadar.filter(elm => elm.idamortissement == emprunt.idamortissement)?.[0]?.id_finance_active : ''}
                      id_tranche_madar={trancheIdxSelected !== null ? emprunt.idamortissement : ''}
                      handleDelete={this.handleDeleteCurrentTranche}
                    />
                  </Paper>
                )}
              {!loadingAmortissements &&
                !forceReload &&
                amortissements.length === 0 &&
                !editMode && (
                  <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <Grid item xs={10} style={{ textAlign: "left", paddingLeft: "0.5em" }}>
                      <Typography variant="title" style={{ lineHeight: "40px" }}>
                        {tranchesActif.length === 0 ? "Aucune tranche" : "Tranche(s)"} d'emprunt pour l'actif
                      </Typography>
                    </Grid>
                    {!readOnly && (
                      <Grid item xs={2} style={{ textAlign: "right" }}>
                        <Button
                          color="primary"
                          variant="fab"
                          size="small"
                          onClick={() => this.handleToggleEditMode(null)}
                          title="Ajouter une tranche"
                          className={classes.button}
                          style={{ marginTop: 0, marginBottom: "0.5em" }}
                        >
                          <AddIcon />
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {tranchesActif.length > 0 &&
                        banques.length > 0 && (
                          <TranchesTable
							tranches={tranchesActif}
                            nombreTranches={tranches.length}
                            onClick={this.handleSelectTranche}
                            onEditClick={this.handleToggleEditMode}
                            readOnly={readOnly}
                            onDeleteClick={this.handleDeleteTrancheIdx}
                            banques={banques}
                          />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Grid item style={{ padding: "0.5em" }}>
                          <Typography variant="subheading">
                            Total emprunté :{" "}
                            <strong>
                              <NumberFormat
                                value={tranchesActif.reduce((m, t) => m + t.montant_financement, 0)}
                                displayType={"text"}
                                thousandSeparator=" "
                                suffix={" €"}
                              />
                            </strong>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              {showAmortissements && (
                <AmortissementCouverture
                  openNotification={this.props.openNotification}
                  trancheIndex={this.state.trancheIdxSelected}
                  emprunt={emprunt}
                  amortissements={amortissements}
                  couverture={couverture}
                  id_tranche_madar={trancheIdxSelected !== null ? emprunt.idamortissement : ''}
                  identifiant_transaction_finance_active={trancheIdxSelected !== null && correspondancesFinanceActiveMadar.filter(elm => elm.id_tranche_madar == emprunt.idactifpatrimonial).length > 0 ? correspondancesFinanceActiveMadar.filter(elm => elm.id_tranche_madar == emprunt.idactifpatrimonial)?.[0]?.id_finance_active : ''}
                />
              )}
            </Grid>,
          ]}
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withGlobals(EmpruntForm)));
