import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../withGlobals";
import NumberFormatCustom from "../../common/NumberFormatCustom";
import PrctFormatCustom from "../../common/PrctFormatCustom";
import NumberFormat from "react-number-format";

class SimulateurEmpruntDialog extends Component {
  constructor(props) {
    super(props);
    this.titresSimulation = {
      echeance_annee: "Annuités (Capital + intérêts)",
    };
    this.state = {
      datas: {
        capital: "0",
        taux: "0",
        duree_annee: "",
      },
      simulation: null,
    };
  }

  handleChange = (e, field) => {
    console.log("e", field, e.target.value);
    this.setState({
      datas: { ...this.state.datas, [field]: e.target.value },
      simulation: null,
    });
  };

  handleSimulate = () => {
    ////emprunts/calculateur?data={"taux":"1.50","capital":"10000000","duree_annee":"15"}
    this.props
      .fetch({
        url: `/emprunts/calculateur?data=${JSON.stringify(this.state.datas)}`,
      })
      .then(simulation => {
        this.setState({ simulation: JSON.parse(simulation) });
      });
  };

  render() {
    const { open, handleClose } = this.props;
    const { datas: { capital, taux, duree_annee }, simulation } = this.state;
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="simuler-emprunt-title">
        <DialogTitle id="simuler-emprunt-title" style={{ minWidth: "500px", textAlign: "center" }}>
          Simuler un emprunt
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" alignItems="stretch" justify="center" spacing={16}>
            <Grid item>
              <Grid container direction="column" spacing={16} justify="flex-start" alignItems="center">
                <Grid item>
                  <TextField
                    name="capital"
                    label="Capital"
                    value={capital}
                    type="text"
                    margin="dense"
                    required
                    autoFocus
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onChange={e => this.handleChange(e, "capital")}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="duree_annee"
                    label="Durée (année)"
                    type="text"
                    value={duree_annee}
                    margin="dense"
                    required
                    fullWidth
                    onChange={e => this.handleChange(e, "duree_annee")}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="taux"
                    label="Taux"
                    value={taux}
                    type="text"
                    margin="dense"
                    required
                    fullWidth
                    InputProps={{
                      inputComponent: PrctFormatCustom,
                    }}
                    onChange={e => this.handleChange(e, "taux")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                spacing={18}
                style={{ height: "100%" }}
              >
                <Grid item>
                  <div style={{ width: "200px" }}>
                    {!simulation && (
                      <Button variant="outlined" fullWidth onClick={this.handleSimulate}>
                        Simuler
                      </Button>
                    )}
                    {simulation && (
                      <Grid
                        container
                        alignItems="stretch"
                        justify="flex-start"
                        spacing={18}
                        direction="column"
                      >
                        {Object.keys(simulation)
                          .filter(k => this.titresSimulation[k])
                          .map(k => {
                            return [
                              <Grid item>
                                <div
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography variant="body1">
                                    Annuités<br />(Capital + intérêts)
                                  </Typography>
                                </div>
                              </Grid>,
                              <Grid item>
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    marginTop: "1em",
                                  }}
                                >
                                  <NumberFormat
                                    value={parseInt(simulation[k], 10)}
                                    displayType={"text"}
                                    thousandSeparator=" "
                                    suffix={" €"}
                                  />
                                </div>
                              </Grid>,
                            ];
                          })}
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withGlobals(SimulateurEmpruntDialog);
