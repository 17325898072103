import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import validationSchema from "./validation";
import BailForm from "./Form";
import ConfirmWrapper from "../../common/ConfirmWrapper";
import ListeActifsDialog from "../../common/ListeActifsDialog";
import EnvoyerParMailDialog from "../../common/EnvoyerParMailDialog";
import SurfacesTotales from "./SurfacesTotales";
import withGlobals from "../withGlobals";
import moment from "moment";
import { serialize, deSerialize } from "./typeDeBailHandler";

const styles = theme => {
  return {
    base: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 1,
      paddingBottom: theme.spacing.unit * 2,
      // marginLeft: theme.spacing.unit * 5,
      // marginRight: theme.spacing.unit * 5,
      // width: `calc(100% - ${theme.spacing.unit * 2})`,
    },
    fullHeight: {
      display: "flex",
      //height: "calc(100vh - 150px)",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
    button: {
      marginTop: theme.spacing.unit * 4,
    },
    buttonHidden: {
      marginTop: theme.spacing.unit * 4,
      visibility: "hidden",
    },
    icon: {
      height: "3em",
      width: "3em",
      fill: theme.palette.primary.dark,
    },
    title: {
      marginBottom: 0,
      fontSize: 14,
      lineHeight: "36px",
      fontWeight: "bold",
    },
    rightIcon: {
      marginLeft: theme.spacing.unit / 2,
      marginRight: theme.spacing.unit / 2,
    },
    titleArea: {
      border: `solid 1px ${theme.palette.primary.main}`,
      padding: "2px",
      borderRadius: "3px 3px",
      marginBottom: theme.spacing.unit * 2,
    },
  };
};

const BailFormik = withFormik({
  mapPropsToValues: props => ({ ...props.bail }),
  validationSchema: validationSchema,
  handleSubmit: (
    values,
    { props, setSubmitting, setErrors /* setValues, setStatus, and other goodies */ },
  ) => {
    const type_de_bail = serialize(values);
   /* if (type_de_bail === "") {
      setSubmitting(false);
      setErrors({ duree_bail_1: "Vous devez saisir une durée de bail" });
      return;
    }*/
    props
      .onSubmit(values)
      .then(() => {
        setSubmitting(false);
      })
      .catch(e => {
        setSubmitting(false);
      });
  },
})(withStyles(styles)(BailForm));

class Bail extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    typesBaux: PropTypes.array.isRequired,
    typesActivites: PropTypes.array.isRequired,
    openNotification: PropTypes.func.isRequired,
    onFormDataChanged: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    onCreate: PropTypes.func,
    formDataChanged: PropTypes.bool.isRequired,
    onResetDataChanged: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,

      // pour forcer le remontage du formulaire
      changingDatas: false,

      submiting: false,
      justSaved: false,
      changed: false,
      simple: false,
      filtredDialogOpen: false,
      messageDialogOpen: false,
      typesActivites: props.typesActivites,
    };
  }

  componentDidMount = () => {
    const { typesActivites } = this.state;
    if (typesActivites.length === 0) {
      setTimeout(() => {
        this.props.fetch({ url: "/activites/" }).then(typesActivites => this.setState({ typesActivites }));
      }, 50);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.bail && this.props.bail && nextProps.bail.idbail !== this.props.bail.idbail) {
      this.setState({
        changingDatas: true,
        justSaved: false,
        submitting: false,
      });
      setTimeout(() => this.setState({ changingDatas: false }), 1);
    }
  };

  handleChangeVue = () => this.setState({ simple: !this.state.simple });
  handleSetBailVacant = () => {
    const bail = this.props.bail;

    this.setState({ submitting: true });



    return new Promise((resolve, reject) => {
      this.props
        .fetch({
          url: `/baux/${bail.idbail}`,
          method: "PUT",
          body: {
          },
        })
        .then(() => {
          window.scrollTo(0, 0);
          try {
            this.props.onResetDataChanged();
             this.props.openNotification(
              "success",
              `Le bail ${bail.idbail} a été mis comme VACANT.`,
              undefined,
              3000,
            );

             this.props.onUpdate(bail);
             this.props.openNotification("success", `Le bail ${bail.idbail} a été mis comme VACANT.`);

            this.setState({ submitting: false, justSaved: true });

            return resolve();
          } catch (e) {
            console.log(e);
            return reject(e);
          }
        }).catch((error) => {
        this.props.onUpdate(this.props.bail);
             this.props.openNotification("success", `Le bail ${this.props.bail.idbail} a été mis comme VACANT.`);
            this.setState({ submitting: false, justSaved: true });
setTimeout(
    function() {
      window.location.reload(); 
    }, 2000);


    });
    });
  };

  handleSubmit = values => {
    const {
      onResetDataChanged,
      openNotification,
      history,
      onUpdate,
      onCreate,
      baux,
      location: { search },
    } = this.props;
    const { adresse, code_postal, ville, idactifpatrimonial, surface_totale_actif, ...bail } = values;

    this.setState({ submitting: true });

    const {
      duree_bail_1,
      duree_bail_2,
      duree_bail_3,
      duree_bail_4,
      duree_bail_5,
      duree_bail_6,
      duree_bail_7,
      id_banque_financement_corporate,
      fin_de_financement,
      ...bailSansChampDuree
    } = bail;

    const type_de_bail = serialize(values);
    const v_id_banque_financement_corporate = (bail.financement_corporate) ? bail.id_banque_financement_corporate : 25;
    const v_fin_de_financement  = (bail.financement_corporate) ? bail.fin_de_financement : null;

    return new Promise((resolve, reject) => {
      var updated =  bail.idbail ? true : false;
      this.props
        .fetch({
          url: `/baux/${bail.idbail ? bail.idbail : ""}`,
          method: bail.idbail ? "PUT" : "POST",
          body: {
            ...bailSansChampDuree,
            id_banque_financement_corporate: v_id_banque_financement_corporate,
            fin_de_financement: v_fin_de_financement,
            type_de_bail,
            variation_indice: bail.variation_indice === 0 ? null : bail.variation_indice,
/*            date_dentree_du_locataire: moment(bail.date_dentree_du_locataire)
              .add(1, "day")
              .toISOString(),
            date_fin_bail: moment(bail.date_fin_bail)
              .add(1, "day")
              .toISOString(),
            date_effet_du_bail: moment(bail.date_effet_du_bail)
              .add(1, "day")
              .toISOString(),
            date_signature_bail: moment(bail.date_signature_bail)
              .add(1, "day")
              .toISOString(),
*/              
            bailactif: bail.idbail ? bail.bailactif : true,
          },
        })
        .then(bail => {
          window.scrollTo(0, 0);
          try {
            onResetDataChanged();
            openNotification(
              "success",
              !updated ? `Le bail ${bail.idbail} a été créé.` : `Le bail ${bail.idbail} a été modifié.`,
              undefined,
              4000,
            );
			
			 var createFlag = 0;

            if (bail.idbail) {
              onUpdate(bail);
            } else {
              let v = onCreate(bail);
			  createFlag = 1;
            }
            //openNotification("success", createFlag ? `Le bail ${bail.idbail} a été créé.` : `Le bail ${bail.idbail} a été modifié.`);

            this.setState({ submitting: false, justSaved: true });

            if (baux.length === 1 || !bail.idbail) {
              history.push(`/baux${search}`);
            }
            return resolve();
          } catch (e) {
            console.log(e);
            return reject(e);
          }
        });
      // this.timeoutRedirect = setTimeout(() => history.push('/baux'), 4100);
    });
  };

  handleDelete = () => {
    const {
      onResetDataChanged,
      openNotification,
      history,
      bail,
      onDelete,
      location: { search },
    } = this.props;
    this.setState({ submitting: true });
    this.props.fetch({ url: `/baux/${bail.idbail}`, method: "DELETE" }).then(() => {
      onResetDataChanged();
      openNotification(
        "success",
        `Bail ${bail.idbail} clôturé ! Afficher le local ?`,
        `/locaux/${bail.idlocal}`,
        4000,
      );
      this.setState({ submitting: false, justSaved: true });
      onDelete(bail.idbail);
      history.push(`/baux${search}`);
    });
  };

  handleClose = () => {
this.handleSetBailVacant();
  /*  this.props.openNotification(
      "warning",
      `La cloture des baux est temporairement désactivé`,
      null,
      4000,
    );
*/
  };

  handleOpenFilteredDialog = () => this.setState({ filtredDialogOpen: true });

  handleCloseFilteredDialog = () => this.setState({ filtredDialogOpen: false });

  handleOpenMessageDialog = () => this.setState({ messageDialogOpen: true });

  handleCloseMessageDialog = () => this.setState({ messageDialogOpen: false });

  handleActifPrecedent = index => {
    const { baux, history } = this.props;
    history.push(`/baux/${baux[index - 1].idbail}`);
  };

  handleActifSuivant = index => {
    const { baux, history } = this.props;
    history.push(`/baux/${baux[index + 1].idbail}`);
  };

  navigateTo = url => {
    this.props.history.push(url);
    this.handleCloseFilteredDialog();
  };

  render() {
    const {
      classes,
      match,
      bail,
      baux,
      formDataChanged,
      onFormDataChanged,
      onResetDataChanged,
      filtred,
      loading,
      readOnly,
      user
    } = this.props;

    const {
      submiting,
      justSaved,
      error,
      changingDatas,
      simple,
      filtredDialogOpen,
      messageDialogOpen,
      typesActivites,
    } = this.state;

    if (!bail) {
      return (
        <div className={classes.fullHeight}>
          <Grid container alignItems="center" direction="row" justify="center">
            <Grid item>{!loading && <Typography variant="display3">Ce bail n'existe pas.</Typography>}</Grid>
          </Grid>
        </div>
      );
    }

    const dureesBaux = deSerialize(bail.type_de_bail);
    const template = 'bail';

    return (
      <Paper elevation={3} className={classes.base}>
        <EnvoyerParMailDialog
          open={messageDialogOpen}
          objet={`Bail ID ${match.params.bailId} - ${bail.adresse} ${bail.code_postal} ${bail.ville}`}
          datas={{ ...bail, user }}
          template={template}
          handleClose={this.handleCloseMessageDialog}
        />
        <ListeActifsDialog
          open={filtredDialogOpen}
          handleClose={this.handleCloseFilteredDialog}
          titre="Sélectionnez un bail"
          baseUrl="/baux/"
          actifs={baux
            .map(a => ({
              id: a.idbail,
              label: `${a.adresse} ${a.code_postal} ${a.ville} / ${a.idactifpatrimonial}`,
            }))
            .sort((a, b) => a.adresse > b.adresse)}
        />
        <Grid
          container
          alignItems="center"
          direction="row"
          justify="space-between"
          className={classes.titleArea}
        >
          {(!filtred || (filtred && baux.length === 1) || Object.keys(baux).length > 1) && (
            <Grid item>


              <Button color="primary" title="Précédent" onClick={() => { this.props.history.goBack(); }}>
                <ArrowBackIcon className={classes.rightButton} />
              </Button>

            </Grid>
          )}
          <Grid item>
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={16}>
              {!bail.idlocal && (
                <Grid item>
                  <Typography className={classes.title} color="primary">
                    Nouveau bail
                  </Typography>
                </Grid>
              )}
              {bail.idlocal && (
                <Grid item style={{ textAlign: "right", minWidth: "400px" }}>
                  <SurfacesTotales localId={bail.idlocal} history={this.props.history} edit={bail.nom_du_locataire != undefined && bail.nom_du_locataire.length > 0 } />
                </Grid>
              )}
            </Grid>
            <Typography className={classes.title} color="primary" />
          </Grid>

          <Grid item>
            <Grid container alignItems="center" direction="row" justify="space-around">
              {submiting && (
                <Grid item>
                  <CircularProgress size={25} />
                </Grid>
              )}
              {justSaved &&
                !formDataChanged &&
                !error && (
                  <Grid item>
                    <DoneIcon color="primary" />
                  </Grid>
                )}
              {bail.idbail &&
                !submiting &&
                !justSaved &&
                !readOnly &&
                !error && (
                  <Fragment>
                    <Grid item>
                      <ConfirmWrapper
                        onAccept={this.handleClose}
                        message="Passer le bail en vacant ?"
                        title="Clôturer un bail"
                      >
                        <IconButton color="primary" title="Passer le bail en vacant">
                          <LockIcon className={classes.rightIcon} />
                        </IconButton>
                      </ConfirmWrapper>
                    </Grid>
                    <Grid item>
                      <IconButton color="primary" title="Envoyer par mail" onClick={this.handleOpenMessageDialog}>
                        <EmailIcon className={classes.rightIcon} />
                      </IconButton>
                    </Grid>
                  </Fragment>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" direction="row" justify="space-between">
          {!changingDatas && (
            <BailFormik
              bail={{
                id_banque_financement_corporate: 25,
                cap_indexation: 0,
                variation_indice: 0,
                duree_preavis: 0,
                valeur_locative: 0,
                depot_de_garantie: 0,
                frais_gestion: 0,
                charges_refacturees: 0,
                affectation_commerce: 0,
                offre_en_cours: 0,
                commentaire_offre: "",
                loyer_contractuel: 0,
                loyer_actuel: 0,
                trimestre: "",
                indice_reference: null,
                annee: "",
                nom_commercial:"",
	        destination_bail:"",
                tunnel_indexation: "",
                affectation_reserve: 0,
                affectation_bureau: 0,
                affectation_stockage: 0,
                affectation_res_sous_sol: 0,
                affectation_comm_sous_sol: 0,
                affectation_habitation: 0,
                duree_bail_1: dureesBaux.duree_bail_1,
                duree_bail_2: dureesBaux.duree_bail_2,
                duree_bail_3: dureesBaux.duree_bail_3,
                duree_bail_4: dureesBaux.duree_bail_4,
                duree_bail_5: dureesBaux.duree_bail_5,
                duree_bail_6: dureesBaux.duree_bail_6,
                duree_bail_7: dureesBaux.duree_bail_7,
                financement_corporate: false,
                ...bail,
                // paliers,
                date_dentree_du_locataire: bail.date_dentree_du_locataire
                  ? moment(bail.date_dentree_du_locataire)
                  : "",
                date_fin_bail: bail.date_fin_bail ? moment(bail.date_fin_bail) : "",
                date_effet_du_bail: bail.date_effet_du_bail ? moment(bail.date_effet_du_bail) : "",
                date_signature_bail: bail.date_signature_bail ? moment(bail.date_signature_bail) : "",
                date_effective_depart: bail.date_effective_depart ? moment(bail.date_effective_depart) : "",
                fin_de_financement: bail.fin_de_financement ? moment(bail.fin_de_financement) : "",
              }}
              typesActivites={typesActivites}
              onSubmit={this.handleSubmit}
              setFormDataChanged={onFormDataChanged}
              resetDataChanged={onResetDataChanged}
              changed={formDataChanged}
              send={this.send}
              vueSimplifiee={simple}
            />
          )}
        </Grid>
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Bail)));
