const filter = (datas, recherche) => {
  const { idinvestissement, idactifpatrimonial, nom_portefeuille, code_postal, ville, adresse, societe, banque } = recherche;

  const filter =
    idinvestissement !== "" ||
    idactifpatrimonial !== "" ||
    nom_portefeuille !== "" ||
    code_postal !== "" ||
    ville !== "" ||
    banque !== "" ||
    societe !== "" ||
    adresse !== "";
  return filter
    ? datas.filter(b => {
        let show = true;
        const a = b.portefeuille.actifs[0];
        const emp = b.portefeuille.emprunts[0];

        if (idinvestissement !== "" && show) show = `${b.idinvestissement}` === idinvestissement;
        if (idactifpatrimonial !== "" && show) show = `${a.idactifpatrimonial}` === idactifpatrimonial;
        if (nom_portefeuille !== "" && show) show = `${a.nom_portefeuille}` === nom_portefeuille;
        if (code_postal !== "" && show) show = `${a.code_postal}`.toUpperCase().indexOf(code_postal) !== -1;
        if (adresse !== "" && show) show = `${a.adresse.replaceAll('è', 'e').replaceAll('é', 'e').toLowerCase()}`.indexOf(adresse.replaceAll('è', 'e').replaceAll('é', 'e').toLowerCase()) !== -1;
        if (ville !== "" && show) show = `${a.ville}`.toUpperCase().indexOf(ville.toUpperCase()) !== -1;
	if (societe !== "" && societe != undefined && show) show = `${b.idsociete}` == societe;
	if (banque !== "" && banque != undefined && show) show = `${emp.idbanque}` == banque;

        return show;
      })
    : datas;
};

export default filter;
