import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DownloadIcon from "@material-ui/icons/GetApp";
import withGlobals from "../withGlobals";
import ReactApexChart from 'react-apexcharts'
import LogoForum from "../forum.png";
import LogoPardes from "../pardes.png";
import Select from "react-select";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { max } from "moment";


const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: "Select all",
  value: ""
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const animatedComponents = makeAnimated();
const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
    // maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  progressMsg: {
    fontSize: "1.3em",
    fontWeight: "bold",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class DscrProjete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
societes: [ {"idsociete":"","nom_societe":"Pardes & Forum" }, {"idsociete":1,"filiale":false,"nom_societe":"Pardes Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null},{"idsociete":2,"filiale":false,"nom_societe":"Forum Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null}],
      globale: false,
      generating: false,
      generated: false,
      selectionSociete: "",
      series: [{name: '', data: []}],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'DSCR PROJETÉ',
          align: 'center'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        },

    };
  }

  handleSubmit = () => {
    this.setState({ generating: true });
var temp2 = "";
if(this.state.selectionSociete){
temp2 += "&idsociete="+this.state.selectionSociete;
}
        this.props.fetch({url: `${this.props.rendererHost}/dscr_projete.pdf?action=dnld${temp2}`})
            .then(() => {
                     this.setState({ generating: false, generated: true });
    }).catch(() => {
        this.setState({ generating: false, generated: true }); 
    });
  };

  handleReset = () => {
    this.setState({
      globale: false,
      generating: false,
      generated: false,
      selectionSociete: "",
      idsociete: null,
    });
  };

  componentDidMount = () => {
    this.getRapportDscrProjete();
  };

   getRapportDscrProjete = () => {
  var query = "";
  var arrayOfSocietes = [];
if (this.state.selectionSociete != "") {
	query = "?idsociete="+this.state.selectionSociete;
  if (this.state.selectionSociete == "1") {
    arrayOfSocietes.push("PARDES");
  } else if (this.state.selectionSociete == "2"){
    arrayOfSocietes.push("FORUM");
  }
} else {
  arrayOfSocietes.push("FORUM");
  arrayOfSocietes.push("PARDES");
  arrayOfSocietes.push("TOUT");
}


this.props.fetch({ url: `/banques/`+query, method: "POST", body: {dscr_projete: "GET"}}).then(result => 
{ 
  var series = [];
  var receivedCategories = result["annee"].slice(0, 14);
	series.push({name: "DSCR Projeté", data:result["dscr"].slice(0, 14)});
  this.state.series = series;

  let maxDSCR = Math.max.apply(Math, result["dscr"].slice(0, 14));
  if (maxDSCR % 100 > 0) {
    maxDSCR += 100;
  }
  maxDSCR = (maxDSCR/100)*100;

  const tick = maxDSCR/100;

var options = {
dataLabels: {
  enabled: true,
  enabledOnSeries: undefined,
  formatter: function (val, opts) {
      return (parseInt(val, 0)).toLocaleString('en').replaceAll(',', ' ');
  },
style: {
      fontSize: '8px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 400,
      colors: ['black', 'black', 'black'] 
  }
},
		  chart: {
			type: 'bar',
			height: 350,
      stacked: true,
			toolbar: {
			  show: true
			},
			zoom: {
			  enabled: true
			}
		  },
		  responsive: [{
			breakpoint: 480,
			options: {
			  chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
			}
		  }],
		  plotOptions: {
			bar: {
			  horizontal: false,
			},
		  },
yaxis: {
        labels: {
        formatter: (value) => { return value.toLocaleString('en').replaceAll(',', ' '); } 
        },
        tickAmount: tick,
        max: maxDSCR
    },
		  xaxis: {
			type: 'text',
labels: {
style: {
              colors: [],
              fontSize: '8px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          }
},
			categories: receivedCategories,
		  },
		  legend: {
			position: 'right',
			offsetY: 40
		  },
		  fill: {
			opacity: 1
		  }
		} ;
this.setState({options: options});

});

 }

  handleChangeCB = name => event => {
    this.setState({ [name]: event.target.checked, generating: false, generated: false });
  };


  handleChangeSocietes = e =>{
this.setState({ selectionSociete: e.value, generating: false, generated: false });
this.state.selectionSociete = e.value;
this.getRapportDscrProjete();

}

  handleChange = e =>{
 this.setState({ [e.target.name]: e.target.value, generating: false, generated: false });
this.state[e.target.name] = e.target.value; 
this.getRapportDscrProjete();
}
  generateRapporUrl = () => {
    return `${this.props.rendererHost}/dscr_projete.pdf?action=dwnld`;
  };

  render() {
    const { classes } = this.props;
    const { generating, generated, societes, selectionSociete } = this.state;
     const rapportsUrl = process.env.REACT_APP_DOMAIN  + "/files/export";
    return [
<Grid>
<Grid item>
<Grid container alignItems="flex-start" justify="flex-start" direction="row">
<Grid item xs={3}>
<Grid item>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-banques">Société</InputLabel>

 <Select
    placeholder="Sociétés"
    value={societes.map((x) =>  { return { value: x.idsociete, label: x.nom_societe }; }).label}
    name="selectionSociete"
    onChange={this.handleChangeSocietes}
    options={societes.map((x) =>  { return { value: x.idsociete, label: x.nom_societe }; })}                
  />
</FormControl>
</Grid>
</Grid>
<Grid item xs={1}>
<Grid item>
<Grid container alignItems="flex-start" justify="flex-start" direction="row">
{false && (this.state.selectionSociete == "" || this.state.selectionSociete == "1") && (
<Grid item  xs={6}>
<img src={LogoPardes} alt="Logo PARDES" width="55%"  style={{paddingTop: "90px"}} />
</Grid>
)}
{false && (this.state.selectionSociete == "" || this.state.selectionSociete == "2") && (
<Grid item  xs={6}>
<img src={LogoForum} alt="Logo FORUM" width="63%"  style={{paddingTop: "90px"}} />
</Grid>
)}
</Grid>
</Grid>
</Grid>
</Grid>
</Grid>
<Grid item>
      <Grid container alignItems="flex-start" justify="flex-start" direction="row">
<Grid item xs={12}>
<div id="chart">
  <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
</div>
</Grid>
</Grid>
</Grid>
      </Grid>,
      generating ? <Grid item style={{ visibility: generating ? "visible" : "hidden" }}>
        <Grid container alignItems="center" justify="center" direction="row">
          <Grid item className={classes.progressMsg}>
            Generation du rapport...
          </Grid>
          <Grid item>
            <CircularProgress className={classes.progress} size={50} />
          </Grid>
        </Grid>
      </Grid> : null,
      generated ? (
        <Grid item>
          <Button color="default" variant="raised" href={`${rapportsUrl}/dscr_projete.pdf`} target="__blank">
            <DownloadIcon />Télécharger le rapport
          </Button>
        </Grid>
      ) : null,
      !generated && !generating ? (
        <Grid item style={{ marginTop: "4em" }}>
          <Button color="primary" variant="raised" onClick={this.handleSubmit}>
            Générer le rapport
          </Button>
        </Grid>
      ) : null,
      generated && !generating ? (
        <Grid item style={{ marginTop: "4em" }}>
          <Button color="primary" variant="raised" onClick={this.handleReset}>
            Nouveau rapport
          </Button>
        </Grid>
      ) : null,
    ];
  }
}

export default withStyles(styles, { withTheme: true })(withGlobals(DscrProjete));
