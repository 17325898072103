import React, { Component } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import withGlobals from "../withGlobals";

class DatasLoader extends Component {
  static propTypes = {
    actifs: PropTypes.array.isRequired,
    actifId: PropTypes.number.isRequired,
    children: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      actifs: props.actifs,
      societes: props.societes
    };
  }

  componentDidMount = () => {
    if (!this.findActif()) {
      this.props.fetch({ url: `/actifs/${this.props.actifId}` }).then(res => {
        const actifs = this.state.actifs.concat([res]);
        if (this.state.societes.length > 0) {
          this.setState({ actifs });
        }
        this.props.fetch({ url: "/societes/" }).then(societes => {
          this.setState({
            actifs,
            societes
          });
        });
      });
    }
  };

  findActif = () =>
    this.state.actifs.find(a => a.idactifpatrimonial === this.props.actifId);

  render() {
    const { actifs, societes } = this.state;

    const actif = this.findActif();
    if (!actif) {
      return <CircularProgress size={50} />;
    }
    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, { actif, actifs, societes })
    );
  }
}

export default withGlobals(DatasLoader);
