import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => {
  return {
    formControl: {
      margin: theme.spacing.unit,
      width: 316,
    },
  };
};

class ListeSocietes extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    societes: PropTypes.array.isRequired,
    onLoaded: PropTypes.func,
  };

  render() {
    const { classes, onChange, societes } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-simple">Société</InputLabel>
        <Select
          native
          fullWidth
          onChange={onChange}
          inputProps={{
            name: "societe",
            id: "societe-native-simple",
          }}
        >
          {[
            <option value="" key={0} />,
            societes.map(s => (
              <option key={s.idsociete} value={s.idsociete}>
                {s.nom_societe}
              </option>
            )),
          ]}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(ListeSocietes);
