import React, { Component } from "react";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AvatarEditor from "react-avatar-editor";
import withGlobals from "../withGlobals";

const annonceImg =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAA8AXHiAAAFdElEQVR4nO3beYhVVRzA8a9aoo7bpJm2kS1EC1KWVihpYdFiZWVIq1CYRLRYYEV/WEkLLRS090+E/2SrthL5RyCh0EJWUi5tRlChbdOo5TL98TuXd97zvZlpfI5P/X7gwrn33OU87m/OOfecMyBJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkqTu9TIwfmcXQo3vKuCvtK0DVgOX1zh3NnB/N5VLu7hrgB+y/UOA74BFFef1AKZ2U5m0E3ydtivrdL/KwCq0EcEE0A9oAf4GNgMTKs6bAbQCW4BLs7ymdF0LsBUYm+W9CmxMeZuz44elc1uATcCQdHwu8G+2tQEHd/pXqkNtabuxTverFVjfAuen9B/AkSndh3jxg7Py3JnSvYH1lF74OmBKSg8hAq9IL8+e1TdLb87uPZwIvkpnE013rxq/SV3QXYH1OTA9pTcCk7JtKXBiVp5B2XVvA/cBA1Je7kNgItCTCKAbKNVIAJcAn1Y8qw3YLzvnCGADBlXddVdgbQVGAP2J2uHeiu3wrDx9suueBp4Cmtk2sF4Hrkjp/kRz+BvwZzo2G/iqyrMGpvy+RPNoE7gDdEdg3UT0YwpbOyjPyGx/FfFVuVe6rkeW10p5s1f4GBhFNH3r2nlWKzCmnXxthx0RWK3AS8ACYC3wI+W1wi3A70TTdT3wPjAsK88vwHVETZUHxsNE0F4NfAB8lI6PB+an43cQtVBhIbCM+CC4NaUBXiG+VudkW94EazvVO7CagIPS1rud83oB1xKBmPdv2tJ1U4BpVa5rBmYBR1UcHwHMJPpVlQYCN1fk7ZuVs9jsZ9VRvQNrexWBpQbWD7i7g60IrHc7OK9aTbAjvEH0p9TAhlIKnO3dXuzmsquBGVjaaRqtj9UZg4hB0UY0uONTaKZ82GS3tCsG1lpixLwRVZsyqrRHzEvuioHVROP+xffv5DmNWv66qXdgPUhpohhi4nlOSh8DvAC8lp65mvIXcQZRG7UBS6jd3H1JaXR+PnASsJIYmX+CGKP6Jt3nyey6i4BfKQ3C5qPuTdk17wCLibnFwjMpbwtwT41yQQy6FoYSA7JtxKDxuHT8J+CAdu6xW6h3YD0LXJbtTwMeSenjKJ9kfp4IDIgXuz677mLiBVSTN4WLiOU3hRZiGqjwD6XaYXh2vDnlFTYRE9oQ/aStwLlp/y1gXnbuMsqX7OSKprBHusexWV7xu20Ku6CjwFqS5e1DTBJDvLyiJmgiVjPUmlOsDKy8ZllOjMAX3gNGZ/sTgceIye02YjVET8qDDOB7SoG1kVLtOYAI+pU1ylYE1qlErViNgdUFHQXWm1ne3sCKlF5GzBk+ULFVaw4rA+v4LO+LiucvBE5O6aXA48TCwhOI3z2MmMhurXjGCiKwipqnsly3VykXlALrAmBNjXMMrC54iFidWZhH5wJrLjFp3RldDax82U1PSoFV5OX9vRZKNdY6ypu09hSBNTLdo5o9IrCGUfqrrYdRxBKZC4nVCIvpXGBBvIgFwCnEBPVnNZ7R1cBaQ/xX0Nj03DywphMrLh4lOuA/A+dk5d5ANLHjiFp5Ro2y5cMNnxBN84R0bfFRs0cE1o4wmphfPA3YHzg6HR9I+ZdYj3RObirR15pJdLCrOZPoh0EEzcAsbwLlX1xjKA1a9iLWht1GfLFNpnzC+0DgLGKucg3bdrxnAXcB59UoF5SCsTCJ+CqekT1rMvX7Q1aDO5QYpugPnE75wkSpy5qJpc6rgOewRpEkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSfof/gMdrG2LZ1WKqwAAAABJRU5ErkJggg==";

class MyEditor extends Component {
  state = {
    image: this.props.image || annonceImg,
    scale: 1,
    modifiee: false
  };

  handleDrop = dropped => {
    this.setState({ image: dropped[0], modifiee: true });
  };

  handleScalePlus = () =>
    this.setState({
      scale: this.state.scale < 2 ? this.state.scale + 0.1 : 2,
      modifiee: true
    });

  handleScaleMinus = () =>
    this.setState({
      scale: this.state.scale > 1 ? this.state.scale - 0.1 : 1,
      modifiee: true
    });

  handleSave = () => {
    if (this.editor) {
      this.props
        .fetch({
          url: `/users/${this.props.userId}`,
          method: "PUT",
          body: {
            image64: this.editor.getImageScaledToCanvas().toDataURL()
          }
        })
        .then(() => {
          this.props.openNotification(
            "success",
            "Avatar modifié, changement visible à la prochaine connexion",
            undefined,
            5000
          );
          this.setState({ modifiee: false });
        });
    }
  };

  handleImageChanged = () => {
    this.props.onImageChanged(this.editor.getImageScaledToCanvas().toDataURL());
  };

  setEditorRef = editor => (this.editor = editor);

  handleLoadSuccess = () => {
    if (this.editor) {
      const image = this.editor.getImageScaledToCanvas().toDataURL();
      this.props.onImageChanged(image);
    }
  };

  handleNewImage = e => {
    this.setState({ image: e.target.files[0], modifiee: true });
  };

  render() {
    const { modifiee } = this.state;
    return (
      <Grid container direction="column">
        <Grid item>
          <Dropzone
            onDrop={this.handleDrop}
            disableClick
            style={{ width: "200px", height: "200px", marginLeft: "35px" }}
          >
            <AvatarEditor
              ref={this.setEditorRef}
              width={150}
              height={150}
              image={this.state.image}
              scale={this.state.scale}
              onLoadSuccess={this.handleLoadSuccess}
              borderRadius={100}
            />
          </Dropzone>
        </Grid>
        <Grid item>
          <IconButton onClick={this.handleScalePlus}>
            <AddIcon />
          </IconButton>
          <IconButton onClick={this.handleScaleMinus}>
            <RemoveIcon />
          </IconButton>
        </Grid>
        {false && (
          <Grid item>
            <input name="newImage" type="file" onChange={this.handleNewImage} />
          </Grid>
        )}
        {modifiee && (
          <div>
            <Button varaiant="raiser" color="primary" onClick={this.handleSave}>
              Sauvegarder
            </Button>
          </div>
        )}
      </Grid>
    );
  }
}

export default withGlobals(MyEditor);
