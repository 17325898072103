import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Prompt } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Emprunt from "./Emprunt";
import DatasLoader from "./DatasLoader";
import Centered from "../../layout/Centered";
import ListeActifs from "../../common/ListeActifs";
import columnEmpruntsData from "./columnEmpruntsData";
import empruntFilter from "./empruntFilter";
import withGlobals from "../withGlobals";

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: 0,
      paddingRight: 0,
      width: "500px",
    },
    zoneAjout: {
      maxHeight: "calc(100vh - 255px)",
      minHeight: "calc(100vh - 255px)",
    },
  };
};

class Emprunts extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    openNotification: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { location: { pathname } } = props;
    const localId = parseInt(pathname.split("/")[2], 10);
    this.state = {
      loading: localId ? true : false,
      emprunts: [],
      tableauAmortissements: [],
      disabledDownloadAmortissements: true,
      locauxFiltred: [],
      creationMode: false,
      formDataChanged: false,
      textFiltered: false,
      filtredDialogOpen: false,
    };
  }

  getDateForExcel = strInput =>  {
    var str = "" + strInput;
      var result = str.charAt(8) + str.charAt(9) + '/' + str.charAt(5) + str.charAt(6) + '/' + str.charAt(0) + str.charAt(1) + str.charAt(2) + str.charAt(3);
      return result;
    }

  componentWillReceiveProps = nextProps => {
    if (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.location.search !== nextProps.location.search
    ) {
      this.handleResetDataChanged();
    }
  };

  handleEmpruntsLoaded = emprunts => {
    this.props.fetch({ url: `/banques/`, method: "POST", body: {amortissements_filtres: "GET", id_investissements: []}}).then(result => 
      { 
        this.setState({disabledDownloadAmortissements: false});
        for (var i = 0; i < result.length; i++) {
          result[i]["date_echeance"] = this.getDateForExcel(result[i]["date_echeance"]);
          if (result[i]["echeances_numero"]) {
            result[i]["echeances_numero"] = Number(result[i]["echeances_numero"]);
          }
          if (result[i]["valeur_residuelle"]) {
            result[i]["valeur_residuelle"] = Number(result[i]["valeur_residuelle"]);
          }
          if (result[i]["capital_rembourse"]) {
            result[i]["capital_rembourse"] = Number(result[i]["capital_rembourse"]);
          }
          if (result[i]["montant_interets"]) {
            result[i]["montant_interets"] = Number(result[i]["montant_interets"]);
          }
          if (result[i]["montant_echeance"]) {
            result[i]["montant_echeance"] = Number(result[i]["montant_echeance"]);
          }
          if (result[i]["capital"]) {
            result[i]["capital"] = Number(result[i]["capital"]);
          }
        }
        this.setState({tableauAmortissements: result});
    });

    

   

    this.setState({
      emprunts: emprunts.reduce((m, d) => {
        const portefeuille = JSON.parse(d.portefeuille);
        portefeuille.emprunts.forEach(e => {
          const actif = portefeuille.actifs.find(a => a.idactifpatrimonial === e.idactifpatrimonial);
          const adresse = actif ? `${actif.adresse} ${actif.code_postal} ${actif.ville}` : "";
          m.push({
            ...e,
            nom_portefeuille: d.nom_portefeuille,
            adresse,
            banque: d.idbanque
          });
        });
        return m;
      }, []),
      loading: false,
    });
  }

  handleLocauxFiltred = (locauxFiltred, textFiltered) => {
    this.setState({ locauxFiltred, textFiltered });
    var ids = [];
    this.state.locauxFiltred.forEach(e => { 
      ids.push(e.idemprunt);
    });

    this.props.fetch({ url: `/banques/`, method: "POST", body: {amortissements_filtres: "GET", id_investissements: ids}}).then(result => 
      { 
        for (var i = 0; i < result.length; i++) {
          result[i]["date_echeance"] = this.getDateForExcel(result[i]["date_echeance"]);
          if (result[i]["echeances_numero"]) {
            result[i]["echeances_numero"] = Number(result[i]["echeances_numero"]);
          }
          if (result[i]["valeur_residuelle"]) {
            result[i]["valeur_residuelle"] = Number(result[i]["valeur_residuelle"]);
          }
          if (result[i]["capital_rembourse"]) {
            result[i]["capital_rembourse"] = Number(result[i]["capital_rembourse"]);
          }
          if (result[i]["montant_interets"]) {
            result[i]["montant_interets"] = Number(result[i]["montant_interets"]);
          }
          if (result[i]["montant_echeance"]) {
            result[i]["montant_echeance"] = Number(result[i]["montant_echeance"]);
          }
          if (result[i]["capital"]) {
            result[i]["capital"] = Number(result[i]["capital"]);
          }
        }
        this.setState({tableauAmortissements: result});
        this.setState({disabledDownloadAmortissements: false});
    });

    
  } 

  navigateToCreationMode = () => this.props.history.push(`/emprunts/nouveau${this.props.location.search}`);


  handleDataChanged = () => this.setState({ formDataChanged: true });

  handleResetDataChanged = () => this.setState({ formDataChanged: false });

  handleClick = (e, selected) => {
    const { history, location: { pathname, search } } = this.props;
    this.setState({ creationMode: false, formDataChanged: false });

    if (pathname !== "/emprunts" && pathname !== `/emprunts/${selected}`) {
      history.push(`/emprunts/${selected}${search}`);
      return;
    }
    history.push(
      pathname !== "/emprunts" || pathname === `/emprunts/${selected}`
        ? "/emprunts"
        : `/emprunts/${selected}${search}`,
    );
  };

  render() {
    const { match: { params: { empruntId } }, location: { search }, openNotification, user } = this.props;

    if (!user || !user.login) return <h1>Acces denied</h1>;
    const { emprunts, loading, formDataChanged, textFiltered, tableauAmortissements, disabledDownloadAmortissements } = this.state;

    const idactifpatrimonial = search.split("actifId=")[1]
      ? parseInt(search.split("actifId=")[1], 10)
      : undefined;

    const emprunt =
      empruntId && empruntId !== "nouveau"
        ? emprunts.find(e => {
            return e.idinvestissement === parseInt(empruntId, 10);
          })
        : null;

    return (
      <Centered alignItems="flex-start" justify="center">
        {!empruntId && (
          <Grid item>
            <ListeActifs
              onSelectRow={this.handleClick}
              datas={emprunts}
              onDatasLoaded={this.handleEmpruntsLoaded}
              onDatasFiltred={this.handleLocauxFiltred}
              onSocieteAdded={this.handleSocietesAdded}
              onAddClicked={this.navigateToCreationMode}
              apiPathname="/portefeuilles/"
              datasName="emprunt(s)"
              idField="idemprunt"
              addButtonTitle="Créer un nouvel emprunt"
              columnData={columnEmpruntsData}
              tableauAmortissements={tableauAmortissements}
              showIdActif
              hideSocietes
              filter={empruntFilter}
              addDownloadAmortissements={true}
              disabledDownloadAmortissements={disabledDownloadAmortissements}
              searchFields={[
                {
                  label: "Id investissement",
                  name: "idemprunt",
                },
                {
                  label: "Id Actif",
                  name: "idactifpatrimonial",
                },
                {
                  label: "Adresse",
                  name: "adresse",
                },
                {
                  label: "Nom financement",
                  name: "nom_portefeuille",
                },
              ]}
            />
          </Grid>
        )}
        <Prompt
          when={formDataChanged}
          message={location => `Si vous cliquez sur OK, les données ne seront pas sauvegardées`}
        />
        {empruntId === "nouveau" && (
          <Grid item xs={11}>
            <Emprunt
              actifId={idactifpatrimonial}
              emprunts={emprunts}
              filtred={textFiltered}
              onFormDataChanged={this.handleDataChanged}
              onResetDataChanged={this.handleResetDataChanged}
              formDataChanged={formDataChanged}
              onDelete={this.handleDelete}
              onUpdate={this.handleUpdate}
              openNotification={openNotification}
            />
          </Grid>
        )}
        {empruntId &&
          empruntId !== "nouveau" && (
            <Grid item xs={11}>
              <DatasLoader empruntId={empruntId}>
                <Emprunt
                  emprunt={emprunt}
                  empruntId={empruntId}
                  onFormDataChanged={this.handleDataChanged}
                  onResetDataChanged={this.handleResetDataChanged}
                  loading={loading}
                  formDataChanged={formDataChanged}
                  openNotification={openNotification}
                  onDelete={this.handleDelete}
                  onUpdate={this.handleUpdate}
                />
              </DatasLoader>
            </Grid>
          )}
      </Centered>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Emprunts)));
