import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    //height: "calc(100vh - 150px)",
  },
});

const CenteredMobile = ({ children, spacing, classes, alignItems, justify, direction }) => (
  <Grid
    container
    className={classes.container}
    spacing={spacing}
    alignItems={alignItems}
    direction={direction}
    justify={justify}
  >
    {children}
  </Grid>
);

CenteredMobile.defaultProps = {
  alignItems: "center",
  justify: "center",
  direction: "row",
  spacing: 0,
};

export default withStyles(styles)(CenteredMobile);
