import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import withGlobals from "../withGlobals";
import { withStyles } from "@material-ui/core/styles";
import CouvertureSwitch from "./couvertureSwitch";

const styles = theme => ({
  table: {
    color: "#333",
    fontFamily: "Roboto, Helvetica, Arial, 'sans-serif'",
    width: "940px",
    borderCollapse: "collapse",
    borderSpacing: 0,
    marginBottom: "3em",
    marginTop: "3em",
  },
  td: {
    border: "1px solid #CCC",
    height: "30px",
    background: "#FAFAFA",
    textAlign: "center",
  },
  th: {
    border: "1px solid #CCC",
    height: "30px",
    background: "#F3F3F3",
    fontWeight: "bold",
  },
});
class Ltc extends React.Component {
  state = {
    datas: null,
    avecCouverture: true,
  };

  componentDidMount() {
    this.loadDatas();
  }

  loadDatas = () => {
    this.setState({ datas: null });
    this.props
      .fetch({ url: `/rapports/dscr/${this.props.idamortissement}` })
      .then(datas => this.setState({ datas }));
  };

  handleToggleCouverture = () => this.setState({ avecCouverture: !this.state.avecCouverture });

  componentDidUpdate(prevProps, prevState) {
    if (prevState.avecCouverture !== this.state.avecCouverture) {
      this.loadDatas();
    }
  }

  render() {
    const { datas } = this.state;
    const { classes } = this.props;
    if (!datas)
      return (
        <Grid container direction="column" alignItems="center" justify="center" spacing={0}>
          <Grid item>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      );
    const options = {
      title: `LTC de l'emprunt ${this.props.idemprunt}`,
      yAxis: {
        labels: {
          formatter: obj => `${obj.value} %`,
        },
        title: {
          text: "Valeur LTC",
        },
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: datas[0].annee_emprunt,
        },
      },
      series: [
        {
          name: "LTC",
          data: datas.map(d => d.LTC),
        },
      ],
    };
    return (
      <div>
        <CouvertureSwitch checked={this.state.avecCouverture} handleToggle={this.handleToggleCouverture} />
        <HighchartsReact highcharts={Highcharts} constructorType={"chart"} options={options} />
        <table className={classes.table}>
          <thead>
            <tr>
              <td />
              {datas.map(data => <th className={classes.th}>{data.annee_emprunt}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className={classes.th}>LTC</th>
              {datas.map(data => <td className={classes.td}>{parseInt(data.LTC, 10)} %</td>)}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withGlobals(withStyles(styles)(Ltc));
