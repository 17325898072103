import React from "react";
import headerStyle from "../../common/tableHeaderStyle";
import paddedTableHeaderStyle from "../../common/paddedTableHeaderStyle";
import truncate from "lodash/truncate";

const columnData = [
  {
    Header: "Id Bail",
    accessor: "idbail",
    width: 65,
    headerStyle,
  },
  { Header: "CP", accessor: "code_postal", width: 90, headerStyle },
  { Header: "Ville", accessor: "ville", width: 120, headerStyle },
  {
    Header: "Adresse",
    accessor: "adresse",
    headerStyle: paddedTableHeaderStyle,
  },
  {
    Header: "Surface",
    accessor: "surface_totale_actif",
    width: 90,
    Cell: props => <span>{props.value} m²</span>,
    headerStyle: paddedTableHeaderStyle,
  },
  {
    Header: "Locataire",
    width: 120,
    accessor: "nom_du_locataire",
    // headerStyle: paddedTableHeaderStyle,
    Cell: props => (
      <div style={{ paddingRight: "25px" }}>
        {truncate(props.value, {
          length: 8,
        })}
      </div>
    ),
    headerStyle: { ...paddedTableHeaderStyle, paddingRight: "45px" },
  },
{ Header: "Société", accessor: "entite", width: 135, headerStyle }
];

export default columnData;
