import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DownloadIcon from "@material-ui/icons/GetApp";
import withGlobals from "../withGlobals";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
    // maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  progressMsg: {
    fontSize: "1.3em",
    fontWeight: "bold",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Vacant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generating: false,
      generated: false,
      societes: [{"idsociete":1,"filiale":false,"nom_societe":"Pardes Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null},{"idsociete":2,"filiale":false,"nom_societe":"Forum Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null}],
selectionSociete: "",
globale: false,
synthese: false,
    };
  }

  handleSubmit = () => {
      console.log('ici');
    this.setState({ generating: true });

        this.props.fetch({url: `${this.props.rendererHost}/rapport_vacants.pdf?action=dnld`})
            .then(() => {
                     this.setState({ generating: false, generated: true });
    }).catch(() => {
        this.setState({ generating: false, generated: true }); 
    });
  };

  handleSubmitPardes = () => {
      console.log('ici');
    this.setState({ generating: true });

        this.props.fetch({url: `${this.props.rendererHost}/rapport_vacants.pdf?action=dnld&idsociete=1`})
            .then(() => {
                     this.setState({ generating: false, generated: true });
    }).catch(() => {
        this.setState({ generating: false, generated: true });
    });
};

  handleSubmitForum = () => {
      console.log('ici');
    this.setState({ generating: true });

        this.props.fetch({url: `${this.props.rendererHost}/rapport_vacants.pdf?action=dnld&idsociete=2`})
            .then(() => {
                     this.setState({ generating: false, generated: true });
    }).catch(() => {
        this.setState({ generating: false, generated: true });
    });
  };

  handleSubmitS = () => {
      console.log('ici');
    this.setState({ generating: true });

        this.props.fetch({url: `${this.props.rendererHost}/rapport_vacants_s.pdf?action=dnld`})
            .then(() => {
                     this.setState({ generating: false, generated: true });
    }).catch(() => {
        this.setState({ generating: false, generated: true });
    });
  };

  handleSubmitPardesS = () => {
      console.log('ici');
    this.setState({ generating: true });

        this.props.fetch({url: `${this.props.rendererHost}/rapport_vacants_s.pdf?action=dnld&idsociete=1`})
            .then(() => {
                     this.setState({ generating: false, generated: true });
    }).catch(() => {
        this.setState({ generating: false, generated: true });
    });
};

  handleSubmitForumS = () => {
      console.log('ici');
    this.setState({ generating: true });

        this.props.fetch({url: `${this.props.rendererHost}/rapport_vacants_s.pdf?action=dnld&idsociete=2`})
            .then(() => {
                     this.setState({ generating: false, generated: true });
    }).catch(() => {
        this.setState({ generating: false, generated: true });
    });
  };

  handleReset = () => {
    this.setState({
      generating: false,
      generated: false,
    });
  };

  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
      generating: false,
      generated: false,
    });

  handleChangeCB = name => event => {
    this.setState({ [name]: event.target.checked, generating: false, generated: false });
    if (name == 'globale' && event.target.checked) {
     this.setState({synthese: false});
    } else if (name == 'synthese' && event.target.checked) {
     this.setState({globale: false});
   }
  };


generate = () => {
if (this.state.globale && this.state.selectionSociete == '') {
this.handleSubmit();
} else if (this.state.globale && this.state.selectionSociete == '1') {
this.handleSubmitPardes();
} else if (this.state.globale && this.state.selectionSociete == '2') {
this.handleSubmitForum();
} else if (this.state.synthese &&  this.state.selectionSociete == '') {
this.handleSubmitS();
} else if (this.state.synthese && this.state.selectionSociete == '1') {
this.handleSubmitPardesS();
} else if (this.state.synthese &&  this.state.selectionSociete == '2') {
this.handleSubmitForumS();
}


};

  generateRapporUrl = () => {
    return `${this.props.rendererHost}/rapport_vacants.pdf?action=dnld}`;
  };

  render() {
    const { classes, theme } = this.props;
    const { societes, selectionSociete, globale, synthese, generating, generated} = this.state;
     const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export";
    return [
      <Grid container alignItems="flex-start" justify="flex-start" direction="column">
	</Grid>,
      generating ? <Grid item style={{ visibility: generating ? "visible" : "hidden" }}>
        <Grid container alignItems="center" justify="center" direction="row">
          <Grid item className={classes.progressMsg}>
            Generation du rapport...
          </Grid>
          <Grid item>
            <CircularProgress className={classes.progress} size={50} />
          </Grid>
        </Grid>
      </Grid> : null,
      generated && globale ? (
        <Grid item>
          <Button color="default" variant="raised" href={`${rapportsUrl}/rapport_vacants.pdf`} target="__blank">
            <DownloadIcon />Télécharger le rapport
          </Button>
        </Grid>
      ) : null,
generated && synthese ? (
        <Grid item>
          <Button color="default" variant="raised" href={`${rapportsUrl}/synthese_vacants.pdf`} target="__blank">
            <DownloadIcon />Télécharger le rapport
          </Button>
        </Grid>
      ) : null,
      generated ? (
        <Grid item style={{ marginTop: "4em" }}>
          <Button color="primary" variant="raised" onClick={this.handleReset}>
            Nouveau rapport
          </Button>
        </Grid>
      ) : null,
!generated && !generating ? (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.globale}
                  onChange={this.handleChangeCB("globale")}
                  value="globale"
                  color="primary"
                />
              }
              label="Globale"
            />
          </Grid>
        ) : null,
!generated && !generating ? (
          <Grid item>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-banques">Société</InputLabel>
              <Select
                value={selectionSociete}
                name="selectionSociete"
                onChange={this.handleChange}
                MenuProps={MenuProps}
              >
                {societes.map(societe => (
                  <MenuItem
                    key={societe.idsociete}
                    value={societe.idsociete}
                    style={{
                      fontWeight:
                        this.state.societes.indexOf(societe) === -1
                          ? theme.typography.fontWeightRegular
                          : theme.typography.fontWeightMedium,
                    }}
                  >
                    {societe.nom_societe}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null,
!generated && !generating ? (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.synthese}
                  onChange={this.handleChangeCB("synthese")}
                  value="synthese"
                  color="primary"
                />
              }
              label="Synthèse"
            />
          </Grid>
        ) : null,
!generated && !generating ? (
        <Grid item style={{ marginTop: "4em" }}>
          <Button color="primary" variant="raised" onClick={this.generate}>
            Générer le rapport
          </Button>
        </Grid>
      ) : null
    ];
  }
}

export default withStyles(styles, { withTheme: true })(withGlobals(Vacant));
