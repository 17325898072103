import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import AddIcon from "@material-ui/icons/Add";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import ConfirmWrapper from "../../common/ConfirmWrapper";
import withGlobals from "../withGlobals";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit / 2,
    height: 40,
    minHeight: 40,
    width: 40,
    minWidth: 40,
  },
  formControl: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    minWidth: "100%",
  },
  user: {
    minWidth: "850px",
    borderTop: "solid 1px silver",
    borderLeft: "solid 1px silver",
    borderRight: "solid 1px silver",
    padding: theme.spacing.unit / 2,
  },
  userBottom: {
    minWidth: "850px",
    borderTop: "solid 1px silver",
    borderLeft: "solid 1px silver",
    borderRight: "solid 1px silver",
    borderBottom: "solid 1px silver",
    padding: theme.spacing.unit / 2,
  },
});

class UserList extends Component {
  state = {
    users: [],
    loading: true,
    dialogOpen: false,
    login: null,
  };

  componentDidMount = () => {
    this.props.fetch({ url: "/users/" }).then(users => {
      this.setState({ users, loading: false });
    });
  };

  handleOpen = () => this.setState({ dialogOpen: true });
  handleClose = () => this.setState({ dialogOpen: false, login: null, droit: null });

  handleChangeStatus = ({ password, ...u }) => {
    const body = { ...u, cloture: !u.cloture };
    this.props
      .fetch({
        url: `/users/${u.id}`,
        method: "PUT",
        body,
      })
      .then(() => {
        this.setState({
          users: this.state.users.map(us => (us.id === u.id ? { ...u, cloture: !u.cloture } : us)),
          dialogOpen: false,
        });
      });
  };

  handleSave = e => {
    e.preventDefault();
    const { users, login, droit } = this.state;
    const user = users.find(u => u.login === login);
    const { password, ...datas } = user; // on ne modifier pas le password
    const body = {
      ...datas,
      modifier: droit === "LECTURE/ECRITURE" || droit === "ADMIN",
      superviseur: droit === "ADMIN",
    };

    this.props
      .fetch({
        url: `/users/${user.id}`,
        method: "PUT",
        body,
      })
      .then(user => {
        this.setState({
          users: users.map(u => (u.login === login ? user : u)),
          dialogOpen: false,
          login,
          droit,
        });
      });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleClick = ({ login, modifier, superviseur }) => {
    let droit = modifier ? "LECTURE/ECRITURE" : "LECTURE";
    if (superviseur) droit = "ADMIN";
    this.setState({ login, droit, dialogOpen: true });
  };

  handleDelete = ({ id }) => {
    this.props
      .fetch({
        url: `/users/${id}`,
        method: "DELETE",
        body: null,
      })
      .then(user => {
        this.setState({
          users: this.state.users.filter(u => u.id !== id),
          dialogOpen: false,
        });
      });
  };

  render() {
    const { loading, users, dialogOpen, login, droit } = this.state;
    const { classes } = this.props;
    if (loading) {
      return <CircularProgress size={50} />;
    }
    return (
      <Grid container spacing={0} alignItems="center" justify="flex-start" direction="column">
        <Dialog open={dialogOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <form onSubmit={this.handleSave}>
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
              Nouvel utilisateur
            </DialogTitle>
            <DialogContent>
              <Grid container alignItems="center" justify="center" direction="row" spacing={16}>
                <Grid item md={12}>
                  <TextField
                    required
                    autoFocus
                    margin="dense"
                    id="login"
                    name="login"
                    value={login}
                    onChange={this.handleChange}
                    label="Identifiant (email)"
                    type="email"
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="droit-native">Droits</InputLabel>
                    <Select
                      native
                      value={droit}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "droit",
                        id: "droit",
                      }}
                    >
                      <option value="" key={"a"} />
                      <option value="ADMIN" key={0}>
                        ADMIN
                      </option>
                      <option value="LECTURE" key={1}>
                        LECTURE
                      </option>
                      <option value="LECTURE/ECRITURE" key={2}>
                        LECTURE/ECRITURE
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="textSecondary">
                Annuler
              </Button>
              <Button type="submit" variant="raised" color="primary">
                Modifer cet utilisateur
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{ maxHeight: "450px", marginTop: "0em" }}
        >
          <Typography component="div">
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="fab"
                size="small"
                onClick={() => this.props.history.push("/register")}
                title="Ajouter une tranche"
                className={classes.button}
                style={{ marginTop: 0, marginBottom: "0.5em" }}
              >
                <AddIcon />
              </Button>
            </Grid>
            {users.map((u, idx) => (
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={idx === users.length - 1 ? classes.userBottom : classes.user}
                >
                  <Grid item xs={1} style={{ paddingLeft: "0.5em" }}>
                    {u.image64 ? <Avatar alt="Remy Sharp" src={u.image64} /> : <PersonIcon />}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "right" }}>
                    {u.login}
                  </Grid>
                  <Grid item xs={3}>
                    {u.modifier ? (u.superviseur ? "ADMIN" : "LECTURE/ÉCRITURE") : "LECTURE SEULE"}
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={0}>
                      <Grid item>
                        <IconButton
                          onClick={() => this.handleChangeStatus(u)}
                          title={u.cloture ? "Désactiver le compte" : "Activer le compte"}
                        >
                          {u.cloture ? <LockOpenIcon /> : <LockIcon />}
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => this.handleClick(u)} title="Modifier le compte">
                          <EditIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <ConfirmWrapper
                          onAccept={() => this.handleDelete(u)}
                          message={`Supprimer l'utilisateur ${u.login} ?`}
                          title="Supprimer un utilisateur"
                        >
                          <IconButton title="Supprimer le compte">
                            <TrashIcon />
                          </IconButton>
                        </ConfirmWrapper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withGlobals(withRouter(UserList)));
