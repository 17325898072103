import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import withGlobals from "../withGlobals";
import moment from "moment";
import LogoArrow from "../arrowleft.png";

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 1,
      paddingBottom: theme.spacing.unit * 2,
    },
    info: {
      textAlign: "left",
      fontSize: "0.9em",
    },
    pA: {
      textAlign: "center",
      borderTop: "solid 1px silver",
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
    commentaire: {
      textAlign: "center",
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      backgroundColor: "silver",
    },
  };
};

class Actif extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    actif: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      oneItem: false,
      actif: props.actif,
      societe: null,
      typefinancements: [],
    };
  }

  componentWillReceiveProps = nextProps => {
    if (!this.props.actifId !== nextProps.actifId && nextProps.actifId) {
    var idForSearch = nextProps.actifId;  
    if (nextProps.actifId.includes('+')) {
      idForSearch = idForSearch.substr(1);
      this.state.oneItem = true;
     } else {
      this.state.oneItem = false;
}
      this.props.fetch({ url: `/actifs/${idForSearch}` }).then(actif => {
        this.setState({ actif });
        this.props.fetch({ url: "/typefinancements/" }).then(typefinancements =>
          this.setState({
            typefinancements,
          }),
        );
        this.props.fetch({ url: `/societes/${actif.idsociete}` }).then(societe => this.setState({ societe }));
        if (actif.financement && actif.idbanque > 0) {
          this.props.fetch({ url: `/banques/${actif.idbanque}` }).then(banque => this.setState({ banque }));
        }
      });
    }
  };

  handleFinancer = index => {
    const { history } = this.props;
    const {actif} = this.state;
    if(!actif.financement || actif.idbanque == 0){
    history.push(`/emprunts/nouveau?actifId=${actif.idactifpatrimonial}`);
} else {
    history.push(`/emprunts_mobile/${actif.idinvestissement}`);
}
  };

  navigateToLocaux = () => {
    const { actif, history } = this.props;
    history.push(`/locaux_mobile?actifId=${actif.idactifpatrimonial}`);
  };

  render() {
    const { classes } = this.props;
    const { actif, banque, societe } = this.state;

    if (!actif) {
      return (
        <Grid container alignItems="center" direction="row" justify="center">
          <Grid item>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container alignItems="stretch" direction="column" justify="center">
        <Paper elevation={3} className={classes.root}>
          <Grid item xs={12}>
            <div style={{ marginBottom: "8px" }}>
<div className={classes.info} style={{marginTop: "-8px", float:"left"}}>
<Button color="primary" title="Précédent" onClick={() => { if (this.state.oneItem == true) { this.props.history.push(`/actifs_mobile`); } else { this.props.history.goBack(); } }}>
        <img src={LogoArrow} alt="Précédent" width="30px" />
</Button>
</div>

              <Typography variant="body1">
                {actif.adresse}
                <br />
                {actif.code_postal} {actif.ville}
              </Typography>
            </div>
            <div className={classes.info}>
              Société :
              {!societe || !societe.nom_societe ? "..." : <strong> {societe.nom_societe}</strong>}
              <br />
              Acquis le : <strong>{moment(actif.date_acquisition).format("DD/MM/YYYY")}</strong>
              <br />
              Surface total actif : <strong>{actif.surface_totale_actif} m²</strong>
              <br />
              Nombre parking intérieur : <strong>{actif.nombre_parking_int}</strong>
              <br />
              Nombre parking extérieur : <strong>{actif.nombre_parking_ext}</strong>
              <br />
              Nom de l'expert <strong> : {actif.nom_expert}</strong>
              <br />
              Valeur expertise : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.valeur_expertise}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Valeur vénale : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.valeur_venale || 0}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              <br />
              Prix net vendeur : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.prix_net_vendeur}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Com. d'intermédiaire : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.commissions_intermediaire}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Frais de notaire : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.frais_de_notaire}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Travaux bailleur : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.travaux_bailleur}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Indemnité d'éviction : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.indemnite_deviction}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Fond de commerce : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.indemnite_deviction}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <div className={classes.pA}>
                Prix de revient : {" "}
                <strong>
                  <NumberFormat
                    value={[
                      "prix_net_vendeur",
                      "frais_de_notaire",
                      "commissions_intermediaire",
                      "travaux_bailleur",
                      "fond_commerce",
                      "indemnite_deviction",
                    ].reduce((m, k) => m + parseInt(actif[k], 10), 0)}
                    displayType={"text"}
                    thousandSeparator=" "
                    suffix={" €"}
                  />
                </strong>
              </div>
              <br />
              Charge d'emprunt : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.charge_emprunt_tmp}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              <br />
              Capital restant dû : {" "}
              <strong>
                {" "}
                <NumberFormat
                  value={actif.capital_reste_du_tmp}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" €"}
                />
              </strong>
              {actif.travaux && [<br />, <br />]}
              {actif.travaux && (
                <div className={classes.pA}>Travaux : {[<br />, actif.commentaire, <br />, <br />]}</div>
              )}
              {actif.commentaire && <div className={classes.commentaire}>{actif.commentaire}</div>}
              <br />
              Banque : {!banque || !banque.nom_banque ? "" : <strong> {banque.nom_banque}</strong>}
              <br />
              <br />
            </div>
          </Grid>
          <Grid item>
            <Button
              style={{marginRight: "5px"}}
              variant="raised"
              color="primary"
              component={Link}
              to={`/locaux_mobile?idactifpatrimonial=${actif.idactifpatrimonial}`}
            >
              Locaux de cet actif
            </Button>
                <Button
                variant="raised"
                color="primary"
                onClick={this.handleFinancer}>
              Financement
            </Button>

          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Actif)));
