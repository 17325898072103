import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../pages/withGlobals";

const styles = theme => {
  return {
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%"
    },
    dialogTitle: {
      paddingBottom: theme.spacing.unit,
      textAlign: "center"
    }
  };
};

class AjoutBanqueDialog extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    onBanqueAjoutee: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  state = {
    image64: "",
    nom_banque: "",
    banques: [],
    errors: {}
  };

  handleClose = () => {
    this.props.handleClose();
  };

componentDidMount = () => {
    this.props.fetch({ url: "/banques/" }).then(banques => {
      this.setState({
          banques : banques.filter(b => (b.is_deleted == false && b.nom_banque != ' Non affectee'))
        });
    });
  };

handleClickAjout = () => {
document.getElementById('group_image').click();
}

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

onImageChange = (event) => {
  if (event.target.files && event.target.files[0]) {
    let reader = new FileReader();
    reader.onload = (e) => {
this.state.image64 = e.target.result.substring(15);
    };
    reader.readAsDataURL(event.target.files[0]);
    document.getElementById("fileNameId").innerHTML = event.target.files[0].name;
  }
}

  handleSave = e => {
    e.preventDefault();
    const { errors, ...datas } = this.state;
this.props.fetch({ url: "/banques/" }).then(banques => {
      this.setState({
          banques : banques.filter(b => (b.is_deleted == false && b.nom_banque != ' Non affectee'))
        });
    });

    var found = false;    
console.log("HELLO");
   for (var i=0; i < this.state.banques.length; i++) {
        if (this.state.banques[i].nom_banque === datas.nom_banque) {
            found = true;
	    break;
        }
    }
   
if (found) {
   alert("Nom de la banque existant. Veuillez choisir un nouveau nom.");
} else {

    this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          image64: datas.image64,
          nom_banque: datas.nom_banque,
          banque_actif: true
        }
      })
      .then(res => {
        this.props.onBanqueAjoutee(res);
        this.props.handleClose();
        this.setState({
          nom_banque: ""
        });
      })
      .catch(err => console.log(err));

}
  };

  render() {
    const { open, classes } = this.props;
    const { nom_banque } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <form>
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Ajouter une banque
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="row"
              spacing={3}
            >
<Grid item xs={6}>
Nom de la banque *
</Grid>
<Grid item xs={6}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="nom_banque"
                  name="nom_banque"
                  value={nom_banque}
                  onChange={this.handleChange}
                  label=""
                  type="text"
                  fullWidth
                />
</Grid>
<Grid item xs={6}>
Logo *
</Grid>
<Grid item xs={3}>
<input type="button" id="loadImageBanque" value="Sélectionner un logo pour la banque" onClick={this.handleClickAjout} />
<input type="file" onChange={this.onImageChange} style={{display:'none'}} className="filetype" id="group_image" name="image64"  />
</Grid>
<Grid item xs={3}>
 <h4 id="fileNameId" style={{float:'right'}} > </h4>
</Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="textSecondary">
              Annuler
            </Button>
            <Button onClick={this.handleSave} variant="raised" color="primary">
              Enregistrer cette banque
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withGlobals(AjoutBanqueDialog));
