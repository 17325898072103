import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DownloadIcon from "@material-ui/icons/GetApp";
import withGlobals from "../withGlobals";
import ReactApexChart from 'react-apexcharts'
import LogoForum from "../forum.png";
import LogoPardes from "../pardes.png";
import Select from "react-select";
import { components } from "react-select";
import MySelect from "./MySelect.js";
import makeAnimated from "react-select/animated";


const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: "Select all",
  value: ""
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const animatedComponents = makeAnimated();
const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
    // maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  progressMsg: {
    fontSize: "1.3em",
    fontWeight: "bold",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

class Crd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banques: [],
societes: [ {"idsociete":"","nom_societe":"Pardes & Forum" }, {"idsociete":1,"filiale":false,"nom_societe":"Pardes Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null},{"idsociete":2,"filiale":false,"nom_societe":"Forum Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null}],
      globale: false,
      generating: false,
      generated: false,
      updateListOfBanque: false,
      selectionBanque: [],
      selectionSociete: "",
      series: [{data: []}, {data: []}, {data: []}],
      options: {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          plotOptions: {
            bar: {
              horizontal: false,
	      columnWidth: '80%'
            },
          },
          xaxis: {
            type: 'datetime',
            categories: [],
          },
          legend: {
            position: 'right',
            offsetY: 40
          },
          fill: {
            opacity: 1
          }
        },

    };
  }

  handleSubmit = () => {
      console.log('ici');
    this.setState({ generating: true });
var temp ="";
const isSelectAll =  this.state.selectionBanque.find(b => b.value === "");
if(this.state.selectionBanque.length>0  && isSelectAll === undefined ){
temp += "&idbanque=(";
for(var i =0; i<this.state.selectionBanque.length ; i++){
    const banqueSelected = this.state.banques.find(b => b.nom_banque === this.state.selectionBanque[i].value);
temp+=banqueSelected.idbanque+",";
}
temp += "-1)"; 
}
var temp2 = "";
if(this.state.selectionSociete && this.state.selectionSociete.length > 0){
temp2 += "&idsociete="+this.state.selectionSociete;
}
        this.props.fetch({url: `${this.props.rendererHost}/crd_banque.pdf?action=dnld${temp}${temp2}`})
            .then(() => {
                     this.setState({ generating: false, generated: true });
    }).catch(() => {
        this.setState({ generating: false, generated: true }); 
    });
  };

  handleReset = () => {
    this.setState({
      globale: false,
      generating: false,
      generated: false,
      selectionBanque: [],
      selectionSociete: "",
      idsociete: null,
    });
  };

  componentDidMount = () => {
    this.props.fetch({ url: `/banques/` }).then(banques => {
this.setState({ banques });
    this.getRapportBanques();
}
);
  };

   getRapportBanques = () => {
  var query = "";
  var arrayOfSocietes = [];
const isSelectAll =  this.state.selectionBanque.find(b => b.value === "");
  if (this.state.selectionSociete != "" && this.state.selectionBanque.length !=0 && isSelectAll === undefined ) {
	query = "?idsociete="+this.state.selectionSociete+"&idbanque=(";
        for (var i=0; i<this.state.selectionBanque.length; i++) {
        const banqueSelected = this.state.banques.find(b => b.nom_banque === this.state.selectionBanque[i].value);
	query = query + ""+ banqueSelected.idbanque+","; 
	}
	query = query.substring(0, query.length-1);
        query = query + ")";
        if (this.state.selectionSociete == "1") {
		arrayOfSocietes.push("PARDES");
        } else if (this.state.selectionSociete == "2"){
                arrayOfSocietes.push("FORUM");
	}
  } else if (this.state.selectionSociete != "") {
	query = "?idsociete="+this.state.selectionSociete;
        if (this.state.selectionSociete == "1") {
                arrayOfSocietes.push("PARDES");
        } else if (this.state.selectionSociete == "2"){
                arrayOfSocietes.push("FORUM");
        }
 } else if ( this.state.selectionBanque.length !=0 && isSelectAll === undefined) {
  query = "?idbanque=(";
        for (var i=0; i<this.state.selectionBanque.length; i++) {
        const banqueSelected = this.state.banques.find(b => b.nom_banque === this.state.selectionBanque[i].value);
        query = query + ""+ banqueSelected.idbanque+",";
        }
        query = query.substring(0, query.length-1);
        query = query + ")";
        arrayOfSocietes.push("FORUM");
        arrayOfSocietes.push("PARDES");
        arrayOfSocietes.push("TOUT");
} else {
        arrayOfSocietes.push("FORUM");
        arrayOfSocietes.push("PARDES");
        arrayOfSocietes.push("TOUT");
}

var queryCall1 = "";
var queryCall2 = "";
var result1, result2;
if ( this.state.selectionSociete == "") {
if (query == "") {
queryCall1 = "?idsociete=1";
queryCall2 = "?idsociete=2";
} else {
queryCall1 = "?idsociete=1&" + query.substring(1);
queryCall2 = "?idsociete=2&" + query.substring(1);
}
this.props.fetch({ url: `/rapports/crd`+queryCall1 }).then(result =>
{
result1 = result;
});

this.props.fetch({ url: `/rapports/crd`+queryCall2 }).then(result =>
{
result2 = result;
});

}

setTimeout(() => {
this.props.fetch({ url: `/rapports/crd`+query }).then(result => 
{
var optionsDatas =  Object.keys(result);
var total = result[optionsDatas[optionsDatas.length-1]];
optionsDatas.pop();
var arrayForum = [];
var arrayPardes = [];
var arrayGlobal = [];
var series = [];

var lenghtt = optionsDatas.length;
for (var kk = 12; kk < lenghtt; kk++) {
optionsDatas.pop();
}

if (!this.state.updateListOfBanque) { 
var listOfBanquesInGraph = [];

for (var compp = 0; compp < this.state.banques.length; compp++)  {
	if (optionsDatas.includes(this.state.banques[compp].nom_banque)){
		listOfBanquesInGraph.push(this.state.banques[compp]);
	}
}
this.setState({banques: listOfBanquesInGraph});
this.setState({updateListOfBanque: true});
}

for (var i = 0; i < optionsDatas.length ; i++) {

	if (this.state.selectionSociete == "") {
		if (result2[optionsDatas[i]] != undefined) {
                	arrayForum.push((100*result2[optionsDatas[i]]/total).toFixed(2));
	        } else {
                	arrayForum.push(0);
       		}
        	if (result1[optionsDatas[i]] != undefined) {
                	arrayPardes.push((100*result1[optionsDatas[i]]/total).toFixed(2));
        	} else {
                	arrayPardes.push(0);
        	}
	}
        if (result[optionsDatas[i]] != undefined) {
                arrayGlobal.push((100*result[optionsDatas[i]]/total).toFixed(2));
        } else {
                arrayGlobal.push(0);
        }
}
if (this.state.selectionSociete == "") {
	series.push({name: "FORUM", data:arrayForum});
	series.push({name: "PARDES", data:arrayPardes});
}
series.push({name: "TOUTES", data:arrayGlobal});

this.state.series = series;

var options = {
dataLabels: {
  enabled: true,
  enabledOnSeries: undefined,
  formatter: function (val, opts) {
      return val + '%';
  },
style: {
      fontSize: '8px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 400,
      colors: ['black', 'black', 'black'] 
  }
},
		  chart: {
			type: 'bar',
			height: 350,
			toolbar: {
			  show: true
			},
			zoom: {
			  enabled: true
			}
		  },
		  responsive: [{
			breakpoint: 480,
			options: {
			  legend: {
				position: 'bottom',
				offsetX: -10,
				offsetY: 0
			  }
			}
		  }],
		  plotOptions: {
			bar: {
			  horizontal: false,
			},
		  },
yaxis: {
        labels: {
        formatter: (value) => { return value+'%'; } 
        }
    },
		  xaxis: {
			type: 'text',
labels: {
style: {
              colors: [],
              fontSize: '8px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          }
},
			categories: optionsDatas,
		  },
		  legend: {
			position: 'right',
			offsetY: 40
		  },
		  fill: {
			opacity: 1
		  }
		} ;
this.setState({options: options});

});
}, 300);

 }

  handleChangeCB = name => event => {
    this.setState({ [name]: event.target.checked, generating: false, generated: false });
  };

  handleChangeBanques = e =>{
 this.setState({ selectionBanque: e, generating: false, generated: false });
this.state.selectionBanque = e;
this.getRapportBanques();
}

  handleChangeSocietes = e =>{
this.setState({ selectionSociete: e.value, generating: false, generated: false });
this.state.selectionSociete = e.value;
this.getRapportBanques();

}

  handleChange = e =>{
 this.setState({ [e.target.name]: e.target.value, generating: false, generated: false });
this.state[e.target.name] = e.target.value; 
this.getRapportBanques();
}
  generateRapporUrl = () => {
    const banqueSelected = this.state.banques.find(b => b.nom_banque === this.state.selectionBanque);
    return `${this.props.rendererHost}/crd_banque.pdf?action=dwnld${
      this.state.selectionBanque ? `&idbanque=${banqueSelected.idbanque}` : ""
    }`;
  };

  render() {
    const { classes, theme } = this.props;
    const { banques, generating, generated, selectionBanque, societes, selectionSociete } = this.state;
     const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export";
    return [
<Grid>
<Grid item>
<Grid container alignItems="flex-start" justify="flex-start" direction="row">
<Grid item xs={2}>
</Grid>
<Grid item xs={3}>
            <Grid item>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="select-multiple-banques">Données de la banque</InputLabel>
	<MySelect
	  placeholder="Banques"
          options={this.state.banques.map((x) =>  { return { value: x.nom_banque, label: x.nom_banque }; })}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            MultiValue,
            ValueContainer,
            animatedComponents
          }}
          onChange={this.handleChangeBanques}
          allowSelectAll={true}
          value={selectionBanque}
        />
              </FormControl>
            </Grid>
</Grid>
<Grid item xs={1}>
</Grid>
<Grid item xs={3}>
<Grid item>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-banques">Société</InputLabel>

 <Select
		placeholder="Sociétés"
                value={societes.map((x) =>  { return { value: x.idsociete, label: x.nom_societe }; }).label}
                name="selectionSociete"
                onChange={this.handleChangeSocietes}
                options={societes.map((x) =>  { return { value: x.idsociete, label: x.nom_societe }; })}                
              />

	      </FormControl>
	      </Grid>
</Grid>
<Grid item xs={1}>
<Grid item>
<Grid container alignItems="flex-start" justify="flex-start" direction="row">
{false && (this.state.selectionSociete == "" || this.state.selectionSociete == "1") && (
<Grid item  xs={6}>
<img src={LogoPardes} alt="Logo PARDES" width="55%"  style={{paddingTop: "90px"}} />
</Grid>
)}
{false && (this.state.selectionSociete == "" || this.state.selectionSociete == "2") && (
<Grid item  xs={6}>
<img src={LogoForum} alt="Logo FORUM" width="63%"  style={{paddingTop: "90px"}} />
</Grid>
)}
</Grid>
</Grid>
</Grid>
</Grid>
</Grid>
<Grid item>
      <Grid container alignItems="flex-start" justify="flex-start" direction="row">
<Grid item xs={12}>
<div id="chart">
  <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
</div>
</Grid>
</Grid>
</Grid>
      </Grid>,
      generating ? <Grid item style={{ visibility: generating ? "visible" : "hidden" }}>
        <Grid container alignItems="center" justify="center" direction="row">
          <Grid item className={classes.progressMsg}>
            Generation du rapport...
          </Grid>
          <Grid item>
            <CircularProgress className={classes.progress} size={50} />
          </Grid>
        </Grid>
      </Grid> : null,
      generated ? (
        <Grid item>
          <Button color="default" variant="raised" href={`${rapportsUrl}/crd_banque.pdf`} target="__blank">
            <DownloadIcon />Télécharger le rapport
          </Button>
        </Grid>
      ) : null,
      !generated && !generating ? (
        <Grid item style={{ marginTop: "4em" }}>
          <Button color="primary" variant="raised" onClick={this.handleSubmit}>
            Générer le rapport
          </Button>
        </Grid>
      ) : null,
      generated && !generating ? (
        <Grid item style={{ marginTop: "4em" }}>
          <Button color="primary" variant="raised" onClick={this.handleReset}>
            Nouveau rapport
          </Button>
        </Grid>
      ) : null,
    ];
  }
}

export default withStyles(styles, { withTheme: true })(withGlobals(Crd));
