import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LaunchIcon from "@material-ui/icons/Launch";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import NumberFormat from "react-number-format";
import moment from "moment";
import DialogNomPortefeuille from "./DialogNomPortefeuille";

const styles = theme => ({
  adresse: {
    padding: theme.spacing.unit * 2,
    fontWeight: "bold",
  },
  actions: {
    display: "flex",
  },
  label: {
    textAlign: "left",
    padding: theme.spacing.unit,
  },
  value: {
    textAlign: "right",
    padding: theme.spacing.unit,
    fontWeight: "bold",
  },
  separation: {
    color: "silver",
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    paddingBottom: 0,
    textAlign: "left",
  },
  navShown: {
    visibility: "inherit",
  },
  navHidden: {
    visibility: "hidden",
  },
});

class FicheActifs extends Component {
  state = {
    anchorEl: null,
    dialogNomOpen: false,
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.actifs.length !== nextProps.actifs.length) {
      this.props.onChangeAcfif(0);
    }
  };

  handleCloseMenu = () => this.setState({ anchorEl: null });

  handleClick = e => this.setState({ anchorEl: e.currentTarget });

  handleAjouteActif = () => {
    this.props.onOpenActifsDialog();
    this.handleCloseMenu();
  };

  handleDeleteActif = () => {
    this.props.onDeleteActif(this.props.actifs[this.state.actifIdxSelected].idactifpatrimonial);
    this.handleCloseMenu();
  };

  handleActifSuivant = () => this.props.onChangeAcfif(this.props.actifIdxSelected + 1);

  handleActifPrecedent = () => this.props.onChangeAcfif(this.props.actifIdxSelected - 1);

  handleJumpActif = () => {
    const { actifs, actifIdxSelected } = this.props;
    this.props.history.push(`/actifs/${actifs[actifIdxSelected].idactifpatrimonial}`);
  };

  handleOpenNomDialog = () => this.setState({ dialogNomOpen: true, anchorEl: null });
  handleCloseNomDialog = () => this.setState({ dialogNomOpen: false });

  render() {
    const {
      actifs,
      classes,
      actifIdxSelected,
      nom_portefeuille,
      changeNomPortefeuille,
      readOnly,
    } = this.props;

    const actif = actifs[actifIdxSelected];

    if (!actif) return null;

    const {
      idactifpatrimonial,
      adresse,
      code_postal,
      ville,
      date_acquisition,
      surface_totale_actif,
      prix_net_vendeur,
      frais_de_notaire,
      commissions_intermediaire,
      travaux_bailleur,
      indemnite_deviction,
      fond_commerce,
    } = actif;

    const { anchorEl, dialogNomOpen } = this.state;

    const navClassName = classes[actifs.length > 1 ? "navShown" : "navHidden"];

    return (
      <Grid container direction="row" alignItems="center" justify="center">
        <DialogNomPortefeuille
          open={dialogNomOpen}
          handleClose={this.handleCloseNomDialog}
          nom_portefeuille={nom_portefeuille}
          changeNomPortefeuille={changeNomPortefeuille}
        />
        <Grid item>
          <Card className={classes.card}>
            {!readOnly && (
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleCloseMenu}
              >
                <MenuItem onClick={this.handleOpenNomDialog}>
                  <ListItemIcon className={classes.icon}>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText
                    inset
                    primary={`${
                      nom_portefeuille !== ""
                        ? "Modifier le nom du financement"
                        : "Donner un nom au financement"
                    }`}
                  />
                </MenuItem>
              </Menu>
            )}
            <CardHeader
              action={[
                <IconButton
                  aria-label="Add to favorites"
                  className={navClassName}
                  disabled={actifIdxSelected === 0}
                  onClick={this.handleActifPrecedent}
                >
                  <ChevronLeftIcon />
                </IconButton>,
                <IconButton
                  aria-label="Add to favorites"
                  className={navClassName}
                  disabled={actifIdxSelected === actifs.length - 1}
                  onClick={this.handleActifSuivant}
                >
                  <ChevronRightIcon />
                </IconButton>,
                <IconButton onClick={this.handleClick} title="Modifier nom du financement">
                  <MoreVertIcon />
                </IconButton>,
              ]}
              title={
                <span>
                  Fiche actif ID {idactifpatrimonial}
                  <IconButton onClick={this.handleJumpActif} title="Consulter l'actif">
                    <LaunchIcon />
                  </IconButton>
                </span>
              }
              subheader={
                <span style={{ fontSize: "1.3em", color: "black" }}>
                  {adresse} {code_postal} {ville}
                </span>
              }
              className={classes.header}
            />
            <CardContent classes={classes.content}>
              <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                {nom_portefeuille !== "" && (
                  <Grid item sm={6} className={classes.label}>
                    Nom du financement
                  </Grid>
                )}
                {nom_portefeuille !== "" && (
                  <Grid item sm={6} className={classes.value}>
                    {nom_portefeuille}
                  </Grid>
                )}
                <Grid item sm={9} className={classes.label}>
                  Date d'acquisition
                </Grid>
                <Grid item sm={3} className={classes.value}>
                  {moment(date_acquisition).format("DD/MM/YYYY")}
                </Grid>
                <Grid item sm={9} className={classes.label}>
                  Surface totale
                </Grid>
                <Grid item sm={3} className={classes.value}>
                  {surface_totale_actif} m²
                </Grid>
                <Grid item sm={12}>
                  <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                    <Grid item sm={12}>
                      <hr className={classes.separation} />
                    </Grid>
                    <Grid item sm={9} className={classes.label}>
                      Prix net vendeur
                    </Grid>
                    <Grid item sm={3} className={classes.value}>
                      <NumberFormat
                        value={prix_net_vendeur}
                        displayType={"text"}
                        thousandSeparator=" "
                        suffix={" €"}
                      />
                    </Grid>
                    <Grid item sm={9} className={classes.label}>
                      Frais de notaire
                    </Grid>
                    <Grid item sm={3} className={classes.value}>
                      <NumberFormat
                        value={frais_de_notaire}
                        displayType={"text"}
                        thousandSeparator=" "
                        suffix={" €"}
                      />
                    </Grid>
                    <Grid item sm={9} className={classes.label}>
                      Travaux bailleur
                    </Grid>
                    <Grid item sm={3} className={classes.value}>
                      <NumberFormat
                        value={travaux_bailleur}
                        displayType={"text"}
                        thousandSeparator=" "
                        suffix={" €"}
                      />
                    </Grid>
                    <Grid item sm={9} className={classes.label}>
                      Indemnité d'éviction
                    </Grid>
                    <Grid item sm={3} className={classes.value}>
                      <NumberFormat
                        value={indemnite_deviction}
                        displayType={"text"}
                        thousandSeparator=" "
                        suffix={" €"}
                      />
                    </Grid>
                    <Grid item sm={9} className={classes.label}>
                      Fond de commerce
                    </Grid>
                    <Grid item sm={3} className={classes.value}>
                      <NumberFormat
                        value={fond_commerce}
                        displayType={"text"}
                        thousandSeparator=" "
                        suffix={" €"}
                      />
                    </Grid>
                    <Grid item sm={9} className={classes.label}>
                      Commision d'intermédiaire
                    </Grid>
                    <Grid item sm={3} className={classes.value}>
                      <NumberFormat
                        value={commissions_intermediaire}
                        displayType={"text"}
                        thousandSeparator=" "
                        suffix={" €"}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <hr />
                    </Grid>
                    <Grid item sm={9} className={classes.label}>
                      Prix de revient
                    </Grid>
                    <Grid item sm={3} className={classes.value}>
                      <NumberFormat
                        value={
                          prix_net_vendeur +
                          frais_de_notaire +
                          commissions_intermediaire +
                          travaux_bailleur +
                          indemnite_deviction +
                          fond_commerce
                        }
                        displayType={"text"}
                        thousandSeparator=" "
                        suffix={" €"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(FicheActifs));
