import React from "react";
import { Route } from "react-router-dom";
import Headers from "../pages/Mobile/HeaderMobile"
import SideBar from "../pages/Mobile/SideBarMobile";

const PrivateRouteMobile = ({ component: Component,auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>(
   
      <div className="wrapper">
        <Headers />     
        <SideBar />
        <div className="content-wrapper">
          <Component {...rest} {...props}  />
        </div>
      </div>
    )         
    }
  />
);

PrivateRouteMobile.propTypes = {
 
};



export default PrivateRouteMobile;