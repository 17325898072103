import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../pages/withGlobals";
import PrctFormatCustom from "./PrctFormatCustom";

const styles = theme => {
  return {
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%",
    },
    dialogTitle: {
      paddingBottom: theme.spacing.unit,
      textAlign: "center",
    },
  };
};

class AjoutSocieteDialog extends Component {
  static propTypes = {
    societes: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    onSocieteAjoutee: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  state = {
    nom_societe: "",
    adresse: "",
    code_postal: "",
    ville: "",
    statut_juridique: "",
    detention_pourcentage: 0,
    idsociete_mere: 0,
    errors: {},
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSave = e => {
    e.preventDefault();
    const { errors, ...datas } = this.state;
    this.props
      .fetch({
        url: "/societes/",
        method: "POST",
        body: {
          ...datas,
        },
      })
      .then(res => {
        this.props.onSocieteAjoutee(res);
        this.props.handleClose();
        this.setState({
          nom_societe: "",
          adresse: "",
          code_postal: "",
          ville: "",
          statut_juridique: "",
          detention_pourcentage: 0,
          idsociete_mere: 0,
        });
      })
      .catch(err => console.log(err));
  };

  render() {
    const { open, societes, classes } = this.props;
    const {
      nom_societe,
      adresse,
      code_postal,
      ville,
      statut_juridique,
      detention_pourcentage,
      idsociete_mere,
    } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form>
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Ajouter une société
          </DialogTitle>
          <DialogContent>
            <Grid container alignItems="center" justify="center" direction="row" spacing={16}>
              <Grid item md={12}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="nom_societe"
                  name="nom_societe"
                  value={nom_societe}
                  onChange={this.handleChange}
                  label="Nom de la société"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  required
                  margin="dense"
                  id="statut_juridique"
                  name="statut_juridique"
                  value={statut_juridique}
                  onChange={this.handleChange}
                  label="Nature juridique"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item md={5}>
                <TextField
                  required
                  margin="dense"
                  id="adresse"
                  name="adresse"
                  value={adresse}
                  onChange={this.handleChange}
                  label="Adresse du siège social"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  required
                  margin="dense"
                  id="code_postal"
                  name="code_postal"
                  value={code_postal}
                  onChange={this.handleChange}
                  label="Code Postal"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item md={5}>
                <TextField
                  required
                  margin="dense"
                  id="ville"
                  name="ville"
                  value={ville}
                  onChange={this.handleChange}
                  label="Ville"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  margin="dense"
                  id="detention_pourcentage"
                  name="detention_pourcentage"
                  value={detention_pourcentage}
                  onChange={this.handleChange}
                  label="Pourcentage de détention par le Groupe MADAR"
                  type="text"
                  InputProps={{
                    inputComponent: PrctFormatCustom,
                  }}
                  fullWidth
                />
              </Grid>
              {societes && (
                <Grid item md={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">Société détenue par</InputLabel>
                    <Select
                      native
                      value={idsociete_mere}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "idsociete_mere",
                        id: "societe-native-simple",
                      }}
                    >
                      {societes.map(s => (
                        <option key={s.idsociete} value={s.idsociete}>
                          {s.nom_societe}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="textSecondary">
              Annuler
            </Button>
            <Button onClick={this.handleSave} variant="raised" color="primary">
              Ajouter cette société
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withGlobals(AjoutSocieteDialog));
