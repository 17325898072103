import React, { Component } from 'react';
import { Card, Col, Row } from "antd";


const Element=(props)=> {
    return ( 
    <Col className='card-element-dashboard'>
      <Card  style={{textAlign:"center", borderRadius:"6px", width:"160px"}}>
        <Row className="dashboard-label-style">
          {props.title}
        </Row>
        <Row className={props.valuePardes || props.valueForum ?"dadhboard-value-style" : "dashboard-value-styleCentred"}>
          {props.valuePardes &&<Col className="dashboard-value-padres">
            {props.valuePardes+" "+ "%"} 
          </Col>}
          {props.valueForum&&<Col className="dashboard-value-forum">
            { props.valueForum &&props.valueForum+" "+ "%"} 
          </Col>}
          {props.Euribor&&<Col className="dashboard-value-euribor">
            { props.Euribor  &&props.Euribor} 
          </Col>}
        </Row>
      </Card>
    </Col>
     );
}

export default Element;