
import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom'
import { Modal, Row } from 'antd';
import Dialog from "@material-ui/core/Dialog";
import LogoAssistance from "./assistance.png";
import LogoClose from "./close.png";
import { Tabs } from 'antd';
import { Table, Input, Button, Space } from 'antd';
import GroupBy from "lodash/groupBy";
import moment from "moment";
import { Avatar, Image } from 'antd';

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          lang: 'fr',
          visible: true,
          topScroll: 0,
          isActive:false,
          isActiveParametre:false,
          user:window.sessionStorage.getItem('madar'),
          visibleModal:false,
          visibleModalNotification:false,
          data: [
            
          ],
       
        }
      }
      componentDidMount(){
       this.getUser()
       
      
      }
     
 getUser(){
   const user = JSON.parse(window.localStorage.getItem('madar'))
    this.setState({user:user}) 
 }
 openModal=()=>{
   console.log("hiii");
   this.setState({visibleModal:true})
 }
  handleOk=()=>{
    this.setState({visibleModal:false})
};

 handleCancel=()=>{
  this.setState({visibleModal:false})
};
openModalNotification =()=>{
  this.setState({visibleModalNotification:true})
  this.getNotification()
}
handleCancelModalNotification =()=>{
  this.setState({visibleModalNotification:false})
}  
callback=(key)=> {
  // console.log(key);
}
handleFetch = ({ url, method, body, silent }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  console.log("user", this.state.user);
  if (this.state.user) {
    headers["X-Api-Key"] = this.state.user.api_key;
  }
  return new Promise((resolve, reject) => {
    const prefix = url.indexOf('/pdf') == -1 ? '/public/index.php' : '';
    console.log('fetch', `${prefix}${url}`);
    return fetch(`${prefix}${url}`, {
      method: method || "GET",
      headers,
      body: body ? JSON.stringify(body) : null
    })
    
  });
};


getNotification =async ()=>{
  const requestOptions = {
    method: 'GET',
    headers:{"X-Api-Key":this.state.user.api_key,
    Accept: "application/json",
    "Content-Type": "application/json"}
  };
    const  data = await fetch('/public/index.php/notifications/', requestOptions);
    const dataJson =await data.json();
    const res = dataJson.data;

    const hardcodedNotif = res && res.length && res.length > 0 ? res : [];
    const notificationsGrouped = GroupBy(hardcodedNotif, "type");
    const types = Object.keys(notificationsGrouped)
      .filter(k => k !== "Euribor")
      .map(k => ({
        k,
        qte: notificationsGrouped[k].length
      }))
      .sort((t2, t1) => {
        if (t1.qte > t2.qte) return 1;
        if (t1.qte < t2.qte) return -1;
        return 0;
      });
     
    
    const preavis =[];
    const baux =[];
    const emprunt =[];
    console.log("hiii",hardcodedNotif);
    hardcodedNotif.forEach(element => {
      if(element.type == "preavis"){
        preavis.push(element)
      }
      if(element.type == "baux"){
        baux.push(element)
      }
      if(element.type == "emprunts"){
        emprunt.push(element)
      }
      
    });
    this.setState({
      loaded: true,
      notifications: hardcodedNotif,
      types,
      typeSelected: types && types.length && types.length > 0 && types[0].k,
      baux: baux,
      preavis:preavis,
      emprunt:emprunt
    });
    
    
}
 toggleClass = () => {
   this.setState({isActive: !this.state.isActive})
  
};
toggleClassParametre = () => {
  this.setState({isActiveParametre: !this.state.isActiveParametre})
}
handleDelete = idnotification => {
  const { notifications } = this.state;
  const requestOptions = {
    method: 'DELETE',
    headers:{"X-Api-Key":this.state.user.api_key,
    Accept: "application/json",
    "Content-Type": "application/json"}
  };
    fetch(`/public/index.php/notifications/${idnotification}`, requestOptions) .then(() =>
    this.setState({
      notifications: notifications.filter(
        n => n.idnotification !== idnotification
      )
    })
    );;
    window.location.reload()
  };
render() {
  const { TabPane } = Tabs;

  const columns = [
    {
      title: 'Title',
      dataIndex: 'message',
      key: 'message',
      width: '70%',
     
    },
    {
      title: 'Action',
      render: (dataIndex) =>(<>
      <Row>
      <div className="sideBar-modal-notification-btn-show" onClick={()=>window.location.href=dataIndex.lien}><i className="fa fa-eye" aria-hidden="true"></i></div>
       <div className="sideBar-modal-notification-btn-delete" onClick={(dataIndex)=>this.handleDelete(dataIndex.idnotification)}><i className="fa fa-trash" aria-hidden="true"></i></div>
      </Row>
       </>),
    
    },
   
   
  ];

    return ( 
      <div>
      <Dialog
        open={this.state.visibleModal}
        onClose={this.handleCancel}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
      <div style={{border: "1px solid red", padding: "8px", position: "relative"}}>Besoin d'aide? Contactez-nous sur <br/>
      <a href="mailto:madar@2m-advisory.atlassian.net">madar@2m-advisory.atlassian.net</a>
      <div style={{ position: "absolute", top: "0px", right: "0px"}}><img src={LogoClose}
      alt="Fermer" width="25px" onClick={this.handleCancel } /></div></div>

      </Dialog>
      <Dialog
        open={this.state.visibleModalNotification}
        onClose={this.handleCancelModalNotification}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"md"}
        
        
      >
      <div>
      <Tabs defaultActiveKey="1" onChange={this.callback}>
        <TabPane tab="BAUX" key="1">
        <Table columns={columns} dataSource={this.state.baux} />
        </TabPane>
        <TabPane tab="PRÉAVIS" key="2">
        <Table columns={columns} dataSource={this.state.preavis} />
        </TabPane>
        <TabPane tab="EMPRUNTS" key="3">
        <Table columns={columns} dataSource={this.state.emprunt} />
        </TabPane>
      </Tabs>
     
      <div style={{ position: "absolute", top: "0px", right: "10px", cursor:"pointer"}} onClick={this.handleCancelModalNotification } >
        <i className="fa fa-times fa-2x" aria-hidden="true" ></i></div></div>
      </Dialog>
      
    
      <aside className="main-sidebar  elevation-4 white">
      
      
    <div className="sidebar">
    
      <div className="user-panel mt-3 pb-3 mb-3 d-flex" style={{cursor:"pointer"}} onClick={this.openModalNotification}>
        <div className="image">
        <Avatar size={40}>{this.state.user && this.state.user.prenom.slice(0,4)}</Avatar>
        </div>
        <div className="info">
          <a className="d-block">{this.state.user && this.state.user.prenom}</a>
        </div>
      </div>

     
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
   
          <li className="nav-item menu-open">
            <Link to={"/"} className="nav-link">
              <i className="nav-icon fas fa-tachometer-alt"></i>
              <p>
              Tableau de bord
              </p>
            </Link>
            
          </li>
          <li className="nav-item">
            <Link to={"users"} className="nav-link">
              <i className="nav-icon fas fa-user"></i>
              <p>
                Utilisateurs
              </p>
            </Link>
          </li>
          <li className={this.state.isActive?"nav-item menu-is-opening menu-open": "nav-item"} onClick={this.toggleClass}>
            <a  className="nav-link">
            <i className="nav-icon fa fa-home"></i>
              <p>
                Patrimoine
                <i className="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <Link to={"/actifs"} className="nav-link">
                <i className="far fa fa-building" aria-hidden="true" style={{marginLeft:"7px",marginRight:"10px"}}></i>
                  {/* <i className="far fa-circle nav-icon"></i> */}
                  <p>Actifs</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/locaux"} className="nav-link">
                <i className="far fa fa-th-large" aria-hidden="true" style={{marginLeft:"7px",marginRight:"10px"}}></i>
                  {/* <i className="far fa-circle nav-icon"></i> */}
                  <p>Locaux</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/baux"} className="nav-link">
                <i className="far fa fa-file-text-o" aria-hidden="true" style={{marginLeft:"7px",marginRight:"10px"}}></i>
                  {/* <i className="far fa-circle nav-icon"></i> */}
                  <p>Baux</p>
                </Link>
              </li>
             
            </ul>
          </li>
          <li className="nav-item">
            <Link to={"/emprunts"} className="nav-link">
            <i className="nav-icon fas fa-usd"></i>
            
              {/* <i className="nav-icon fas fa-user"></i> */}
              <p>
                Gestion financière
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/rapports"} className="nav-link">
            {/* <i className="nav-icon fas fa-dollar"></i> */}
            <i className="nav-icon fas fa fa-file-text-o" aria-hidden="true"></i>
              <p>
                Reporting
              </p>
            </Link>
          </li>
          <li className={this.state.isActiveParametre?"nav-item menu-is-opening menu-open": "nav-item"} onClick={this.toggleClassParametre}>
            <a className="nav-link">
              {/* <i className="nav-icon fas fa-edit"></i> */}
              <i className="nav-icon fas fa fa-cogs" aria-hidden="true"></i>
              <p>
                Paramètrage
                <i className="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <Link to={"/controle-baux-altaix"} className="nav-link">
                <i className="fa fa-eye" style={{marginLeft:"7px",marginRight:"10px"}} aria-hidden="true"></i>
                  <p>Controle des baux Altaix</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/controle-emprunts-finance-active"} className="nav-link">
                <i className="fa fa-eye" style={{marginLeft:"7px",marginRight:"10px"}} aria-hidden="true"></i>
                  <p>Contrôle Emprunts Finance Active</p>
                </Link>
              </li>
              <li className="nav-item">
              <Link to={"/exclusionVRs"} className="nav-link">
              <i className="fa fa-ban" style={{marginLeft:"7px",marginRight:"10px"}} aria-hidden="true"></i>
                  <p>Exclusion des VRs</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/gestionDesBanques"} className="nav-link">
                <i className="fa fa-money" style={{marginLeft:"7px",marginRight:"10px"}} aria-hidden="true"></i>
                  <p>Gestion des banques</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/projectionIndexationLoyersOld"} className="nav-link">
                <i className="fa fa-pencil" style={{marginLeft:"7px",marginRight:"10px"}} aria-hidden="true"></i>
                  <p>Modification des charges d'emprunts</p>  
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/projectionIndexationLoyers"} className="nav-link">
                <i className="fa fa-chain-broken" style={{marginLeft:"7px",marginRight:"10px"}} aria-hidden="true"></i>
                  <p>Paramètrage VR</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/parametrage"} className="nav-link">
                <i className="fa fa-line-chart" style={{marginLeft:"7px",marginRight:"10px"}} aria-hidden="true"></i>
                  <p>Projection d'indexation des loyers</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/files/export/MAJFinanceActive.log"} className="nav-link" target="_blank">
                <i className="fa fa-server" style={{marginLeft:"7px",marginRight:"10px"}} aria-hidden="true"></i>
                  <p>Batch Finance Active</p>
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item" style={{position:"fixed", bottom:"50px"}} onClick={this.openModal}>
            <Link to={""} className="nav-link">
              {/* <i className="nav-icon fas fa-user"></i> */}
              <i className="nav-icon fas fa fa-envelope-o" aria-hidden="true"></i>
              <p>
                Assistance
              </p>
            </Link>
          </li>
          <li className="nav-item" style={{position:"fixed", bottom:"10px"}}>
            <Link to={"/historiqueConnexion"} className="nav-link">
              {/* <i className="nav-icon fas fa-user"></i> */}
              <i className="nav-icon fas fa fa-clock-o" aria-hidden="true"></i>
              <p>
                Historique de connexion
              </p>
            </Link>
          </li>
        </ul>
      </nav>
  
    </div>
    
  </aside>
  
  </div>
);
}
}

export default (withRouter(SideBar))