import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

class CreationRedirectionModal extends Component {
  static defaultProps = {
    cancel: "Non",
    ok: "Oui",
    onCancel: () => ({}),
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  handleOk = () => {
    this.props.onAccept();
  };

  render() {
    const { children, open, titre, ok, cancel, onCancel, onAccept } = this.props;
    return (
      <Dialog open={open} modal aria-labelledby="confirmation-dialog-title">
        <DialogTitle id="confirmation-dialog-title">{titre}</DialogTitle>
        <DialogContent>{React.cloneElement(children)}</DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancel}</Button>
          <Button type="submit" onClick={onAccept} variant="outlined" color="primary">
            {ok}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreationRedirectionModal;
