import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Prompt } from "react-router-dom";
import { Route } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Local from "./Local";
import DatasLoader from "./DatasLoader";
import NouveauDatasLoader from "./NouveauDatasLoader";
import Centered from "../../layout/Centered";
import ListeActifs from "../../common/ListeActifs";
import columnData from "./columnData";
import "./columnData.css";

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: 0,
      paddingRight: 0,
      width: "500px"
    },
    zoneAjout: {
      maxHeight: "calc(100vh - 255px)",
      minHeight: "calc(100vh - 255px)"
    }
  };
};

class Locaux extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    openNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const {
      location: { pathname }
    } = props;
    const localId = parseInt(pathname.split("/")[2], 10);
    this.state = {
      loading: localId ? true : false,
      locaux: [],
      locauxFiltred: [],
      creationMode: false,
      formDataChanged: false,
      textFiltered: false
    };
  }

  componentWillReceiveProps = nextProps => {
    if (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.location.search !== nextProps.location.search
    ) {
      this.handleResetDataChanged();
    }
  };

  handleLocauxLoaded = locaux => this.setState({ locaux, loading: false });

  handleSocietesAdded = societe =>
    this.props.openNotification(
      "success",
      `Société ${societe.nom_societe} ajoutée`,
      undefined,
      4000
    );

  handleLocauxFiltred = (locauxFiltred, textFiltered) =>
    this.setState({ locauxFiltred, textFiltered });

  navigateToCreationMode = () =>
    this.props.history.push(`/locaux/nouveau${this.props.location.search}`);

  handleDataChanged = () => this.setState({ formDataChanged: true });

  handleResetDataChanged = () => this.setState({ formDataChanged: false });

  handleDelete = id => {
    this.setState({
      locaux: this.state.locaux.filter(a => a.idlocal !== id),
      formDataChanged: false
    });
  };

  handleUpdate = values => {
    this.setState({
      locaux: this.state.locaux.map(
        l => (l.idlocal !== values.idlocal ? l : { ...l, ...values })
      ),
      formDataChanged: false
    });
  };

  handleCreate = values =>
    this.setState({
      locaux: this.state.locaux.concat([values]),
      formDataChanged: false
    });

  handleClick = (e, selected) => {
    const {
      history,
      location: { pathname, search }
    } = this.props;
    this.setState({ creationMode: false, formDataChanged: false });

    if (pathname !== "/locaux" && pathname !== `/locaux/${selected}`) {
      history.push(`/locaux/${selected}${search}`);
      return;
    }
    history.push(
      pathname !== "/locaux" || pathname === `/locaux/${selected}`
        ? "/locaux"
        : `/locaux/${selected}${search}`
    );
  };

  render() {
    const {
      match,
      location: { pathname, search },
      openNotification,
      user
    } = this.props;
    if (false && (!user || !user.login)) return <h1>Acces denied</h1>;
    const {
      locaux,
      loading,
      formDataChanged,
      textFiltered,
      locauxFiltred
    } = this.state;

    const idactifpatrimonial = search.split("actifId=")[1]
      ? parseInt(search.split("actifId=")[1], 10)
      : undefined;
    const idLocalUrl = pathname.split("/")[2];
    const localId = parseInt(idLocalUrl, 10);
    const apiPathname = `/locaux/${
      idactifpatrimonial ? `?idactifpatrimonial=${idactifpatrimonial}` : ""
      //`bailactif=true`
    }`;
    return (
      <Centered alignItems="flex-start" justify="center">
        {!localId &&
          idLocalUrl !== "nouveau" && (
            <Grid item>
              <ListeActifs
                onSelectRow={this.handleClick}
                datas={locaux.filter(
                  l =>
                    idactifpatrimonial
                      ? l.idactifpatrimonial === idactifpatrimonial
                      : true
                )}
                onDatasLoaded={this.handleLocauxLoaded}
                onDatasFiltred={this.handleLocauxFiltred}
                onSocieteAdded={this.handleSocietesAdded}
                onAddClicked={this.navigateToCreationMode}
                apiPathname={apiPathname}
                datasName="locaux"
                idField="idlocal"
                addButtonTitle="Créer un nouveau local"
                columnData={columnData}
                showIdActif
                searchFields={[
                  {
                    label: "Id local",
                    name: "idlocal"
                  },
                  {
                    label: "Id Actif",
                    name: "idactifpatrimonial"
                  },
                  {
                    label: "Code postal",
                    name: "codePostal"
                  },
                  {
                    label: "Ville",
                    name: "ville"
                  },
                  {
                    label: "Adresse",
                    name: "adresse"
                  },
                  {
                    label: "Surface",
                    name: "surfaceTotale"
                  }
                ]}
              />
            </Grid>
          )}
        <Prompt
          when={formDataChanged}
          message={location =>
            `Si vous cliquez sur OK, les données ne seront pas sauvegardées`
          }
        />
        <Route
          path={`${match.url}/nouveau`}
          exact
          render={() => (
            <Grid item sm={11}>
              <NouveauDatasLoader
                idactifpatrimonial={idactifpatrimonial}
                locaux={locauxFiltred.filter(
                  l => l.idactifpatrimonial === idactifpatrimonial
                )}
              >
                <Local
                  local={idactifpatrimonial ? { idactifpatrimonial } : {}}
                  locaux={locauxFiltred}
                  filtred={textFiltered}
                  onFormDataChanged={this.handleDataChanged}
                  onResetDataChanged={this.handleResetDataChanged}
                  formDataChanged={formDataChanged}
                  onDelete={this.handleDelete}
                  onUpdate={this.handleUpdate}
                  onCreate={this.handleCreate}
                  openNotification={openNotification}
                />
              </NouveauDatasLoader>
            </Grid>
          )}
        />
        <Route
          path={`${match.url}/:localId(\\d+)`}
          render={() =>
            !loading && locaux.length === 0 ? null : (
              <Grid item sm={11}>
                <DatasLoader
                  locaux={
                    idactifpatrimonial
                      ? locauxFiltred.filter(
                          l => l.idactifpatrimonial === idactifpatrimonial
                        )
                      : locauxFiltred
                  }
                  localId={localId}
                >
                  <Local
                    local={locaux.find(b => b.idlocal === localId)}
                    filtred={textFiltered || idactifpatrimonial}
                    onFormDataChanged={this.handleDataChanged}
                    onResetDataChanged={this.handleResetDataChanged}
                    loading={loading}
                    formDataChanged={formDataChanged}
                    openNotification={openNotification}
                    onDelete={this.handleDelete}
                    onUpdate={this.handleUpdate}
                  />
                </DatasLoader>
              </Grid>
            )
          }
        />
      </Centered>
    );
  }
}

export default withRouter(withStyles(styles)(Locaux));
