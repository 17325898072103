import React from "react";
import ReactTable from "react-table";
import { withStyles } from "@material-ui/core/styles";
import headerStyle from "../../common/tableHeaderStyle";
import NumberFormat from "react-number-format";
import moment from "moment";

const styles = theme => {
  return {
    table: {
      height: "calc(100vh - 250px)",
    },
    tableM: {
      height: "calc(100vh - 150px)",
    },
  };
};

const freqHeaders = {
  mensuelle: "Mensualité",
  trimestrielle: "Trimestrialité",
  annuelle: "Annualité",
};

const Amortissements = ({ amortissements, frequence, classes, layout }) => (
  <ReactTable
    data={amortissements}
    columns={[
      {
        Header: "Échéance",
        accessor: "echeances_numero",
        width: 80,
        Cell: row => <span>{row.value}</span>,
        headerStyle: { ...headerStyle, textAlign: "right" },
      },
      {
        Header: "Capital début période",
        accessor: "capital",
        Cell: row => (
          <NumberFormat
            value={parseFloat(row.value).toFixed(2)}
            displayType={"text"}
            thousandSeparator=" "
            suffix={" €"}
          />
        ),
        headerStyle: { ...headerStyle, textAlign: "right" },
      },
      {
        Header: "Remb. en capital",
        accessor: "capital_rembourse",
        width: 120,
        Cell: row => (
          <NumberFormat
            value={parseFloat(row.value).toFixed(2)}
            displayType={"text"}
            thousandSeparator=" "
            suffix={" €"}
          />
        ),
        headerStyle: { ...headerStyle, textAlign: "right" },
      },
      {
        Header: "Intérets",
        accessor: "montant_interets",
        width: 110,
        Cell: row => (
          <NumberFormat
            value={parseFloat(row.value).toFixed(2)}
            displayType={"text"}
            thousandSeparator=" "
            suffix={" €"}
          />
        ),
        headerStyle: { ...headerStyle, textAlign: "right" },
      },
      {
        Header: "Capital fin période",
        accessor: "valeur_residuelle",
        width: 150,
        Cell: row => (
          <NumberFormat
            value={parseFloat(row.value).toFixed(2)}
            displayType={"text"}
            thousandSeparator=" "
            suffix={" €"}
          />
        ),
        headerStyle: {
          ...headerStyle,
          textAlign: "right",
          paddingRight: "10px",
        },
      },
      {
        Header: "Écheance",
        accessor: "date_echeance",
        Cell: row => (
          <div style={{ paddingRight: 0 }}>
            {row.value !== "" ? moment(row.value).format("DD/MM/YYYY") : ""}
          </div>
        ),
        headerStyle: {
          ...headerStyle,
          textAlign: "right",
          paddingRight: "15px",
        },
      },
    ]}
    noDataText="Aucune donnée"
    showPagination={false}
    pageSize={amortissements.length}
    className={`-striped -highlight ${classes[layout === "desktop" ? "table" : "tableM"]}`}
  />
);

export default withStyles(styles)(Amortissements);
