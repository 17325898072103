import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import withGlobals from "../withGlobals";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";

const styles = theme => ({
  adresse: {
    padding: theme.spacing.unit * 2,
    fontWeight: "bold",
  },
  actions: {
    display: "flex",
  },
  label: {
    textAlign: "left",
    paddingLeft: theme.spacing.unit,
  },
  value: {
    textAlign: "right",
    padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
    fontWeight: "bold",
  },
  label2: {
    textAlign: "left",
    padding: theme.spacing.unit,
    backgroundColor: "silver",
  },
  value2: {
    textAlign: "right",
    padding: theme.spacing.unit,
    fontWeight: "bold",
    backgroundColor: "silver",
  },
  separation: {
    color: "silver",
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    paddingBottom: 0,
    textAlign: "left",
  },
  navShown: {
    visibility: "inherit",
  },
  navHidden: {
    visibility: "hidden",
  },
});

class ActifsEmpruntMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      actifs: props.actifs,
    };
  }

  componentDidMount = () => {
    this.loadActif();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { actifs, index } = this.state;
    if (index !== prevState.index && !actifs[index].date_acquisition) {
      this.loadActif();
    }
  };

  loadActif = () => {
    this.props
      .fetch({ url: `/actifs/${this.state.actifs[this.state.index].idactifpatrimonial}` })
      .then(actif => {
        this.setState({
          actifs: this.state.actifs.map(
            a =>
              a.idactifpatrimonial === this.state.actifs[this.state.index].idactifpatrimonial ? actif : a,
          ),
        });
      });
  };
handleJumpActif = () => {
    const { actifs, index } = this.state;
    window.location = `/actifs_mobile/${actifs[index].idactifpatrimonial}`;
  };


  handleActifSuivant = () => this.setState({ index: this.state.index + 1 });

  handleActifPrecedent = () => this.setState({ index: this.state.index - 1 });

  render() {
    const { classes, nomPorteufeuille } = this.props;
    const { index, actifs } = this.state;
    const actif = actifs[index];

    if (!actif) return null;

    const {
      idactifpatrimonial,
      adresse,
      code_postal,
      ville,
      date_acquisition,
      surface_totale_actif,
      prix_net_vendeur,
      frais_de_notaire,
      travaux_bailleur,
      commissions_intermediaire,
      indemnite_deviction,
      fond_commerce,
      banque,
    } = actif;

    return (
      <Paper elevation={2}>
        <Typography variant="body1">
          ID Actif {idactifpatrimonial}
<IconButton onClick={this.handleJumpActif} title="Consulter l'actif">
<LaunchIcon />
</IconButton>
          <br />
          {adresse} {code_postal} {ville}
        </Typography>
        <hr />
        {/* <Grid container alignItems="flex-start" justify="stretch" direction="row"> */}
        <Grid item>
          <Grid container alignItems="flex-start" justify="flex-start" direction="row">
            <Grid item xs={8} className={classes.label}>
              Date d'acquisition
            </Grid>
            <Grid item xs={4} className={classes.value}>
              {moment(date_acquisition).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={8} className={classes.label}>
              Surface totale
            </Grid>
            <Grid item xs={4} className={classes.value}>
              {surface_totale_actif} m²
            </Grid>
            <Grid item xs={9} className={classes.label}>
              Prix net vendeur
            </Grid>
            <Grid item xs={3} className={classes.value}>
              <NumberFormat
                value={prix_net_vendeur}
                displayType={"text"}
                thousandSeparator=" "
                suffix={" €"}
              />
            </Grid>
            <Grid item xs={9} className={classes.label}>
              Frais de notaire
            </Grid>
            <Grid item xs={3} className={classes.value}>
              <NumberFormat
                value={frais_de_notaire}
                displayType={"text"}
                thousandSeparator=" "
                suffix={" €"}
              />
            </Grid>

            <Grid item xs={9} className={classes.label}>
              Travaux bailleur
            </Grid>
            <Grid item xs={3} className={classes.value}>
              <NumberFormat
                value={travaux_bailleur}
                displayType={"text"}
                thousandSeparator=" "
                suffix={" €"}
              />
            </Grid>
            <Grid item xs={9} className={classes.label}>
              Indemnité d'éviction
            </Grid>
            <Grid item xs={3} className={classes.value}>
              <NumberFormat
                value={indemnite_deviction}
                displayType={"text"}
                thousandSeparator=" "
                suffix={" €"}
              />
            </Grid>
            <Grid item xs={9} className={classes.label}>
              Fond de commerce
            </Grid>
            <Grid item xs={3} className={classes.value}>
              <NumberFormat value={fond_commerce} displayType={"text"} thousandSeparator=" " suffix={" €"} />
            </Grid>
            <Grid item xs={9} className={classes.label}>
              Commission d'intermédiaire
            </Grid>
            <Grid item xs={3} className={classes.value}>
              <NumberFormat
                value={commissions_intermediaire}
                displayType={"text"}
                thousandSeparator=" "
                suffix={" €"}
              />
            </Grid>
            <Grid item xs={7} className={classes.label2}>
              Prix de revient
            </Grid>
            <Grid item xs={5} className={classes.value2}>
              <NumberFormat
                value={
                  prix_net_vendeur +
                  frais_de_notaire +
                  commissions_intermediaire +
                  travaux_bailleur +
                  indemnite_deviction +
                  fond_commerce
                }
                displayType={"text"}
                thousandSeparator=" "
                suffix={" €"}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Paper>
    );
  }
}

export default withStyles(styles)(withGlobals(ActifsEmpruntMobile));
