import { Card, Row, Col } from 'antd';
import React, { Component } from 'react';


const ElementSynthese =(props) => {
    return ( <div style={{paddingTop:props.paddingCard ? props.paddingCard : "18px", paddingLeft:"5%" }}>
        <Card className='card-dashboard-element-synthese' style={{border:props.styledCard, background:props.backgroundCard}}>
            <Row>
                <Col span={24}>
                    {props.title}
                </Col>
            </Row>
        </Card>

    </div> );
}

export default ElementSynthese;