import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { withFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import EuroIcon from "@material-ui/icons/EuroSymbol";
import ListIcon from "@material-ui/icons/List";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import validationSchema from "./validation";
import ActifForm from "./Form";
import ConfirmWrapper from "../../common/ConfirmWrapper";
import ListeActifsDialog from "../../common/ListeActifsDialog";
import EnvoyerParMailDialog from "../../common/EnvoyerParMailDialog";
import CreationRedirectionModal from "../../common/CreationRedirectionModal";
import withGlobals from "../withGlobals";
import moment from "moment";

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 1,
      paddingBottom: theme.spacing.unit * 2,
      // marginRight: theme.spacing.unit * 2,
      // width: `calc(100% - ${theme.spacing.unit * 2})`,
    },
    fullHeight: {
      display: "flex",
      //height: "calc(100vh - 150px)",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
    button: {
      marginTop: theme.spacing.unit * 4,
    },
    buttonHidden: {
      marginTop: theme.spacing.unit * 4,
      visibility: "hidden",
    },
    icon: {
      height: "3em",
      width: "3em",
      fill: theme.palette.primary.dark,
    },
    title: {
      marginBottom: 0,
      fontSize: 14,
      lineHeight: "36px",
      fontWeight: "bold",
    },
    rightIcon: {
      marginLeft: theme.spacing.unit / 2,
      marginRight: theme.spacing.unit / 2,
    },
    titleArea: {
      border: `solid 1px ${theme.palette.primary.main}`,
      padding: "2px",
      borderRadius: "3px 3px",
      marginBottom: theme.spacing.unit * 2,
    },
  };
};

const ActifFormik = withFormik({
  mapPropsToValues: props => {
    const { actif } = props;
    return {
      ...actif,
      financement: actif.financement ? "financement" : "fonds_propres",
    };
  },
  validationSchema: validationSchema,
  handleSubmit: (
    values,
    { props, setSubmitting, setErrors /* setValues, setStatus, and other goodies */ },
  ) => {
    setSubmitting(true);
    props
      .onSubmit(values)
      .then(() => setSubmitting(false))
      .catch(e => setSubmitting(false));
  },
})(withStyles(styles)(ActifForm));

class Actif extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    actif: PropTypes.object.isRequired,
    banques: PropTypes.array.isRequired,
    societes: PropTypes.array.isRequired,
    typesFinancement: PropTypes.array.isRequired,
    openNotification: PropTypes.func.isRequired,
    onFormDataChanged: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    formDataChanged: PropTypes.bool.isRequired,
    onResetDataChanged: PropTypes.func.isRequired,
    filtred: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,

      // pour forcer le remontage du formulaire
      changingDatas: false,

      submiting: false,
      justSaved: false,
      changed: false,
      simple: false,
      filtredDialogOpen: false,
      messageDialogOpen: false,
      societes: props.societes,
      modalRedirectionOpen: false,
      idactifpatrimonialCreated: null,
    };
  }

  componentDidMount = () => {
    if (this.state.societes.length === 0) {
      this.props.fetch({ url: "/societes/" }).then(societes => this.setState({ societes }));
    }
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.actif &&
      this.props.actif &&
      nextProps.actif.idactifpatrimonial !== this.props.actif.idactifpatrimonial
    ) {
      // this.props.onResetDataChanged();
      this.setState({
        changingDatas: true,
        justSaved: false,
        submitting: false,
        filtredDialogOpen: false,
      });
      setTimeout(() => this.setState({ changingDatas: false }), 1);
    }
  };

  handleChangeVue = () => this.setState({ simple: !this.state.simple });

  handleSubmit = values => {
    const { openNotification, onResetDataChanged, onUpdate, onCreate } = this.props;
    const { idactifpatrimonial } = values;
    const prixVente  =   parseFloat(values.prix_net_vendeur, 2) +  parseFloat(values.frais_de_notaire, 2) +  parseFloat(values.commissions_intermediaire, 2) +  parseFloat(values.travaux_bailleur, 2) +  parseFloat(values.indemnite_deviction, 2) +  parseFloat(values.fond_commerce, 2) ;
    const date_acquisition =
      values.date_acquisition !== ""
        ? moment(values.date_acquisition)
          .add(1, "day")
          .toISOString()
        : null;
    this.setState({ submiting: true });
    if (values.idlocaux) {
      delete values.idlocaux;
    }
    if (values.code_bail) {
      delete values.code_bail;
    }
    if (values.nouvel_indice) {
      delete values.nouvel_indice;
    }
    return new Promise((resolve, reject) => {
      this.props
        .fetch({
        
          url: `/actifs/${idactifpatrimonial ? idactifpatrimonial : ""}`,
          method: idactifpatrimonial ? "PUT" : "POST",
          body: {
            ...values,
            prix_revient_dachat : prixVente,
            financement: values.financement === "financement",
            date_acquisition,
            idbanque: values.idbanque === 0 ? null : values.idbanque,
          },
        })
        .then(res => {
          try {
            onResetDataChanged();
             openNotification(
               "success",
               !idactifpatrimonial
                 ? `L'actif ${res.idactifpatrimonial} a été créé. Créer un local ?`
                 : `Actif ${idactifpatrimonial} modifié`,
               !idactifpatrimonial ? `/locaux/nouveau?actifId=${res.idactifpatrimonial}` : undefined,
               2000,
             );

            if (idactifpatrimonial) {
              // maj datas pour idactifpatrimonial dans actifs
              onUpdate(res);
            } else {
              onCreate(res);
            }

            this.setState({
              submiting: false,
              justSaved: true,
              modalRedirectionOpen: !idactifpatrimonial ? true : false,
              idactifpatrimonialCreated: !idactifpatrimonial ? res.idactifpatrimonial : null,
            });

            // if (actifs.length === 1 || !idactifpatrimonial) {
            //   history.push("/actifs");
            // }
            //window.location.reload();
            return resolve();
          } catch (e) {
            return reject(e);
          }
          // this.timeoutRedirect = setTimeout(() => history.push("/actifs"), 4100);
        }).catch(e => {
          this.setState({ submiting: false });
        })
    });
  };

  handleDelete = () => {
    const { onResetDataChanged, openNotification, actif, onDelete, history } = this.props;
    this.setState({ submiting: true });
    this.props.fetch({ url: `/actifs/${actif.idactifpatrimonial}`, method: "DELETE" }).then(() => {
      onResetDataChanged();
      openNotification("success", `Actif (${actif.entite})-(${actif.adresse} ${actif.ville}) supprimé !`, null, 5000);
      this.setState({ submiting: false, justSaved: true });
      onDelete(actif.idactifpatrimonial);
      history.push("/actifs");
    });
  };

  navigateToLocaux = () => {
    const { actif, history } = this.props;
    history.push(`/locaux?actifId=${actif.idactifpatrimonial}`);
  };

  handleOpenFilteredDialog = () => this.setState({ filtredDialogOpen: true });

  handleCloseFilteredDialog = () => this.setState({ filtredDialogOpen: false });

  handleOpenMessageDialog = () => this.setState({ messageDialogOpen: true });

  handleCloseMessageDialog = () => this.setState({ messageDialogOpen: false });

  handleCloseModalRedireactionDialog = () => this.setState({ modalRedirectionOpen: false });

  handleActifPrecedent = index => {
    const { actifs, history } = this.props;
    history.push(`/actifs/${actifs[index - 1].idactifpatrimonial}`);
  };

  handleActifSuivant = index => {
    const { actifs, history } = this.props;
    history.push(`/actifs/${actifs[index + 1].idactifpatrimonial}`);
  };

  handleFinancer = index => {
    const { actif, history } = this.props;
    if(!actif.financement){
    history.push(`/emprunts/nouveau?actifId=${actif.idactifpatrimonial}`);
} else {
    history.push(`/emprunts/${actif.idinvestissement}`);
}
  };

  navigateTo = url => {
    this.props.history.push(url);
    this.handleCloseFilteredDialog();
  };

  handleAcceptRediretion = () => {
    const { history } = this.props;
    history.push(`/locaux/nouveau?actifId=${this.state.idactifpatrimonialCreated}`);
    this.setState({ modalRedirectionOpen: false, idactifpatrimonialCreated: null });
  };

  handleCancelRediretion = () => {
    const { history } = this.props;
    history.push(`/actifs/${this.state.idactifpatrimonialCreated}`);
    this.setState({ modalRedirectionOpen: false, idactifpatrimonialCreated: null });
  };

  render() {
    const {
      classes,
      match,
      actif,
      actifs,
      banques,
      formDataChanged,
      onFormDataChanged,
      onResetDataChanged,
      typesFinancement,
      readOnly,
      user,
    } = this.props;
    const {
      submiting,
      // justSaved,
      error,
      changingDatas,
      simple,
      filtredDialogOpen,
      messageDialogOpen,
      societes,
      modalRedirectionOpen,
      idactifpatrimonialCreated,
    } = this.state;
    const template = 'actif';

    if (!actif) {
      return (
        <div className={classes.fullHeight}>
          <Grid container alignItems="center" direction="row" justify="center">
            <Grid item>
              <Typography variant="display3">Cet actif n'existe pas.</Typography>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <Paper elevation={3} className={classes.root}>
        <CreationRedirectionModal
          open={modalRedirectionOpen}
          titre="Actif créé"
          onClose={this.handleCloseModalRedireactionDialog}
          onCancel={this.handleCancelRediretion}
          onAccept={this.handleAcceptRediretion}
        >
          <Typography variant="body1">
            L'actif {idactifpatrimonialCreated} a été créé avec succès.<br />
            Souhaitez vous créer un local pour cet actif ?
          </Typography>
        </CreationRedirectionModal>
        <EnvoyerParMailDialog
          open={messageDialogOpen}
          objet={`Actif ID ${actif.idactifpatrimonial} - ${actif.adresse} ${actif.code_postal} ${
            actif.ville
            }`}
          datas={{ ...actif, societes, user }}
          template={template}
          handleClose={this.handleCloseMessageDialog}
        />
        <ListeActifsDialog
          open={filtredDialogOpen}
          handleClose={this.handleCloseFilteredDialog}
          titre="Sélectionnez un actif"
          baseUrl="/actifs/"
          actifs={actifs
            .map(a => ({
              id: a.idactifpatrimonial,
              label: `${a.adresse} ${a.code_postal} ${a.ville} / ${a.idactifpatrimonial}`,
            }))
            .sort((a, b) => a.adresse > b.adresse)}
        />
        <Grid
          container
          alignItems="center"
          direction="row"
          justify="space-between"
          className={classes.titleArea}
        >
          <Grid item>
            <Button color="primary" title="Précédent" onClick={() => { this.props.history.goBack(); }}>
              <ArrowBackIcon className={classes.rightButton} />
            </Button>
          </Grid>
          <Grid item>
            {actif.idactifpatrimonial && (
              <Typography className={classes.title} style={{ textAlign: "left" }} color="primary">
                ID {match.params.actifId}
                <br />
                {actif.adresse} {actif.code_postal} {actif.ville}
              </Typography>
            )}
            {!actif.idactifpatrimonial && (
              <Typography className={classes.title} color="primary">
                Nouvel actif
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Grid container alignItems="center" direction="row" justify="space-around">
              {submiting && (
                <Grid item>
                  <CircularProgress size={25} />
                </Grid>
              )}
              {actif.idactifpatrimonial &&
                !submiting &&
                // !justSaved &&
                !error && (
                  <Grid item>
                    <IconButton color="primary" title="Financer cet actif" onClick={this.handleFinancer}>
                      <EuroIcon className={classes.rightIcon} />
                    </IconButton>
                  </Grid>
                )}

              {actif.idactifpatrimonial &&
                !submiting &&
                // !justSaved &&
                !error && (
                  <Fragment>
                    <Grid item>
                      <IconButton color="primary" title="Locaux de cet actif" onClick={this.navigateToLocaux}>
                        <ListIcon className={classes.rightIcon} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton color="primary" title="Envoyer par mail" onClick={this.handleOpenMessageDialog}>
                        <EmailIcon className={classes.rightIcon} />
                      </IconButton>
                    </Grid>
                  </Fragment>
                )}

              {actif.idactifpatrimonial &&
                !submiting &&
                !readOnly &&
                !error && (
                  <Grid item>
                    <ConfirmWrapper
                      onAccept={this.handleDelete}
                      message="Supprimer cet actif ?"
                      title="Suppression d'un actif"
                    >
                      <IconButton color="primary" title="Supprimer cet actif">
                        <DeleteIcon className={classes.rightIcon} />
                      </IconButton>
                    </ConfirmWrapper>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" direction="row" justify="space-between">
          {!changingDatas && (
            <ActifFormik
              actif={{
                prix_net_vendeur: 0,
                idbanque: 0,
                frais_de_notaire: 0,
                valeur_expertise: 0,
                valeur_venale: 0,
                commissions_intermediaire: 0,
                travaux_bailleur: 0,
                fond_commerce: 0,
                surface_totale_actif: 0,
                capital_reste_du_tmp: 0,
                indemnite_deviction: 0,
                charge_emprunt_tmp: 0,
                nombre_parking_ext: 0,
                nombre_parking_int: 0,
                idsociete: "",
                ...actif,
                date_acquisition: actif.date_acquisition
                  ? moment(actif.date_acquisition).format("YYYY-MM-DD")
                  : null,
              }}
              banques={banques}
              societes={societes}
              typesFinancement={typesFinancement}
              onSubmit={this.handleSubmit}
              setFormDataChanged={onFormDataChanged}
              resetDataChanged={onResetDataChanged}
              changed={formDataChanged}
              send={this.send}
              vueSimplifiee={simple}
            />
          )}
        </Grid>
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Actif)));
