import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import withGlobals from "../withGlobals";
import Button from "@material-ui/core/Button";
import CheckLogo from "./check.png";
import KoLogo from "./ko.png";
import headerStyle from "../../common/tableHeaderStyle";
import ReactTable from "react-table";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as alasql from 'alasql';
import ConfirmWrapper from "../../common/ConfirmWrapper";
import DeleteIcon from "@material-ui/icons/Delete";
var XLSX = require('xlsx')
alasql.setXLSX(XLSX);


const rapprochementTable = [
    "CODDEMTRT",
    "CODGEST",
    "DATEX",
    "CODCAB",
    "NOMCAB",
    "NOMREDCAB",
    "COMTNOMCAB",
    "NUMSIRTCAB",
    "IDTLGN1CAB",
    "IDTLGN2CAB",
    "IDTLGN3CAB",
    "IDTLGN4CAB",
    "ADRLGN1CAB",
    "ADRLGN2CAB",
    "CODPOSCAB",
    "BURDISCAB",
    "CODIMM",
    "ADRREDIMM",
    "LIBIMM",
    "ADRLGN1IMM",
    "ADRLGN2IMM",
    "CODPOSIMM",
    "BURDISIMM",
    "LIBPAYIMM",
    "CODTYPIMM",
    "LIBTYPIMM",
    "CODCLI",
    "NOMPRO",
    "CODQUACIVPRO",
    "LIBQUACIVPRO",
    "COMTNOMPRO",
    "ADRLGN1PRO",
    "ADRLGN2PRO",
    "CODPOSPRO",
    "BURDISPRO",
    "LIBPAYPRO",
    "CODTYPCLI",
    "LIBTYPCLI",
    "CODGRPCLI",
    "LIBGRPCLI",
    "DATDEBPER",
    "DATFINPER",
    "CODELTLOTDEF",
    "LIBELTLOTDEF",
    "CODELTLOTPCO",
    "LIBELTLOTPCO",
    "CODELTLOT1",
    "CODELTLOT2",
    "LIBELTLOT2",
    "DATSITREEL",
    "ANSIT",
    "MOISIT",
    "INDTYPLGN",
    "CODELTLOTCOM",
    "LIBELTLOTCOM",
    "CODELTLOTBUR",
    "LIBELTLOTBUR",
    "CODELTLOTSTO",
    "LIBELTLOTSTO",
    "CODELTLOTSTA",
    "LIBELTLOTSTA",
    "CODGESTRESP",
    "LIBGESTRESP",
    "SURFPCOIMM",
    "CODBAI",
    "CODNATBAI",
    "2 TYPE DE BAIL",
    "DATSIGNBAI",
    "DATDEBOCC",
    "DATFINOCC",
    "1 DATE D'EFFET DE BAIL",
    "DATDEBBAICOUR",
    "DATFINBAICOUR",
    "CODPERFAC",
    "LIBPERFAC",
    "CODNATTER",
    "LIBNATTER",
    "CODNATTERCG",
    "LIBNATTERCG",
    "8 PERIODE D'INDEXATION (en mois)CODPERREVI",
    "LIBPERREVI",
    "CODCATREVI",
    "LIBCATREVI",
    "CODIDCREVI",
    "MODREVILOY",
    "DATE PROCHAINE INDEXATION",
    "9 VALEUR INDEXATION ACTUELLE",
    "MOIIDCPRCHREVI",
    "INDEXOTAX",
    "DURBAIAN",
    "DURBAIJOUR",
    "DURBAIMOI",
    "DATECH1",
    "DATECH2",
    "DATECH3",
    "6 INDEXATION",
    "VALIDCREVIINIT",
    "MOIIDCREVIINIT",
    "VALEUR ACTUEL DE L'INDICE",
    "MOIIDCREVIACTU",
    "DATREVIACTU",
    "DATREVIPER",
    "MOIIDCREVIPER",
    "VALIDCREVIPER",
    "DATREVIFAC",
    "VALIDCREVIFAC",
    "MOIIDCREVIFAC",
    "INDTF",
    "INDTB",
    "INDTVA",
    "INDCAU",
    "NBMOICAU",
    "MONCAU",
    "CODCLILOC",
    "10 NOM LOCATAIRE",
    "COMTNOMLOC",
    "LIBENS",
    "CODAPE",
    "TYPCLILOC",
    "INDCTX",
    "CODCTX",
    "CODTYPCTX",
    "LIBCTX",
    "INDCAB",
    "CHIAFFCA",
    "ANCHIAFFCA",
    "PCTEXOCG",
    "INDRNVAUTO",
    "CGANNRECUP",
    "INDASSURCPB",
    "DATDEPPREV",
    "DATPREAPRO",
    "INDCAP",
    "INDBAIRENOU",
    "CODLOT",
    "CODLOTPRL",
    "INDLOTPRL",
    "CODGRPLOT",
    "CODLOTSYND",
    "CODTYPLGT",
    "LIBTYPLGT",
    "CODDSGLOT",
    "LIBDSGLOT",
    "CODUSGLOT",
    "LIBUSGLOT",
    "CODBAT",
    "CODESC",
    "ETG",
    "NBPIE",
    "CODVAC",
    "LIBVAC",
    "DATVAC",
    "INDPLFCHG",
    "INDLOYVACA",
    "INDPALLOY",
    "CATNATBAI",
    "CODGRPLOTDERLOC",
    "NOMDERLOC",
    "DATSORDERLOC",
    "CODIMMDERLOC",
    "CODDERLOC",
    "NOMNOULOC",
    "DATDEBNOUBAI",
    "DATFINPREVNOUBAI",
    "INDFRANLOY",
    "DATDEBFRANLOY",
    "DURFRANLOY",
    "DATDEBSURLOY",
    "DURSURLOY",
    "MODREVISURLOY",
    "PORT",
    "DATDEBRTH",
    "DATFINRTH",
    "MOIIDCREVILOT",
    "VALIDCREVILOT",
    "MONFRANLOY",
    "SURLOYANN",
    "LOYQUITMOI",
    "LOYQUITCUM",
    "LOYVLM",
    "MONDDG",
    "MONDDGREVI",
    "LOYER INITIAL",
    "3 LOYER ACTUEL",
    "LOYANNFUT",
    "LOYANNSOR",
    "LOYANNINIFR",
    "LOYANNREVIFR",
    "LOYANNFUTFR",
    "LOYANNSORFR",
    "LOYANNINIPR",
    "LOYANNREVIPR",
    "LOYANNFUTPR",
    "LOYANNSORPR",
    "LOYANNINIVA",
    "LOYANNREVIVA",
    "LOYANNFUTVA",
    "LOYANNSORVA",
    "LOYANNINIAV",
    "LOYANNREVIAV",
    "LOYANNFUTAV",
    "LOYANNSORAV",
    "SURFDEF",
    "SURF1",
    "SURF2",
    "SURFPCO",
    "SURFCOM",
    "SURFBUR",
    "SURFSTO",
    "SURFSTA",
    "VALELTLOTTANT",
    "NUMTRLOY",
    "BORINFDECL",
    "BORSUPDECL",
    "PCTAUGLOY",
    "NUMPAL",
    "DATDEBPAL",
    "DATFINPAL",
    "MONDEDLOY",
    "CODIMMAXA",
    "CODVACAXA",
    "CODAFCTLOTAXA",
    "CODNATBAIAXA",
    "CODPERFACAXA",
    "CODPERREVIAXA",
    "LIBELTLOT1",
    "CODELTLOTTANT",
    "LIBELTLOTTANT",
    "CODENSIMMR",
    "LIBENSIMMR",
    "CODORIPROR",
    "LIBORIPROR",
    "CODSERV",
    "LIBSERV",
    "CODSITE",
    "NOMSITE",
    "CODCLAIMM",
    "LIBCLAIMM",
    "CODSCLAIMM",
    "LIBSCLAIMM",
    "CODSERVPRO",
    "LIBSERVPRO",
    "CODGRPCLILOC",
    "LIBGRPCLILOC",
    "DURECH1",
    "DURECH2",
    "DURECH3",
    "INDCAUBANC",
    "MONCAUBANC",
    "INDTAXBURRCPB",
    "LOYANNINIAST",
    "LOYANNAST",
    "SURFAST",
    "DATECH4",
    "DATECH5",
    "DATECH6",
    "DATECH7",
    "DATECH8",
    "DATECH9",
    "DATECH10",
    "DATECH11",
    "DATECH12",
    "DURECH4",
    "DURECH5",
    "DURECH6",
    "DURECH7",
    "DURECH8",
    "DURECH9",
    "DURECH10",
    "DURECH11",
    "DURECH12",
    "SURLOYANNINI",
    "INDEXCPTAUEXO",
    "INDEXCPDATFRAN",
    "INDFRFCHG",
    "INDCHGPRF",
    "INDPLFCHG2",
    "CODTYPCHG1",
    "LIBTYPCHG1",
    "CODCATAUTM1",
    "LIBCATAUTM1",
    "INDTYPCHG1",
    "CODTYPCHG2",
    "LIBTYPCHG2",
    "CODCATAUTM2",
    "LIBCATAUTM2",
    "INDTYPCHG2",
    "CODTYPCHG3",
    "LIBTYPCHG3",
    "CODCATAUTM3",
    "LIBCATAUTM3",
    "INDTYPCHG3",
    "CODTYPCHG4",
    "LIBTYPCHG4",
    "CODCATAUTM4",
    "LIBCATAUTM4",
    "INDTYPCHG4",
    "CODTYPCHG5",
    "LIBTYPCHG5",
    "CODCATAUTM5",
    "LIBCATAUTM5",
    "INDTYPCHG5",
    "CODTYPCHG6",
    "LIBTYPCHG6",
    "CODCATAUTM6",
    "LIBCATAUTM6",
    "INDTYPCHG6",
    "CODTYPCHG7",
    "LIBTYPCHG7",
    "CODCATAUTM7",
    "LIBCATAUTM7",
    "INDTYPCHG7",
    "CODTYPCHG8",
    "LIBTYPCHG8",
    "CODCATAUTM8",
    "LIBCATAUTM8",
    "INDTYPCHG8",
    "CODTYPCHG9",
    "LIBTYPCHG9",
    "CODCATAUTM9",
    "LIBCATAUTM9",
    "INDTYPCHG9",
    "CODTYPCHG10",
    "LIBTYPCHG10",
    "CODCATAUTM10",
    "LIBCATAUTM10",
    "INDTYPCHG10",
    "INDASSR",
    "CODQUAINDI",
    "LIBQUAINDI",
    "NUMSIRNLOC",
    "NUMSIRTLOC",
    "NUMSIRNDERLOC",
    "NUMSIRTDERLOC",
    "INDLOIACTPE",
    "NUMSIRNNOULOC",
    "NUMSIRTNOULOC"
];

const rapprochementTable2 = [
    "CODDEMTRT",
    "CODGEST",
    "DATEX",
    "CODCAB",
    "NOMCAB",
    "NOMREDCAB",
    "COMTNOMCAB",
    "NUMSIRTCAB",
    "IDTLGN1CAB",
    "IDTLGN2CAB",
    "IDTLGN3CAB",
    "IDTLGN4CAB",
    "ADRLGN1CAB",
    "ADRLGN2CAB",
    "CODPOSCAB",
    "BURDISCAB",
    "CODIMM",
    "ADRREDIMM",
    "LIBIMM",
    "ADRLGN1IMM",
    "ADRLGN2IMM",
    "CODPOSIMM",
    "BURDISIMM",
    "LIBPAYIMM",
    "CODTYPIMM",
    "LIBTYPIMM",
    "CODCLI",
    "NOMPRO",
    "CODQUACIVPRO",
    "LIBQUACIVPRO",
    "COMTNOMPRO",
    "ADRLGN1PRO",
    "ADRLGN2PRO",
    "CODPOSPRO",
    "BURDISPRO",
    "LIBPAYPRO",
    "CODTYPCLI",
    "LIBTYPCLI",
    "CODGRPCLI",
    "LIBGRPCLI",
    "DATDEBPER",
    "DATFINPER",
    "CODELTLOTDEF",
    "LIBELTLOTDEF",
    "CODELTLOTPCO",
    "LIBELTLOTPCO",
    "CODELTLOT1",
    "CODELTLOT2",
    "LIBELTLOT2",
    "DATSITREEL",
    "ANSIT",
    "MOISIT",
    "INDTYPLGN",
    "CODELTLOTCOM",
    "LIBELTLOTCOM",
    "CODELTLOTBUR",
    "LIBELTLOTBUR",
    "CODELTLOTSTO",
    "LIBELTLOTSTO",
    "CODELTLOTSTA",
    "LIBELTLOTSTA",
    "CODGESTRESP",
    "LIBGESTRESP",
    "SURFPCOIMM",
    "CODBAI",
    "CODNATBAI",
    "LIBNATBAI",
    "DATSIGNBAI",
    "DATDEBOCC",
    "DATFINOCC",
    "DATDEBPREBAI",
    "DATDEBBAICOUR",
    "DATFINBAICOUR",
    "CODPERFAC",
    "LIBPERFAC",
    "CODNATTER",
    "LIBNATTER",
    "CODNATTERCG",
    "LIBNATTERCG",
    "CODPERREVI",
    "LIBPERREVI",
    "CODCATREVI",
    "LIBCATREVI",
    "CODIDCREVI",
    "MODREVILOY",
    "DATPRCHREVI",
    "VALIDCPRCHREVI",
    "MOIIDCPRCHREVI",
    "INDEXOTAX",
    "DURBAIAN",
    "DURBAIJOUR",
    "DURBAIMOI",
    "DATECH1",
    "DATECH2",
    "DATECH3",
    "CODIDCREVIINIT",
    "VALIDCREVIINIT",
    "MOIIDCREVIINIT",
    "VALIDCREVIACTU",
    "MOIIDCREVIACTU",
    "DATREVIACTU",
    "DATREVIPER",
    "MOIIDCREVIPER",
    "VALIDCREVIPER",
    "DATREVIFAC",
    "VALIDCREVIFAC",
    "MOIIDCREVIFAC",
    "INDTF",
    "INDTB",
    "INDTVA",
    "INDCAU",
    "NBMOICAU",
    "MONCAU",
    "CODCLILOC",
    "NOMLOC",
    "COMTNOMLOC",
    "LIBENS",
    "CODAPE",
    "TYPCLILOC",
    "INDCTX",
    "CODCTX",
    "CODTYPCTX",
    "LIBCTX",
    "INDCAB",
    "CHIAFFCA",
    "ANCHIAFFCA",
    "PCTEXOCG",
    "INDRNVAUTO",
    "CGANNRECUP",
    "INDASSURCPB",
    "DATDEPPREV",
    "DATPREAPRO",
    "INDCAP",
    "INDBAIRENOU",
    "CODLOT",
    "CODLOTPRL",
    "INDLOTPRL",
    "CODGRPLOT",
    "CODLOTSYND",
    "CODTYPLGT",
    "LIBTYPLGT",
    "CODDSGLOT",
    "LIBDSGLOT",
    "CODUSGLOT",
    "LIBUSGLOT",
    "CODBAT",
    "CODESC",
    "ETG",
    "NBPIE",
    "CODVAC",
    "LIBVAC",
    "DATVAC",
    "INDPLFCHG",
    "INDLOYVACA",
    "INDPALLOY",
    "CATNATBAI",
    "CODGRPLOTDERLOC",
    "NOMDERLOC",
    "DATSORDERLOC",
    "CODIMMDERLOC",
    "CODDERLOC",
    "NOMNOULOC",
    "DATDEBNOUBAI",
    "DATFINPREVNOUBAI",
    "INDFRANLOY",
    "DATDEBFRANLOY",
    "DURFRANLOY",
    "DATDEBSURLOY",
    "DURSURLOY",
    "MODREVISURLOY",
    "PORT",
    "DATDEBRTH",
    "DATFINRTH",
    "MOIIDCREVILOT",
    "VALIDCREVILOT",
    "MONFRANLOY",
    "SURLOYANN",
    "LOYQUITMOI",
    "LOYQUITCUM",
    "LOYVLM",
    "MONDDG",
    "MONDDGREVI",
    "LOYANNINI",
    "LOYANNREVI",
    "LOYANNFUT",
    "LOYANNSOR",
    "LOYANNINIFR",
    "LOYANNREVIFR",
    "LOYANNFUTFR",
    "LOYANNSORFR",
    "LOYANNINIPR",
    "LOYANNREVIPR",
    "LOYANNFUTPR",
    "LOYANNSORPR",
    "LOYANNINIVA",
    "LOYANNREVIVA",
    "LOYANNFUTVA",
    "LOYANNSORVA",
    "LOYANNINIAV",
    "LOYANNREVIAV",
    "LOYANNFUTAV",
    "LOYANNSORAV",
    "SURFDEF",
    "SURF1",
    "SURF2",
    "SURFPCO",
    "SURFCOM",
    "SURFBUR",
    "SURFSTO",
    "SURFSTA",
    "VALELTLOTTANT",
    "NUMTRLOY",
    "BORINFDECL",
    "BORSUPDECL",
    "PCTAUGLOY",
    "NUMPAL",
    "DATDEBPAL",
    "DATFINPAL",
    "MONDEDLOY",
    "CODIMMAXA",
    "CODVACAXA",
    "CODAFCTLOTAXA",
    "CODNATBAIAXA",
    "CODPERFACAXA",
    "CODPERREVIAXA",
    "LIBELTLOT1",
    "CODELTLOTTANT",
    "LIBELTLOTTANT",
    "CODENSIMMR",
    "LIBENSIMMR",
    "CODORIPROR",
    "LIBORIPROR",
    "CODSERV",
    "LIBSERV",
    "CODSITE",
    "NOMSITE",
    "CODCLAIMM",
    "LIBCLAIMM",
    "CODSCLAIMM",
    "LIBSCLAIMM",
    "CODSERVPRO",
    "LIBSERVPRO",
    "CODGRPCLILOC",
    "LIBGRPCLILOC",
    "DURECH1",
    "DURECH2",
    "DURECH3",
    "INDCAUBANC",
    "MONCAUBANC",
    "INDTAXBURRCPB",
    "LOYANNINIAST",
    "LOYANNAST",
    "SURFAST",
    "DATECH4",
    "DATECH5",
    "DATECH6",
    "DATECH7",
    "DATECH8",
    "DATECH9",
    "DATECH10",
    "DATECH11",
    "DATECH12",
    "DURECH4",
    "DURECH5",
    "DURECH6",
    "DURECH7",
    "DURECH8",
    "DURECH9",
    "DURECH10",
    "DURECH11",
    "DURECH12",
    "SURLOYANNINI",
    "INDEXCPTAUEXO",
    "INDEXCPDATFRAN",
    "INDFRFCHG",
    "INDCHGPRF",
    "INDPLFCHG2",
    "CODTYPCHG1",
    "LIBTYPCHG1",
    "CODCATAUTM1",
    "LIBCATAUTM1",
    "INDTYPCHG1",
    "CODTYPCHG2",
    "LIBTYPCHG2",
    "CODCATAUTM2",
    "LIBCATAUTM2",
    "INDTYPCHG2",
    "CODTYPCHG3",
    "LIBTYPCHG3",
    "CODCATAUTM3",
    "LIBCATAUTM3",
    "INDTYPCHG3",
    "CODTYPCHG4",
    "LIBTYPCHG4",
    "CODCATAUTM4",
    "LIBCATAUTM4",
    "INDTYPCHG4",
    "CODTYPCHG5",
    "LIBTYPCHG5",
    "CODCATAUTM5",
    "LIBCATAUTM5",
    "INDTYPCHG5",
    "CODTYPCHG6",
    "LIBTYPCHG6",
    "CODCATAUTM6",
    "LIBCATAUTM6",
    "INDTYPCHG6",
    "CODTYPCHG7",
    "LIBTYPCHG7",
    "CODCATAUTM7",
    "LIBCATAUTM7",
    "INDTYPCHG7",
    "CODTYPCHG8",
    "LIBTYPCHG8",
    "CODCATAUTM8",
    "LIBCATAUTM8",
    "INDTYPCHG8",
    "CODTYPCHG9",
    "LIBTYPCHG9",
    "CODCATAUTM9",
    "LIBCATAUTM9",
    "INDTYPCHG9",
    "CODTYPCHG10",
    "LIBTYPCHG10",
    "CODCATAUTM10",
    "LIBCATAUTM10",
    "INDTYPCHG10",
    "INDASSR",
    "CODQUAINDI",
    "LIBQUAINDI",
    "NUMSIRNLOC",
    "NUMSIRTLOC",
    "NUMSIRNDERLOC",
    "NUMSIRTDERLOC",
    "INDLOIACTPE",
    "NUMSIRNNOULOC",
    "NUMSIRTNOULOC"
];

const styles = theme => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    }
  });

  const tableauRapprochement = [
    "DATE D'EFFET DE BAIL",
    "TYPE DE BAIL",
    "NOM LOCATAIRE",
    "LOYER ACTUEL",
    "INDEXATION",
    "PERIODE D'INDEXATION",
    "VALEUR ACTUELLE DE L'INDICE"
];

const tableauRapprochementMadar = [
    "date_effet_du_bail",
    "type_de_bail",
    "nom_du_locataire",
    "loyer_actuel",
    "indice_reference",
    "tunnel_indexation",
    "nouvel_indice"
];

const datas = {
    "DATE D'EFFET DE BAIL": [],
    "TYPE DE BAIL": [],
    "NOM LOCATAIRE": [],
    "LOYER ACTUEL": [],
    "INDEXATION": [],
    "PERIODE D'INDEXATION": [],
    "VALEUR ACTUELLE DE L'INDICE": []
}; 
;

class RapprochementAltaix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        fileName: '',
        currentTableauTitle: tableauRapprochement[0],
        altaixData: datas[tableauRapprochement[0]],
        importAltaixValide: false,
        tableParametrageValide: false,
        tableParametrageValidationErrorMessage: '',
        uploading: false,
        processingRapprochement: false,
        altaixBaux: [],
        baux: [],
        rapprochementDatas: [],
        atLeastOneImportIsDone: false
    };
  }

  componentDidMount = () => {
    this.props.fetch({method: "GET", url: `/baux/?bailactif=true` }).then(baux => {
        this.setState({baux: baux});
    });  
  };

    handleSave = () => {
        alert("TO DO: save");
    }

    handleUpload = () => {
        document.getElementById('input').click();
    }

    onFileChange = (event) => {
        var a = this;
        this.setState({ uploading: true });
        if (event.target.files && event.target.files[0]) {
            const fileName = event.target.files[0].name;
            const importValide = fileName.endsWith(".xls") || fileName.endsWith(".xlsx");
            this.setState({importAltaixValide: importValide});
            if (!importValide) {
                alert('Veuillez télécharger un fichier au format xls ou xlsx svp !');
                this.setState({ uploading: false });
                return;
            }
            this.setState({fileName: fileName});
            const input = document.getElementById('input');
           /* readXlsxFile(input.files[0]).then((rows) => {
                this.extractUniqueAltaixBaux(rows);
                this.validateExcelFileColumns(rows[0]);
                this.startRapprochement();
                this.setState({ uploading: false });
            })*/

            alasql('SELECT * FROM FILE(?,{headers:false})',[event.nativeEvent],function(rows){
                a.extractUniqueAltaixBaux(rows);
                a.validateExcelFileColumns(rows[0]);
                a.startRapprochement();
                a.setState({ uploading: false });
            });
      }
      
      this.setState({ atLeastOneImportIsDone: true });
    }

    extractUniqueAltaixBaux(baux) {
        const uniqueValuesSet = new Set();
        const filteredArr = baux.filter((obj, index) => {
            if (index === 0) {
                return false;
            }
            /*
                70.BS.DATE D'EFFET DE BAIL
                66.BO.TYPE DE BAIL
                114.DK.NOM LOCATAIRE
                183.GB.LOYER ACTUEL
                95.CR.INDEXATION
                98.CU.VALEUR ACTUEL DE L'INDICE
                99.CV.MOIIDCREVIACTU
            */
           if (!obj.BS || !obj.BO || !obj.DK || !obj.GB || !obj.CR || !obj.CU || !obj.CV) {
                return false;
           } else {
                // const key = obj.BS + '-' + obj.BO + '-' + obj.DK + '-' + obj.GB + '-' + obj.CR + '-' + obj.CU + '-' + obj.CV;
                const key = 'BEGIN' + obj.BM + obj.Q + 'END';
                const isPresentInSet = uniqueValuesSet.has(key);
                uniqueValuesSet.add(key);
                return !isPresentInSet;
           }
        }).map(item => {
            return {
                code_bail: item.BM,
                date_effet_bail: this.getJsDateFromExcel(item.BS).toISOString().split('T')[0],
                type_de_bail: item.BO.trim(),
                nom_locataire: item.DK.trim(),
                loyer_actuel: item.GB,
                indexation: item.CR.trim(),
                periode_indexation: this.getPeriodeIndexationFromExcel(item.CV),
                valeur_actuelle_indice: item.CU,
                code_immeuble: item.Q
            } 
        });
        this.setState({altaixBaux: filteredArr});
        return filteredArr;
    }

    formattedDate(d = new Date) {
        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());
      
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
      
        return `${day}/${month}/${year}`;
      }

      getJsDateFromExcel(excelDate) {
        var result;          
        // 2. Convert to milliseconds.
        result = new Date((excelDate - (25567 + 2))*86400*1000);
        if (Object.prototype.toString.call(result) === "[object Date]" && !isNaN(result)) {
            return result;
        } else {
            var dateParts = excelDate.split("/");

            // month is 0-based, that's why we need dataParts[1] - 1
            return new Date(+dateParts[2], dateParts[1] - 1, 1 + parseInt(dateParts[0]));
        }
      }

      getPeriodeIndexationFromExcel(excelDate) {
        var result;
        let year = 0; 
        let month = 0; 
        // 2. Convert to milliseconds.
        result = new Date((excelDate - (25567 + 2))*86400*1000);
        if (Object.prototype.toString.call(result) === "[object Date]" && !isNaN(result)) {
            year = result.getFullYear();
            month = result.getMonth();
        } else {
            const dateParts = excelDate.split("/");
            year = dateParts[2];
            month = dateParts[1] - 1;
        }
        let trimestre = 'T0';
        if (month == 0) {
            trimestre = 'T1';
        } else if (month == 3) {
            trimestre = 'T2';
        } else if (month == 6) {
            trimestre = 'T3';
        } else if (month == 9) {
            trimestre = 'T4';
        }
        return trimestre + ' ' + year;
      }


      getFrenchDate(englishDate) {
          return englishDate.substring(8) + "/" + englishDate.substring(5, 7) + "/" + englishDate.substring(0, 4);
      }
        
      
    startRapprochement() {
        var datas = {
            "DATE D'EFFET DE BAIL": [],
            "TYPE DE BAIL": [],
            "NOM LOCATAIRE": [],
            "LOYER ACTUEL": [],
            "INDEXATION": [],
            "PERIODE D'INDEXATION": [],
            "VALEUR ACTUELLE DE L'INDICE": []
        };
        this.state.altaixBaux.forEach(altaixBail => {
            const madarBail = this.state.baux.find(b => b.code_bail_altaix == altaixBail.code_bail && b.code_immeuble == altaixBail.code_immeuble);
            if (madarBail) {
                const madarDate = (new Date(madarBail.date_effet_du_bail)).toISOString().split('T')[0];
                // DATE D'EFFET DE BAIL
                if (altaixBail.date_effet_bail == madarDate) {
                    /*
                    datas["DATE D'EFFET DE BAIL"].push(
                        {
                            bail : madarBail.idbail,
                            altaix_libelle_nature_bail: new Date(altaixBail.date_effet_bail).toString(),
                            madar_type_bail: new Date(madarBail.date_effet_du_bail).toString(),
                            verif: "VRAI",
                            etat: "Validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                    */
                } else {
                    datas["DATE D'EFFET DE BAIL"].push(
                        {
                            bail : madarBail.idbail + ' - ' + madarBail.nom_du_locataire,
                            idbail : madarBail.idbail,
                            altaix_libelle_nature_bail: this.getFrenchDate(altaixBail.date_effet_bail),
                            madar_type_bail: this.getFrenchDate(madarDate),
                            verif: "FAUX",
                            etat: "Non validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                }
                // TYPE DE BAIL
                if (altaixBail.type_de_bail == madarBail.type_de_bail ||
                    (altaixBail.type_de_bail.includes("(3-6-9)") && madarBail.type_de_bail.includes("3/6/9 ")) ||
                    (altaixBail.type_de_bail.includes("(3-6-9-12)") && madarBail.type_de_bail.includes("3/6/9/12 ")) ||
                    (altaixBail.type_de_bail.includes("(3-6-9)") && madarBail.type_de_bail.includes("3/6/9/ ")) ||
                    (altaixBail.type_de_bail.includes("(3-6-9-12)") && madarBail.type_de_bail.includes("3/6/9/12/ "))) {
                    /*
                    datas["TYPE DE BAIL"].push(
                        {
                            bail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.type_de_bail,
                            madar_type_bail: madarBail.type_de_bail,
                            verif: "VRAI",
                            etat: "Validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                    */
                } else {
                    datas["TYPE DE BAIL"].push(
                        {
                            bail : madarBail.idbail + ' - ' + madarBail.nom_du_locataire,
                            idbail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.type_de_bail,
                            madar_type_bail: madarBail.type_de_bail,
                            verif: "FAUX",
                            etat: "Non validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                }
                // NOM LOCATAIRE
                if (altaixBail.nom_locataire.toLowerCase() == madarBail.nom_du_locataire.toLowerCase()
                    || altaixBail.nom_locataire.toLowerCase() == madarBail.nom_du_locataire.toLowerCase().replaceAll('`', '\'')) {
                    /*
                    datas["NOM LOCATAIRE"].push(
                        {
                            bail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.nom_locataire,
                            madar_type_bail: madarBail.nom_du_locataire,
                            verif: "VRAI",
                            etat: "Validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                    */
                } else {
                    datas["NOM LOCATAIRE"].push(
                        {
                            bail : madarBail.idbail + ' - ' + madarBail.nom_du_locataire,
                            idbail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.nom_locataire,
                            madar_type_bail: madarBail.nom_du_locataire,
                            verif: "FAUX",
                            etat: "Non validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                }
                // LOYER ACTUEL
                if (altaixBail.loyer_actuel == madarBail.loyer_actuel) {
                    /*
                    datas["LOYER ACTUEL"].push(
                        {
                            bail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.loyer_actuel,
                            madar_type_bail: madarBail.loyer_actuel,
                            verif: "VRAI",
                            etat: "Validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                    */
                } else {
                    datas["LOYER ACTUEL"].push(
                        {
                            bail : madarBail.idbail + ' - ' + madarBail.nom_du_locataire,
                            idbail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.loyer_actuel.toFixed(2),
                            madar_type_bail: madarBail.loyer_actuel.toFixed(2),
                            verif: "FAUX",
                            etat: "Non validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                }
                // INDEXATION
                if (altaixBail.indexation == madarBail.indice_reference) {
                    /*
                    datas["INDEXATION"].push(
                        {
                            bail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.indexation,
                            madar_type_bail: madarBail.indice_reference,
                            verif: "VRAI",
                            etat: "Validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                    */
                } else {
                    datas["INDEXATION"].push(
                        {
                            bail : madarBail.idbail + ' - ' + madarBail.nom_du_locataire,
                            idbail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.indexation,
                            madar_type_bail: madarBail.indice_reference,
                            verif: "FAUX",
                            etat: "Non validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                }
                // PERIODE D'INDEXATION
                if (altaixBail.periode_indexation == ('T' + madarBail.trimestre + ' ' + madarBail.annee)) {
                    /*
                    datas["PERIODE D'INDEXATION"].push(
                        {
                            bail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.periode_indexation,
                            madar_type_bail: madarBail.tunnel_indexation,
                            verif: "VRAI",
                            etat: "Validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                    */
                } else {
                    datas["PERIODE D'INDEXATION"].push(
                        {
                            bail : madarBail.idbail + ' - ' + madarBail.nom_du_locataire,
                            idbail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.periode_indexation,
                            madar_type_bail: 'T' + madarBail.trimestre + ' ' + madarBail.annee,
                            verif: "FAUX",
                            etat: "Non validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                }
                // VALEUR ACTUELLE DE L'INDICE
                if (altaixBail.valeur_actuelle_indice == madarBail.nouvel_indice) {
                    /*
                    datas["LOYER ACTUEL"].push(
                        {
                            bail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.loyer_actuel,
                            madar_type_bail: madarBail.loyer_actuel,
                            verif: "VRAI",
                            etat: "Validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                    */
                } else {
                    datas["VALEUR ACTUELLE DE L'INDICE"].push(
                        {
                            bail : madarBail.idbail + ' - ' + madarBail.nom_du_locataire,
                            idbail : madarBail.idbail,
                            altaix_libelle_nature_bail: altaixBail.valeur_actuelle_indice,
                            madar_type_bail: madarBail.nouvel_indice,
                            verif: "FAUX",
                            etat: "Non validé",
                            commentaire: madarBail.commentaire
                        }
                    );
                }
            }
        });
        this.setState({rapprochementDatas: datas});
        this.setState({currentTableauTitle: "NOM LOCATAIRE"});
        this.setState({altaixData: datas["NOM LOCATAIRE"]});
    }

    validateExcelFileColumns(columns) {
        const keys = Object.keys(columns);
        var i = keys.length;
        if (i != rapprochementTable.length) {
            this.setState({tableParametrageValidationErrorMessage: 'Nombre de colonnes eronné'});
            return false;
        }
        while (i--) {
            if (columns[keys[i]].trim() !== rapprochementTable[i] && columns[keys[i]].trim() !== rapprochementTable2[i]) {
                this.setState({tableParametrageValide: false});
                alert('Erreur sur la correspondance des identifiants ALTAIX / MADAR. ('+ this.state.tableParametrageValidationErrorMessage +') Veuillez contacter le support !');
                const errorMessage = columns[keys[i]] + ' doit etre ' + rapprochementTable[i] + " ou " + rapprochementTable2[i];
                this.setState({tableParametrageValidationErrorMessage: errorMessage});
                return false;
            }
        }
        this.setState({tableParametrageValide: true});
        return true;
    }

    handleChangeCommentaire = (e, index) => {
        this.state.rapprochementDatas[this.state.currentTableauTitle][index]['commentaire'] = e.target.value;
    };

    handleValidate = (index) => {
        try {
            const newRapprochementDatas = this.state.rapprochementDatas;
            const idbail = newRapprochementDatas[this.state.currentTableauTitle][index].idbail;
            const altaixValue = newRapprochementDatas[this.state.currentTableauTitle][index].altaix_libelle_nature_bail;
            const madarValue = newRapprochementDatas[this.state.currentTableauTitle][index].madar_type_bail;
            const fieldToUpdate = tableauRapprochementMadar[tableauRapprochement.indexOf(this.state.currentTableauTitle)];
            if (fieldToUpdate === 'indice_reference' && !(['ICC', 'ILAT', 'ILC'].includes(altaixValue))) {
                this.props.openNotification(
                    "error",
                    `Mise à jour impossible`,
                    null,
                    5000,
                );
                return;
            }
            this.props
            .fetch({
                url: "/banques/",
                method: "POST",
                body: {
                    validateRapprochement: "oui",
                    idbail: idbail,
                    altaixValue: altaixValue,
                    fieldToUpdate: fieldToUpdate
                }
            })
            .then(() => {
                var newRapprochementDatas = this.state.rapprochementDatas;
                newRapprochementDatas[this.state.currentTableauTitle][index].etat = "Validé";
                newRapprochementDatas[this.state.currentTableauTitle].push(newRapprochementDatas[this.state.currentTableauTitle].splice(index, 1)[0]);
                this.setState({rapprochementDatas: newRapprochementDatas});
                this.props.openNotification(
                    "success",
                    `ID bail ${idbail} : la donnée ${madarValue} a été mise à jour avec la valeur ${altaixValue}.\nSouhaitez-vous ouvrir le bail modifié?`,
                    `/baux/${idbail}`,
                    3500,
                );
            })
            .catch(() =>  {
                var newRapprochementDatas = this.state.rapprochementDatas;
                newRapprochementDatas[this.state.currentTableauTitle][index].etat = "Validé";
                newRapprochementDatas[this.state.currentTableauTitle].push(newRapprochementDatas[this.state.currentTableauTitle].splice(index, 1)[0]);
                this.setState({rapprochementDatas: newRapprochementDatas});
                this.props.openNotification(
                    "success",
                    `ID bail ${idbail} : la donnée ${madarValue} a été mise à jour avec la valeur ${altaixValue}.\nSouhaitez-vous ouvrir le bail modifié?`,
                    `/baux/${idbail}`,
                    3500,
                );
            });
        } catch {
            console.log("Erreur de validation2");
        }
    };


    handlePrevious = () => {
        const currentIndex = tableauRapprochement.indexOf(this.state.currentTableauTitle);
        const computedIndex = currentIndex > 0 ? currentIndex - 1 : tableauRapprochement.length - 1;
        this.state.currentTableauTitle = tableauRapprochement[computedIndex];
        this.setState({currentTableauTitle: tableauRapprochement[computedIndex]});
        this.setState({altaixData: this.state.rapprochementDatas[tableauRapprochement[computedIndex]]});
    }
    handleNext = () => {
        const currentIndex = tableauRapprochement.indexOf(this.state.currentTableauTitle);
        const computedIndex = currentIndex < tableauRapprochement.length - 1 ? currentIndex + 1 : 0;
        this.setState({currentTableauTitle: tableauRapprochement[computedIndex]});
        this.setState({altaixData: this.state.rapprochementDatas[tableauRapprochement[computedIndex]]});
    }
    
  render() {
    const { uploading } = this.state;
    return [
        <Grid>
            {!uploading && (
            <Grid>
            <Grid container alignItems="flex-start" justify="flex-start" direction="column" style={{marginTop: "2rem"}}>
                <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={6}>
                        <h4 style={{textAlign: 'left'}}>Rapprochement ALTAIX - Madar Patrimoine</h4>
                    </Grid>
                </Grid>
            </Grid>
            <br/>
            { !(this.state.importAltaixValide && this.state.tableParametrageValide) && (
            <Grid container alignItems="flex-start" justify="flex-start" direction="column">
                <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                    <Grid item xs={1}>
                    </Grid>
                    <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'left' }}>
                            Fichier ALTAIX à charger
                        </Grid>
                        <Grid item xs={2} style={{textAlign: 'right'}}>
                        <input type="file" id="input" onChange={this.onFileChange}  hidden/>
                        <Button
                            variant="contained"
                            onClick={this.handleUpload}
                        >
                            ...
                        </Button>
                        </Grid>
                        <Grid item xs={2}>
                            {this.state.fileName}
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={this.handleSave}
                            >Upload</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>)}
            { this.state.importAltaixValide && this.state.tableParametrageValide && (
            <Grid container spacing={0} style={{marginTop: "3rem"}}>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={this.handlePrevious} title="Précédent">
                        <NavigateBeforeIcon color="primary" />
                    </IconButton>
                </Grid>
                <Grid item xs={3} style={{color: "rgb(166, 0, 0)", fontSize: "18px", fontWeight: "bold", paddingTop: "15px"}}>
                    {this.state.currentTableauTitle}
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={this.handleNext} title="Suivant">
                        <NavigateNextIcon color="primary" />
                    </IconButton>
                </Grid>
            </Grid>
            )}
            <Grid>
                { this.state.importAltaixValide && this.state.tableParametrageValide && (
            <ReactTable
                style={{marginTop: "2rem"}}
                data={this.state.altaixData}
                getTrProps={(state, rowInfo, column, instance) => ({
                    style: {
                        height: '40px',
                    },
                })}
                columns={[
                    {
                        Header: "Id bail Synthèse - Nom locataire",
                        accessor: "bail",
                        Cell: row => <span style={{ lineHeight: "30px", textAlign: "left", display: "block" }}>{row.value}</span>,
                        headerStyle: { ...headerStyle, textAlign: "left", minHeight: "30px", lineHeight: "25px" },
                        width: 305,
                    },
                    {
                        Header: "Synthèse - " + this.state.currentTableauTitle,
                        accessor: "madar_type_bail",
                        Cell: row => <span style={{ lineHeight: "30px", textAlign: "left", display: "block" }}>{row.value}</span>,
                        headerStyle: { ...headerStyle, textAlign: "left", minHeight: "30px", lineHeight: "25px" },
                        width: 222,
                    },
                    {
                        Header: "Altaix - " + this.state.currentTableauTitle,
                        accessor: "altaix_libelle_nature_bail",
                        Cell: row => <span style={{ lineHeight: "30px", textAlign: "left", display: "block" }}>{row.value}</span>,
                        headerStyle: { ...headerStyle, textAlign: "left", minHeight: "30px", lineHeight: "25px" },
                        width: 180,
                    },
                    {
                        Header: "VERIF",
                        accessor: "verif",
                        filterable: false,
                        width: 60,
                        Cell: row => <span style={{ backgroundColor: row.value == 'FAUX' ? "red" : "green", lineHeight: "30px",position:"relative" }}>{row.value}</span>,
                        headerStyle: { ...headerStyle, textAlign: "center", minHeight: "30px", lineHeight: "25px" },
                    },
                    {
                        Header: "ETAT",
                        accessor: "etat",
                        filterable: false,
                        width: 95,
                        Cell: row => <span style={{ lineHeight: "30px",position:"relative" }}>{row.value}</span>,
                        headerStyle: { ...headerStyle, textAlign: "center", minHeight: "30px", lineHeight: "25px" },
                    },
                    {
                        Header: "COMMENTAIRE",
                        accessor: "commentaire",
                        headerStyle: { ...headerStyle, textAlign: "left", minHeight: "30px", lineHeight: "25px" },
                        Cell: row => { return ( <span>
                            <TextField
                                type="text"
                                key={'commentaire' + row.index} 
                                defaultValue={row.original.commentaire}
                                style={{ lineHeight: "30px" , border: "5px solid transparent", textAlign: "left", marginTop: "-13px", display: "block"}}
                                name={'commentaire' + row.index}
                                onChange={e => this.handleChangeCommentaire(e, row.index)}
                            />
                        </span>
                        );
                        }
                    },
                    {
                        Header: "ACTION",
                        headerStyle: { ...headerStyle, textAlign: "center", minHeight: "30px", lineHeight: "25px" },
                        filterable: false,
                        width: 130,
                        Cell: row => { return ( <span style={{display: "flex"}}>


                            <ConfirmWrapper
                                onAccept={() => this.handleValidate(row.index)}
                                message="Voulez-vous importer la donnée ALTAIX dans la synthèse ?"
                                titre="Validation des données ALTAIX"
                                key={'confirm' + row.index} 
                                style={{position: "absolute"}}
                                cancel="Non"
                                ok="Oui"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{marginTop: "-5px"}}
                                    key={'confirm-button' + row.index} 
                                >Valider</Button>
                            </ConfirmWrapper>
                            <IconButton title="Consulter" style={{marginTop: "-13px"}}>
                            <a href={'/baux/'+(row.original.bail.split(' '))[0]} target='_blank'><LaunchIcon color="primary" /></a>
                            </IconButton>
                        </span>
                        );
                        }
                    }
                
                ]}
                noDataText="Aucune donnée"
                showPagination={true}
                filterable={true}
                defaultPageSize={this.state.altaixData.length > 9 ? 10 : this.state.altaixData.length}
                showPageSizeOptions={false}
                className="-striped -highlight noScroll"
                key={this.state.altaixData.length > 9 ? 10 : this.state.altaixData.length}
            />
        )}
            </Grid>
                { !(this.state.importAltaixValide && this.state.tableParametrageValide) && this.state.atLeastOneImportIsDone && (
            <Grid container  alignItems="flex-start" justify="flex-start" direction="column" style={{marginTop: "3rem"}}>
                <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <b>Contrôle d'import</b>
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        Validité import ALTAIX
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "right"}}>
                    {this.state.importAltaixValide ? 'OK' : 'KO' } <span style={{marginLeft: "10px"}}><img src={this.state.importAltaixValide ? CheckLogo : KoLogo} width="20px"/></span>
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        Validité table de paramètrage
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "right"}}>
                    {this.state.tableParametrageValide ? 'OK' : 'KO' } <span style={{marginLeft: "10px"}}><img src={this.state.tableParametrageValide ? CheckLogo : KoLogo} width="20px"/></span>
                    </Grid>
                </Grid>
            </Grid>
            )}
            </Grid>
            )}
            {uploading && (
                <Grid item>
                <Grid container alignItems="center" justify="center" direction="row">
                  <Grid item >
                    Upload du fichier en cours...
                  </Grid>
                  <Grid item>
                    <CircularProgress  size={50} />
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
    ];
  }
}

export default withStyles(styles, { withTheme: true })(withGlobals(RapprochementAltaix));
