import React from "react";
import ReactTable from "react-table";
import NumberFormat from "react-number-format";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import headerStyle from "../../common/tableHeaderStyle";

export default class TranchesTable extends React.Component {
  handleDelete = (e, index) => {
    e.stopPropagation();
    if (
      //eslint-disable-next-line
      confirm(
        `Supprimer cette tranche ?${
          this.props.nombreTranches === 1 ? " Cela entrainera la suppression de l'emprunt" : ""
        }`,
      )
    ) {
      this.props.onDeleteClick(index);
    }
  };

  handleEdit = (e, index) => {
    e.stopPropagation();
    this.props.onEditClick(index);
  };

  render() {
    const { tranches, onClick, readOnly, banques } = this.props;
    var compteur = 0;
var banquess = banques;
    return (
      <ReactTable
        data={tranches.map((t, index) => {
          let couverture = t.montant_cap > 0 || t.montant_swap > 0 ? "oui" : "non";
          let statut = "ACTIF";
          if (t.modifie != undefined && t.modifie == 'MODIFIE') {
            statut = "MODIFIE"; 
          } else if (t.modifie != undefined && t.modifie == 'MAJ FI') {
           statut = "MAJ FI"; 
          }
          return { ...t, id: index + 1, couverture, statut };
        })}
        columns={[
          {
            Header: "Banque",
            accessor: "idbanque",
            width: 130,
            Cell: row => <span style={{ lineHeight: "40px" }}>{banquess[(row.value-1)].nom_banque}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
          {
            Header: "Montant emprunté",
            accessor: "montant_financement",
            width: 130,
            Cell: row => (
              <span style={{ lineHeight: "40px" }}>
                {<NumberFormat displayType="text" value={row.value} thousandSeparator=" " suffix=" €" />}
              </span>
            ),
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
          {
            Header: "Durée",
            width: 70,
            accessor: "duree_financement",
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value} mois</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
          {
            Header: "Type taux",
            accessor: "type_emprunt",
            Cell: row => (
              <span style={{ lineHeight: "40px" }}>
                {row.value ? row.value.replace("taux_", " ").toUpperCase() : "???"}
              </span>
            ),
            headerStyle: { ...headerStyle, textAlign: "right" },
            width: 70,
          },
          {
            id: "taux",
            Header: "Taux",
            accessor: d => (d.type_emprunt === "taux_fixe" ? d.taux : d.marge_banque),
            Cell: ({ value, original }) => {
              return (
                <span style={{ lineHeight: "40px" }}>
                  {original.type_emprunt === "taux_fixe" ? value : "E3M " + value} %
                </span>
              );
            },
            headerStyle: { ...headerStyle, textAlign: "right" },
            width: 50,
          },
          {
            Header: "Durée résiduelle",
            accessor: "nombre_echeances_restante",
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value} {( tranches[compteur] != undefined && tranches[compteur].frequence == "mensuelle")? "mois" : ""} {( tranches[compteur] != undefined && tranches[compteur].frequence == "trimestrielle")? "trimestre(s)" : ""}  {(  tranches[compteur] != undefined && tranches[compteur].frequence == "semestrielle")? "semestre(s)" : ""}  {(  tranches[compteur] != undefined && tranches[compteur].frequence == "annuelle")? "année(s)" : ""} {(compteur++ == -1)? "":""} </span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
            width: 115,
          },
          {
            Header: "CRD",
            accessor: "CRD",
            Cell: row => (
              <span style={{ lineHeight: "40px" }}>
                {
                  <NumberFormat
                    displayType="text"
                    value={parseInt(row.value, 10)}
                    thousandSeparator=" "
                    suffix=" €"
                  />
                }
              </span>
            ),
            headerStyle: { ...headerStyle,textAlign: "right" },
            width: 85,
          },
          {
            Header: "Couverture",
            accessor: "couverture",
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
            width: 80,
          },
          {
		Header: "Statut",
		accessor: "statut",
		Cell: row => <span style={{  backgroundColor: row.value == 'MODIFIE' ? "red" : "green",  fontWeight: "bold", textColor: "white", lineHeight: "40px" }}> {row.value} </span>,
		headerStyle: {  ...headerStyle, fontWeight: "bold", textAlign: "right" },
		width: 80,
	},
          {
            Header: "Actions",
            accessor: "couverture",
            Cell: row => {
              if (readOnly) return null;
              return (
                <span>
                  <IconButton aria-label="Delete" onClick={e => this.handleDelete(e, row.index)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton aria-label="Delete" onClick={e => this.handleEdit(e, row.index)}>
                    <EditIcon />
                  </IconButton>
                </span>
              );
            },
            headerStyle: {
              ...headerStyle,
              textAlign: "right",
              paddingRight: "20px",
            },
          },
        ]}
        noDataText="Aucune donnée"
        showPagination={false}
        pageSize={tranches.length}
        className="-striped -highlight noScroll"
        getTdProps={(state, rowInfo, column, instance) => ({
          onClick: (e, handleOriginal) => {
            console.log(e.target.name, state, rowInfo, column);
            onClick(rowInfo.index);
            if (handleOriginal) {
              handleOriginal();
            }
          },
        })}
      />
    );
  }
}
