import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash/debounce";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ListeSocietes from "./ListeSocietes";
import ListeBanques from "./ListeBanques";
import withGlobals from "../pages/withGlobals";

const styles = theme => ({
  form: {
    padding: theme.spacing.unit * 2,
  },
  btn: {
    marginTop: theme.spacing.unit * 2,
  },
  ville: {
    textTransform: "uppercase",
  },
});

class RechercheForm extends React.Component {
  static propTypes = {
    idField: PropTypes.string.isRequired,
    moduleRoute: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      [props.idField]: null,
      code_postal: null,
      ville: null,
      adresse: null,
      surface: null,
      nom_portefeuille: null,
      idbanque: null,
      idsociete: null,
      societes: [],
      banques: [],
      banqueSelected: null,
      societeSelected: null
    };
    this.delayedCallback = debounce(
      e =>
        this.setState({
          [e.target.name]: e.target.name === "ville" ? e.target.value.toUpperCase() : e.target.value,
        }),
      250,
    );
  }

  handleChange = e => {
    e.persist();
    this.delayedCallback(e);
    if (e.target.name === 'societe') {
      this.state['idsociete'] = e.target.value;
    } else if (e.target.name === 'banque') {
      this.state['idbanque'] = e.target.value;
    } else {
      this.state[e.target.name] = e.target.value;
    }
    const { idField } = this.props;
    const s = [idField, "idactifpatrimonial", "nom_du_locataire", "code_postal", "ville", "adresse", "surface_totale_actif", "nom_portefeuille", "idbanque", "idsociete"].reduce(
      (m, k) => (this.state[k] && this.state[k] !== "" ? `${m}upper(${k})=${this.state[k]}&` : m),
      "?",
    );

    this.props.onSearch(s);
  };

componentDidMount() {
      this.props.fetch({ url: "/societes/" }).then(societes => {
        var array = [];
        array.push(societes[0]);
        array.push(societes[1]);
        this.setState({societes : array});
      });
      this.props.fetch({method: "GET", url: `/banques/` }).then(banques => {
var banquess = [];
banques.forEach(function(entry) {
 if (entry.idbanque === 38) {
banquess.push(entry);
}
});
banques.forEach(function(entry) {
 if (entry.nom_banque !== 38) {
banquess.push(entry);
}
});

var arrayOfBanque = banquess;
arrayOfBanque.sort(function(a, b){
    if(a.idbanque === 38) { return -1; }
    if(a.nom_banque < b.nom_banque) { return -1; }
    if(a.nom_banque > b.nom_banque) { return 1; }
    return 0;
})

        this.setState({banques :arrayOfBanque});
      });
  };  
 
onSocieteChange = e => {
    this.setState({ idsociete: parseInt(e.target.value, 10) });
    this.handleChange(e);
  };

  onBanqueChange = e => {
    this.setState({ idbanque: parseInt(e.target.value, 10) });
    this.handleChange(e);
  };
  

  render() {
    const { idField, idFieldName, classes, societes, banques } = this.props;
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <form className={this.props.classes.form}>

  <Grid item>
	<TextField
	  id="adresse"
	  name="adresse"
	  label="Adresse"
	  type="text"
	  margin="dense"
	  fullWidth
	  onChange={this.handleChange}
	/>
  </Grid>
<Grid item>
	<TextField
	  id="code_postal"
	  name="code_postal"
	  label="Code postal"
	  type="text"
	  margin="dense"
	  fullWidth
	  onChange={this.handleChange}
	/>
  </Grid>
  <Grid item>
	<TextField
	  id="ville"
	  name="ville"
	  label="Ville"
	  type="text"
	  margin="dense"
	  fullWidth
	  inputProps={{ className: this.props.classes.ville }}
	  onChange={this.handleChange}
	/>
  </Grid>

<Grid item>
	<TextField
	  id={idField}
	  name={idField}
	  label={idFieldName}
	  type="text"
	  margin="dense"
	  fullWidth
	  onChange={this.handleChange}
	/>
  </Grid>
  
{ idField != 'idactifpatrimonial' && (<Grid item>
	<TextField
	  id="idactifpatrimonial"
	  name="idactifpatrimonial"
	  label="Id de l'actif"
	  type="text"
	  margin="dense"
	  fullWidth
	  onChange={this.handleChange}
	/>
  </Grid> ) }
 
{ idField == 'idbail' && (<Grid item>
	<TextField
	  id="nom_du_localataire"
	  name="nom_du_locataire"
	  label="Nom du locataire"
	  type="text"
	  margin="dense"
	  fullWidth
	  onChange={this.handleChange}
	/>
  </Grid> ) }
 
  <Grid item>
<ListeBanques
  onChange={this.onBanqueChange}
  banques={this.state.banques}
  banqueSelected={this.state.banqueSelected}
  className={classes.listeSocietes}
/>
</Grid>
<Grid item>
<ListeSocietes
  onChange={this.onSocieteChange}
  societes={this.state.societes}
  societeSelected={this.state.societeSelected}
  className={classes.listeSocietes}
/>
</Grid>
{ idField == 'idactifpatrimonial' && (<Grid item>
	<TextField
	  id="nom_portefeuille"
	  name="nom_portefeuille"
	  label="Nom du financement"
	  type="text"
	  margin="dense"
	  fullWidth
	  onChange={this.handleChange}
	/>
  </Grid> ) }
        </form>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(RechercheForm)));
