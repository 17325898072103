import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const defaults = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

class DialogAmortissementProgressif extends Component {
  constructor(props) {
    super(props);
    const { coefprogressivites, montantFinancement, valeurResiduelle } = this.props;
    const prct = (montantFinancement  - valeurResiduelle) * 100 / montantFinancement;
    if (coefprogressivites) {
      this.state = {
        amortissements: coefprogressivites.map(c => c * 1000),
        prct,
      };
    } else {
      this.state = { amortissements: this.calculeDefaults(props), prct };
    }
  }

  componentWillReceiveProps = nextProps => {
    const state = {};
    if (this.props.nombreAnnees !== nextProps.nombreAnnees) {
      state.amortissements = this.calculeDefaults(nextProps);
    }
    if (
      this.props.montantFinancement !== nextProps.montantFinancement ||
      this.props.valeurResiduelle ||
      nextProps.valeurResiduelle
    ) {
      state.prct =
        (nextProps.montantFinancement - nextProps.valeurResiduelle ) * 100 / nextProps.montantFinancement;
    }
    this.setState(state);
  };

  calculeDefaults = props => {
    const { nombreAnnees } = props;
    const amortissements = [];
    for (let cpt = 0; cpt < nombreAnnees; cpt++) {
      amortissements[cpt] = defaults[cpt];
    }
    return amortissements;
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSave(this.state.amortissements.map(a => a / 1000));
  };

  handleChange = e => {
    this.setState({
      amortissements: this.state.amortissements.map(
        (a, idx) =>
          idx === parseInt(e.target.name, 10)
            ? e.target.value !== "" && !Number.isNaN(e.target.value) ? parseFloat(e.target.value) : 0
            : a,
      ),
    });
  };

  render() {
    const { open, handleClose } = this.props;
    const { amortissements, prct } = this.state;
    const somme = (amortissements.reduce((m, p) => m + parseFloat(p), 0)).toFixed(3);
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">Amortissement progressif - {prct} %</DialogTitle>
          <DialogContent>
            <Grid container alignItems="center" justify="center" direction="row" spacing={8}>
              <Grid item xs={6}>
                {amortissements.map((v, idx) => (
                  <TextField
                    required
                    autoFocus={idx === 0}
                    margin="dense"
                    id={`${idx}`}
                    defaultValue={`${amortissements[idx]}`}
                    name={`${idx}`}
                    onChange={this.handleChange}
                    label={`Amortissement annee ${idx + 1} (%)`}
                    type="text"
                    fullWidth
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <h1 style={{ textAlign: "center" }}>{somme} %</h1>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="textSecondary">
              Annuler
            </Button>
            <Button type="submit" variant="raised" color="primary" disabled={somme != prct}>
              Valider cette progressivité
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default DialogAmortissementProgressif;
