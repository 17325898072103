export const dureesBaux = {
  duree_bail_1: "",
  duree_bail_2: "",
  duree_bail_3: "",
  duree_bail_4: "",
  duree_bail_5: "",
  duree_bail_6: "",
  duree_bail_7: "",
};

export const serialize = values => {
  // du champs textField renommé durée au dernier moment
  let typeDeBail = "";
  typeDeBail = Object.keys(dureesBaux)
    .filter(k => k !== "duree_bail_7")
    .reduce((m, tB) => (values[tB] !== "" ? `${m}/${values[tB]}` : m), "");

  if (typeDeBail !== "") {
    // début forcément par un "/" qu'il faut supprimer
    typeDeBail = typeDeBail.substr(1) + " ans";
  }

  if (values["duree_bail_7"] !== "") typeDeBail += ` ${values["duree_bail_7"]} mois`;

  return typeDeBail;
};

export const deSerialize = type_de_bail => {
  // 3/6/9 ans 4 mois
  const pattern = type_de_bail.indexOf(" ans ") === -1 ? " ans" : " ans ";
  const dureeBailSplit = type_de_bail.split(pattern);
  dureeBailSplit[0].split("/").forEach((tB, index) => {
    dureesBaux[`duree_bail_${index + 1}`] = tB;
  });

  if ((pattern === "ans " || pattern === " ans ") && dureeBailSplit[1].indexOf("mois") !== -1) {
    dureesBaux.duree_bail_7 = dureeBailSplit[1].split(" mois")[0];
  }

  return dureesBaux;
};
