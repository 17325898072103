import React from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListIcon from "@material-ui/icons/List";
import MenuItem from "@material-ui/core/MenuItem";
import NativeSelect from "@material-ui/core/NativeSelect";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import { withFormik } from "formik";
import DialogAmortissementProgressif from "./DialogAmortissementProgressif";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DatePicker from "material-ui-pickers/DatePicker";
import { withStyles } from "@material-ui/core/styles";
import validationSchema from "./validation";
import NumberFormatCustom from "../../common/NumberFormatCustom";
import AnneeFormatCustom from "../../common/AnneeFormatCustom";
import PrctFormatCustom from "../../common/PrctFormatCustom";


const styles = theme => {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing.unit,
    },
    cFormControl: {
      // marginRight: theme.spacing.unit * 2,
    },
    select: {
      // width: "200px",
    },
    progressifButton: {
      marginTop: theme.spacing.unit * 2,
    },
    textField: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
    },
    dateField: {
      paddingTop: theme.spacing.unit * 2,
      width: 200,
    },
    label: {
      paddingLeft: theme.spacing.unit,
    },
    textFieldDuree: {
      paddingTop: 10,
    },
  };
};

const diviseurFrequence = {
  mensuelle: 1,
  trimestrielle: 3,
  semestrielle: 6,
  annuelle: 12,
};

class TranchForm extends React.Component {
  state = {
    dlgAmortOpen: false,
    avecCouverture: false,
  };

  changeValue = (field, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, value, false);
  };

  handleOpenDialogAmortissements = () => {
    this.setState({ dlgAmortOpen: true });
  };

  handleCloseDialogAmortissements = () => {
    this.setState({ dlgAmortOpen: false });
  };

  handleSetAmortissementsProgressifs = value => {
    const { setFieldValue } = this.props;
    setFieldValue("coefprogressivites", value, false);
    this.handleCloseDialogAmortissements();
  };

  handleDateChange = (field, date) =>
    this.props.setFieldValue(field, date ? date.format("YYYY-MM-DD") : null);

  toggleCouverture = () => {
    const avecCouverture = !this.state.avecCouverture;
    this.props.setFieldValue("couverture_taux", this.state.avecCouverture ? "swap" : "produit");
    this.setState({ avecCouverture });
  };

  render() {
    const {
      handleSubmit,
      touched,
      values,
      errors,
      isSubmitting,
      classes,
      editMode,
      handleBlur,
      onCancel,
      identifiant_transaction_finance_active,
      id_tranche_madar,
    } = this.props;

const banques = this.props.banques.filter(b => b.is_deleted == false);

    console.log("errors", errors);

    const { montant_financement, valeur_residuelle_emprunt, duree_financement } = values;
    const prctValeurResiduelle =
      valeur_residuelle_emprunt && montant_financement
        ? ` (${parseFloat(valeur_residuelle_emprunt * 100 / montant_financement).toFixed(2)} %)`
        : "";

    const nombreAnnees = parseInt(duree_financement / 12, 10) + (duree_financement % 12 !== 0 ? 1 : 0);
    const remplisseurAnnees = [];
    for (let cpt = 0; cpt < nombreAnnees; cpt++) {
      remplisseurAnnees.push(cpt + 1);
    }

    const nombre_echeances = parseInt(
      parseInt(values.duree_financement, 10) / diviseurFrequence[values.frequence],
      10,
    );

    return [
      <DialogAmortissementProgressif
        open={this.state.dlgAmortOpen}
        handleClose={this.handleCloseDialogAmortissements}
        nombreAnnees={nombreAnnees}
        handleSave={this.handleSetAmortissementsProgressifs}
        coefprogressivites={values.coefprogressivites}
        montantFinancement={values.montant_financement}
        valeurResiduelle={values.valeur_residuelle_emprunt}
      />,
      <form onSubmit={handleSubmit} className={classes.container}>
        <Grid container justify="center" alignItems="flex-start" direction="row" spacing={16}>
          <input name="idactifpatrimonial" value={values.idactifpatrimonial} type="hidden" />
          <Grid item sm={12} style={{ padding: "1.5em" }}>
{ (editMode && values.modifie && values.modifie == 'MODIFIE' ) && (        
  <div  style={{color: 'white', marginBottom: '10px', fontWeight: 'bold', backgroundColor: '#e5e600', position: 'relative', top: '-70px' }} > Attention-Tableau d'amortissement modifié. 
<br/>
Les paramètres ci dessous sont ceux de l'emprunt initial.
 </div> )}
            <Grid
              container
              justify="flex-start"
              alignItems="flex-end"
              direction="row"
              spacing={16}
              style={{ border: "solid 1px silver" }}
            >
              <Grid item sm={12}>
              {editMode && (
              <Grid container justify="flex-start" alignItems="flex-end" direction="row" spacing={16}>
                  <Grid item sm={4}>
                    <TextField
                      id="id_tranche_madar"
                      value={id_tranche_madar}
                      name="id_tranche_madar"
                      label="ID Tranche"
                      type="text"
                      margin="dense"
                      fullWidth
                      onBlur={handleBlur}
                      /*onChange={e => this.changeValue("id_tranche_madar", e.target.value, false)}*/
                    />
                  </Grid>
                  </Grid>
              )}
                <Grid container justify="flex-start" alignItems="flex-end" direction="row" spacing={16}>
                  <Grid item sm={4}>
                    <TextField
                      id="montant_financement"
                      value={values.montant_financement}
                      name="montant_financement"
                      label="Montant du financement"
                      type="text"
                      margin="dense"
                      required
                      fullWidth
                      onBlur={handleBlur}
                      helperText={
                        errors.montant_financement && touched.montant_financement
                          ? errors.montant_financement
                          : null
                      }
                      error={touched.montant_financement && errors.montant_financement}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onChange={e => this.changeValue("montant_financement", e.target.value, false)}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl className={classes.select} required fullWidth margin="dense">
                      <InputLabel htmlFor="idbanque-native-helper">Banque</InputLabel>
                      <Select
		        required
                        value={values.idbanque}
                        onChange={(event, key) => this.changeValue("idbanque", event.target.value)}
                        input={<Input name="idbanque" id="idbanque-native-helper" />}
                      >
                        {banques.sort((b2, b1) => {
		if (b1.nom_banque > b2.nom_banque) return -1;
		if (b1.nom_banque < b2.nom_banque) return 1;
		return 0;
	  }).map(b => (
                          <MenuItem value={b.idbanque} key={b.idbanque}><img src={"data:image/jpeg;" + b.image64} style={{height: 25, paddingTop: 5}}/>{ b.nom_banque}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

		              <Grid item style = {{width: "1px"}}>
                    <TextField
                      id="idbanque"
                      value={values.idbanque}
                      name="idbanque"
                      type="text"
                      required
                      margin="dense"
                      style = {{width: "1px"}}
                    />
                  </Grid>
                  {editMode && (
                  <Grid item style ={{width: "calc(33% - 15px)"}}>
                    <TextField
                      id="identifiant_transaction_finance_active"
                      value={identifiant_transaction_finance_active}
                      name="identifiant_transaction_finance_active"
                      label="Id Transaction Finance Active"
                      type="text"
                      margin="dense"
                      fullWidth
                      onBlur={handleBlur}
                      /* onChange={e => this.changeValue("identifiant_transaction_finance_active", e.target.value, false)} */
                    />
                  </Grid>
                  )}
                  </Grid>

                <Grid container justify="flex-start" alignItems="flex-end" direction="row" spacing={16}>
                  <Grid item sm={4}>
                    <TextField
                      id="duree_financement"
                      value={values.duree_financement}
                      name="duree_financement"
                      label="Durée (mois)"
                      type="text"
                      required
                      margin="dense"
                      fullWidth
                      onBlur={handleBlur}
                      helperText={
                        errors.duree_financement && touched.duree_financement
                          ? errors.duree_financement
                          : null
                      }
                      error={touched.duree_financement && errors.duree_financement}
                      onChange={e => this.changeValue("duree_financement", e.target.value, false)}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl required fullWidth margin="normal" className={classes.select}>
                      <InputLabel htmlFor="frequence-native-helper">Fréquence</InputLabel>
                      <NativeSelect
                        defaultValue="trimestrielle"
                        value={values.frequence}
                        onChange={(event, key) => this.changeValue("frequence", event.target.value)}
                        input={<Input name="frequence" id="frequence-native-helper" />}
                      >
                        <option value={"mensuelle"}>Mensuelle</option>
                        <option value={"trimestrielle"}>Trimestrielle</option>
                        <option value={"semestrielle"}>Semestrielle</option>
                        <option value={"annuelle"}>Annuelle</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="nombre_echeances"
                      value={nombre_echeances}
                      name="nombre_echeances"
                      label="Nombre d'échéances"
                      type="text"
                      disabled
                      margin="normal"
                      fullWidth
                      onBlur={handleBlur}
                      helperText={
                        errors.nombre_echeances && touched.nombre_echeances ? errors.nombre_echeances : null
                      }
                      error={touched.nombre_echeances && errors.nombre_echeances}
                      onChange={e => this.changeValue("nombre_echeances", e.target.value, false)}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="flex-start" alignItems="flex-end" direction="row" spacing={16}>
                  <Grid item sm={4}>
                    <FormControl className={classes.select} required fullWidth margin="dense">
                      <InputLabel htmlFor="type_emprunt-native-helper">Type de taux</InputLabel>
                      <NativeSelect
                        value={values.type_emprunt}
                        onChange={(event, key) => this.changeValue("type_emprunt", event.target.value)}
                        input={<Input name="type_emprunt" id="type_emprunt-native-helper" />}
                      >
                        <option value={"taux_fixe"}>Taux fixe</option>
                        <option value={"taux_variable"}>Taux variable</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    {values.type_emprunt === "taux_fixe" && (
                      <TextField
                        id="taux"
                        value={values.taux}
                        name="taux"
                        label="Taux"
                        type="text"
                        required
                        margin="dense"
                        fullWidth
                        onBlur={handleBlur}
                        helperText={errors.taux && touched.taux ? errors.taux : null}
                        error={touched.taux && errors.taux}
                        InputProps={{
                          inputComponent: PrctFormatCustom,
                        }}
                        onChange={e => this.changeValue("taux", e.target.value, false)}
                      />
                    )}
                    {values.type_emprunt === "taux_variable" && (
                      <TextField
                        id="produit"
                        value="Euribor 3M"
                        name="produit"
                        label="Indice"
                        type="text"
                        disabled
                        margin="dense"
                      />
                    )}
                  </Grid>
                  <Grid item sm={4}>
                    {values.type_emprunt === "taux_variable" && (
                      <TextField
                        id="marge_banque"
                        value={values.marge_banque}
                        name="marge_banque"
                        label="Marge sur taux variable"
                        type="text"
                        margin="dense"
                        required
                        fullWidth
                        InputProps={{
                          inputComponent: PrctFormatCustom,
                        }}
                        onBlur={handleBlur}
                        helperText={errors.marge_banque && touched.marge_banque ? errors.marge_banque : null}
                        error={touched.marge_banque && errors.marge_banque}
                        onChange={e => this.changeValue("marge_banque", e.target.value, false)}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container justify="flex-start" alignItems="flex-end" direction="row" spacing={16}>
                  <Grid item sm={4}>
                    <Grid container justify="flex-start" alignItems="flex-start">
                      <Grid item style={{ width: "160px" }}>
                        <FormControl required fullWidth margin="dense">
                          <InputLabel htmlFor="type_amortissement-native-helper">Amortissement</InputLabel>
                          <NativeSelect
                            value={values.type_amortissement}
                            onChange={(event, key) => {
                              this.changeValue("type_amortissement", event.target.value);
                              if (event.target.value === "progressif") {
                                this.setState({ dlgAmortOpen: true });
                              }
                            }}
                            input={<Input name="type_amortissement" id="type_amortissement-native-helper" />}
                          >
                            <option value={"constant"}>Constant</option>
                            <option value={"progressif"}>Progressif</option>
                          </NativeSelect>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={values.type_amortissement !== "progressif"}
                          title="Editer les progressions"
                          onClick={this.handleOpenDialogAmortissements}
                          className={classes.progressifButton}
                        >
                          <ListIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify="flex-start" alignItems="center" direction="row" spacing={16}>
                  <Grid item sm={3}>
                    <TextField
                      id="valeur_residuelle_emprunt"
                      value={values.valeur_residuelle_emprunt}
                      name="valeur_residuelle_emprunt"
                      label={`Valeur résiduelle${prctValeurResiduelle}`}
                      type="text"
                      margin="dense"
                      fullWidth
                      onBlur={handleBlur}
                      helperText={
                        errors.valeur_residuelle_emprunt && touched.valeur_residuelle_emprunt
                          ? errors.valeur_residuelle_emprunt
                          : null
                      }
                      error={touched.valeur_residuelle_emprunt && errors.valeur_residuelle_emprunt}
                      className={classes.textFieldi}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onChange={e => this.changeValue("valeur_residuelle_emprunt", e.target.value, false)}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <DatePicker
                      keyboard
                      value={values.date_premiere_echeance || null}
                      onChange={date => this.handleDateChange("date_premiere_echeance", date)}
                      format="DD/MM/YYYY"
                      autoOk
                      required
                      label="Date 1ère échéance"
                      emptyLabel="JJ/MM/AAAA"
                      cancelLabel="Annuler"
                      style={{ width: "180px" }}
                      invalidDateMessage="Date non valide"
                      mask={value =>
                        value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                      }
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <DatePicker
                      value={values.decalage_amortissement || null}
                      onChange={date => this.handleDateChange("decalage_amortissement", date)}
                      format="DD/MM/YYYY"
                      autoOk
                      label="Décalage amortissement"
                      emptyLabel="JJ/MM/AAAA"
                      fullWidth
                      clearable
                      cancelLabel="Annuler"
                      invalidDateMessage="Date non valide"
                      keyboard
                      style={{ width: "190px" }}
                      mask={value =>
                        value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {values.type_emprunt === "taux_variable" && (
            <Grid item sm={12} style={{ padding: "1.5em" }}>
              <Grid
                container
                justify="space-between"
                alignItems="flex-start"
                direction="row"
                spacing={16}
                style={{ border: "solid 1px silver" }}
              >
                <Grid item sm={12}>
                  <Grid container justify="flex-start" alignItems="flex-end" direction="row" spacing={16}>
                    <Grid item sm={3}>
                      <Grid
                        container
                        alignItems="center"
                        justify="space-around"
                        direction="column"
                        style={{ height: "300px" }}
                      >
                        <Grid item>
                          <Typography variant="title">Couverture de taux</Typography>
                          <RadioGroup
                            aria-label="Gender"
                            name="couverture_taux"
                            className={classes.group}
                            value={values.couverture_taux}
                            onChange={e => this.changeValue("couverture_taux", e.target.value)}
                          >
                            <FormControlLabel value="swap" control={<Radio />} label="SWAP" />
                            <FormControlLabel value="cap" control={<Radio />} label="CAP" />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={9}>
                      <Grid
                        container
                        alignItems="center"
                        justify="flex-start"
                        direction="column"
                        style={{ height: "300px", padding: "3em" }}
                        spacing={16}
                      >
                        {values.couverture_taux === "swap" && (
                          <Grid item>
                            <Grid
                              container
                              justify="flex-start"
                              alignItems="flex-start"
                              direction="row"
                              spacing={16}
                            >
                              <Grid item>
                                <TextField
                                  id="montant_swap"
                                  value={values.montant_swap}
								  style={{width:235}}
                                  required
                                  name="montant_swap"
                                  label="Notionnel du SWAP"
                                  type="text"
                                  margin="dense"
                                  fullWidth
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.montant_swap && touched.montant_swap ? errors.montant_swap : null
                                  }
                                  error={touched.montant_swap && errors.montant_swap}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={e => this.changeValue("montant_swap", e.target.value, false)}
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  id="taux_swap"
                                  value={values.taux_swap}
                                  name="taux_swap"
                                  label="Taux de SWAP"
                                  type="text"
                                  margin="dense"
                                  required
                                  fullWidth
                                  onBlur={handleBlur}
                                  helperText={errors.taux_swap && touched.taux_swap ? errors.taux_swap : null}
                                  error={touched.taux_swap && errors.taux_swap}
                                  InputProps={{
                                    inputComponent: PrctFormatCustom,
                                  }}
                                  onChange={e => this.changeValue("taux_swap", e.target.value, false)}
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  id="duree_swap"
                                  value={values.duree_swap}
                                  required
                                  name="duree_swap"
                                  label="Durée SWAP"
                                  type="text"
                                  margin="dense"
                                  fullWidth
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.duree_swap && touched.duree_swap ? errors.duree_swap : null
                                  }
                                  error={touched.duree_swap && errors.duree_swap}
                                  InputProps={{
                                    inputComponent: AnneeFormatCustom,
                                  }}
                                  onChange={e => this.changeValue("duree_swap", e.target.value, false)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {values.couverture_taux === "cap" && [
                          <Grid item>
                            <Grid
                              container
                              justify="flex-start"
                              alignItems="center"
                              direction="row"
                              spacing={16}
                            >
                              <Grid item>
                                <TextField
                                  id="montant_cap"
                                  value={values.montant_cap}
                                  name="montant_cap"
                                  label="Notionnel du CAP"
                                  type="text"
                                  margin="dense"
                                  required
                                  fullWidth
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.montant_cap && touched.montant_cap ? errors.montant_cap : null
                                  }
                                  error={touched.montant_cap && errors.montant_cap}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={e => this.changeValue("montant_cap", e.target.value, false)}
                                />
                              </Grid> 
                              <Grid item>
                                <TextField
                                  id="duree_cap"
                                  value={values.duree_cap}
                                  required
                                  name="duree_cap"
                                  label="Durée CAP"
                                  type="text"
                                  margin="dense"
                                  fullWidth
                                  onBlur={handleBlur}
                                  helperText={errors.duree_cap && touched.duree_cap ? errors.duree_cap : null}
                                  error={touched.duree_cap && errors.duree_cap}
                                  InputProps={{
                                    inputComponent: AnneeFormatCustom,
                                  }}
                                  onChange={e => this.changeValue("duree_cap", e.target.value, false)}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justify="flex-start"
                              alignItems="flex-start"
                              direction="row"
                              spacing={8}
                            >
                              <Grid item>
                                <TextField
                                  id="strike_cap"
                                  value={values.strike_cap}
                                  name="strike_cap"
                                  label="Strike CAP"
                                  type="text"
                                  margin="dense"
                                  required
                                  fullWidth
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.strike_cap && touched.strike_cap ? errors.strike_cap : null
                                  }
                                  error={touched.strike_cap && errors.strike_cap}
                                  InputProps={{
                                    inputComponent: PrctFormatCustom,
                                  }}
                                  onChange={e => this.changeValue("strike_cap", e.target.value, false)}
                                />
                              </Grid>,
                              <Grid item style={{ width: "145px" }}>
                                <FormControl required fullWidth margin="dense" style={{width:195}}>
                                  <InputLabel htmlFor="type_amortissement-native-helper">Prime</InputLabel>
                                  <NativeSelect
                                    value={values.prime_cap}
                                    onChange={(event, key) =>
                                      this.changeValue("prime_cap", event.target.value)
                                    }
                                    input={<Input name="prime_cap" id="type_amortissement-native-helper" />}
                                  >
                                    <option value={"upfront"}>Upfront</option>
                                    <option value={"lissee"}>Lissee</option>
                                  </NativeSelect>
                                </FormControl>
                              </Grid>,
                              {values.prime_cap === "lissee" ? (
                                <Grid item>
                                  <TextField
                                    id="montant_prime_lisse"
                                    value={values.montant_prime_lisse}
                                    name="montant_prime_lisse"
                                    label="Montant prime lisséé"
                                    type="text"
                                    margin="dense"
                                    required
                                    fullWidth
                                    onBlur={handleBlur}
                                    helperText={
                                      errors.montant_prime_lisse && touched.montant_prime_lisse
                                        ? errors.montant_prime_lisse
                                        : null
                                    }
                                    error={touched.montant_prime_lisse && errors.montant_prime_lisse}
                                    InputProps={{
                                      inputComponent: PrctFormatCustom,
                                    }}
                                    onChange={e =>
                                      this.changeValue("montant_prime_lisse", e.target.value, false)
                                    }
                                  />
                                </Grid>
                              ) : null}
                            </Grid>
                          </Grid>,
                        ]}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container justify="center" alignItems="flex-start" direction="row" spacing={16}>
          <Grid item sm={4}>
            <Button
              style={{ marginTop: "1em" }}
              disabled={
                isSubmitting ||
                values.duree_financement === 0 ||
                (values.type_amortissement === "progressif" && values.coefprogressivites.length === 0)
              }
              variant="raised"
              color="primary"
              fullWidth
              type="submit"
            >
              {editMode ? "Modifier" : "Enregistrer"} cette tranche
            </Button>
          </Grid>
          <Grid item sm={4}>
            <Button
              style={{ marginTop: "1em" }}
              variant="outlined"
              disabled={isSubmitting}
              color="primary"
              fullWidth
              onClick={() => onCancel(null)}
            >
              Annuler
            </Button>
          </Grid>

        </Grid>
      </form>,
    ];
  }
}

const TranchFormFormik = withFormik({
  mapPropsToValues: props => {
    return { ...props.emprunt };
  },
  validationSchema: validationSchema,
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
      setErrors,
      setFieldValue,
      /* setValues, setStatus, and other goodies */ resetForm,
    },
  ) => {
    props.onSubmit(values);
    setSubmitting(false);
    resetForm();
    setFieldValue("idbanque", null);
  },
})(withStyles(styles)(TranchForm));

export default withRouter(TranchFormFormik);
