import Yup from "yup";

const schema = {
  ville: Yup.string().required("Ville est requis."),
  code_postal: Yup.string()
    .required("Code postal est requis.")
    .max(5, "5 caractères max"), // max 5 char
  adresse: Yup.string().required("Adresse est requis."),
  //nom_expert: Yup.string().required("Nom de l'expert est requis."),
  valeur_expertise: Yup.string().required("Valeur vénale est requise."),
  valeur_venale: Yup.string().required("Valeur vénale."),
  surface_totale_actif: Yup.number()
    .required("Surface totale est requis.")
    .min(0, "Minimum 0")
    .typeError("Surface totale doit être un nombre.")
    .typeError("Ça doit être un nombre."),
  nombre_parking_int: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
  nombre_parking_ext: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
  indemnite_deviction: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
  prix_net_vendeur: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
  frais_de_notaire: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
  commissions_intermediaire: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
  fond_commerce: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
  travaux_bailleur: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
  charge_emprunt_tmp: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
  capital_reste_du_tmp: Yup.number()
    .min(0, "Minimum 0")
    .typeError("Ça doit être un nombre."),
};

export default Yup.object().shape(schema);
