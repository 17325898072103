import React, { Component } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import withGlobals from "../withGlobals";

class DatasLoader extends Component {
  static propTypes = {
    locaux: PropTypes.array.isRequired,
    localId: PropTypes.number.isRequired,
    children: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      locaux: props.locaux,
      surface_totale_actif: null,
      adresse: null,
    };
  }

  componentDidMount = () => {
    const local = this.findLocal();
    if (!local) {
      this.props.fetch({ url: `/locaux/${this.props.localId}` }).then(local => {
        this.props.fetch({ url: `/actifs/${local.idactifpatrimonial}` }).then(actif => {
          const { surface_totale, code_postal, ville, adresse } = actif;
          this.props.fetch({ url: `/locaux/actifs/${local.idactifpatrimonial}` }).then(locaux => {
            this.setState({
              locaux: this.state.locaux.concat(locaux),
              surface_totale_actif: surface_totale,
              adresse: `${adresse} ${code_postal} ${ville}`,
            });
          });
        });
      });
    } else {
      this.props.fetch({ url: `/actifs/${local.idactifpatrimonial}` }).then(actif => {
        const { surface_totale, code_postal, ville, adresse } = actif;
        this.props.fetch({ url: `/locaux/actifs/${local.idactifpatrimonial}` }).then(locaux => {
          this.setState({
            locaux: this.state.locaux.concat(locaux),
            surface_totale_actif: surface_totale,
            adresse: `${adresse} ${code_postal} ${ville}`,
          });
        });
      });
    }
  };

  findLocal = () => this.state.locaux.find(l => l.idlocal === this.props.localId);

  render() {
    const local = this.findLocal();
    if (!local) {
      return <CircularProgress size={50} />;
    }

    const { adresse, code_postal, ville } = local;
    const surface_autres_biens = this.state.locaux
      .filter(l => l.idlocal !== local.idlocal)
      .reduce((m, l) => m + (l.surface_totale || l.surface_rdc + l.surface_etage + l.surface_sous_sol), 0);

    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        local,
        locaux: this.state.locaux,
        surface_totale_actif: local.surface_totale_actif || this.state.surface_totale_actif,
        surface_occupee: surface_autres_biens || 0,
        adresse: adresse ? `${adresse} ${code_postal} ${ville}` : this.state.adresse,
      }),
    );
  }
}

export default withGlobals(DatasLoader);
