import React from "react";
import Grid from "@material-ui/core/Grid";
import ReactTable from "react-table";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../../pages/withGlobals";
import headerStyle from "../../common/tableHeaderStyle";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import moment from "moment";

class DialogProjectionIndexationLoyers extends React.Component {

  state = {
    dialogOpen: true,
    emprunts: []
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
    window.location = "/";
  };

  componentDidMount = () => {
     this.props
      .fetch({
        url: "/euribors/",
        method: "POST",
        body: {
          parametrage: "VRGET"
        }
      })
      .then(emprunts => {
        this.setState({emprunts});
      })
      .catch(err => console.log(err));
  };

  handleSave = () => {
    this.props
      .fetch({
        url: "/euribors/",
        method: "POST",
        body: {
          parametrage: "VRPUT",
          emprunts: this.state.emprunts
        }
      })
      .then(res => {
        this.handleCloseDialog();
      })
      .catch(err =>  {
        this.props.openNotification("success", "Paramétrage modifié", false);
        this.handleCloseDialog();
    });
  };

  handleChange = (e, index) => {
    this.state.emprunts[index][e.target.name] = parseFloat(e.target.value.replace('%', '').trim());
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">Paramètrage
          <Button onClick={this.handleCloseDialog} color="textSecondary" style={{ marginLeft: "85%" }}>
              Fermer
            </Button></DialogTitle>
          <DialogContent>
          
            

          <Grid item xs={12}>
			<ReactTable
        data={this.state.emprunts.map((t, index) => {
          return { ...t};
        })}
        columns={[
          {
            Header: "ID emprunt",
            accessor: "idemprunt",
            width: 74,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
          {
            Header: "Adresse",
            accessor: "adresse",
            width: 260,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          },
          {
            Header: "Date de première échéance",
            accessor: "date_premiere_echeance",
            width: 120,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value !== "" ? moment(row.value).format("DD/MM/YYYY") : ""}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
          {
            Header: "Date de dernière échéance",
            accessor: "date_derniere_echeance",
            width: 120,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value !== "" ? moment(row.value).format("DD/MM/YYYY") : ""}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          }
          ,
          {
            Header: "Montant emprunté",
            accessor: "montant_financement",
            width: 80,
            Cell: row => <NumberFormat
            style={{ lineHeight: "40px" }}
            value={parseInt(row.value, 10)}
            displayType={"text"}
            thousandSeparator=" "
            suffix={""}
          />,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
          {
            Header: "Valeur résiduelle",
            accessor: "valeur_residuelle_emprunt",
            width: 80,
            Cell: row => <NumberFormat
            style={{ lineHeight: "40px" }}
            value={parseInt(row.value, 10)}
            displayType={"text"}
            thousandSeparator=" "
            suffix={""}
          />,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
          {
            Header: "Taux",
            accessor: "taux_parametrage",
            width: 80,
            headerStyle: { ...headerStyle, textAlign: "right" },
            Cell: row => { return ( <span>
             <TextField
              type="text"
              style={{ lineHeight: "40px" , border: "5px solid transparent"}}
              defaultValue={row.value? parseFloat(row.value).toFixed(2) + " %" : '2.00 %'}
              name="taux_parametrage"
              onChange={e => this.handleChange(e, row.index)}
              suffix={" %"}
               />
            </span>
            );
            }
          },
          {
            Header: "Durée d'amortissement",
            accessor: "duree_parametrage",
            width: 80,
            headerStyle: { ...headerStyle, textAlign: "right" },
            Cell: row => { return ( <span>
             <TextField
              style={{ lineHeight: "40px" , border: "5px solid transparent"}}
              type="text"
              defaultValue={row.value? row.value : 15}
              name="duree_parametrage"
              onChange={e => this.handleChange(e, row.index)}
               />
            </span>
            );
            }
          }
          
        ]}
        noDataText="Aucune donnée"
        showPagination={false}
        pageSize={this.state.emprunts.length}
        className="-striped -highlight noScroll"
      />
		</Grid>


            <DialogActions>
              <Button onClick={this.handleCloseDialog} color="textSecondary">
                Annuler
              </Button>
              <Button
                onClick={this.handleSave}
                variant="raised"
                color="primary"
              >
                Valider ce paramètrage
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withGlobals(DialogProjectionIndexationLoyers);

