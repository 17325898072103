import React from "react";
import { Route } from "react-router-dom";
import Headers from "../pages/Header"
import SideBar from "../pages/SideBar";

const PrivateRoute = ({ component: Component,auth, openNotification, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>(
      
      <div className="wrapper">
        <Headers />     
        <SideBar />
        <div className="content-wrapper">
          <Component {...props} openNotification={openNotification}  />
        </div>
      </div>
    )         
    }
  />
);

PrivateRoute.propTypes = {
 
};



export default PrivateRoute;