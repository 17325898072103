import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LogoImg from "./logo.jpg";


const styles = theme => ({
  container: {
    height: "calc(100vh - 150px)",
    cursor:"pointer"
  }
});

const Logo = ({ history, classes }) => {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid item onClick={() => (history.push("/login"))}>
        <img
          src={LogoImg}
          alt="logo du groupe Madar"
          style={{ width: "80%" }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(Logo));
