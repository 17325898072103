import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash/debounce";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ListeSocietes from "../../common/ListeSocietes";
import ListeBanques from "../../common/ListeBanques";
import withGlobals from "../withGlobals";


const styles = theme => ({
  form: {
    padding: theme.spacing.unit,
  },
  btn: {
    marginTop: theme.spacing.unit * 2,
  },
  ville: {
    textTransform: "uppercase",
  },
});

class RechercheForm extends React.Component {
  static propTypes = {
    moduleRoute: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      idinvestissement: null,
      nom_portefeuille: null,
      code_postal: null,
      ville: null,
      adresse: null,
      idbanque: null,
      idsociete: null,
      societes: [],
      banques: [],
      banqueSelected: null,
      societeSelected: null
    };
    this.delayedCallback = debounce(e => this.props.onChange(e), 250);
  }

componentDidMount() {
      this.props.fetch({ url: "/societes/" }).then(societes => {
        var array = [];
        array.push(societes[0]);
        array.push(societes[1]);
        this.setState({societes : array});
      });
      this.props.fetch({method: "GET", url: `/banques/` }).then(banques => {
var arrayOfBanque = banques;
arrayOfBanque.sort(function(a, b){
    if(a.nom_banque < b.nom_banque) { return -1; }
    if(a.nom_banque > b.nom_banque) { return 1; }
    return 0;
})
        this.setState({banques :arrayOfBanque});
      });
  };

onSocieteChange = e => {
    this.setState({ idsociete: parseInt(e.target.value, 10) });
    this.handleChange(e);
  };

  onBanqueChange = e => {
    this.setState({ idbanque: parseInt(e.target.value, 10) });
    this.handleChange(e);
  };


  handleChange = e => {
    e.persist();
    this.delayedCallback(e);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { history } = this.props;
    history.push(`/emprunts_mobile?recherche=true`);
  };

  render() {
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <form onSubmit={this.handleSubmit} className={this.props.classes.form}>
          
<Grid item>
	<TextField
	  id="adresse"
	  name="adresse"
	  label="Adresse"
	  type="text"
	  margin="dense"
	  fullWidth
	  onChange={this.handleChange}
	/>
  </Grid>
<Grid item>
	<TextField
	  id="idactifpatrimonial"
	  name="idactifpatrimonial"
	  label="Id actif patrimonial"
	  type="text"
	  margin="dense"
	  fullWidth
	  onChange={this.handleChange}
	/>
  </Grid>

<Grid item>
	<TextField
	  id="nom_portefeuille"
	  name="nom_portefeuille"
	  label="Nom du financement"
	  type="text"
	  margin="dense"
	  fullWidth
	  onChange={this.handleChange}
	/>
  </Grid>


<Grid item>
<ListeBanques
  onChange={this.handleChange}
  banques={this.state.banques}
  banqueSelected={this.state.banqueSelected}
  className={this.props.classes.listeSocietes}
/>
</Grid>
<Grid item>
<ListeSocietes
  onChange={this.handleChange}
  societes={this.state.societes}
  societeSelected={this.state.societeSelected}
  className={this.props.classes.listeSocietes}
/>
</Grid>
	<Grid item>
            <Button type="submit" variant="raised" color="primary" className={this.props.classes.btn}>
              Rechercher
            </Button>
          </Grid>
        </form>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(RechercheForm)));
