import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../../pages/withGlobals";
import headerStyle from "../../common/tableHeaderStyle";
import ReactTable from "react-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseLogo from "./close.png";


class DialogControleBauxAltaix extends React.Component {
  state = {
    dialogOpen: true,
    controles: [],
    nbrCorrespondancesManquantes: 0,
    pctCorrespondancesManquantes: 0,
    controlesUpdated: true
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
    window.location = "/";
  };


  componentDidMount = () => {
     this.getData();
  };

  getData(afterUpdate) {
    this.props
    .fetch({
      url: "/banques/",
      method: "POST",
      body: {
        controles: "GET"
      }
    })
    .then(parametrage => {
      this.setState({controles: parametrage[0].data});
      this.setState({nbrCorrespondancesManquantes: parametrage[0].data.length});
      const pct = 100 * parametrage[0].data.length / parametrage[0].total_baux;
      this.setState({pctCorrespondancesManquantes: pct.toFixed(2)});
      if (afterUpdate) {
        this.setState({controlesUpdated: true});
        this.props.openNotification("success", "Correspndances manquantes modifiées avec succès !", false)
      }
    })
    .catch(err => console.log(err));
  }

  handleSave = () => {
    this.setState({controlesUpdated: false});
    this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          controlesdatas: this.state.controles,
          controles: "oui"
        }
      })
      .then(res => {
        this.getData(true);
      })
      .catch(err =>  {
        this.getData(true);
      });
  };

  handleChangeCodeImmeuble = (e, index) => {
    this.state.controles[index]['code_immeuble_altaix'] = parseInt(e.target.value.trim());
  };

  handleChangeCodeBail = (e, index) => {
    this.state.controles[index]['code_bail_altaix'] = parseInt(e.target.value.trim());
  };

  render() {
    let closeImg = {cursor:'pointer', float:'right', marginTop: '5px', width: '20px'};
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">Contrôle des Baux Altaix <img onClick={this.handleCloseDialog} src={CloseLogo} style={closeImg}/></DialogTitle>
          <DialogContent>
          <Grid item xs={12}>
          <h4>Liste des données ALTAIX manquantes</h4>
          <Grid
            container
            alignItems="center"
            direction="row"
            justify="flex-start"
            spacing={0}
          >
        <Grid item xs={11}><h5>Nombre de correspndances manquantes {this.state.nbrCorrespondancesManquantes}</h5></Grid> 
          <Grid item xs={1}><b>({this.state.pctCorrespondancesManquantes} %)</b></Grid>
      </Grid>
          
			</Grid>
          <Grid item xs={12}>
      <ReactTable
        data={this.state.controles}
        columns={[
          {
            Header: "Code bail Madar",
            accessor: "code_bail",
            Cell: row => <span style={{ lineHeight: "40px", right: "48%", position:"relative" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          },
          {
            Header: "Code immeuble ALTAIX",
            accessor: "code_immeuble_altaix",
            headerStyle: { ...headerStyle, textAlign: "center" },
            Cell: row => { return ( <span>
             <TextField
              type="number"
              key={'code_immeuble_altaix' + row.index} 
              defaultValue={row.original.code_immeuble_altaix}
              style={{ lineHeight: "40px" , border: "5px solid transparent"}}
              name={'code_immeuble_altaix' + row.index}
              onChange={e => this.handleChangeCodeImmeuble(e, row.index)}
               />
            </span>
            );
            }
          },
          {
            Header: "Code bail ALTAIX",
            accessor: "code_bail_altaix",
            headerStyle: { ...headerStyle, textAlign: "center" },
            Cell: row => { return ( <span>
             <TextField
              key={'code_bail_altaix' + row.index} 
              style={{ lineHeight: "40px" , border: "5px solid transparent"}}
              type="number"
              defaultValue={row.original.code_bail_altaix}
              name={'code_bail_altaix' + row.index}
              onChange={e => this.handleChangeCodeBail(e, row.index)}
               />
            </span>
            );
            }
          }
          
        ]}
        noDataText="Aucune donnée"
        showPagination={true}
        defaultPageSize={this.state.controles.length > 9 ? 10 : this.state.controles.length}
        showPageSizeOptions={false}
        className="-striped -highlight noScroll"
        key={this.state.controles.length > 9 ? 10 : this.state.controles.length}
      />
		</Grid>

            <DialogActions>
            {this.state.controlesUpdated && <Button onClick={this.handleCloseDialog}>
                Annuler
              </Button> }
              {this.state.controlesUpdated && <Button
                onClick={this.handleSave}
                variant="raised"
                color="primary"
              >
                Valider ce paramètrage
              </Button> }
              {!this.state.controlesUpdated &&
              <Grid container alignItems="center" justify="center" direction="row">
              <Grid item >
                Mise à jour encours...
              </Grid>
              <Grid item>
                <CircularProgress size={50} />
              </Grid>
            </Grid>
              }
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withGlobals(DialogControleBauxAltaix);

