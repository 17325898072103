import React from "react";
import ReactTable from "react-table";
import { withStyles } from "@material-ui/core/styles";
import headerStyle from "../../common/tableHeaderStyle";
import DownloadIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import ReactExport from "react-export-excel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import readXlsxFile from 'read-excel-file'
import withGlobals from "../withGlobals";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const styles = theme => {
  return {
    tableM: {
      height: "calc(100vh - 150px)",
    },
    centerTD: {
	textAlign: "center",
        paddingTop: "20px",
    },
    leftTD: {
        textAlign: "right",
        paddingTop: "20px",
    },

  };
};




class Parametrage extends React.Component {

 state = {
    amortissements_modifies: [],
    values: [],
    arrayExcel: [],
  };


handleUpload = () => {
document.getElementById('input').click();
}


getNumberFromExcel = strInput =>  {
var str = "" + strInput;
console.log(str);
	if(str.indexOf(',') === -1) {
		return str;
	} else {
		var result = str.replace(',', '.');
		return result;
	}
}

getNumberForExcel = strInput =>  {
var str = "" + strInput;
console.log(str);
        if(str.indexOf('.') === -1) {
                return str;
        } else {
                var result = str.replace('.', ',');
                return result;
        }
}

getDateForExcel = strInput =>  {
var str = "" + strInput;
console.log(str);
	var result = str.charAt(8) + str.charAt(9) + '/' + str.charAt(5) + str.charAt(6) + '/' + str.charAt(0) + str.charAt(1) + str.charAt(2) + str.charAt(3);
	return result;
}

getDateFromExcel = strInput =>  {
var str = "" + strInput;
console.log(str);
        var result = str.charAt(6) + str.charAt(7) + str.charAt(8) + str.charAt(9) + '-' + str.charAt(3) + str.charAt(4) + '-' + str.charAt(0) + str.charAt(1);
        return result;
}




onFileChange = (event) => {
var a = this;
  if (event.target.files && event.target.files[0]) {
const input = document.getElementById('input');
  readXlsxFile(input.files[0]).then((rows) => {
for(var j =1; j<rows.length; j++) {
	if (((rows[j][5]).toString().split("/").length - 1) != 2) {
document.getElementById("input").value = "";
		alert("Vous devez formatter les dates en TEXT et réessayer SVP!");
		return;	
	}
}

        var amortissements = [];
        for(var i =1; i<rows.length; i++) {
           amortissements.push({"echeances_numero": rows[i][0], "capital": a.getNumberFromExcel(rows[i][1]), "capital_rembourse": a.getNumberFromExcel(rows[i][2]), "montant_interets": a.getNumberFromExcel(rows[i][3]), "valeur_residuelle": a.getNumberFromExcel(rows[i][4]), "date_echeance": a.getDateFromExcel(rows[i][5])});
        }


a.props.fetch({
url: "/banques/",
method: "POST",
body: {
  amortissement_modifies: "POST",
  trancheindex: a.props.trancheIndex,
  idemprunt: a.props.idemprunt,
  nom_fichier: input.files[0].name,
  num_fichier: ""+(parseInt(this.state.amortissements_modifies[this.state.amortissements_modifies.length-1].num_fichier) + 1),
  amortissements: JSON.stringify(amortissements)
}
})
.then(parametrage => {
document.getElementById("input").value = "";
this.loadDatas();
})
.catch(err => console.log(err));


  })

  }
}


handleValider = () => {
var num_fichier = 1;
var nbChecked = 0;
for (var i = 0; i <this.state.values.length; i++ ){
  if (this.state.values[i] == true) {
    nbChecked++;
    num_fichier = i+1;
  }
}

if (nbChecked == 0) {
   alert("Vous devez sélectionner au moins un fichier SVP.");
} else if (nbChecked > 1) {
   alert("Vous devez sélectionner un seul fichier SVP.");
}/* else if (num_fichier ==1) {
   alert("Pour revenir à la version initial, veuillez utiliser le formulaire de modification de la tranche d'emprunt.");
} */ else {


this.props.fetch({
url: "/banques/",
method: "POST",
body: {
  amortissement_modifies: "VALIDER",
  trancheindex: this.props.trancheIndex,
  idemprunt: this.props.idemprunt,
  num_fichier: num_fichier,
}
})
.then(parametrage => {
window.location.reload();
})
.catch(err => console.log(err));

}
} 


  componentDidMount() {

console.log(this.props.amortissements);

this.props.
fetch({
url: "/banques/",
method: "POST",
body: {
  amortissement_modifies: "GET",
  trancheindex: this.props.trancheIndex,
  idemprunt: this.props.idemprunt,
}
})
.then(data => {
 if(data.length > 0) {
this.loadDatas();
   } else {
this.props.fetch({
url: "/banques/",
method: "POST",
body: {
  amortissement_modifies: "POST",
  trancheindex: this.props.trancheIndex,
  idemprunt: this.props.idemprunt,
  nom_fichier: 'ID' + this.props.idactifpatrimonial + '_T' + (this.props.trancheIndex + 1),
  num_fichier: "1",
  amortissements: JSON.stringify(this.props.amortissements)
}
})
.then(parametrage => {
this.loadDatas();
})
.catch(err => console.log(err));
   }


})
.catch(err => console.log(err));

  }



  loadDatas =  () => {

   var amortissements_modifiesTemp = [];
   var valuesTemp = [];
   var arrayExcelTemp = [];



this.props.
fetch({
url: "/banques/",
method: "POST",
body: {
  amortissement_modifies: "GET",
  trancheindex: this.props.trancheIndex,
  idemprunt: this.props.idemprunt,
}
})
.then(data => {
for(var i=0; i<data.length; i++) {
    amortissements_modifiesTemp.push({ 'num_fichier': data[i].num_fichier, 'nom_fichier': data[i].nom_fichier});
    arrayExcelTemp[i] =  this.getAmortissementsForExcel(JSON.parse(data[i].amortissements));
    valuesTemp.push(false);
}

this.setState({
amortissements_modifies: amortissements_modifiesTemp,
arrayExcel: arrayExcelTemp,
values: valuesTemp
});

console.log(this.state.arrayExcel);
console.log(this.state.amortissements_modifies);

})
.catch(err => console.log(err));



this.setState({
amortissements_modifies: amortissements_modifiesTemp,
arrayExcel: arrayExcelTemp
});


  };



handleDelete = (e, index) => {
    e.stopPropagation();

if(index == 0) {
//eslint-disable-next-line
alert("Vous ne pouvez pas supprimer le fichier original");
} else {

    if (
//eslint-disable-next-line
      confirm(
        `Voulez-vous vraiment supprimer ce fichier?`,
      )
    ) {
this.props.
fetch({
url: "/banques/",
method: "POST",
body: {
  amortissement_modifies: "DELETE",
  trancheindex: this.props.trancheIndex,
  idemprunt: this.props.idemprunt,
  num_fichier: this.state.amortissements_modifies[index].num_fichier
}
})
.then(data => {

 this.loadDatas();

   } );
}
}

  };
  

getAmortissementsForExcel = amort =>  {
	var array = [];
	for (var i=0; i < amort.length; i++) {		
		array.push({"echeance":(i+1), "capital_debut_periode":this.getNumberForExcel(amort[i].capital), "remb_en_capital":this.getNumberForExcel(amort[i].capital_rembourse), "interets":this.getNumberForExcel(amort[i].montant_interets), "capital_fin_periode":this.getNumberForExcel(amort[i].valeur_residuelle), "date_echeance":this.getDateForExcel(amort[i].date_echeance)});
	}
	return array;
}
  
handleDownload = (e, index) => {
    e.stopPropagation();
   if (index === 0) {
    console.log(this.state.arrayExcel);
   } 
};

handleChangeCheckbox = (e, index) => {
    console.log(e.target.name);
    console.log(e.target.checked);
    this.state.values[index] = e.target.checked;
   
  };


handleSelect = (e, index) => {
    e.stopPropagation();

};


 render() {

const { trancheIndex, amortissements, frequence, classes, layout } = this.props;


return (
<Grid>
  <ReactTable
    data={this.state.amortissements_modifies.map((t, index) => {
          return { ...t};
        })}
    columns={[
       {
	Header: "Sélectionner",
        accessor: "num_fichier",
        width: 250,
        Cell: row => { return ( <span>
	 <Checkbox
   	 disabled = {false}
	 onChange={e => this.handleChangeCheckbox(e, row.index)}
	 value={this.state.values[row.value-1]}
   	/>
	</span>
	);
	},
        headerStyle: { ...headerStyle, textAlign: "center" },
        className: 'centerTD2',
      },
      {
        Header: "N° de fichier",
        accessor: "num_fichier",
        width: 270,
        Cell: row => <span>{row.value}</span>,
        headerStyle: { ...headerStyle, textAlign: "center" },
        className: 'centerTD',
      },
      {
        Header: "Nom du fichier",
        accessor: "nom_fichier",
        width: 270,
        Cell: row => <span>{row.value}</span>,
        headerStyle: { ...headerStyle, textAlign: "center" },
        className: 'centerTD',
      },
{
            Header: "Actions",
            accessor: "nom_fichier",
            Cell: row => {
              return (
                <span>
                  <IconButton aria-label="Delete" onClick={e => this.handleDelete(e, row.index)}>
                    <DeleteIcon />
                  </IconButton>
		<ExcelFile filename={this.state.amortissements_modifies[row.index].nom_fichier} element={<IconButton aria-label="Download">
                    <DownloadIcon />
                  </IconButton>}>
			<ExcelSheet data={this.state.arrayExcel[row.index]} name="Tableau d'amortissement">
				<ExcelColumn label="Échéance" value="echeance"/>
				<ExcelColumn label="Capital début période" value="capital_debut_periode"/>
				<ExcelColumn label="Remboursé en capital" value="remb_en_capital"/>
				<ExcelColumn label="Intérêts" value="interets"/>
				<ExcelColumn label="Capital fin période" value="capital_fin_periode"/>
				<ExcelColumn label="Date échéance" value="date_echeance"/>
			</ExcelSheet>
		</ExcelFile>
                </span>
              );
            },
        className: 'centerTD2',
            headerStyle: {
              ...headerStyle,
              textAlign: "center",
              paddingRight: "20px",
            },
          }
    ]}
    noDataText="Aucune donnée"
    showPagination={false}
    pageSize={this.state.amortissements_modifies.length}
    className={`-striped -highlight ${classes[layout === "desktop" ? "table" : "tableM"]}`}
  />


<Grid container alignItems="flex-start" justify="flex-start" direction="row" spacing={0} style={{ paddingTop: "28px" }} >
<Grid item  xs={5}>
</Grid>
<Grid item  xs={3}>
<input type="file" id="input" onChange={this.onFileChange}  hidden/>
<Button
  variant="contained"
  color="primary"
  fullWidth
   onClick={this.handleUpload}
>
Charger
</Button>
</Grid>
<Grid item  xs={1}>
</Grid>
<Grid item  xs={3}>
<Button
  variant="contained"
  color="primary"
  fullWidth
   onClick={this.handleValider}
>
valider
</Button>
</Grid>

</Grid>
</Grid>


);

}
}


export default withGlobals(withStyles(styles)(Parametrage));
