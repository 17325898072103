import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HotKeys } from "react-hotkeys";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../withRoot";
import Login from "./Login";
import Baux from "./Bail/Baux";
import BauxMobile from "./BailMobile/Baux";
import Actifs from "./Actif/Actifs";
import ActifsMobile from "./ActifMobile/Actifs";
import EmpruntsMobile from "./EmpruntMobile/Emprunts";
import Locaux from "./Local/Locaux";
import LocauxMobile from "./LocauxMobile/Locaux";
import Profil from "./User/Profil";
import UserList from "./User/List";
import Emprunts from "./Emprunt/Emprunts";
import Rapports from "./Rapport/Rapports";
import Parametrage from "./Parametrage/DialogParametrage";
import ControleBauxAltaix from "./Parametrage/DialogControleBauxAltaix";
import ControleEmpruntsFinanceActive from "./Parametrage/DialogControleEmpruntsFinanceActive";
import ProjectionIndexationLoyers from "./Parametrage/DialogProjectionIndexationLoyers";
import ProjectionIndexationLoyersOld from "./Parametrage/DialogProjectionIndexationLoyersOld";
import ExclusionVRs from "./Parametrage/DialogExclusionVRs";
import GestionDesBanques from "./Parametrage/DialogGestionDesBanques";
import MadarSnackbarContent from "./Notifications";
import Register from "./Register";
import ListeNotificationsMadar from "./ListeNotificationsMadar";
import ListeNotificationsMobileMadar from "./ListeNotificationsMobileMadar";
import Logo from "./Logo";
import HotkeyManager from "./HotkeyManager";
import GlobalContext from "./GlobalContext";
import './globalStylesheet.css';
import InfoBulle from "./Bail/DialogInfoBulle"
import HistoriqueConnexion from "./HistoriqueConnexion"
import PrivateRoute from "../Route/PrivateRoute";
import PrivateRouteMobile from "../Route/PrivateRouteMobile";

const styles = theme => ({
  root: {
    textAlign: "center",
    paddingTop: "34px",
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
});

class Index extends React.Component {
  constructor(props) {
    super(props);
    const user = localStorage.getItem("madar");
    this.state = {
      open: false,
      user: user ? JSON.parse(user) : null,
      notificationOpen: false,
      typeNotification: "success",
      msgNotification: null,
      redirect: null, // redirect action dans notification
      duration: 1500,
      assistance: false,
      windowWidth:window.innerWidth
    };
  }


  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleClick = () => {
    this.setState({
      open: true
    });
  };

  handleLoginSuccess = user => {
    localStorage.setItem("madar", JSON.stringify(user));
    console.log("logged !", user);
    return this.setState({ user });
  };

  handleFetch = ({ url, method, body, silent}) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    if (this.state.user) {
      headers["X-Api-Key"] = this.state.user.api_key;
    }
    return new Promise((resolve, reject) => {
      const prefix = url.indexOf('/pdf') == -1 ? '/public/index.php' : '';
      console.log('fetch', `${prefix}${url}`);
      return fetch(`${prefix}${url}`, {
        method: method || "GET",
        headers,
        body: body ? JSON.stringify(body) : null
      })
        .then(res => {
	  if (res.status == 413){
		this.handleOpenNotification(
              "error",
              "Veuillez ajouter une image de taille plus réduite",
              undefined,
              4000
            );
            return reject(`Erreur backend ${res.status}`);

	} else if(res.status > 204 && !silent) {
            this.handleOpenNotification(
              "error",
              `Erreur backend ${res.status}`,
              undefined,
              4000
            );
            return reject(`Erreur backend ${res.status}`);
          }
          return res.json();
        })
        .then(
          res =>
            res && res.data
              ? resolve(res.data)
              : reject('Pas de propriété "data" dans la réponse')
        );
    });
  };

  openAssistance = () => this.setState({ assistance: !this.state.assistance });

  handleLogout = () => {
    localStorage.removeItem("madar");
    window.location = "/";
    this.setState({ user: null });
  };

  handleOpenNotification = (
    typeNotification,
    msgNotification,
    redirect = null,
    duration = 1500
  ) =>
    this.setState({
      notificationOpen: true,
      typeNotification,
      msgNotification,
      redirect,
      duration
    });

  handleCloseNotification = () =>
    this.setState({
      notificationOpen: false,
      typeNotification: "success",
      msgNotification: null,
      redirect: null,
      duration: 1500,
      menuOpen: false,
      anchorEl: null,
      shortCutPressed: null
    });

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  clearShortcut = () => this.setState({ shortCutPressed: null });

  goToActifs = () => {
    this.setState({ shortCutPressed: "actifs" });
  };

  goToEmprunts = () => {
    this.setState({ shortCutPressed: "emprunts" });
  };

  goToLocaux = () => {
    this.setState({ shortCutPressed: "locaux" });
  };

  gotToBaux = () => {
    this.setState({ shortCutPressed: "baux" });
  };

  gotToRapport = () => {
    this.setState({ shortCutPressed: "rapports" });
  };

  gotToHome = () => {
    this.setState({ shortCutPressed: "" });
  };
  
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => this.setState({
    windowWidth: window.innerWidth
  });

  render() {
    console.log("size", this.state.windowWidth);
    const { classes } = this.props;
    const {
      open,
      user,
      notificationOpen,
      typeNotification,
      msgNotification,
      redirect,
      duration,
      anchorEl,
      assistance,
      windowWidth
    } = this.state;
    const handlers = {
      showActifs: this.goToActifs,
      showEmprunts: this.goToEmprunts,
      showLocaux: this.goToLocaux,
      showBaux: this.gotToBaux,
      showRapport: this.gotToRapport,
      showHome: this.gotToHome
    };
    const keyMap = {
      showActifs: "ctrl+alt+a",
      showEmprunts: "ctrl+alt+e",
      showLocaux: "ctrl+alt+l",
      showBaux: "ctrl+alt+b",
      showRapport: "ctrl+alt+r",
      showHome: "ctrl+alt+h"
    };

    const menuOpen = Boolean(anchorEl);
    return (
      <HotKeys keyMap={keyMap} handlers={handlers} focused>
        <Router>
        <Switch>
          <GlobalContext.Provider value={{ user, fetch: this.handleFetch }}>
            <div className={classes.root}>
              <HotkeyManager
                shortCutPressed={this.state.shortCutPressed}
                clearShortcut={this.clearShortcut}
              />
              <MadarSnackbarContent
                message={msgNotification}
                open={notificationOpen}
                onClose={this.handleCloseNotification}
                variant={typeNotification}
                redirect={redirect}
                duration={duration}
              />
              {/* <MainDrawer user={user} onClose={this.handleClose} open={open} /> */}
              {/* <CustomAppBar>
                <Toolbar>
                  <IconButton
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={this.handleClick}
                  >
                    <MenuIcon />
                  </IconButton>
                  <TitreApp user={user} />
                  {user && ( <Hidden smUp>
                  <Button color="primary" title="Assistance" onClick={this.openAssistance} >
                    <img src={LogoAssistance} alt="Assistance" width="30px" />
                  </Button>
                  </Hidden> ) }
                  
                  {user && (
                    <Hidden smDown>
                      <IconButton
                        aria-owns={menuOpen ? "menu-appbar" : null}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit"
                      >
                        {user.image64 && (
                          <Avatar alt="Remy Sharp" src={user.image64} />
                        )}
                        {!user.image64 && <PersonIcon />}
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                        open={menuOpen}
                        onClose={this.handleCloseMenu}
                      >
                        <MenuItem onClick={() => (window.location = "/profil")}>
                          Profil
                        </MenuItem>
                        <MenuItem onClick={this.handleLogout}>
                          Déconnexion
                        </MenuItem>
                      </Menu>
                    </Hidden>
                  )}
                  <Hidden smDown>
                    {!user && (
                      <LinkButton color="inherit" to="/login">
                        Connexion
                      </LinkButton>
                    )}
                  </Hidden>
                </Toolbar>
              </CustomAppBar> */}
              <Grid container className={classes.root}>
                {/* <Hidden smDown>
                  <BreadCrumb />
                </Hidden> */}
                <Grid item xs={12}>
                  {!user && <Route path="/" exact render={() => <Logo />} />}
                  {!user && <Route path="/index.html" exact render={() => <Logo />} />}
                  {user &&  windowWidth > 550 ? (
                      <PrivateRoute path="/"
                      exact component={ListeNotificationsMadar} />
                   
                  ) : null}
                  {user && windowWidth < 550 ?(
                     <PrivateRouteMobile path="/"
                     exact component={ListeNotificationsMobileMadar} />
                    // <Hidden smUp>
                    //   { (assistance === true) && <div width="90%" style={{border: "1px solid red", marginBottom: "5px", width: "80%", left: "10%", position: "relative"}}>Besoin d'aide? Contactez-nous sur <br/><a href="mailto:madar@2m-advisory.atlassian.net">madar@2m-advisory.atlassian.net</a></div>}
                    //   {/* <Route
                    //     path="/"
                    //     exact
                    //     render={() => <ListeNotificationsMobileMadar />}
                    //   /> */}
                    //    <PrivateRouteMobile path="/"
                    //     exact component={ListeNotificationsMobileMadar} />
                    // </Hidden>
                  ) :null}
                  {user && (
                    // <Route
                    //   path="/profil"
                    //   render={() => (
                    //     <Profil
                    //       user={user}
                    //       onSave={this.handleLoginSuccess}
                    //       openNotification={this.handleOpenNotification}
                    //     />
                    //   )}
                    // />
                    <PrivateRoute path="/profil" user={user} openNotification={this.handleOpenNotification} onSave={this.handleLoginSuccess} component={Profil}  />
                  )}
                  {user &&
                    user.superviseur && (
                      <Route
                        path="/register"
                        render={() => (
                          <Register
                            openNotification={this.handleOpenNotification}
                          />
                        )}
                      />
                    )}
                    <PrivateRoute path="/users" user={user} component={UserList}  />
                  {/* {<Route path="/users" render={() => <UserList />} />} */}
                  <PrivateRoute path="/actifs" user={user} openNotification={this.handleOpenNotification} component={Actifs}  />
                  {/* <Route
                    path="/actifs"
                    render={() => (
                      <Actifs
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                  {/* <Route
                    path="/emprunts/:empruntId?"
                    render={() => (
                      <Emprunts
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                  <PrivateRoute path="/emprunts/:empruntId?" user={user} openNotification={this.handleOpenNotification} component={Emprunts}  />
                  <PrivateRouteMobile path="/emprunts_mobile/:empruntId?" user={user} openNotification={this.handleOpenNotification} component={EmpruntsMobile}  />
                  
                  {/* <Route
                    path="/emprunts_mobile/:empruntId?"
                    render={() => (
                      <EmpruntsMobile
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                  {/* <Route
                    path="/rapports"
                    render={() => (
                      <Rapports
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                   <PrivateRoute path="/rapports" user={user} openNotification={this.handleOpenNotification} component={Rapports}  />
		              {/* <Route
                    path="/parametrage"
                    render={() => (
                      <Parametrage
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                <PrivateRoute path="/parametrage" user={user} openNotification={this.handleOpenNotification} component={Parametrage}  />

                  {/* <Route
                    path="/controle-baux-altaix"
                    render={() => (
                      <ControleBauxAltaix
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                  <PrivateRoute path="/controle-baux-altaix" user={user} openNotification={this.handleOpenNotification} component={ControleBauxAltaix}  />

                  <PrivateRoute path="/controle-emprunts-finance-active" user={user} openNotification={this.handleOpenNotification} component={ControleEmpruntsFinanceActive}  />

                  {/* <Route
                    path="/infoBulle"
                    render={() => (
                      <InfoBulle
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                <PrivateRoute path="/infoBulle" user={user} openNotification={this.handleOpenNotification} component={InfoBulle}  />

                {/* <Route
                    path="/historiqueConnexion"
                    render={() => (
                      <HistoriqueConnexion
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                <PrivateRoute path="/historiqueConnexion" user={user} openNotification={this.handleOpenNotification} component={HistoriqueConnexion}  />
                {/* <Route
                    path="/projectionIndexationLoyers"
                    render={() => (
                      <ProjectionIndexationLoyers
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                  <PrivateRoute path="/projectionIndexationLoyers" user={user} openNotification={this.handleOpenNotification} component={ProjectionIndexationLoyers}  />
                  {/* <Route
                    path="/projectionIndexationLoyersOld"
                    render={() => (
                      <ProjectionIndexationLoyersOld
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                  <PrivateRoute path="/projectionIndexationLoyersOld" user={user} openNotification={this.handleOpenNotification} component={ProjectionIndexationLoyersOld}  />
                  {/* <Route
                    path="/exclusionVRs"
                    render={() => (
                      <ExclusionVRs
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                <PrivateRoute path="/exclusionVRs" user={user} openNotification={this.handleOpenNotification} component={ExclusionVRs}  />  
                {/* <Route
                    path="/gestionDesBanques"
                    render={() => (
                      <GestionDesBanques
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                <PrivateRoute path="/gestionDesBanques" user={user} openNotification={this.handleOpenNotification} component={GestionDesBanques}  />
                  {/* <Route
                    path="/baux"
                    render={() => (
                      <Baux
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                  <PrivateRoute path="/baux" user={user} openNotification={this.handleOpenNotification} component={Baux}  />
                  {/* <Route
                    path="/locaux"
                    render={() => (
                      <Locaux
                        user={user}
                        openNotification={this.handleOpenNotification}
                      />
                    )}
                  /> */}
                  <PrivateRoute path="/locaux" user={user} openNotification={this.handleOpenNotification} component={Locaux}  />
                  <Route
                    path="/login"
                    render={() => (
                      <Login onLoginSuccess={this.handleLoginSuccess} />
                    )}
                  />
                  <Route
                    path="/index.html"
                    render={() => (
                      <Login onLoginSuccess={this.handleLoginSuccess} />
                    )}
                  />
                  <PrivateRouteMobile path="/actifs_mobile/:actifId?" user={user}  component={ActifsMobile}  />

                  {/* <Route
                    path="/actifs_mobile/:actifId?"
                    render={() => <ActifsMobile user={user} />}
                  /> */}
                  <PrivateRouteMobile path="/locaux_mobile/:localId?" user={user}  component={LocauxMobile}  />
                  {/* <Route
                    path="/locaux_mobile/:localId?"
                    render={() => <LocauxMobile user={user} />}
                  /> */}
                  <PrivateRouteMobile path="/baux_mobiles/:bailId/:localId" user={user}  component={BauxMobile}  />
		  {/* <Route
                    path="/baux_mobiles/:bailId/:localId"
                    render={() => <BauxMobile user={user} />}
                  /> */}
                  <PrivateRouteMobile path="/baux_mobile/:bailId?" user={user}  component={BauxMobile}  />
                  {/* <Route
                    path="/baux_mobile/:bailId?"
                    render={() => <BauxMobile user={user} />}
                  /> */}
                </Grid>
              </Grid>
            </div>
          </GlobalContext.Provider>
          
          </Switch>
        </Router>
      </HotKeys>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(Index));
