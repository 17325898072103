import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { withFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LaunchIcon from "@material-ui/icons/Launch";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import validationSchema from "./validation";
import ConfirmWrapper from "../../common/ConfirmWrapper";
import ListeActifsDialog from "../../common/ListeActifsDialog";
import LocalForm from "./Form";
import EnvoyerParMailDialog from "../../common/EnvoyerParMailDialog";
import CreationRedirectionModal from "../../common/CreationRedirectionModal";
import withGlobals from "../withGlobals";

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 1,
      paddingBottom: theme.spacing.unit * 2,
      // marginRight: theme.spacing.unit * 20,
      // marginLeft: theme.spacing.unit * 20,
    },
    fullHeight: {
      display: "flex",
      //height: "calc(100vh - 150px)",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
    button: {
      marginTop: theme.spacing.unit * 4,
    },
    surfaceDispo: {
      color: "#725356",
    },
    surfaceNonDispo: {
      color: "black",
    },
    buttonHidden: {
      marginTop: theme.spacing.unit * 4,
      visibility: "hidden",
    },
    icon: {
      height: "3em",
      width: "3em",
      fill: theme.palette.primary.dark,
    },
    title: {
      marginBottom: 0,
      fontSize: 14,
      lineHeight: "36px",
      fontWeight: "bold",
    },
    rightIcon: {
      marginLeft: theme.spacing.unit / 2,
      marginRight: theme.spacing.unit / 2,
    },
    titleArea: {
      border: `solid 1px ${theme.palette.primary.main}`,
      padding: "2px",
      borderRadius: "3px 3px",
      marginBottom: theme.spacing.unit * 2,
    },
  };
};

const LocalFormik = withFormik({
  mapPropsToValues: props => ({ ...props.local }),
  validationSchema: validationSchema,
  handleSubmit: (
    values,
    { props, setSubmitting, setErrors /* setValues, setStatus, and other goodies */ },
  ) => {
    props
      .onSubmit(values)
      .then(() => {
        setSubmitting(false);
      })
      .catch(e => {
        setSubmitting(false);
      });
  },
})(withStyles(styles)(LocalForm));

class Local extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    local: PropTypes.object.isRequired,
    openNotification: PropTypes.func.isRequired,
    onFormDataChanged: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    formDataChanged: PropTypes.bool.isRequired,
    onResetDataChanged: PropTypes.func.isRequired,
    onActifSelected: PropTypes.func.isRequired,
    filtred: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      // pour forcer le remontage du formulaire
      changingDatas: false,

      submiting: false,
      justSaved: false,
      changed: false,
      simple: false,
      filtredDialogOpen: false,
      messageDialogOpen: false,
      surface_totale_actif: props.surface_totale_actif,
      surface_occupee: props.surface_occupee,
      surface_occupee_local: this.calculeSurfaceOccuppeLocal(props.local),
      modalRedirectionOpen: false,
      idLocalCreated: null,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.local && this.props.local && nextProps.local.idlocal !== this.props.local.idlocal) {
      // this.props.onResetDataChanged();
      this.setState({
        changingDatas: true,
        justSaved: false,
        submitting: false,
        filtredDialogOpen: false,
        surface_totale_actif: nextProps.surface_totale_actif,
        surface_occupee: nextProps.surface_occupee,
        surface_occupee_local: this.calculeSurfaceOccuppeLocal(nextProps.local),
      });
      setTimeout(() => this.setState({ changingDatas: false }), 1);
    }
  };

  handleChange = e => {
    this.setState({
      local: { ...this.state.local, [e.target.name]: e.target.value },
      justSaved: false,
    });
  };

  handleChangeVue = () => {
    this.setState({
      simple: !this.state.simple,
      surface_occupee_local: this.calculeSurfaceOccuppeLocal(this.props.local),
    });
  };

  handleSubmit = values => {
    const { openNotification, onResetDataChanged, onUpdate, onCreate } = this.props;
    const {
      nombre_parking_ext,
      nombre_parking_int,
      surface_totale,
      surface_etage,
      surface_sous_sol,
      surface_rdc,
      idactifpatrimonial,
      licence4,
      extraction,
      restauration,
      activite_interdite,
      etage,
      idlocal,
    } = values;
    this.setState({ submiting: true });
    return new Promise((resolve, reject) => {
      this.props
        .fetch({
          url: `/locaux/${idlocal ? idlocal : ""}`,
          method: idlocal ? "PUT" : "POST",
          body: {
            nombre_parking_ext,
            nombre_parking_int,
            surface_totale: surface_etage > 0 || surface_sous_sol > 0 || surface_rdc > 0 ? 0 : surface_totale,
            surface_etage,
            surface_sous_sol,
            surface_rdc,
            idactifpatrimonial,
            licence4,
            extraction,
            restauration,
            activite_interdite,
            etage,
            idlocal,
          },
        })
        .then(local => {
          onResetDataChanged();
           openNotification(
             "success",
             !idlocal ? `Le local ${local.idlocal} a été créé. Créer un bail ?` : "Local modifié",
             !idlocal ? `/baux/nouveau?localId=${local.idlocal}` : undefined,
             2000,
           );

          if (idlocal) {
            // maj datas pour idlocal dans actifs
            onUpdate(local);
          } else {
            onCreate(local);
          }

          this.setState({
            submiting: false,
            justSaved: true,
            modalRedirectionOpen: !idlocal ? true : false,
            idLocalCreated: !idlocal ? local.idlocal : null,
          });
          //
          // this.setState({ submiting: false, justSaved: true });
          // if (locaux.length === 1 || !idlocal) {
          //   history.push("/locaux");
          // }
          //window.location.reload();
          return resolve();
          // this.timeoutRedirect = setTimeout(() => history.push('/locaux'), 4100);
        });
    });
  };

  handleDelete = () => {
    const { onResetDataChanged, openNotification, history, local, onDelete } = this.props;
    const id = local.idlocal;
    this.setState({ submiting: true });
    this.props.fetch({ url: `/locaux/${id}`, method: "DELETE" }).then(() => {
      onResetDataChanged();
      openNotification("success", `Local ${local.idlocal} supprimé !`);
      this.setState({ submiting: false, justSaved: true });
      onDelete(id);
      history.push("/locaux");
    });
  };

  navigateToBaux = () => {
    const { local, history } = this.props;
    history.push(`/baux?localId=${local.idlocal}`);
    return;
  };

  navigateToActif = () => {
    const { local, history } = this.props;
    if (local.idactifpatrimonial) {
      history.push(`/actifs/${local.idactifpatrimonial}`);
    }
    return;
  };

  handleOpenFilteredDialog = () => this.setState({ filtredDialogOpen: true });

  handleCloseFilteredDialog = () => this.setState({ filtredDialogOpen: false });

  handleOpenMessageDialog = () => this.setState({ messageDialogOpen: true });

  handleCloseMessageDialog = () => this.setState({ messageDialogOpen: false });

  handleCloseModalRedireactionDialog = () => this.setState({ modalRedirectionOpen: false });

  handleLocalPrecedent = index => {
    const { locaux, history } = this.props;
    history.push(`/locaux/${locaux[index - 1].idlocal}`);
  };

  handleLocalSuivant = index => {
    const { locaux, history } = this.props;
    history.push(`/locaux/${locaux[index + 1].idlocal}`);
  };

  navigateTo = url => {
    this.props.history.push(url);
    this.handleCloseFilteredDialog();
  };

  handleValueChanged = ({ surface_rdc, surface_etage, surface_sous_sol, surface_totale }) => {
    this.setState({
      surface_occupee_local: this.state.simple
        ? parseInt(surface_totale, 10)
        : this.calculeSurfaceOccuppeLocal({
          surface_rdc,
          surface_etage,
          surface_sous_sol,
        }),
    });
  };

  calculeSurfaceOccuppeLocal = ({ surface_rdc, surface_etage, surface_sous_sol }) => {
    const total = parseInt(surface_rdc, 10) + parseInt(surface_etage, 10) + parseInt(surface_sous_sol, 10);
    return total;
  };

  handleAcceptRediretion = () => {
    const { history } = this.props;
    history.push(`/baux/nouveau?localId=${this.state.idLocalCreated}`);
    this.setState({ modalRedirectionOpen: false, idactifpatrimonialCreated: null });
  };

  handleCancelRediretion = () => {
    const { history } = this.props;
    history.push(`/locaux/${this.state.idLocalCreated}`);
    this.setState({ modalRedirectionOpen: false, idactifpatrimonialCreated: null });
  };

  render() {
    const {
      classes,
      match,
      local,
      locaux,
      formDataChanged,
      onFormDataChanged,
      onResetDataChanged,
      onActifSelected,
      adresse,
      readOnly,
      user
    } = this.props;
    const {
      submiting,
      justSaved,
      error,
      changingDatas,
      filtredDialogOpen,
      messageDialogOpen,
      simple,
      surface_occupee,
      surface_totale_actif,
      modalRedirectionOpen,
      idLocalCreated,
    } = this.state;

    let surfaceDispoActif = parseInt(surface_totale_actif, 10) - parseInt(surface_occupee, 10) - parseInt(local.surface_rdc, 10) - parseInt(local.surface_etage, 10) - parseInt(local.surface_sous_sol, 10);
    surfaceDispoActif = surfaceDispoActif < 0 ? 0 : surfaceDispoActif;

    const template = 'local';

    if (!local) {
      return (
        <div className={classes.fullHeight}>
          <Grid container alignItems="center" direction="row" justify="center">
            <Grid item>
              <Typography variant="display3">Ce local n'existe pas.</Typography>
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <Paper elevation={3} className={classes.root}>
        <EnvoyerParMailDialog
          open={messageDialogOpen}
          objet={`Local ID ${match.params.localId} - ${adresse}`}
          datas={{ ...local, user }}
          template={template}
          handleClose={this.handleCloseMessageDialog}
        />
        <CreationRedirectionModal
          open={modalRedirectionOpen}
          titre="Local créé"
          onClose={this.handleCloseModalRedireactionDialog}
          onCancel={this.handleCancelRediretion}
          onAccept={this.handleAcceptRediretion}
        >
          <Typography variant="body1">
            Le local {idLocalCreated} a été créé avec succès.<br />
            Souhaitez vous créer un bail pour ce local ?
          </Typography>
        </CreationRedirectionModal>
        <ListeActifsDialog
          open={filtredDialogOpen}
          handleClose={this.handleCloseFilteredDialog}
          titre="Sélectionnez un actif"
          baseUrl="/locaux/"
          actifs={locaux
            .map(a => ({
              id: a.idlocal,
              label: `${a.adresse} ${a.code_postal} ${a.ville} / ${a.idactifpatrimonial}`,
            }))
            .sort((a, b) => a.adresse > b.adresse)}
        />
        <Grid
          container
          alignItems="center"
          direction="row"
          justify="space-between"
          className={classes.titleArea}
        >
          <Grid item>
            <Button color="primary" title="Précédent" onClick={() => { this.props.history.goBack(); }}>
              <ArrowBackIcon className={classes.rightButton} />
            </Button>
          </Grid>
          <Grid item>
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={16}>
              {!local.idactifpatrimonial && (
                <Grid item>
                  <Typography className={classes.title} color="primary">
                    Nouveau local
                  </Typography>
                </Grid>
              )}
              {local.idactifpatrimonial && (
                <Grid item style={{ textAlign: "left" }}>
                  <Grid container direction="column" alignItems="flex-start" justify="center" spacing={0}>
                    <Grid item>
                      <Typography color="primary">{adresse}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="primary">
                        {`Surface totale de l'actif : ${surface_totale_actif} m²`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="primary">
                        {`Surface disponible de l'actif : ${surfaceDispoActif} m²`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" direction="row" justify="space-around">
              {submiting && (
                <Grid item>
                  <CircularProgress size={25} />
                </Grid>
              )}
              {justSaved &&
                !error &&
                !formDataChanged && (
                  <Grid item>
                    <DoneIcon color="primary" />
                  </Grid>
                )}

              <Grid item>
                <IconButton color="primary" title="Consulter l'actif" onClick={this.navigateToActif}>
                  <LaunchIcon className={classes.rightIcon} />
                </IconButton>
              </Grid>
              {local.idlocal &&
                !submiting &&
                !justSaved &&
                !error && (
                  <Fragment>
                    <Grid item>
                      <IconButton color="primary" title="bail de ce local" onClick={this.navigateToBaux}>
                        <ListIcon className={classes.rightIcon} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton color="primary" title="Envoyer par mail" onClick={this.handleOpenMessageDialog}>
                        <EmailIcon className={classes.rightIcon} />
                      </IconButton>
                    </Grid>
                  </Fragment>
                )}

              {local.idlocal &&
                !submiting &&
                !justSaved &&
                !readOnly &&
                !error && (
                  <Grid item>
                    <ConfirmWrapper
                      onAccept={this.handleDelete}
                      message="Supprimer ce local ?"
                      title="Suppression d'un local"
                    >
                      <IconButton color="primary" title="Supprimer cet actif">
                        <DeleteIcon className={classes.rightIcon} />
                      </IconButton>
                    </ConfirmWrapper>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" direction="row" justify="space-between">
          {!changingDatas && (
            <LocalFormik
              local={{
                surface_totale: 0,
                surface_etage: 0,
                surface_sous_sol: 0,
                surface_rdc: 0,
                ...local,
              }}
              onSubmit={this.handleSubmit}
              setFormDataChanged={onFormDataChanged}
              onActifSelected={onActifSelected}
              onValueChanged={this.handleValueChanged}
              resetDataChanged={onResetDataChanged}
              changed={formDataChanged}
              send={this.send}
              vueSimplifiee={simple}
              surface_occupee={surface_occupee}
              surface_totale_actif={surface_totale_actif}
            />
          )}
        </Grid>
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Local)));
