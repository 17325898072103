import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  dialogTitleArea: {
    padding: theme.spacing.unit * 2,
  },
  dialogTitle: {
    padding: theme.spacing.unit,
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: "3px 3px",
  },
});

const CustomDialogTitle = ({ title, onClose, classes }) => (
  <div className={classes.dialogTitleArea}>
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="space-between"
      className={classes.dialogTitle}
    >
      <Grid item>
        <Typography variant="title">{title}</Typography>
      </Grid>
      <Grid item>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(CustomDialogTitle);
