import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import moment from "moment";
import NumberFormat from "react-number-format";
import ConfirmWrapper from "../../common/ConfirmWrapper";

const styles = theme => ({
  adresse: {
    padding: theme.spacing.unit * 2,
    fontWeight: "bold",
  },
  actions: {
    display: "flex",
  },
  label: {
    textAlign: "left",
    padding: `${theme.spacing.unit}px 0`,
  },
  value: {
    textAlign: "right",
    padding: theme.spacing.unit,
    fontWeight: "bold",
  },
  separation: {
    color: "silver",
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: "484px",
  },
  header: {
    paddingBottom: 0,
    textAlign: "left",
  },
  navShown: {
    visibility: "inherit",
  },
  navHidden: {
    visibility: "hidden",
  },
});

class FicheEmprunt extends Component {
  state = {
    anchorEl: null,
  };

  handleCloseMenu = () => this.setState({ anchorEl: null });

  handleClick = e => this.setState({ anchorEl: e.currentTarget });

  handleEdit = () => {
    this.setState({ anchorEl: null });
    this.props.onEditClick();
  };

  handleDelete = () => {
    this.setState({ anchorEl: null });
    this.props.onDeleteClick();
  };

  render() {
    const { emprunt, classes, banque, noTranche, nombreTranches, readOnly, actifs } = this.props;

    if (!emprunt) return null;

    const {
      montant_financement,
      duree_financement,
      valeur_residuelle,
      date_premiere_echeance,
      frequence,
      taux,
      type_emprunt,
      couverture_taux,
      montant_cap,
      montant_swap,
      date_debut_cap,
      date_debut_swap,
      duree_cap,
      duree_swap,
      marge_banque,
      taux_swap,
      strike_cap,
      idactifpatrimonial,
    } = emprunt;

    const { anchorEl } = this.state;
    const actif = actifs.find(a => a.idactifpatrimonial === idactifpatrimonial);
    const { adresse, code_postal, ville, nom_portefeuille } = actif;

    return (
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs={12}>
          <Card className={classes.card}>
            {!readOnly && (
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleCloseMenu}
              >
                <MenuItem onClick={this.handleEdit}>
                  <ListItemIcon className={classes.icon}>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Modifier cette tranche" />
                </MenuItem>
                <ConfirmWrapper
                  onAccept={this.handleDelete}
                  onCancel={this.handleCloseMenu}
                  message={`Supprimer cette tranche ?${
                    nombreTranches === 1 ? " Cela entrainera la suppression de l'emprunt" : ""
                  }`}
                  title="Suppression d'une tranche"
                >
                  <MenuItem onClick={this.handleDelete}>
                    <ListItemIcon className={classes.icon}>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Supprimer cette tranche" />
                  </MenuItem>
                </ConfirmWrapper>
              </Menu>
            )}
            <CardHeader
              action={[
                <IconButton onClick={this.handleClick}>
                  <MoreVertIcon />
                </IconButton>,
              ]}
              title={`ID Actif ${idactifpatrimonial}`}
              subheader={`${adresse} ${code_postal} ${ville}`}
              className={classes.header}
            />
            <CardContent classes={classes.content}>
              <Grid container alignItems="flex-start" justify="flex-start" direction="row" spacing={0}>
                <Grid item sm={12} className={classes.label}>
                  Tranche <strong>{noTranche}</strong>
                </Grid>
                {nom_portefeuille && (
                  <Grid item sm={12} className={classes.label}>
                    Nom du financement <strong>{nom_portefeuille}</strong>
                  </Grid>
                )}
                {banque && (
                  <Grid item sm={12} className={classes.label}>
                    {banque.nom_banque}
                  </Grid>
                )}
                <Grid item sm={12} className={classes.label}>
                  Première échéance le <strong>{moment(date_premiere_echeance).format("DD/MM/YYYY")}</strong>
                </Grid>
                <Grid item sm={12} className={classes.label}>
                  Montant emprunté{" "}
                  <strong>
                    {" "}
                    <NumberFormat
                      value={montant_financement}
                      displayType={"text"}
                      thousandSeparator=" "
                      suffix={" €"}
                    />
                    {" sur "}
                    {duree_financement}
                  </strong>{" "}
                  mois
                </Grid>
                <Grid item sm={12} className={classes.label}>
                  Fréquence de remboursement <strong>{frequence}</strong>
                </Grid>
                {type_emprunt !== "taux_variable" && (
                  <Grid item sm={12} className={classes.label}>
                    Taux de <strong>{taux} %</strong>
                  </Grid>
                )}
                {type_emprunt === "taux_variable" && (
                  <Grid item sm={12} className={classes.label}>
                    Euribor 3M <strong>{marge_banque} %</strong>
                  </Grid>
                )}
                {!!valeur_residuelle && (
                  <Grid item sm={12} className={classes.label}>
                    <span>
                      CRD :{" "}
                      <strong>
                        {" "}
                        <NumberFormat
                          value={valeur_residuelle}
                          displayType={"text"}
                          thousandSeparator=" "
                          suffix={" €"}
                        />
                      </strong>
                    </span>
                  </Grid>
                )}
                {type_emprunt === "taux_variable" &&
                  couverture_taux && [
                    <Grid item sm={12} className={classes.label}>
                      Couverture de taux : <strong>{couverture_taux.toUpperCase()}</strong>
                      <div style={{ paddingLeft: "20px" }}>
                        <strong>
                          {!!montant_cap && (
                            <NumberFormat
                              value={montant_cap}
                              displayType={"text"}
                              thousandSeparator=" "
                              suffix={" €"}
                            />
                          )}
                          {!!montant_swap && (
                            <NumberFormat
                              value={montant_swap}
                              displayType={"text"}
                              thousandSeparator=" "
                              suffix={" €"}
                            />
                          )}
                        </strong>
                        <br />
                        À partir du{" "}
                        <strong>
                          {!!date_debut_cap && moment(date_debut_cap).format("DD/MM/YYYY")}
                          {!!date_debut_swap && moment(date_debut_swap).format("DD/MM/YYYY")}
                        </strong>
                        <br />
                        {!!duree_swap && (
                          <span>
                            Durée swap : <strong>{duree_swap} année(s)</strong>
                          </span>
                        )}
                        {!!duree_cap && (
                          <span>
                            Durée cap : <strong>{duree_cap} année(s)</strong>
                          </span>
                        )}
                        <br />
                        {!!strike_cap && (
                          <span>
                            Strike <strong>{strike_cap} %</strong>
                          </span>
                        )}
                        {!!taux_swap && (
                          <span>
                            Taux <strong>{taux_swap} %</strong>
                          </span>
                        )}
                      </div>
                    </Grid>,
                  ]}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(FicheEmprunt);
