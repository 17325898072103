import React from "react";
import headerStyle from "../../common/tableHeaderStyle";
import paddedTableHeaderStyle from "../../common/paddedTableHeaderStyle";

const columnData = [
  {
    Header: "Id Actif",
    accessor: "idactifpatrimonial",
    width: 75,
    headerStyle
  },
  { Header: "Code postal", accessor: "code_postal", width: 120, headerStyle },
  { Header: "Ville", accessor: "ville", headerStyle, width: 150 },
  {
    Header: "Adresse",
    accessor: "adresse",
    headerStyle: paddedTableHeaderStyle
  },
  {
    Header: "Surface",
    width: 120,
    accessor: "surface_totale_actif",
    Cell: props => <div style={{ paddingRight: "25px" }}>{props.value} m²</div>,
    headerStyle: { ...paddedTableHeaderStyle, paddingRight: "45px" }
  },
  { Header: "Société", accessor: "entite", width: 135, headerStyle }
];

export default columnData;
