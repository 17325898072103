
import React, { Component } from "react";
import { Link, Redirect, withRouter } from 'react-router-dom'
import { Modal, Row } from 'antd';
import Dialog from "@material-ui/core/Dialog";
import LogoAssistance from "../assistance.png";
import LogoClose from "../close.png";
import { Tabs } from 'antd';
import { Table, Input, Button, Space } from 'antd';
import GroupBy from "lodash/groupBy";
import moment from "moment";
import { Avatar, Image } from 'antd';

class SideBarMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
          lang: 'fr',
          visible: true,
          topScroll: 0,
          user:window.sessionStorage.getItem('madar'),
          visibleModal:false,
          visibleModalNotification:false,
          data: [
            
          ],
       
        }
      }
      componentDidMount(){
       this.getUser()
     
       
      
      }
     
 getUser(){
   const user = JSON.parse(window.localStorage.getItem('madar'))
    this.setState({user:user}) 
 }
 openModal=()=>{
   console.log("hiii");
   this.setState({visibleModal:true})
 }
  handleOk=()=>{
    this.setState({visibleModal:false})
};

 handleCancel=()=>{
  this.setState({visibleModal:false})
};
openModalNotification =()=>{
  this.setState({visibleModalNotification:true})
  this.getNotification()
}
handleCancelModalNotification =()=>{
  this.setState({visibleModalNotification:false})
}  
callback=(key)=> {
  // console.log(key);
}
handleFetch = ({ url, method, body, silent }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  console.log("user", this.state.user);
  if (this.state.user) {
    headers["X-Api-Key"] = this.state.user.api_key;
  }
  return new Promise((resolve, reject) => {
    const prefix = url.indexOf('/pdf') == -1 ? '/public/index.php' : '';
    console.log('fetch', `${prefix}${url}`);
    return fetch(`${prefix}${url}`, {
      method: method || "GET",
      headers,
      body: body ? JSON.stringify(body) : null
    })
    
  });
};


getNotification =async ()=>{
  const requestOptions = {
    method: 'GET',
    headers:{"X-Api-Key":this.state.user.api_key,
    Accept: "application/json",
    "Content-Type": "application/json"}
  };
    const  data = await fetch('/public/index.php/notifications/', requestOptions);
    const dataJson =await data.json();
    const res = dataJson.data;

    const hardcodedNotif = res && res.length && res.length > 0 ? res : [];
    const notificationsGrouped = GroupBy(hardcodedNotif, "type");
    const types = Object.keys(notificationsGrouped)
      .filter(k => k !== "Euribor")
      .map(k => ({
        k,
        qte: notificationsGrouped[k].length
      }))
      .sort((t2, t1) => {
        if (t1.qte > t2.qte) return 1;
        if (t1.qte < t2.qte) return -1;
        return 0;
      });
     
    
    const preavis =[];
    const baux =[];
    const emprunt =[];
    console.log("hiii",hardcodedNotif);
    hardcodedNotif.forEach(element => {
      if(element.type == "preavis"){
        preavis.push(element)
      }
      if(element.type == "baux"){
        baux.push(element)
      }
      if(element.type == "emprunts"){
        emprunt.push(element)
      }
      
    });
    this.setState({
      loaded: true,
      notifications: hardcodedNotif,
      types,
      typeSelected: types && types.length && types.length > 0 && types[0].k,
      baux: baux,
      preavis:preavis,
      emprunt:emprunt
    });
    
    
}
handleDelete = idnotification => {
  const { notifications } = this.state;
  const requestOptions = {
    method: 'DELETE',
    headers:{"X-Api-Key":this.state.user.api_key,
    Accept: "application/json",
    "Content-Type": "application/json"}
  };
    fetch(`/public/index.php/notifications/${idnotification}`, requestOptions) .then(() =>
    this.setState({
      notifications: notifications.filter(
        n => n.idnotification !== idnotification
      )
    })
    );;
    window.location.reload()
  };
  redirect=()=>{
     
      window.location="/"
    
  }
render() {
//     const majTaux = this.state.notifications
// .filter(n => n.type === "Euribor")
// .sort((n1, n2) => {
// if (n1.idnotification > n2.idnotification) return -1;
// if (n1.idnotification < n2.idnotification) return 1;
// return 0;
// });
// if (majTaux && majTaux.length > 0) {

// }
// const euribor = majTaux.length > 0 ? JSON.parse(majTaux[0].message) : null;
// euribor.date_notification = moment(majTaux[0].date_notification).format("DD/MM/YYYY");

// console.log("euribor", euribor);
  const { TabPane } = Tabs;

  const columns = [
    {
      title: 'Title',
      dataIndex: 'message',
      key: 'message',
      width: '70%',
     
    },
    {
      title: 'Action',
      render: (dataIndex) =>(<>
      <Row>
      <div className="sideBar-modal-notification-btn-show" onClick={()=>window.location.href=dataIndex.lien}><i class="fa fa-eye" aria-hidden="true"></i></div>
       <div className="sideBar-modal-notification-btn-delete" onClick={(dataIndex)=>this.handleDelete(dataIndex.idnotification)}><i class="fa fa-trash" aria-hidden="true"></i></div>
      </Row>
       </>),
    
    },
   
   
  ];

    return ( 
      <div>
      <Dialog
        open={this.state.visibleModal}
        onClose={this.handleCancel}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
      <div style={{border: "1px solid red", padding: "8px", position: "relative"}}>Besoin d'aide? Contactez-nous sur <br/>
      <a href="mailto:madar@2m-advisory.atlassian.net">madar@2m-advisory.atlassian.net</a>
      <div style={{ position: "absolute", top: "0px", right: "0px"}}><img src={LogoClose}
      alt="Fermer" width="25px" onClick={this.handleCancel } /></div></div>

      </Dialog>
      <Dialog
        open={this.state.visibleModalNotification}
        onClose={this.handleCancelModalNotification}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
        
      >
      <div style={{border: "1px solid red", padding: "8px", position: "relative"}}>
      <Tabs defaultActiveKey="1" onChange={this.callback}>
        <TabPane tab="BAUX" key="1">
        <Table columns={columns} dataSource={this.state.baux} />
        </TabPane>
        <TabPane tab="PRÉAVIS" key="2">
        <Table columns={columns} dataSource={this.state.preavis} />
        </TabPane>
        <TabPane tab="EMPRUNTS" key="3">
        <Table columns={columns} dataSource={this.state.emprunt} />
        </TabPane>
      </Tabs>
      <div style={{ position: "absolute", top: "0px", right: "10px"}} onClick={this.handleCancelModalNotification } >
        <i class="fa fa-times" aria-hidden="true"></i></div></div>

      </Dialog>
    
      <aside class="main-sidebar  elevation-4 white">
      
      
    <div class="sidebar">
    
      <div class="user-panel mt-3 pb-3 mb-3 d-flex" style={{cursor:"pointer"}} onClick={this.openModalNotification}>
        <div class="image">
        <Avatar size={40}>{this.state.user && this.state.user.prenom.slice(0,4)}</Avatar>
        </div>
        <div class="info">
          <a class="d-block">{this.state.user && this.state.user.prenom}</a>
        </div>
      </div>

     
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
   
          <li class="nav-item menu-open">
            <div onClick={()=>window.location="/"}  class="nav-link">

           
          
                
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
              Tableau de bord
              </p>
            </div>
            
          </li>
          {/* <li class="nav-item">
            <Link to={"users"} class="nav-link">
              <i class="nav-icon fas fa-user"></i>
              <p>
                Utilisateurs
              </p>
            </Link>
          </li> */}
          <li class="nav-item" id="menu-drop-down">
            <div class="nav-link" onClick={() => {
              const classes = document.getElementById('menu-drop-down').classList;
              if (!classes.contains('menu-open')) {
                document.getElementById('menu-drop-down').classList.add('menu-open')
              } else {
                document.getElementById('menu-drop-down').classList.remove('menu-open')
              }
            }
              }>
            <i class="nav-icon fa fa-home"></i>
              <p>
                Patrimoine
                <i class="fas fa-angle-left right"></i>
              </p>
            </div>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <div onClick={()=>window.location="/actifs_mobile"} class="nav-link">
                <i class="far fa fa-building" aria-hidden="true" style={{marginLeft:"7px",marginRight:"10px"}}></i>
                  <p>Actifs</p>
                </div>
              </li>
              <li class="nav-item">
                <div onClick={()=>window.location="/locaux_mobile"} class="nav-link">
                <i class="far fa fa-th-large" aria-hidden="true" style={{marginLeft:"7px",marginRight:"10px"}}></i>
                  <p>Locaux</p>
                </div>
              </li>
              <li class="nav-item">
                <div onClick={()=>window.location="/baux_mobile"} class="nav-link">
                <i class="far fa fa-file-text-o" aria-hidden="true" style={{marginLeft:"7px",marginRight:"10px"}}></i>
                  <p>Baux</p>
                </div>
              </li>
             
            </ul>
          </li>
          <li class="nav-item">
            <div onClick={()=>window.location="/emprunts_mobile"} class="nav-link">
            <i class="nav-icon fas fa-usd"></i>
            
              {/* <i class="nav-icon fas fa-user"></i> */}
              <p>
                Gestion financière
              </p>
            </div>
          </li>
          {/* <li class="nav-item">
            <Link to={"/rapports"} class="nav-link">
           
            <i class="nav-icon fas fa fa-file-text-o" aria-hidden="true"></i>
              <p>
                Reporting
              </p>
            </Link>
          </li> */}
          {/* <li class="nav-item">
            <a href="#" class="nav-link">
             
              <i class="nav-icon fas fa fa-cogs" aria-hidden="true"></i>
              <p>
                Paramètrage
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <Link to={"/gestionDesBanques"} class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Gestion des banques</p>
                </Link>
              </li>
              <li class="nav-item">
                <Link to={"/parametrage"} class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Projection d'indexation des loyers</p>
                </Link>
              </li>
              <li class="nav-item">
                <Link to={"/projectionIndexationLoyersOld"} class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Modification des charges d'emprunts</p>
                </Link>
              </li>
              <li class="nav-item">
              <Link to={"/exclusionVRs"} class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Exclusion des VRs</p>
                </Link>
              </li>
              <li class="nav-item">
                <Link to={"/projectionIndexationLoyers"} class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Paramètrage VR</p>
                </Link>
              </li>
              <li class="nav-item">
                <Link to={"/controle-baux-altaix"} class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Controle des baux Altaix</p>
                </Link>
              </li>
            </ul>
          </li>
          <li class="nav-item" style={{position:"fixed", bottom:"50px"}} onClick={this.openModal}>
            <Link to={""} class="nav-link">
           
              <i class="nav-icon fas fa fa-envelope-o" aria-hidden="true"></i>
              <p>
                Assistance
              </p>
            </Link>
          </li>
          <li class="nav-item" style={{position:"fixed", bottom:"10px"}}>
            <Link to={"/historiqueConnexion"} class="nav-link">
            
              <i class="nav-icon fas fa fa-clock-o" aria-hidden="true"></i>
              <p>
                Historique de connexion
              </p>
            </Link>
          </li> */}
        </ul>
      </nav>
  
    </div>
    
  </aside>
  
  </div>
);
}
}

export default (withRouter(SideBarMobile))