import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

class ConfirmWrapper extends Component {
  static defaultProps = {
    cancel: "Annuler",
    ok: "Confirmer",
    onCancel: () => ({}),
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  state = {
    open: false,
  };

  handleClick = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false });

  handleOk = () => {
    this.handleClose();
    this.props.onAccept();
  };

  handleCancel = () => {
    this.handleClose();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  render() {
    const { children, message, titre, ok, cancel } = this.props;
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="confirmation-dialog-title">
          <DialogTitle id="confirmation-dialog-title">{titre}</DialogTitle>
          <DialogContent>
            <Typography variant="subheading">{message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel}>{cancel}</Button>
            <Button type="submit" onClick={this.handleOk} variant="outlined" color="primary">
              {ok}
            </Button>
          </DialogActions>
        </Dialog>
        {React.cloneElement(children, { onClick: this.handleClick })}
      </div>
    );
  }
}

export default ConfirmWrapper;
