import React from "react";
import NumberFormat from "react-number-format";
import headerStyle from "../../common/tableHeaderStyle";
import paddedTableHeaderStyle from "../../common/paddedTableHeaderStyle";

const columnData = [
  {
    Header: "Id invest.",
    accessor: "idemprunt",
    width: 100,
    headerStyle,
  },
  {
    Header: "Id Actif.",
    accessor: "idactifpatrimonial",
    width: 75,
    headerStyle,
  },
  {
    Header: "Adresse",
    accessor: "adresse",
    headerStyle: paddedTableHeaderStyle,
  },
  {
    Header: "Capital restant",
    accessor: "capital_restant",
    width: 150,
    headerStyle: paddedTableHeaderStyle,
    Cell: props => (
      <NumberFormat
        value={parseInt(props.value, 10)}
        displayType={"text"}
        thousandSeparator=" "
        suffix={" €"}
      />
    ),
  },
];

export default columnData;
