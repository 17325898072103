import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../../pages/withGlobals";
import ReactTable from "react-table";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import headerStyle from "../../common/tableHeaderStyle";
import AjoutBanqueDialog from "../../common/AjoutBanqueDialog";
import ModifierBanqueDialog from "./ModifierBanqueDialog";

class DialogGestionDesBanques extends React.Component {
  state = {
    dialogOpen: true,
    idbanque: 0,
    image64: '',
    nom_banque: '',
    banques: [],
    dialogAddBanqueOpen: false,
    dialogModifierBanqueOpen: false,
  };

  componentDidMount = () => {
    this.props.fetch({ url: "/banques/" }).then(banques => {
      this.setState({
          banques : banques.filter(b => (b.is_deleted == false && b.nom_banque != ' Non affectee')).sort(function(a, b){
    if(a.nom_banque < b.nom_banque) { return -1; }
    if(a.nom_banque > b.nom_banque) { return 1; }
    return 0;
})
        });
    });
  };
  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
    window.location = "/";
  };


handleDelete = (e, index) => {
    e.stopPropagation();
    if (
      //eslint-disable-next-line
      confirm(
        `Voulez-vous vraiment supprimer la banque `+ this.state.banques[index]['nom_banque'],
      )
    ) {
       this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          deleteBanque: "OUI",
          idbanque: this.state.banques[index]['idbanque']
        }
      })
      .then(parametrage => {

this.props.fetch({ url: "/banques/" }).then(banques => {
      this.setState({
          banques : banques.filter(b => (b.is_deleted == false && b.nom_banque != 'Non affectee'))
        });
    });

 
      })
      .catch(err => console.log(err));
    }
  };

  handleEdit = (e, index) => {
    e.stopPropagation();
    this.setState({idbanque: this.state.banques[index]['idbanque'] });
    this.setState({nom_banque: this.state.banques[index]['nom_banque'] });
    this.setState({image64: this.state.banques[index]['image64'] });
console.log(this.state.nom_banque);
    this.handleOpenModifierBanqueDialog(); 
  };

handleOpenAddBanqueDialog = () => this.setState({ dialogAddBanqueOpen: true });

handleCloseAddBanqueDialog = () => this.setState({ dialogAddBanqueOpen: false });

handleOpenModifierBanqueDialog = () => this.setState({ dialogModifierBanqueOpen: true });

handleCloseModifierBanqueDialog = () => this.setState({ dialogModifierBanqueOpen: false });




handleBanqueModifiee = banqueAjoutee => {
this.componentDidMount();
};


  render() {
    const { open, handleClose } = this.props;
const { dialogAddBanqueOpen, dialogModifierBanqueOpen } = this.state;
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >

        <form onSubmit={this.handleSubmit}>

          <DialogTitle id="form-dialog-title">Paramètrage</DialogTitle>
          <DialogContent>

<AjoutBanqueDialog
open={dialogAddBanqueOpen}
onBanqueAjoutee={this.handleBanqueAjoutee}
handleClose={this.handleCloseAddBanqueDialog}
/>

<ModifierBanqueDialog
idbanque={this.state.idbanque}
image64={this.state.image64}
nom_banque={this.state.nom_banque}
open={dialogModifierBanqueOpen}
onBanqueModifiee={this.handleBanqueModifiee}
handleClose={this.handleCloseModifierBanqueDialog}
/>


            <Grid
              container
              justify="space-around"
              alignItems="flex-start"
              direction="row"
              spacing={16}
            >
              {(
                <Grid item xs={12}>

                </Grid>
              )}
              <Grid item xs={12}>
                  Gestion des banques
              </Grid>


            </Grid>


		<Grid item xs={12}>
			<ReactTable
        data={this.state.banques.map((t, index) => {
          return { ...t};
        })}
        columns={[
          {
            Header: "Banque",
            accessor: "nom_banque",
            width: 350,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
          {
            Header: "Actions",
            accessor: "nom_banque",
            Cell: row => {
              return (
                <span>
                  <IconButton aria-label="Delete" onClick={e => this.handleDelete(e, row.index)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton aria-label="Delete" onClick={e => this.handleEdit(e, row.index)}>
                    <EditIcon />
                  </IconButton>
                </span>
              );
            },
            headerStyle: {
              ...headerStyle,
              textAlign: "right",
              paddingRight: "20px",
            },
          },
        ]}
        noDataText="Aucune donnée"
        showPagination={false}
        pageSize={this.state.banques.length}
        className="-striped -highlight noScroll"
      />
		</Grid>

            <DialogActions>
              <Button onClick={this.handleCloseDialog} color="textSecondary">
                Annuler
              </Button>
              <Button
                onClick={this.handleOpenAddBanqueDialog}
                variant="raised"
                color="primary"
              >
                + Créer une banque
              </Button>

            </DialogActions>

          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withGlobals(DialogGestionDesBanques);

