import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import withGlobals from "../../pages/withGlobals";
import { withStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
import NumberFormat from "react-number-format";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import headerStyle from "../../common/tableHeaderStyle";
import AddIcon from "@material-ui/icons/Add";



class DialogInfoBulle extends React.Component {
  state = {
     historiques: [] 
  };




  handleCloseDialog = () => {
    this.props.handleClose();
  };


  componentDidMount = () => {
    if (this.props.idbail && this.props.idbail != undefined) {
     this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          historique_indexation: "GET",
          idbail: this.props.idbail
        }
      })
      .then(result => {
      this.setState({
          historiques : result
        });

     })
      .catch(err => console.log(err));
}
  };





  render() {
    const { idbail, open, handleClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >

        <form onSubmit={this.handleSubmit}>

          <DialogTitle id="form-dialog-title">Historique des Indexations du bail {idbail}</DialogTitle>
          <DialogContent>
<Grid item xs={12}>
                        <ReactTable
        data={this.state.historiques.map((t, index) => {
          return { ...t};
        })}
        columns={[
		  {
            Header: "Id Bail",
            accessor: "idbail",
            width: 100,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
		  {
            Header: "Date",
            accessor: "date",
            width: 200,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value.substr(0, 10)}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
		  {
            Header: "Loyer actuel",
            accessor: "loyer_actuel",
            width: 120,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
		  {
            Header: "Loyer actuel indexé",
            accessor: "loyer_actuel_indexe",
            width: 150,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
		  {
            Header: "Indice de référence",
            accessor: "indice_de_reference",
            width: 155,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
		  {
            Header: "Trimestre",
            accessor: "trimestre",
            width: 100,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
		  {
            Header: "Année",
            accessor: "annee",
            width: 100,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
		  {
            Header: "Tunnel d'indexation",
            accessor: "tunnel_indexation",
            width: 150,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
		  {
            Header: "Indexation appliquée",
            accessor: "indexation_appliquee",
            width: 170,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
                  {
            Header: "Ancien Indice",
            accessor: "ancien_indice",
            width: 150,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },
                  {
            Header: "Nouvel Indice",
            accessor: "nouvel_indice",
            width: 150,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "right" },
          },

        ]}
        noDataText="Aucune donnée"
        showPagination={false}
        pageSize={this.state.historiques.length}
        className="-striped -highlight noScroll"
      />
    </Grid>

            <DialogActions>
              <Button onClick={this.handleCloseDialog} color="textSecondary">
                Annuler
              </Button>
            </DialogActions>

          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withGlobals(DialogInfoBulle);

