import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import withGlobals from "../pages/withGlobals";
import RechercheForm from "./RechercheForm";

const styles = theme => ({
  page: {
    padding: theme.spacing.unit * 2,
    border: "solid 1px silver"
  },
  liste: {
    padding: theme.spacing.unit
  }
});

class ListItemsMobile extends React.Component {
  static propTypes = {
    queryPathname: PropTypes.string.isRequired,
    moduleRoute: PropTypes.string.isRequired,
    lastFiltre: PropTypes.string
  };

  static defaultProps = {
    lastFiltre: ""
  };

  constructor(props) {
    super(props);
    this.state = {
      datas: this.props.datas || []
    };
  }

  componentDidMount = () => {
    if (!this.state.datas.length > 0) {
      this.loadDatas(this.props);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.location.search !== nextProps.location.props) {
      // this.loadDatas(nextProps);
    }
  };

  searchDatas = (filter) => {
    const {idField} = this.props;
    this.props.fetch({ url: `${this.props.queryPathname}${filter}` }).then(datas => {
      this.setState({
        datas: this.props.dataMapper ? this.props.dataMapper(datas) : datas
      });
      const { history, moduleRoute } = this.props;
      if (this.state.datas.length > 0) {
      const id = this.state.datas[0][idField];
      if (this.state.datas.length == 1 && (this.props.location.pathname != `/${moduleRoute}/${id}`)) {
         this.handleClickOneItem(id);
      }
    }
   }
    );
  };

  loadDatas = ({ location: { search }, queryPathname, lastFiltre }) => {
    if (!queryPathname) return;
    let lF = lastFiltre;
    if (lF) {
      lF = search ? lF : lF.replace("&", "?");
    }
    const {idField} = this.props;
    this.props.fetch({ url: `${queryPathname}${search}${lF}` }).then(datas => {
      this.setState({
        datas: this.props.dataMapper ? this.props.dataMapper(datas) : datas
      });
    const { history, moduleRoute } = this.props;
    if (this.state.datas.length > 0) {
      const id = this.state.datas[0][idField];
    if (this.state.datas.length == 1 && (this.props.location.pathname != `/${moduleRoute}/${id}`)) {
      this.handleClickOneItem(id);
    }
}

   }
    );
  };

  handleClick = id => {
    const { history, moduleRoute } = this.props;
    history.push(`/${moduleRoute}/${id}`);
  };

  handleClickOneItem = id => {
    const { history, moduleRoute } = this.props;
    if (this.props.location.search.indexOf("fromLocal") >= 0) {
	    history.push(`/${moduleRoute}s/+${id}/${this.props.location.search.slice(10+this.props.location.search.indexOf("fromLocal"))}`);
    } else {
	    history.push(`/${moduleRoute}/+${id}`);
    }
  };

  render() {
    const { buildPrimaryText, buildSecondaryText, idField, idFieldName, moduleRoute, societe } = this.props;
    return (
      <Grid container direction="column">
        <Grid>
        {this.props.sameFormSearch && (<RechercheForm
          idField={idField}
          idFieldName={idFieldName}
          moduleRoute={moduleRoute}
          onSearch={this.searchDatas}
        /> )}
        </Grid>
        <Grid item className={this.props.classes.liste}>
          <List>
            {this.state.datas.map((a, idx) => (
              <ListItem
                key={idx}
                className={this.props.classes.page}
                button
                onClick={() => this.handleClick(a[idField])}
              >
                <ListItemText
                  primary={buildPrimaryText(a)}
                  secondary={<div><div>{buildSecondaryText(a)}</div><div>{a.entite}</div></div>}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        {this.state.datas.length === 0 && (
          <Grid item>Aucun résultat pour cette recherche</Grid>
        )}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(ListItemsMobile)));
