import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MyEditor from "./AvatarEditor";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import withGlobals from "../withGlobals";
const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    width: 200
  },
  button: {
    marginTop: theme.spacing.unit * 2
  }
});

class Profil extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    openNotification: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { ...this.props.user };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { nom, prenom, telephone, image64 } = this.state;
    this.props
      .fetch({
        url: "/users/1",
        method: "PUT",
        body: {
          nom,
          prenom,
          telephone,
          image64
        }
      })
      .then(res => {
        this.props.openNotification("success", "Profil modifié", false);
        return this.props.onSave(res);
      });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleImageChanged = image64 => {
    this.setState({ image64 });
  };

  render() {
    const { nom, prenom, telephone } = this.state;
    const { classes, openNotification, user } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={8}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={16}
          >
            <Grid item>
              <MyEditor
                image={this.props.user.image64}
                onImageChanged={this.handleImageChanged}
                openNotification={openNotification}
                userId={user.id}
              />
            </Grid>
            <Grid item>
              <form onSubmit={this.handleSubmit}>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                  spacing={8}
                >
                  <Grid item>
                    <TextField
                      id="nom"
                      value={nom}
                      name="nom"
                      label="Nom"
                      type="text"
                      margin="dense"
                      required
                      fullWidth
                      className={classes.textField}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="prenom"
                      value={prenom}
                      name="prenom"
                      label="Prénom"
                      type="text"
                      margin="dense"
                      required
                      fullWidth
                      className={classes.textField}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="telephone"
                      value={telephone}
                      name="telephone"
                      label="Téléphone"
                      type="text"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="raised"
                  color="primary"
                  type="submit"
                  className={classes.button}
                >
                  Sauvegarder
                </Button>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withGlobals(Profil));
