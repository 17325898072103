import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../pages/withGlobals";
import emailFormater from "../helpers/emailFormater";

const styles = theme => {
  return {
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%",
    },
    dialogTitle: {
      paddingBottom: theme.spacing.unit,
      textAlign: "center",
    },
  };
};

class EnvoyerParMailDialog extends Component {
  static propTypes = {
    objet: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      objet: this.props.objet,
      message: "",
      destinataire: "",
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSend = e => {
    e.preventDefault();
    const datas = emailFormater(this.props.template, this.props.datas);
    const dest = this.state.destinataire;
    const from = this.props.user.login;
    const subject = this.state.objet;
    const message = this.state.message;
    const template_id = this.props.template === 'actif' ?
      'template_6dToVqO8_clone' :
      (this.props.template === 'local' ?
        'template_6dToVqO8_clone_clone' : 'template_6dToVqO8_clone_clone_clone');

    fetch('https://api.emailjs.com/api/v1.0/email/send', {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        service_id: 'gmail',
        template_id,
        user_id: 'user_M0uFEewrBFNPFGkgwYAjJ',
        template_params: {
          dest,
          from,
          subject,
          message,
          ...datas
        }
      }),
    })
      .catch(error => console.error('Error:', error))
      .then(response => {
        console.log('Success:', JSON.stringify(response));
        this.props.handleClose();
      });
  };

  render() {
    const { open, classes } = this.props;
    const { message, objet, destinataire } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form onSubmit={this.handleSend}>
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Envoyer par mail
          </DialogTitle>
          <DialogContent>
            <Grid container alignItems="center" justify="center" direction="row" spacing={16}>
              <Grid item md={12}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="Destinataire"
                  name="destinataire"
                  defaultValue={destinataire}
                  onChange={this.handleChange}
                  label="Destinataire"
                  type="email"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="Objet"
                  name="objet"
                  defaultValue={objet}
                  onChange={this.handleChange}
                  label="Objet du message"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  required
                  margin="dense"
                  id="message"
                  name="message"
                  multiline
                  rowsMax="4"
                  defaultValue={message}
                  onChange={this.handleChange}
                  label="message"
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="textSecondary">
              Annuler
            </Button>
            <Button type="submit" variant="raised" color="primary">
              Envoyer ce message
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withGlobals(EnvoyerParMailDialog));
