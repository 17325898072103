import React from "react";
import Highcharts from "highcharts/highstock";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import HighchartsReact from "highcharts-react-official";
import withGlobals from "../withGlobals";
import CouvertureSwitch from "./couvertureSwitch";

const styles = theme => ({
  table: {
    color: "#333",
    fontFamily: "Roboto, Helvetica, Arial, 'sans-serif'",
    width: "940px",
    borderCollapse: "collapse",
    borderSpacing: 0,
    marginBottom: "3em",
    marginTop: "3em",
  },
  td: {
    border: "1px solid #CCC",
    height: "30px",
    background: "#FAFAFA",
    textAlign: "center",
  },
  th: {
    border: "1px solid #CCC",
    height: "30px",
    background: "#F3F3F3",
    fontWeight: "bold",
  },
});

class Dscr extends React.Component {
  state = {
    datas: null,
    avecCouverture: true,
  };

  componentDidMount() {
    this.loadDatas();
  }

  loadDatas = () => {
    this.setState({ datas: null });
    this.props
      .fetch({
        url: `/rapports/dscr/${this.props.idamortissement}?couverture=${
          this.state.avecCouverture ? "true" : "false"
        }`,
      })
      .then(datas => this.setState({ datas }));
  };

  handleToggleCouverture = () => this.setState({ avecCouverture: !this.state.avecCouverture });

  componentDidUpdate(prevProps, prevState) {
    if (prevState.avecCouverture !== this.state.avecCouverture) {
      this.loadDatas();
    }
  }

  render() {
    const { datas } = this.state;
    const { classes } = this.props;
    if (!datas)
      return (
        <Grid container direction="column" alignItems="center" justify="center" spacing={0}>
          <Grid item>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      );
    const options = {
      title: `DSCR de l'emprunt ${this.props.idemprunt}`,
      yAxis: {
        labels: {
          formatter: obj => `${obj.value} %`,
        },
        title: {
          text: "Valeur DSCR",
        },
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: datas[0].annee_emprunt,
        },
      },
      series: [
        {
          name: "Dscr Actuel",
          data: datas.map(d => d.dscr_actuel),
        },
        {
          name: "Dscr facial",
          data: datas.map(d => d.dscr_facial_acte),
        },
        {
          name: "Dscr potentiel",
          data: datas.map(d => d.dscr_potentiel),
        },
      ],
    };
    return (
      <div>
        <CouvertureSwitch checked={this.state.avecCouverture} handleToggle={this.handleToggleCouverture} />
        <HighchartsReact highcharts={Highcharts} constructorType={"chart"} options={options} />
        <table className={classes.table}>
          <thead>
            <tr>
              <td />
              {datas.map(data => <th className={classes.th}>{data.annee_emprunt}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className={classes.th}>DSCR Actuel</th>
              {datas.map(data => <td className={classes.td}>{data.dscr_actuel} %</td>)}
            </tr>
            <tr>
              <th className={classes.th}>DSCR Facial</th>
              {datas.map(data => <td className={classes.td}>{data.dscr_facial_acte} %</td>)}
            </tr>
            <tr>
              <th className={classes.th}>DSCR Potentiel</th>
              {datas.map(data => <td className={classes.td}>{data.dscr_potentiel} %</td>)}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withGlobals(withStyles(styles)(Dscr));
