import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import withGlobals from "../withGlobals";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
    // maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  progressMsg: {
    fontSize: "1.3em",
    fontWeight: "bold",
  },
  downloadBtn: {
    minWidth: 150,
  },
  downloadBtnBig: {
    minWidth: 250,
  },
  titreSection: {
    padding: theme.spacing.unit,
    backgroundColor: "#eae0ad",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Synthese extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banques: [],
      societes: [{"idsociete":1,"filiale":false,"nom_societe":"Pardes Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null},{"idsociete":2,"filiale":false,"nom_societe":"Forum Patrimoine","nom_commercial":null,"adresse":null,"code_postal":null,"ville":null,"nom_responsable":null,"tel":null,"gsm":null,"statut_juridique":null,"detention_pourcentage":0,"logo_societe":null,"idsociete_mere":0,"attach_file":null}],
      selectionBanque: "",
      selectionSociete: "",
      idsociete: null,
      sansDonneesFinancement: false,
      sansCouvertureDeTaux: false,
      inclureCA: false,
      inclureCRD: false,
      generating: false,
      generated: false,
      globale: false,
      banqueSelected: null,
      etapes: [],
      etapeEnCours: null,
      generationResult: null,
    };
  }

  componentDidMount = () => {
    this.props.fetch({ url: `/banques/` }).then(banques => {
//      this.props.fetch({ url: `/societes/` }).then(societes => {
        //this.setState({societes, banques });
this.setState({banques });
//      });
    });
  };

  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
      generating: false,
      generated: false,
    });

  handleChangeCB = name => event => {
    this.setState({
      [name]: event.target.checked,
      generating: false,
      generated: false,
    });
  };

  handleReset = () =>
    this.setState({
      globale: false,
      sansDonneesFinancement: false,
      sansCouvertureDeTaux: false,
      idsociete: null,
      selectionBanque: "",
      selectionSociete: "",
      inclureCA: false,
      inclureCRD: false,
      generating: false,
      generated: false,
      etapes: [],
      etapeEnCours: null,
    });

  generateRapporQuery = () => {
    const { sansDonneesFinancement, sansCouvertureDeTaux } = this.state;
    if (this.state.type === "global") {
      return "";
    } else {
      const financement = `sansFinancement=${sansDonneesFinancement ? "1" : "0"}`;
      const couverture = `sansCouverture=${sansCouvertureDeTaux ? "1" : "0"}`;
const banqueSelected = this.state.banques.find(b => b.nom_banque === this.state.selectionBanque);
      const banque = this.state.selectionBanque !== "" ? `&idbanque=${banqueSelected.idbanque}` : "";
      return `?${financement}&${couverture}${banque}`;
    }
  };

  buildUi = () => {
    const { classes, theme } = this.props;
    const {
      banques,
      societes,
      globale,
      inclureCRD,
      inclureCA,
      selectionBanque,
      selectionSociete,
    } = this.state;
    return (
      <Grid container alignItems="flex-start" justify="flex-start" direction="column">
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={globale}
                onChange={this.handleChangeCB("globale")}
                value="globale"
                color="primary"
              />
            }
            label="Globale"
          />
        </Grid>
        {!globale && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sansCouvertureDeTaux}
                  onChange={this.handleChangeCB("sansCouvertureDeTaux")}
                  value="sansCouvertureDeTaux"
                  color="primary"
                />
              }
              label="Sans couverture de taux"
            />
          </Grid>
        )}
        {!globale && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sansDonneesFinancement}
                  onChange={this.handleChangeCB("sansDonneesFinancement")}
                  value="sansDonneesFinancement"
                  color="primary"
                />
              }
              label="Sans données financières"
            />
          </Grid>
        )}
        {!globale && (
          <Grid item>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-banques">Société</InputLabel>
              <Select
                value={selectionSociete}
                name="selectionSociete"
                onChange={this.handleChange}
                MenuProps={MenuProps}
              >
                {societes.map(societe => (
                  <MenuItem
                    key={societe.idsociete}
                    value={societe.idsociete}
                    style={{
                      fontWeight:
                        this.state.societes.indexOf(societe) === -1
                          ? theme.typography.fontWeightRegular
                          : theme.typography.fontWeightMedium,
                    }}
                  >
                    {societe.nom_societe}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {!globale && (
          <Grid item>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="select-banque">Banque</InputLabel>
              <Select
                fullWidth
                value={selectionBanque}
                name="selectionBanque"
                onChange={this.handleChange}
                MenuProps={MenuProps}
              >
                {banques.map(banque => (
                  <MenuItem
                    key={banque.idbanque}
                    value={banque.nom_banque}
                    style={{
                      fontWeight:
                        this.state.banques.indexOf(banque) === -1
                          ? theme.typography.fontWeightRegular
                          : theme.typography.fontWeightMedium,
                    }}
                  >
                    <img src={"data:image/jpeg;" + banque.image64} style={{height: 25, paddingTop: 5}}/> {banque.nom_banque}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={inclureCA}
                onChange={this.handleChangeCB("inclureCA")}
                value="inclureCA"
                color="primary"
              />
            }
            label="Inclure les CA par activité, par région et par arrondissement"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={inclureCRD}
                onChange={this.handleChangeCB("inclureCRD")}
                value="inclureCRD"
                color="primary"
              />
            }
            label="Inclure le CRD par banque"
          />
        </Grid>

      </Grid>
    );
  };

  getEtapes = () => {
    const {
      globale,
      sansDonneesFinancement,
      sansCouvertureDeTaux,
      selectionSociete,
      selectionBanque,
      inclureCA,
      inclureCRD
    } = this.state;
    const etapes = [
      {
        nom: "Calcul des données",
        url: "/rapports/",
      },
    ];
    if (globale) {
      etapes.push({
        nom: "Génération de la synthèse",
        url: "/synthese.pdf?sansDonneesFinancement=0&sansCouvertureDeTaux=0",
      });
      etapes.push({ nom: "Génération du rapport vacants", url: "/rapport_vacants.pdf" });
      etapes.push({ nom: "Génération de synthese générale", url: "/synthese_generale.pdf" });
      if (inclureCA) {
        etapes.push({ nom: "Génération du rapport CA", url: "/rapport_ca.pdf" });
      }
      if (inclureCRD) {
        etapes.push({ nom: "Génération du rapport CRD", url: "/crd_banque.pdf" });
      }

      etapes.push({
        nom: "Génération DSCR",
        url: "/rapport_dscr.pdf?idsociete=1",
      });
      etapes.push({ nom: "Assemblage du document", url: `/generate?query=convinent${inclureCA ? "&avecCa=true" : ""}${
                  inclureCRD ? "&avecCrd=true" : ""
                }` });
    } else {
      let querySociete = "";
      if (selectionSociete) {
        querySociete = `idsociete=${selectionSociete}`;
      }
      let queryBanque = "";
      let queryBanqueId = "";
      if (selectionBanque) {
        queryBanque = `banque=${selectionBanque}`;
        const banque = this.state.banques.find(b => b.nom_banque === selectionBanque);
        queryBanqueId = `idbanque=(${banque.idbanque})`;
      }
      etapes.push({
        nom: "Génération de la synthèse",
        url: `/synthese.pdf?sansDonneesFinancement=${
          sansDonneesFinancement ? "1" : "0"
        }&sansCouvertureDeTaux=${sansCouvertureDeTaux ? "1" : "0"}${querySociete ? `&${querySociete}` : ""}${
          queryBanque ? `&${queryBanqueId}` : ""
        }`,
      });
      etapes.push({
        nom: "Génération du rapport vacants",
        url: `/rapport_vacants.pdf${querySociete ? `?${querySociete}` : ""}${
          queryBanque ? `&${queryBanqueId}` : ""
        }`,
      });
      etapes.push({
        nom: "Génération de synthese générale",
        url: `/synthese_generale.pdf${querySociete ? `?${querySociete}` : ""}${
          queryBanque ? `&${queryBanqueId}` : ""
        }`,
      }); /*
      etapes.push({
        nom: "Génération DSCR",
        url: `/rapport_dscr.pdf${querySociete ? `?${querySociete}` : ""}`,
      });*/
      if (inclureCA) {
        etapes.push({ nom: "Génération du rapport CA", url: "/rapport_ca.pdf" });
      }
      if (inclureCRD) {
        etapes.push({
          nom: "Génération du rapport CRD",
          url: `/crd_banque.pdf${querySociete ? `?${querySociete}` : ""}${(querySociete && queryBanque) ? `&${queryBanqueId}` : ""}${(queryBanque && !querySociete) ? `?${queryBanqueId}` : ""}`,
        });
      }
      etapes.push({ nom: "Assemblage du document", url: `/generate?query=convinent${inclureCA ? "&avecCa=true" : ""}${
                  inclureCRD ? "&avecCrd=true" : ""
        }` });
    }
    return etapes;
  };

  generate = () => {
    // bon un peu à la bourre, c'est fait à l'arrache
    // mais ça fonctionne
    const etapes = this.getEtapes();
    let i = 0;
    const { fetch, rendererHost } = this.props;
    this.setState({ etapeEnCours: etapes[i].nom, generating: true, generated: false });
      i++;
      if (etapes[i]) {
        this.setState({ etapeEnCours: etapes[i].nom });
        fetch({ url: rendererHost + etapes[i].url }).then(() => {
          i++;
          if (etapes[i]) {
            this.setState({ etapeEnCours: etapes[i].nom });
            fetch({ url: rendererHost + etapes[i].url }).then(() => {
              i++;
              if (etapes[i]) {
                this.setState({ etapeEnCours: etapes[i].nom });
                fetch({ url: rendererHost + etapes[i].url }).then(() => {
                  i++;
                  if (etapes[i]) {
                    this.setState({ etapeEnCours: etapes[i].nom });
                    fetch({ url: rendererHost + etapes[i].url }).then(() => {
                      i++;
                      if (etapes[i]) {
                        this.setState({ etapeEnCours: etapes[i].nom });
                        fetch({ url: rendererHost + etapes[i].url }).then(() => {
                          i++;
                          if (etapes[i]) {
                            this.setState({ etapeEnCours: etapes[i].nom });
                            fetch({ url: rendererHost + etapes[i].url }).then(() => {
                              i++;
                              if (etapes[i]) {
                                  this.setState({ etapeEnCours: etapes[i].nom });
                                  fetch({ url: rendererHost + etapes[i].url }).then(() => {
                                      this.setState({ generated: true, generating: false });
                                  });
                              } else {
                                  this.setState({ generated: true, generating: false });
                              }                              
                            });
                          } else {
                            this.setState({ generated: true, generating: false });
                          }
                        });
                      } else {
                        this.setState({ generated: true, generating: false });
                      }
                    });
                  } else {
                    this.setState({ generated: true, generating: false });
                  }
                });
              } else {
                this.setState({ generated: true, generating: false });
              }
            });
          } else {
            this.setState({ generationComplete: true });
          }
        });
      } else {
        this.setState({ generationComplete: true });
      }
  };

  render() {
    const { classes } = this.props;
    const { generating, generated, inclureCRD, inclureCA, etapeEnCours} = this.state;
    
    //######## config  #####
     const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export";
    
    return [
      !generated && !generating ? this.buildUi() : null,
      generating ? (
        <Grid item>
          <Grid container alignItems="center" justify="center" direction="row">
            <Grid item className={classes.progressMsg}>
              {etapeEnCours}
            </Grid>
            <Grid item>
              <CircularProgress className={classes.progress} size={50} />
            </Grid>
          </Grid>
        </Grid>
      ) : null,
      false && generated ? <ul>{this.getEtapes().map(e => <li>{e.url}</li>)}</ul> : null,
      generated ? (
        <Grid item>
          <Grid container direction="column" spacing={8} justify="center" alignItems="stretch">
            <Grid item>
              <Button
                color="default"
                variant="raised"
                href={`${rapportsUrl}/synthese_complete.pdf`}
                target="__blank"
                className={classes.downloadBtn}
              >
                Télécharger le rapport complet
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null,
      !generated && !generating ? (
        <Grid item style={{ marginTop: "4em" }}>
          <Button color="primary" variant="raised" onClick={this.generate}>
            Générer le rapport
          </Button>
        </Grid>
      ) : null,
      generated ? (
        <Grid item style={{ marginTop: "4em" }}>
          <Button color="primary" variant="raised" onClick={this.handleReset}>
            Nouveau rapport
          </Button>
        </Grid>
      ) : null,
    ];
  }
}

export default withGlobals(withStyles(styles, { withTheme: true })(Synthese));
