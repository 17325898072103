import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import withGlobals from "../withGlobals";

class DatasLoader extends Component {
  static propTypes = {
    locaux: PropTypes.array.isRequired,
    idactifpatrimonial: PropTypes.number,
    children: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      locaux: props.locaux,
      surface_totale_actif: null,
      adresse: null,
      loading: true,
      local: {
        idactifpatrimonial: props.idactifpatrimonial,
        surface_rdc: 0,
        surface_etage: 0,
        surface_sous_sol: 0
      }
    };
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.idactifpatrimonial !== nextProps.idactifpatrimonial) {
      this.loadActifInfos(nextProps.idactifpatrimonial);
    }
  };

  componentDidMount = () => {
    const { idactifpatrimonial } = this.props;
    if (idactifpatrimonial) {
      this.loadActifInfos(idactifpatrimonial);
    } else {
      this.setState({ loading: false });
    }
  };

  loadActifInfos = actifId => {
    if (actifId) {
      this.setState({ loading: true });
      this.props.fetch({ url: `/actifs/${actifId}` }).then(actif => {
        const { surface_totale_actif, code_postal, ville, adresse } = actif;
        this.props.fetch({ url: `/locaux/actifs/${actifId}` }).then(locaux => {
          this.setState({
            idactifpatrimonial: actifId,
            locaux,
            surface_totale_actif,
            adresse: `${adresse} ${code_postal} ${ville}`,
            loading: false
          });
        });
      });
    } else {
      console.log('Try to fetch /actifs/{actifId} but actifId is not defined');
    }
  };

  onActifSelected = local => {
    this.setState(local);
    this.props.history.push(
      `/locaux/nouveau?actifId=${local.idactifpatrimonial}`
    );
  };

  render() {
    const { locaux, loading, local } = this.state;
    if (loading) {
      return <CircularProgress size={50} />;
    }

    const surface_autres_biens = locaux.reduce(
      (m, l) =>
        m +
        (l.surface_totale ||
          l.surface_rdc + l.surface_etage + l.surface_sous_sol),
      0
    );
    let props = {};
    props = {
      surface_totale_actif: this.state.surface_totale_actif,
      surface_occupee: surface_autres_biens || 0,
      adresse: this.state.adresse,
      onActifSelected: this.onActifSelected,
      filtred: false,
      locaux: [local].concat(locaux),
      local: {
        ...local,
        idactifpatrimonial: this.props.idactifpatrimonial
          ? this.props.idactifpatrimonial
          : local.idactifpatrimonial
      }
    };

    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, { ...props })
    );
  }
}

export default withRouter(withGlobals(DatasLoader));
