import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

class ListeActifsDialog extends Component {
  handleClick = id => {
    const { history, baseUrl, handleClose } = this.props;
    history.push(`/${baseUrl}/${id}`);
    handleClose();
  };
  render() {
    const { actifs, open, handleClose, titre, baseUrl } = this.props;
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="list-actif-title">
        <DialogTitle id="list-actif-title">{titre}</DialogTitle>
        <DialogContent>
          <List component="nav">
            {actifs.map(actif => (
              <ListItem key={actif.id} button component={Link} to={`${baseUrl}${actif.id}`}>
                {actif.label}
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withRouter(ListeActifsDialog);
