import React from "react";
import { withRouter } from "react-router-dom";

class HotkeyManager extends React.Component {
  componentWillReceiveProps = nextProps => {
    if (nextProps.shortCutPressed !== null && this.props.shortCutPressed !== nextProps.shortCutPressed) {
      this.props.history.push(`/${nextProps.shortCutPressed}`);
      this.props.clearShortcut();
    }
  };
  render() {
    return null;
  }
}

export default withRouter(HotkeyManager);
