import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Amortissements from "./Amortissements";
import Couverture from "./Couverture";
import Parametrage from "./Parametrage";
import Dscr from "./Dscr";
import Ltv from "./Ltv";
import Ltc from "./Ltc";
import DownloadIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import withGlobals from "../withGlobals";

const styles = theme => ({
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
    backgroundColor: "#FFF",
  },
  tabsIndicator: {
    backgroundColor: "gray",
  },
  tabSelected: {
    color: "white",
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: "1em" }}>
      {props.children}
    </Typography>
  );
}

class AmortissementCouverture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      maj: false
    };
  }

  static defaultProps = {
    layout: "desktop",
  };

  handleChange = (event, index) => {
    this.setState({ index });
  };

  calculateIdx = idx => (!this.props.couverture || this.props.couverture.length === 0 ? idx - 1 : idx);

  majFinanceActive = () => {
    this.setState({ maj: true});
    this.props
    .fetch({
      url: "/banques/",
      method: "POST",
      body: {
        id_tranche_madar: this.props.id_tranche_madar,
        idFinanceActive: this.props.identifiant_transaction_finance_active,
        idemprunt: this.props.emprunt.idemprunt,
        index_tranche: this.props.trancheIndex,
        majFinanceActive: "MAJ"
      }
    })
    .then(result => {
      this.setState({ maj: false});
      this.props.openNotification(
        "success",
        `Les amortissements ont été mis à jour.`,
        undefined,
        3000,
      );
    })
    .catch(err => 
      {
        this.setState({ maj: false});
        this.props.openNotification(
      "success",
      `Les amortissements ont été mis à jour.`,
      undefined,
      3000,
    );
      });
  }

  render() {
    const { amortissements, emprunt, couverture, classes, layout } = this.props;
    const { index } = this.state;
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick = {this.majFinanceActive}
          style={{ float: "right"}}
          disabled={this.state.maj}
        >
          <DownloadIcon />
          MAJ FINANCE ACTIVE
        </Button>
        <AppBar position="static">
          <Tabs
            value={index}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Tableau amortissement"
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
            {couverture &&
              couverture.length > 0 && (
                <Tab
                  label="Couverture"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
              )}
            <Tab label="PARAMETRAGE" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
            <Tab label="DSCR" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
            <Tab label="LTV" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
            <Tab label="LTC" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          </Tabs>
        </AppBar>
        {index === 0 && (
          <TabContainer>
            <Amortissements layout={layout} frequence={emprunt.frequence} amortissements={amortissements} />
          </TabContainer>
        )}
        {this.props.couverture &&
          this.props.couverture.length > 0 &&
          index === 1 && (
            <TabContainer>
              <Couverture couverture={couverture} frequence={emprunt.frequence} />
            </TabContainer>
          )}
        {index === this.calculateIdx(3) && (
          <TabContainer>
            <Dscr
              couverture={couverture && couverture.length > 0}
              idamortissement={emprunt.idamortissement}
              idemprunt={emprunt.idemprunt}
            />
          </TabContainer>
        )}
        {index === this.calculateIdx(2) && (
          <TabContainer>
            <Parametrage layout={layout} trancheIndex={this.props.trancheIndex} idemprunt={this.props.emprunt.idemprunt} idactifpatrimonial={this.props.emprunt.idactifpatrimonial} frequence={emprunt.frequence} amortissements={amortissements} />
          </TabContainer>
        )}
        {index === this.calculateIdx(4) && (
          <TabContainer>
            <Ltv
              couverture={couverture && couverture.length > 0}
              idamortissement={emprunt.idamortissement}
              idemprunt={emprunt.idemprunt}
            />
          </TabContainer>
        )}
        {index === this.calculateIdx(5) && (
          <TabContainer>
            <Ltc
              couverture={couverture && couverture.length > 0}
              idamortissement={emprunt.idamortissement}
              idemprunt={emprunt.idemprunt}
            />
          </TabContainer>
        )}
      </div>
    );
  }
}

export default withGlobals(withStyles(styles)(AmortissementCouverture));
