const filter = (props, state) => {
  const { datas, idField } = props;
  const {
    banque,
    societe,
    id,
    idlocal,
    idactifpatrimonial,
    codePostal,
    ville,
    adresse,
    surfaceTotale,
    biensNonAcquis,
    nom_du_locataire,
    nom_portefeuille,
  } = state;

  const datasSociete = societe ? datas.filter(b => b.idsociete === societe) : datas;
  const datasBanques = banque ? datasSociete.filter(b => b.idbanque === banque) : datasSociete;
  const datasSocieteF = datasBanques.filter(
    b => (biensNonAcquis ? b.date_acquisition === null : b.date_acquisition !== null),
  );

  const filter =
    id !== "" ||
    idlocal !== "" ||
    idactifpatrimonial !== "" ||
    codePostal !== "" ||
    ville !== "" ||
    adresse !== "" ||
    surfaceTotale !== "" ||
    nom_portefeuille !== "" ||
    nom_du_locataire !== "";
  const datasFiltred = filter
    ? datasSocieteF.filter(b => {
        let show = true;
        if (surfaceTotale !== "") {
          const [sC, value] = surfaceTotale.split(" ");
          const valueNum = parseInt(value, 10);
          if (!Number.isNaN(valueNum) && (sC === ">" || sC === "<")) {
            const val = parseInt(b.surface_totale || b.surface_totale_actif, 10);
            if (sC === ">") show = val > valueNum;
            if (sC === "<") show = val < valueNum;
          }
        }

        if (id !== "" && show) show = `${b.idlocaux}`.split(",").includes(id);
        if (idlocal !== "" && show) show = `${b["idlocal"]}` === idlocal;
        if (idactifpatrimonial !== "" && show) show = `${b["idactifpatrimonial"]}` === idactifpatrimonial;
        if (codePostal !== "" && show) show = `${b.code_postal}`.toUpperCase().indexOf(codePostal) !== -1;
        if (adresse !== "" && show) show = `${b.adresse.replaceAll('è', 'e').replaceAll('é', 'e').toLowerCase()}`.indexOf(adresse.replaceAll('è', 'e').replaceAll('é', 'e').toLowerCase()) !== -1;
        if (ville !== "" && show) show = `${b.ville}`.toUpperCase().indexOf(ville.toUpperCase()) !== -1;
        if (nom_du_locataire !== "" && show)
          show = `${b.nom_du_locataire}`.toLowerCase().indexOf(nom_du_locataire.toLowerCase()) !== -1;
        if (nom_portefeuille !== "" && show)
          show = `${b.nom_portefeuille}`.toLowerCase().indexOf(nom_portefeuille.toLowerCase()) !== -1;

        return show;
      })
    : datasSocieteF;

  return { datasFiltred, filter };
};

export default filter;
