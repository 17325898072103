import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import classNames from "classnames";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, redirect, onClose, variant, ...other } = props;
  const action = redirect ? (
    <Button color="primary" size="small" to={redirect} component={Link} variant="contained">
      OUI
    </Button>
  ) : null;
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      action={action}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
      }
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const MadarSnackbarContent = ({ variant, open, onClose, message, duration, redirect }) => (
  <Snackbar
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    open={open}
    onClose={onClose}
    autoHideDuration={duration}
    ContentProps={{
      "aria-describedby": "message-id",
    }}
  >
    <MySnackbarContentWrapper onClose={onClose} variant={variant} message={message} redirect={redirect} />
  </Snackbar>
);

MadarSnackbarContent.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  redirect: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

MadarSnackbarContent.defaultProps = {
  duration: 1500,
};

export default MadarSnackbarContent;
