import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../../pages/withGlobals";

class DialogExclusionVRs extends React.Component {
  state = {
    dialogOpen: true,
    vr_lissee_mode: "false",
    charge_forum: 0,
    charge_padre: 0
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
    window.location = "/";
  };


  componentDidMount = () => {
     this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          parametrage: "GET"
        }
      })
      .then(parametrage => {
this.setState({charge_forum: Number.parseFloat(parametrage[0]["charge_forum"]).toFixed(2)});
this.setState({charge_padre: Number.parseFloat(parametrage[0]["charge_padre"]).toFixed(2)});
this.setState({vr_lissee_mode: parametrage[0]["vr_lissee_mode"] == true ? "true" : "false"});
      })
      .catch(err => console.log(err));
  };

  handleSave = () => {
    this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          charge_forum: this.state.charge_forum,
          charge_padre: this.state.charge_padre,
          vr_lissee_mode: this.state.vr_lissee_mode == "true" ? true : false,
          parametrage: "charges"
        }
      })
      .then(res => {
        this.handleCloseDialog();
        this.props.openNotification("success", "Paramétrage modifié", false);
      })
      .catch(err =>  {
        this.handleCloseDialog();
        this.props.openNotification("success", "Paramétrage modifié", false);
      }
        );
  };

  render() {
    const { open, handleClose } = this.props;
    const vr_lissee_mode = this.state.vr_lissee_mode;
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">Paramètrage</DialogTitle>
          <DialogContent>
            <Grid
              container
              justify="space-around"
              alignItems="flex-start"
              direction="row"
              spacing={16}
            >
              {(
                <Grid item xs={12}>

                </Grid>
              )}
              <Grid item xs={12}>
              <Grid
                  container
                  alignItems="flex-start"
                  direction="row"
                  justify="center"
                  style={{ paddingLeft: "20px" }}
                >
                <Grid item xs={6}>
                  Exclusion des VR dans le calcul des DSCR
                </Grid>
                </Grid>
                </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  direction="row"
                  style={{ paddingLeft: "20px" }}
                >

                  <Grid item xs={6} style={{ minHeight: "50px" }}>

                  <input type="radio"
                  style={{marginLeft: '4rem'}}
                    name="vr_lissee_mode"
                    value="true"
                    checked={this.state.vr_lissee_mode === "true"}
                    onChange={e => {this.setState({vr_lissee_mode : e.target.value});}}
                  ></input> Oui
                  <input type="radio"
                  style={{marginLeft: '3rem'}}
                    name="vr_lissee_mode"
                    value="false"
                    checked={this.state.vr_lissee_mode === "false"}
                    onChange={e => {this.setState({vr_lissee_mode : e.target.value});}}
                  ></input> Non
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <Button onClick={this.handleCloseDialog}>
                Annuler
              </Button>
              <Button
                onClick={this.handleSave}
                variant="raised"
                color="primary"
              >
                Valider ce paramètrage
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withGlobals(DialogExclusionVRs);

