import React from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import withGlobals from "./withGlobals";
import Logo from "./logo.jpg";
import LogoPdf from "./pdf.png";
import LogoExcel from "./excel.png";
import LogoForum from "./forum.png";
import LogoPardes from "./pardes.png";
import GroupBy from "lodash/groupBy";
import { Button, Card, Row, Col } from "antd";
import {
  SyncOutlined,
} from '@ant-design/icons';


const styles = theme => ({
  title: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
  },
  container: {
  },
 notificationContainer: {
    overflowY: "auto"
  },
  notification: {
    padding: theme.spacing.unit * 1,
    backgroundColor: "#eaeaea",
    boxShadow: "3px 3px 3px gray",
    textAlign: "left",
    marginBottom: theme.spacing.unit * 2,
    minWidth: "600px;"
  },
  link: {
    "&:hover": {
      textDecoration: "none"
    },
    color: "black"
  },
  palier: {
    backgroundColor: "blue"
  },
  taux: {
    backgroundColor: "black"
  },
  baux: {
    backgroundColor: "green"
  },
  preavis: {
    backgroundColor: "red"
  },
  palierS: {
    borderLeft: "4px solid blue"
  },
  tauxS: {
    borderLeft: "4px solid black"
  },
  bauxS: {
    borderLeft: "4px solid green"
  },
  preavisS: {
    borderLeft: "4px solid red"
  },
  infoS: {
    borderLeft: "4px solid brown"
  },
  euribor: {
    border: "solid 2px black",
    padding: theme.spacing.unit * 1,
    marginTop: theme.spacing.unit * 2
  },
  excelLink: {
    lineHeight: "25px",
    color: "black"
  },
  pdfLink: {
    lineHeight: "25px",
    color: "red"
  },
  rapportLinks: {
    listStyleType: "none",
    padding: theme.spacing.unit * 1
  },
  titreHisto: {
    textAlign: "center",
    paddingTop: theme.spacing.unit * 1
  },
  table: {
    color: "#333",
    fontFamily: "Roboto, Helvetica, Arial, 'sans-serif'",
    borderCollapse: "collapse",
    borderSpacing: 0,
    marginBottom: "3em",
    width:"100%"
  },
  td: {
    borderRight: "4px solid #BC0703",
    height: "30px",
    // background: "#FAFAFA",
    textAlign: "center",
    minWidth: "78px",
    maxWidth:"100px"
  },
  th: {
    // border: "1px solid #CCC",
    height: "30px",
    // background: "#C2C2C2",
    fontWeight: "bold",
    textAlign: "left",
    minWidth: "140px",
  },
  td1: {
    
    height: "30px",
    // background: "#FAFAFA",
    textAlign: "center",
    minWidth: "78px",
    maxWidth:"100px"
  },
});

const getAsideRapportPdfGlobalePardesLink = rapports => {
  return rapports.filter(rapport => rapport.file.includes('-1.pdf'))
    .map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
    .sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
    .shift();
}
const getAsideRapportPdfGlobaleForumLink = rapports => {
  return rapports.filter(rapport => rapport.file.includes('-2.pdf'))
    .map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
    .sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
    .shift();
}
const getAsideRapportPdfSVLink = rapports => {
  return rapports.filter(rapport => rapport.file.includes('-SV.pdf'))
    .map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
    .sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
    .shift();
}
const getAsideRapportPdfCRDLink = rapports => {
  return rapports.filter(rapport => rapport.file.includes('-CRD.pdf'))
    .map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
    .sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
    .shift();
}


const getAsideRapportPdfCourteLink = rapports => {
  return rapports.filter(rapport => rapport.file.includes('+.pdf'))
    .map(rapport => { rapport.date = moment(rapport.creation_date); return rapport; })
    .sort((r1, r2) => r2.date.valueOf() - r1.date.valueOf())
    .shift();
}



class ListeNotificationsMobileMadar extends React.Component {
  state = {
    rapports: [],
    loaded: false,
    dscr_actuel: "Loading...",
    dscr_facial: "Loading...",
    dscr_potentiel: "Loading...",
    ltv: "Loading",
    dscr_actuelF: "Loading...",
    dscr_facialF: "Loading...",
    dscr_potentielF: "Loading...",
    ltvF: "Loading...",
    NetInitialYield: "Loading...",
    NetPotentielYield: "Loading...",
    InitialDebtYield: "Loading...",
    PotentielDebtYield: "Loading...",
    ICR: "Loading...",
    TO_PHYSIQUE: "Loading...",
    TO_FINANCIER: "Loading...",
    dscr_actuelF: "Loading...",
    dscr_facialF: "Loading...",
    dscr_potentielF: "Loading...",
    ltvF: "Loading...",
    NetInitialYieldF: "Loading...",
    NetPotentielYieldF: "Loading...",
    InitialDebtYieldF: "Loading...",
    PotentielDebtYieldF: "Loading...",
    ICRF: "Loading...",
    TO_PHYSIQUE_F: "Loading...",
    TO_FINANCIER_F: "Loading...",
    euribor:"Loading...",
    refreshStatus:false
  };


  handleGenerateNewExcelWithRefresh = () => {
    this.setState({ refreshText: "Calcul en cours...", refreshStatus:true });
    setTimeout(function(){window.location.reload();}, 180000);
    
      
      this.props.fetch({ url: "/rapports/total?idsociete=2" }).then(rapports => {
      this.setState({
        dscr_actuelF: rapports.dscr_actuel,
        dscr_facialF: rapports.dscr_facial,
        dscr_potentielF: rapports.dscr_potentiel,
        ltvF: rapports.ltv,
      NetInitialYieldF: rapports.NetInitialYield,
      NetPotentielYieldF: rapports.NetPotentielYield,
      InitialDebtYieldF: rapports.InitialDebtYield,
      PotentielDebtYieldF: rapports.PotentielDebtYield,
      ICRF: rapports.ICR,
      TO_PHYSIQUE_F: rapports.TO_PHYSIQUE,
      TO_FINANCIER_F: rapports.TO_FINANCIER
      });
  
      this.props.fetch({ url: "/rapports/total?idsociete=1" }).then(rapports => {
        this.setState({
          dscr_actuel: rapports.dscr_actuel,
          dscr_facial: rapports.dscr_facial,
          dscr_potentiel: rapports.dscr_potentiel,
          ltv: rapports.ltv,
        NetInitialYield: rapports.NetInitialYield,
        NetPotentielYield: rapports.NetPotentielYield,
        InitialDebtYield: rapports.InitialDebtYield,
        PotentielDebtYield: rapports.PotentielDebtYield,
        ICR: rapports.ICR,
        TO_PHYSIQUE: rapports.TO_PHYSIQUE,
        TO_FINANCIER: rapports.TO_FINANCIER
        });
        // window.location.reload();
    
        this.setState({ refreshText: "", refreshStatus:false });
        });
      
      });
    };
  

  
  componentDidMount() {
    const user = JSON.parse(window.localStorage.getItem('madar'))
    const requestOptions = {
      method: 'GET',
      headers:{"X-Api-Key":user.api_key,
      Accept: "application/json",
      "Content-Type": "application/json"}
    };
       fetch('/public/index.php/notifications/', requestOptions).then(response => response.json()).then(data =>{

      const hardcodedNotif = data.data && data.data.length && data.data.length > 0 ? data.data : [];
      const notificationsGrouped = GroupBy(hardcodedNotif, "type");
      const types = Object.keys(notificationsGrouped)
        .filter(k => k !== "Euribor")
        .map(k => ({
          k,
          qte: notificationsGrouped[k].length
        }))
        .sort((t2, t1) => {
          if (t1.qte > t2.qte) return 1;
          if (t1.qte < t2.qte) return -1;
          return 0;
        });
       console.log("imhere", hardcodedNotif);
        const majTaux = hardcodedNotif
        .filter(n => n.type === "Euribor")
        .sort((n1, n2) => {
        if (n1.idnotification > n2.idnotification) return -1;
        if (n1.idnotification < n2.idnotification) return 1;
        return 0;
        });
        if (majTaux && majTaux.length > 0) {
        
        }
        const euribor = majTaux.length > 0 ? JSON.parse(majTaux[0].message) : null;
        euribor.date_notification = moment(majTaux[0].date_notification).format("DD/MM/YYYY");
        const selectedNotifications = hardcodedNotif
        .filter(n => n.type !== "Euribor")
        .filter(n => n.type === types);
       console.log("euribor", euribor);
      
      this.setState({
        loaded: true,
        notifications: hardcodedNotif,
        types,
        typeSelected: types && types.length && types.length > 0 && types[0].k,
        euribor:euribor
      
      });
       })
     
    
  
      





const date_dashboard = new Date().toJSON().slice(0,10);
const saved_date = localStorage.getItem("date_dashboard");


if (date_dashboard == saved_date && false) {
this.setState({
          dscr_actuel: localStorage.getItem("dscr_actuel"),
          dscr_facial: localStorage.getItem("dscr_facial"),
          dscr_potentiel: localStorage.getItem("dscr_potentiel"),
          ltv: localStorage.getItem("ltv"),
          dscr_actuelF: localStorage.getItem("dscr_actuelF"),
          dscr_facialF: localStorage.getItem("dscr_facialF"),
          dscr_potentielF: localStorage.getItem("dscr_potentielF"),
          ltvF: localStorage.getItem("ltvF"),
NetInitialYield: localStorage.getItem("NetInitialYield"),
        NetPotentielYield: localStorage.getItem("NetPotentielYield"),
        InitialDebtYield: localStorage.getItem("InitialDebtYield"),
        PotentielDebtYield: localStorage.getItem("PotentielDebtYield"),
        ICR: localStorage.getItem("ICR"),
        TO_PHYSIQUE: localStorage.getItem("TO_PHYSIQUE"),
        TO_FINANCIER: localStorage.getItem("TO_FINANCIER"),
        NetInitialYieldF: localStorage.getItem("NetInitialYieldF"),
        NetPotentielYieldF: localStorage.getItem("NetPotentielYieldF"),
        InitialDebtYieldF: localStorage.getItem("InitialDebtYieldF"),
        PotentielDebtYieldF: localStorage.getItem("PotentielDebtYieldF"),
        TO_PHYSIQUE_F: localStorage.getItem("TO_PHYSIQUE_F"),
        TO_FINANCIER_F: localStorage.getItem("TO_FINANCIER_F"),
        ICR: localStorage.getItem("ICR")

        });
} else {


this.props.fetch({ url: "/rapports/total?idsociete=1&dashboard=1" }).then(rapports => {
      this.setState({
          dscr_actuel: rapports.dscr_actuel,
          dscr_facial: rapports.dscr_facial,
          dscr_potentiel: rapports.dscr_potentiel,
          ltv: rapports.ltv,
NetInitialYield: rapports.NetInitialYield,
NetPotentielYield: rapports.NetPotentielYield,
InitialDebtYield: rapports.InitialDebtYield,
PotentielDebtYield: rapports.PotentielDebtYield,
ICR: rapports.ICR,
TO_PHYSIQUE: rapports.TO_PHYSIQUE,
TO_FINANCIER: rapports.TO_FINANCIER
        });
localStorage.setItem("date_dashboard", date_dashboard);
localStorage.setItem("dscr_actuel", rapports.dscr_actuel);
localStorage.setItem("dscr_facial", rapports.dscr_facial);
localStorage.setItem("dscr_potentiel", rapports.dscr_potentiel);
localStorage.setItem("ltv", rapports.ltv);
localStorage.setItem("NetInitialYield", rapports.NetInitialYield);
localStorage.setItem("NetPotentielYield", rapports.NetPotentielYield);
localStorage.setItem("InitialDebtYield", rapports.InitialDebtYield);
localStorage.setItem("PotentielDebtYield", rapports.PotentielDebtYield);
localStorage.setItem("ICR", rapports.ICR);
localStorage.setItem("TO_PHYSIQUE", rapports.TO_PHYSIQUE);
localStorage.setItem("TO_FINANCIER", rapports.TO_FINANCIER);
      });

this.props.fetch({ url: "/rapports/total?idsociete=2&dashboard=1" }).then(rapports => {
      this.setState({
          dscr_actuelF: rapports.dscr_actuel,
          dscr_facialF: rapports.dscr_facial,
          dscr_potentielF: rapports.dscr_potentiel,
          ltvF: rapports.ltv,
NetInitialYieldF: rapports.NetInitialYield,
NetPotentielYieldF: rapports.NetPotentielYield,
InitialDebtYieldF: rapports.InitialDebtYield,
PotentielDebtYieldF: rapports.PotentielDebtYield,
ICRF: rapports.ICR,
TO_PHYSIQUE_F: rapports.TO_PHYSIQUE,
TO_FINANCIER_F: rapports.TO_FINANCIER

        });
localStorage.setItem("dscr_actuelF", rapports.dscr_actuel);
localStorage.setItem("dscr_facialF", rapports.dscr_facial);
localStorage.setItem("dscr_potentielF", rapports.dscr_potentiel);
localStorage.setItem("ltvF", rapports.ltv);
localStorage.setItem("NetInitialYieldF", rapports.NetInitialYield);
localStorage.setItem("NetPotentielYieldF", rapports.NetPotentielYield);
localStorage.setItem("InitialDebtYieldF", rapports.InitialDebtYield);
localStorage.setItem("PotentielDebtYieldF", rapports.PotentielDebtYield);
localStorage.setItem("ICRF", rapports.ICR);
localStorage.setItem("TO_PHYSIQUE_F", rapports.TO_PHYSIQUE);
localStorage.setItem("TO_FINANCIER_F", rapports.TO_FINANCIER);
      });
}

    this.props.fetch({ url: "/rapports/excel" }).then(rapports => {
      this.setState({
        loaded: true,
        rapports: rapports.sort((r1, r2) => {
          if (r1.file > r2.file) return -1;
          if (r1.file < r2.file) return 1;
          return 0;
        }),
	asideRapportPdfGlobalePardesLink: getAsideRapportPdfGlobalePardesLink(rapports),
	asideRapportPdfGlobaleForumLink: getAsideRapportPdfGlobaleForumLink(rapports),
        asideRapportPdfSVLink: getAsideRapportPdfSVLink(rapports),
	asideRapportPdfCRDLink: getAsideRapportPdfCRDLink(rapports),
        asideRapportPdfCourteLink: getAsideRapportPdfCourteLink(rapports)
      });
    });
  }

  buildRapportPdfGlobalePardesLink = (rapport, displayPhoto) => {
if(!rapport.file.includes("-1.pdf")){
                  return;
          }

    const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
    const split = rapport.file
      .replace("-1.pdf", "")
      .replace("_", " ")
      .replace("_", " ")
      .split(" ");
    return (
      <a
        className={this.props.classes.excelLink}
        // config
        href={`${rapportsUrl}${rapport.file}`}
      >
       <img src={LogoPdf} alt="Synthèse globale Pardes" width="30px" /> 
       PARDES
      </a>
    );
  };

  buildRapportPdfGlobaleForumLink = (rapport, displayPhoto) => {
if(!rapport.file.includes("-2.pdf")){
                  return;
          }

    const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
    const split = rapport.file
      .replace("-2.pdf", "")
      .replace("_", " ")
      .replace("_", " ")
      .split(" ");
    return (
      <a
        className={this.props.classes.excelLink}
        // config
        href={`${rapportsUrl}${rapport.file}`}
      >
       <img src={LogoPdf} alt="Synthèse globale Forum" width="30px" />
	FORUM
      </a>
    );
  };

  buildRapportPdfCRDLink = (rapport, displayPhoto) => {
if(!rapport.file.includes("-CRD.pdf")){
                  return;
          }

    const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
    const split = rapport.file
      .replace("-CRD.pdf", "")
      .replace("_", " ")
      .replace("_", " ")
      .split(" ");
    return (
      <a
        className={this.props.classes.excelLink}
        // config
        href={`${rapportsUrl}${rapport.file}`}
      >
       <img src={LogoPdf} alt="CRD" width="30px" />
       CRD
      </a>
    );
  };
  buildRapportPdfSVLink = (rapport, displayPhoto) => {
if(!rapport.file.includes("-SV.pdf")){
                  return;
          }

    const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
    const split = rapport.file
      .replace("-SV.pdf", "")
      .replace("_", " ")
      .replace("_", " ")
      .split(" ");
    return (
      <a
        className={this.props.classes.excelLink}
        // config
        href={`${rapportsUrl}${rapport.file}`}
      >
       <img src={LogoPdf} alt="Synthèse Vacants" width="30px" />
       VACANTS
      </a>
    );
  };



  buildRapportPdfCourteLink = (rapport, displayPhoto) => {
if(!rapport.file.includes("+.pdf")){
                  return;
          }

    const rapportsUrl = process.env.REACT_APP_DOMAIN + "/files/export/";
    const split = rapport.file
      .replace("+.pdf", "")
      .replace("_", " ")
      .replace("_", " ")
      .split(" ");
    return (
      <a
        className={this.props.classes.excelLink}
        // config
        href={`${rapportsUrl}${rapport.file}`}
      >
       <img src={LogoPdf} alt="Synthèse courte" width="30px" />
       COURTE 
      </a>
    );
  };

  openDrawer = () => this.setState({ historiqueOpen: true });

  closeDrawer = () => this.setState({ historiqueOpen: false });

  render() {
    console.log("euribor",this.state.euribor);
    const { classes } = this.props;
    const { rapports, loaded, asideRapportPdfGlobalePardesLink, asideRapportPdfGlobaleForumLink, asideRapportPdfSVLink, asideRapportPdfCRDLink, asideRapportPdfCourteLink } = this.state;

    return (
      <>
    <Card className="card-dashboard">
      <Row>
        <Col xs={18}>
          <div className="dashboard-mobile-title">Tableau de bord</div>  
        </Col>
        <Col xs={6} className="dashboard-mobile-btn-refresh">
        {this.state.refreshStatus == false ?
        <Button className="dashboard-refresh-button" onClick={this.handleGenerateNewExcelWithRefresh }><SyncOutlined style={{fontSize:"22px", display:"block"}} /> </Button>
        : <Button className="dashboard-refresh-button" disabled={true}><SyncOutlined spin style={{fontSize:"22px", display:"block"}}  /> </Button>} 
        </Col>
      </Row>
      
    
      <table className={classes.table}>
        <tbody>
        <tr>
                <th className={classes.th}> </th>
                <td style={{textAlign:"center", fontSize:"18px", fontWeight:"bold"}}> Pardes </td>
                <td style={{textAlign:"center", fontSize:"18px", fontWeight:"bold"}}> Forum</td>
              </tr>
              <tr>
                <th className={classes.th}>DSCR Actuel</th>
                <td className={classes.td}>{this.state.dscr_actuel} %</td>
          <td className={classes.td1}>{this.state.dscr_actuelF} %</td>
              </tr>
              <tr>
                <th className={classes.th}>DSCR Facial</th>
                <td className={classes.td}>{this.state.dscr_facial} %</td>
                <td className={classes.td1}>{this.state.dscr_facialF} %</td>
              </tr>
              <tr>
                <th className={classes.th}>DSCR Potentiel</th>
                <td className={classes.td}>{this.state.dscr_potentiel} %</td>
                <td className={classes.td1}>{this.state.dscr_potentielF} %</td>
              </tr>
              <tr>
                <th className={classes.th}>ICR</th>
                <td className={classes.td}>{this.state.ICR} %</td>
                <td className={classes.td1}>{this.state.ICRF} %</td>
              </tr>
              <tr>
                <th className={classes.th}>LTV</th>
                <td className={classes.td}>{this.state.ltv} %</td>
                <td className={classes.td1}>{this.state.ltvF} %</td>
              </tr>
        <tr>
          <th className={classes.th}>Net Initial Yield</th>
          <td className={classes.td}>{this.state.NetInitialYield} %</td>
                <td className={classes.td1}>{this.state.NetInitialYieldF} %</td>
        </tr>
        <tr>
          <th className={classes.th}>Net Potential Yield</th>
          <td className={classes.td}>{this.state.NetPotentielYield} %</td>
                <td className={classes.td1}>{this.state.NetPotentielYieldF} %</td>
        </tr>
        <tr>
          <th className={classes.th}>Initial Debt Yield</th>
          <td className={classes.td}>{this.state.InitialDebtYield} %</td>
                <td className={classes.td1}>{this.state.InitialDebtYieldF} %</td>
        </tr>
        <tr>
          <th className={classes.th}>Potential Debt Yield</th>
          <td className={classes.td}>{this.state.PotentielDebtYield} %</td>
                <td className={classes.td1}>{this.state.PotentielDebtYieldF} %</td>
        </tr>
        <tr>
          <th className={classes.th}>TO Physique</th>
          <td className={classes.td}>{this.state.TO_PHYSIQUE} %</td>
                <td className={classes.td1}>{this.state.TO_PHYSIQUE_F} %</td>
        </tr>
        <tr>
          <th className={classes.th}>TO Financier</th>
          <td className={classes.td}>{this.state.TO_FINANCIER} %</td>
                <td className={classes.td1}>{this.state.TO_FINANCIER_F} %</td>
        </tr>
        </tbody>
      </table>
    </Card>
    <Card className="card-dashboard">
      <Row className="dashboard-mobile-eurobird-card-text-style">
        
          <Col xs={18}>
            Euribor 3M {this.state.euribor.date_notification}
          </Col>
          <Col xs={6}>
            {this.state.euribor.taux_euribor}%
          </Col>
          
     
      </Row>
    </Card>
      
     
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        spacing={0}
        className={classes.container}
      >
       


<Grid container alignItems="flex-start" justify="flex-start" direction="row" spacing={0}>
<Grid item  xs={1}>
</Grid>


<Grid item  xs={1}>
</Grid>
</Grid>




        <Grid item className={classes.title} style={{ marginTop: "0px" }}>
          {!loaded && <CircularProgress size={50} />}
<Card className="card-dashboard">
<table>
<tr>
<td>
<Typography variant="body1">
{loaded && rapports.length > 0 && this.buildRapportPdfGlobalePardesLink(asideRapportPdfGlobalePardesLink)}
</Typography>
</td>
<td>
<Typography variant="body1">
{loaded && rapports.length > 0 && this.buildRapportPdfGlobaleForumLink(asideRapportPdfGlobaleForumLink)}
</Typography>
</td>
<td>
<Typography variant="body1">
{loaded && rapports.length > 0 && this.buildRapportPdfCourteLink(asideRapportPdfCourteLink)}
</Typography>
</td>
<td>
<Typography variant="body1">
{loaded && rapports.length > 0 && this.buildRapportPdfSVLink(asideRapportPdfSVLink)}
</Typography>
</td>
<td>
<Typography variant="body1">
{loaded && rapports.length > 0 && this.buildRapportPdfCRDLink(asideRapportPdfCRDLink)}
</Typography>
</td>
</tr>
</table>
</Card>
        </Grid>
      </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withGlobals(ListeNotificationsMobileMadar));
