import React from "react";
// import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ListIcon from "@material-ui/icons/List";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import CustomDialogTitle from "../../common/CustomDialogTitle";
import ListeActifs from "../../common/ListeActifs";
import columnData from "../Actif/columnData";
import M2FormatCustom from "../../common/M2FormatCustom";
import withGlobals from "../withGlobals";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      width: 200,
    },
    actifSelected: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
    },
    actifSelectedLabel: {
      color: "rgba(0, 0, 0, 0.54)",
      padding: 0,
      fontSize: "0.8rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: 1.2,
      marginBottom: theme.spacing.unit,
    },
    textFieldLong: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 300,
    },
    button: {
      marginTop: theme.spacing.unit * 4,
    },
    buttonHidden: {
      marginTop: theme.spacing.unit * 4,
      visibility: "hidden",
    },
    label: {
      paddingLeft: theme.spacing.unit,
    },
    submitButton: {
      marginTop: theme.spacing.unit * 2,
      padding: theme.spacing.unit * 2,
    },
    bloc: {
      border: "solid 1px silver",
      borderRadius: "3px 3px 3px",
      padding: theme.spacing.unit,
      minHeight: "400px",
    },
    fieldRight: {
      width: "400px",
    },
    invisibleFieldset: {
      margin: 0,
      padding: 0,
      border: 0,
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    }
  };
};

class LocalForm extends React.Component {
  state = {
    actifs: [],
    dialogOpen: false,
    actifSelected: null,
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.vueSimplifiee !== this.props.vueSimplifiee) {
      this.props.setFieldValue("surface_etage", 0);
      this.props.setFieldValue("surface_sous_sol", 0);
      this.props.setFieldValue("surface_rdc", 0);
    }
  };

  handleActifsLoaded = actifs => this.setState({ actifs });

  handleAnnuleSelection = () => {
    this.props.setFieldValue("idactifpatrimonial", this.props.local.idactifpatrimonial, false);
    this.setState({ actifSelected: null });
  };

  handleSelectActif = (e, actifSelected) => {
    const { setFieldValue, setFormDataChanged, changed, onActifSelected, values } = this.props;
    setFieldValue("idactifpatrimonial", actifSelected, false);
    if (!changed) setFormDataChanged();
    if (onActifSelected) {
      onActifSelected({ ...values, idactifpatrimonial: actifSelected });
    }
    this.setState({ actifSelected, dialogOpen: false });
  };

  handleOpenDialog = () => this.setState({ dialogOpen: true });

  handleCloseDialog = () => this.setState({ dialogOpen: false });

  changeValue = (field, value) => {
    const { setFieldValue, setFormDataChanged, changed, values } = this.props;
    if (!changed) setFormDataChanged();
    if (field.indexOf("surface") !== -1) {
      this.props.onValueChanged({ ...values, [field]: value });
    }
    setFieldValue(field, value, false);
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/locaux");
  };

  render() {
    const {
      handleSubmit,
      handleBlur,
      touched,
      values,
      errors,
      isSubmitting,
      classes,
      changed,
      readOnly,
      surface_totale_actif,
      surface_occupee,
      user
    } = this.props;
    const { actifSelected, actifs } = this.state;
    const { surface_etage, surface_sous_sol, surface_rdc } = values;
    const surfaceTotaleLocal =
      parseInt(surface_etage, 10) + parseInt(surface_sous_sol, 10) + parseInt(surface_rdc, 10);
    let surfaceRestante = surface_totale_actif - surface_occupee - surfaceTotaleLocal;
    //if (surfaceRestante < 0) surfaceRestante = 0;
let texteSurfaceRestante = (surfaceRestante < 0) ? "Surface maximale dépassée" : " reste "+surfaceRestante+" m² ";


    return (
      <form onSubmit={handleSubmit} className={classes.container}>
        <fieldset className={classes.invisibleFieldset} disabled={!user.modifier}>
          <Dialog
            open={this.state.dialogOpen}
            fullScreen
            TransitionComponent={Transition}
            onClose={this.handleCloseDialog}
            aria-labelledby="form-dialog-title"
          >
            <CustomDialogTitle title="Sélectionnez un actif" onClose={this.handleCloseDialog} />
            <DialogContent>
              <ListeActifs
                onSelectRow={this.handleSelectActif}
                datas={actifs}
                onDatasLoaded={this.handleActifsLoaded}
                apiPathname="/actifs/"
                datasName="actifs"
                idField="idactifpatrimonial"
                inDialog
                columnData={columnData}
              />
            </DialogContent>
          </Dialog>
          <Grid container justify="space-around" alignItems="flex-start" direction="row" spacing={16}>
            {!user.modifier && (
              <Grid item xs={12}>
                <Typography variant="subheading">Lecture seule pour l'utilisateur courant</Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography variant="title">Local</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="title">Type d'activité</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justify="flex-start"
                alignItems="strech"
                direction="column"
                spacing={4}
                className={classes.bloc}
              >
                {!actifSelected ? (
                  <Grid item>
                    <Grid container justify="flex-start" alignItems="center" direction="row">
                      <Grid item>
                        <TextField
                          id="idactifpatrimonial"
                          value={values.idactifpatrimonial}
                          label="Id de l'actif"
                          name="idactifpatrimonial"
                          type="text"
                          required
                          disabled
                          margin="dense"
                          fullWidth
                          onBlur={handleBlur}
                          helperText={
                            errors.idactifpatrimonial && touched.idactifpatrimonial
                              ? errors.idactifpatrimonial
                              : null
                          }
                          error={touched.idactifpatrimonial && errors.idactifpatrimonial}
                          onChange={e => this.changeValue("idactifpatrimonial", e.target.value, false)}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={this.handleOpenDialog}
                          style={{ paddingTop: 20 }}
                          aria-label="list"
                        >
                          <ListIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                    <Grid item>
                      <Grid container direction="row" alignItems="space-between" justify="flex-start">
                        <Grid item>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.actifSelectedLabel}>
                              Id de l'actif
                        </Grid>
                            <Grid item className={classes.actifSelected}>
                              {actifSelected}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={this.handleAnnuleSelection}
                            style={{ marginTop: 0 }}
                            aria-label="clear"
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                <Grid item>
                  <Grid container direction="row" alignItems="stretch" justify="space-between" spacing={4}>
                    <Grid item>
                      <Grid container direction="column" justify="flex-start" alignItems="center">
                        <Grid item>
                          <TextField
                            id="surface_rdc"
                            value={values.surface_rdc}
                            label="Surface rdc"
                            name="surface_rdc"
                            type="text"
                            required
                            margin="dense"
                            fullWidth
                            onBlur={handleBlur}
                            helperText={errors.surface_rdc && touched.surface_rdc ? errors.surface_rdc : null}
                            error={touched.surface_rdc && errors.surface_rdc}
                            InputProps={{
                              inputComponent: M2FormatCustom,
                            }}
                            onChange={e => this.changeValue("surface_rdc", e.target.value, false)}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            id="surface_sous_sol"
                            value={values.surface_sous_sol}
                            label="Surface sous-sol"
                            name="surface_sous_sol"
                            type="text"
                            required
                            margin="dense"
                            fullWidth
                            onBlur={handleBlur}
                            helperText={
                              errors.surface_sous_sol && touched.surface_sous_sol
                                ? errors.surface_sous_sol
                                : null
                            }
                            error={touched.surface_sous_sol && errors.surface_sous_sol}
                            InputProps={{
                              inputComponent: M2FormatCustom,
                            }}
                            onChange={e => this.changeValue("surface_sous_sol", e.target.value, false)}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            id="surface_etage"
                            value={values.surface_etage}
                            label="Surface étage"
                            name="surface_etage"
                            type="text"
                            required
                            margin="dense"
                            fullWidth
                            onBlur={handleBlur}
                            helperText={
                              errors.surface_etage && touched.surface_etage ? errors.surface_etage : null
                            }
                            error={touched.surface_etage && errors.surface_etage}
                            InputProps={{
                              inputComponent: M2FormatCustom,
                            }}
                            onChange={e => this.changeValue("surface_etage", e.target.value, false)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ width: "350px" }}
                      >
                        <Grid item>
                          <h3>Surface totale du local {surfaceTotaleLocal} m²</h3>
                          <h4>({texteSurfaceRestante})</h4>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>,
              <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="flex-start" spacine={8}>
                    <Grid item xs={3}>
                      <TextField
                        id="etage"
                        value={values.etage}
                        label="Étage"
                        name="etage"
                        type="text"
                        margin="dense"
                        autoComplete="off"
                        fullWidth
                        onBlur={handleBlur}
                        helperText={errors.etage && touched.etage ? errors.etage : null}
                        error={touched.etage && errors.etage}
                        onChange={e => this.changeValue("etage", e.target.value, false)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="nombre_parking_ext"
                        value={values.nombre_parking_ext}
                        label="Nbre park. ext."
                        name="nombre_parking_ext"
                        type="text"
                        margin="dense"
                        fullWidth
                        onBlur={handleBlur}
                        helperText={
                          errors.nombre_parking_ext && touched.nombre_parking_ext
                            ? errors.nombre_parking_ext
                            : null
                        }
                        error={touched.nombre_parking_ext && errors.nombre_parking_ext}
                        onChange={e => this.changeValue("nombre_parking_ext", e.target.value, false)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="nombre_parking_int"
                        value={values.nombre_parking_int}
                        label="Nbre park. int."
                        name="nombre_parking_int"
                        type="text"
                        margin="dense"
                        fullWidth
                        onBlur={handleBlur}
                        helperText={
                          errors.nombre_parking_int && touched.nombre_parking_int
                            ? errors.nombre_parking_int
                            : null
                        }
                        error={touched.nombre_parking_int && errors.nombre_parking_int}
                        onChange={e => this.changeValue("nombre_parking_int", e.target.value, false)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                direction="column"
                spacing={4}
                className={classes.bloc}
                style={{ paddingLeft: "150px" }}
              >
                <Grid item xs={6} style={{ minHeight: '50px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.licence4}
                        name="licence4"
                        onChange={(event, key) => this.changeValue("licence4", !values.licence4)}
                        value={1}
                        color="primary"
                      />
                    }
                    label="Licence 4"
                  />
                </Grid>
                <Grid item xs={6} style={{ minHeight: '50px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.extraction}
                        name="extraction"
                        onChange={(event, key) => this.changeValue("extraction", !values.extraction)}
                        value={1}
                        color="primary"
                      />
                    }
                    label="Extraction"
                  />
                </Grid>
                <Grid item xs={6} style={{ minHeight: '50px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.restauration}
                        name="restauration"
                        onChange={(event, key) =>
                          this.changeValue("restauration", values.restauration ? false : true)
                        }
                        value={1}
                        color="primary"
                      />
                    }
                    label="Activité restauration"
                  />
                </Grid>
                <Grid item xs={6} style={{ minHeight: '50px'}}>
                  <TextField
                    className={classes.fieldRight}
                    id="activite_interdite"
                    value={values.activite_interdite}
                    label="Activités interdites"
                    name="activite_interdite"
                    type="text"
                    margin="dense"
                    fullWidth
                    onBlur={handleBlur}
                    helperText={
                      errors.activite_interdite && touched.activite_interdite ? errors.activite_interdite : null
                    }
                    error={touched.activite_interdite && errors.activite_interdite}
                    onChange={e => this.changeValue("activite_interdite", e.target.value, false)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {changed &&
              !readOnly && (
                <Grid item xs={12}>
                  <Grid container justify="flex-end" alignItems="center" direction="row" spacing={8}>
                    <Grid item xs={2} className={classes.submitButton}>
                      <Button
                        variant="outlined"
                        disabled={isSubmitting}
                        color="textSecondary"
                        fullWidth
                        onClick={this.handleCancel}
                      >
                        Annuler
                    </Button>
                    </Grid>
                    <Grid item xs={2} className={classes.submitButton}>
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        Sauvegarder
                    </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </fieldset>
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(LocalForm)));
