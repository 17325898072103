const filter = (props, state) => {
  const { datas } = props;
  const { idemprunt, idactifpatrimonial, nom_portefeuille, adresse, banque } = state;
  const filter = idemprunt !== "" || idactifpatrimonial !== "" || nom_portefeuille !== "" || adresse !== "" || (banque !== null && !isNaN(banque));

  const datasFiltred = filter
    ? datas.filter(b => {
        let show = true;

        if (idemprunt !== "" && show) show = `${b.idemprunt}` === idemprunt;
        if (idactifpatrimonial !== "" && show) show = `${b["idactifpatrimonial"]}` === idactifpatrimonial;
        if (adresse !== "" && show) show = `${b.adresse.replaceAll('è', 'e').replaceAll('é', 'e').toLowerCase()}`.indexOf(adresse.replaceAll('è', 'e').replaceAll('é', 'e').toLowerCase()) !== -1;
        if (banque !== null && !isNaN(banque) && show) show = b.banque === banque;
        if (nom_portefeuille !== "" && show)
          show = `${b.nom_portefeuille}`.toLowerCase().indexOf(nom_portefeuille.toLowerCase()) !== -1;

        return show;
      })
    : datas;

  return { datasFiltred, filter };
};

export default filter;
