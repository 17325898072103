import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

class DialogNomPortefeuille extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom_portefeuille: props.nom_portefeuille,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.changeNomPortefeuille(this.state.nom_portefeuille);
    this.props.nom_portefeuille = this.state.nom_portefeuille;
    this.props.handleClose();
  };

  handleChange = e => {
    this.setState({ nom_portefeuille: e.target.value });
  };

  render() {
    const { open, handleClose } = this.props;
    const { nom_portefeuille } = this.state;

    return (
      <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {this.props.nom_portefeuille === ""
              ? "Ajouter un nom au portefeuille"
              : "Modifier le nom du portefeuille"}
          </DialogTitle>
          <DialogContent>
            <Grid container alignItems="center" justify="center" direction="row" spacing={8}>
              <Grid item xs={6}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  value={nom_portefeuille}
                  name="nom_portefeuille"
                  onChange={this.handleChange}
                  label="Nom"
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="textSecondary">
              Annuler
            </Button>
            <Button type="submit" variant="raised" color="primary">
              Valider
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default DialogNomPortefeuille;
