import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import validationSchema from "./validation";
import EmpruntForm from "./Form";

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 1,
      paddingBottom: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 20,
      marginLeft: theme.spacing.unit * 20
    },
    fullHeight: {
      display: "flex",
      height: "calc(100vh - 150px)"
    },
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200
    },
    button: {
      marginTop: theme.spacing.unit * 4
    },
    surfaceDispo: {
      color: "#725356"
    },
    surfaceNonDispo: {
      color: "black"
    },
    buttonHidden: {
      marginTop: theme.spacing.unit * 4,
      visibility: "hidden"
    },
    icon: {
      height: "3em",
      width: "3em",
      fill: theme.palette.primary.dark
    },
    title: {
      marginBottom: 0,
      fontSize: 14,
      lineHeight: "36px",
      fontWeight: "bold"
    },
    rightIcon: {
      marginLeft: theme.spacing.unit / 2,
      marginRight: theme.spacing.unit / 2
    },
    titleArea: {
      border: `solid 1px ${theme.palette.primary.main}`,
      padding: "2px",
      borderRadius: "3px 3px",
      marginBottom: theme.spacing.unit * 2
    }
  };
};

const EmpruntFormik = withFormik({
  mapPropsToValues: props => ({ ...props.emprunt }),
  validationSchema: validationSchema,
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
      setErrors /* setValues, setStatus, and other goodies */
    }
  ) => {
    props
      .onSubmit(values)
      .then(() => {
        setSubmitting(false);
      })
      .catch(e => {
        setSubmitting(false);
      });
  }
})(withStyles(styles)(EmpruntForm));

class Emprunt extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    emprunt: PropTypes.object.isRequired,
    empruntId: PropTypes.string,
    openNotification: PropTypes.func.isRequired,
    onFormDataChanged: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    formDataChanged: PropTypes.bool.isRequired,
    onResetDataChanged: PropTypes.func.isRequired,
    filtred: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      // pour forcer le remontage du formulaire
      changingDatas: false,

      submiting: false,
      justSaved: false,
      changed: false,
      simple: false,
      filtredDialogOpen: false
    };
  }

  // componentWillReceiveProps = nextProps => {
  //   if (
  //     nextProps.emprunt &&
  //     this.props.emprunt &&
  //     nextProps.emprunt.idemprunt !== this.props.emprunt.idemprunt
  //   ) {
  //     // this.props.onResetDataChanged();
  //     this.setState({
  //       changingDatas: true,
  //       justSaved: false,
  //       submitting: false,
  //       filtredDialogOpen: false,
  //     });
  //     setTimeout(() => this.setState({ changingDatas: false }), 1);
  //   }
  // };

  handleChange = e => {
    this.setState({
      emprunt: { ...this.state.emprunt, [e.target.name]: e.target.value },
      justSaved: false
    });
  };

  handleOpenFilteredDialog = () => this.setState({ filtredDialogOpen: true });

  handleCloseFilteredDialog = () => this.setState({ filtredDialogOpen: false });

  handleLocalPrecedent = index => {
    const { emprunts, history } = this.props;
    history.push(`/emprunts/${emprunts[index - 1].idemprunt}`);
  };

  handleLocalSuivant = index => {
    const { emprunts, history } = this.props;
    history.push(`/emprunts/${emprunts[index + 1].idemprunt}`);
  };

  navigateTo = url => {
    this.props.history.push(url);
    this.handleCloseFilteredDialog();
  };

  render() {
    const {
      classes,
      emprunt,
      formDataChanged,
      onFormDataChanged,
      onResetDataChanged,
      actifId,
      empruntId,
      openNotification
    } = this.props;

    if (!emprunt && empruntId) {
      return (
        <div className={classes.fullHeight}>
          <Grid container alignItems="center" direction="row" justify="center">
            <Grid item>
              <Typography variant="display3">
                Ce emprunt n'existe pas.
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <EmpruntFormik
        emprunt={emprunt}
        actifId={actifId}
        onSubmit={this.handleSubmit}
        setFormDataChanged={onFormDataChanged}
        resetDataChanged={onResetDataChanged}
        changed={formDataChanged}
        send={this.send}
        openNotification={openNotification}
      />
    );
  }
}

export default withRouter(withStyles(styles)(Emprunt));
