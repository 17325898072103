import Yup from "yup";

const schema = {
/*  idlocal: Yup.number()
    .required("Id Local est requis.")
    .typeError("Ça doit être un nombre."),
  cap_indexation: Yup.number()
    .required("Cap indexation est requis.")
    .typeError("Ça doit être un nombre."),
  loyer_contractuel: Yup.number()
    .required("Loyer contractuel est requis.")
    .typeError("Ça doit être un nombre."),
  loyer_actuel: Yup.number()
    .required("Loyer actuel est requis.")
    .typeError("Ça doit être un nombre."),
  annee: Yup.number()
    .required("Année est requis.")
    .typeError("Ça doit être un nombre."),
  trimestre: Yup.number()
    .required("Trimestre est requis.")
    .typeError("Ça doit être un nombre."),
  indice_reference: Yup.string()
    .required("Indice est requis.")
    .min(3, "Veuillez sélectionner une valeur"),
  variation_indice: Yup.number()
    .required("Indice est requis.")
    .typeError("Ça doit être un nombre."),
  nom_du_locataire: Yup.string(),//.required("Nom du locataire est requis."),
  etage: Yup.number()
    .required("Etage est requis.")
    .typeError("Ça doit être un nombre."),
*/
  tunnel_indexation: Yup.string().matches(/^[-][0-9]+[+][0-9]+$/, {
    excludeEmptyString: true,
    message: "Le tunnel d'indexation doit être de la forme -[chiffre]+[chiffre], -2+6 par ex. ",
  })
/*,
  date_effet_du_bail: Yup.date()
    .required("Date effet est requise.")
    .typeError("Ça doit être une date."),
  date_dentree_du_locataire: Yup.date()
    .required("Date entrée est requise.")
    .typeError("Ça doit être une date."),
  date_fin_bail: Yup.date()
    .required("Date fin bail est requise.")
    .typeError("Ça doit être une date."),
  duree_preavis: Yup.number()
    .required("Durée préavis est requise.")
    .typeError("Ça doit être un nombre."),
  valeur_locative: Yup.number()
    .required("Valeur locative est requise.")
    .typeError("Ça doit être un nombre."),
   type_de_bail_1: Yup.number()
     .required("Vous devez fournir un type de bail.")
     .typeError("Ça doit être un nombre."),
  depot_de_garantie: Yup.number().typeError("Ça doit être un nombre."),
  charges_refacturees: Yup.string(),
  affectation_bureau: Yup.number().typeError("Ça doit être un nombre."),
  affectation_reserve: Yup.number().typeError("Ça doit être un nombre."),
  affectation_commerce: Yup.number().typeError("Ça doit être un nombre."),
  affectation_stockage: Yup.number().typeError("Ça doit être un nombre."),
  affectation_res_sous_sol: Yup.number().typeError("Ça doit être un nombre."),
  affectation_comm_sous_sol: Yup.number().typeError("Ça doit être un nombre."),
*/
};

export default Yup.object().shape(schema);
