import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AmortissementCouverture from "../Emprunt/AmortissementCouverture";
import withGlobals from "../withGlobals";
import LogoArrow from "../arrowleft.png";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  adresse: {
    padding: theme.spacing.unit * 2,
    fontWeight: "bold",
  },
  actions: {
    display: "flex",
  },
  label: {
    textAlign: "left",
    paddingLeft: theme.spacing.unit,
  },
  value: {
    textAlign: "right",
    padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
    fontWeight: "bold",
  },
  separation: {
    color: "silver",
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    paddingBottom: 0,
    textAlign: "left",
  },
  financement: {
    marginTop: theme.spacing.unit * 2,
  },
  details: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class DetailsTrancheEmpruntMobile extends Component {
  state = {
    loadingAmortissements: true,
    amortissements: [],
    couverture: [],
  };

  componentDidMount() {
    this.props
      .fetch({
        url: `/amortissements/${this.props.emprunt.idamortissement}`,
      })
      .then(res => {
        const amortissements = JSON.parse(res.amortissements);
        const amortissements_finance_active = JSON.parse(res.amortissements_finance_active);
        const couverture = res.couverture ? JSON.parse(res.couverture) : [];
        if (amortissements_finance_active && amortissements_finance_active.length > 0) {
          this.setState({
            loadingAmortissements: false,
            amortissements: amortissements_finance_active,
            couverture,
          });
        } else {
          this.setState({
            loadingAmortissements: false,
            amortissements,
            couverture,
          });
        }
      })
      .catch(e => {
        console.log("error", e);
        this.setState({
          loadingAmortissements: false,
          amortissements: [],
          couverture: [],
        });
      });
  }

  componentDidUpdate = () => {
    window.scrollTo(0, 0);
  }; 
  render() {
    console.log("tranches");
    const { emprunt } = this.props;
    const { classes } = this.props;
    const { amortissements, couverture, loadingAmortissements } = this.state;
    console.log(loadingAmortissements, emprunt, couverture, amortissements);
    if (loadingAmortissements || amortissements.length === 0) {
      return null;
    }
    console.log("ici...");
    return (
<div>
<div className={classes.info}  style={{marginTop: "-8px", float:"left"}} >
<Button color="primary" title="Précédent" onClick={() => {  this.props.history.goBack(); }}>
        <img src={LogoArrow} alt="Précédent" width="30px" />
</Button>
</div>
      <AmortissementCouverture
        layout="mobile"
        emprunt={emprunt}
        amortissements={amortissements}
        couverture={couverture}
      />
</div>
    );
  }
}

export default withStyles(styles)(withRouter(withGlobals(DetailsTrancheEmpruntMobile)));
