import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "./withGlobals";
import ReactExport from "react-export-excel";
import ReactApexChart from 'react-apexcharts'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class HistoriqueConnexion extends React.Component {
  //  constructor(props) {
  //  super(props);
  state = {
    dialogOpen: true,
    historiqueConnexion: [],
    series: [{
              name: this.getDate(0),
              data: []
            }, {
              name: this.getDate(1),
              data: []
            }, {
              name: this.getDate(2),
              data: []
            }, {
              name: this.getDate(3),
              data: []
            }, {
              name: this.getDate(4),
              data: []
            }, {
              name: this.getDate(5),
              data: []
            }, {
              name: this.getDate(6),
              data: []
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                }
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                  }
                }
              }],
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              xaxis: {
                type: 'datetime',
                categories: [],
              },
              legend: {
                position: 'right',
                offsetY: 40
              },
              fill: {
                opacity: 1
              }
            },
  };
  //  }

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
    window.location = "/";
  };

   getDate(numberOfDayToRemove) {
	var date_ob = new Date();
	date_ob.setDate(date_ob.getDate() - numberOfDayToRemove);
	// adjust 0 before single digit date
let date = ("0" + date_ob.getDate()).slice(-2);

// current month
let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

// current year
let year = date_ob.getFullYear();

// prints date in YYYY-MM-DD format
return (date + "/" + month + "/"+ year);
  }

   getEnglishDate(numberOfDayToRemove) {
        var date_ob = new Date();
        date_ob.setDate(date_ob.getDate() - numberOfDayToRemove);
        // adjust 0 before single digit date
let date = ("0" + date_ob.getDate()).slice(-2);

// current month
let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

// current year
let year = date_ob.getFullYear();

// prints date in YYYY-MM-DD format
return (year + "-" + month + "-"+ date);
  }

setTableOfUsers(){
	var users = [];
	this.state.historiqueConnexion.forEach(user =>
	{
		if (!users.includes(user.prenom)) {
			users.push(user.prenom);
		}
	}
	);

var series = [{
              name: this.getDate(0),
              data: this.getNumberOfConnexionPerDay(0, users),
            }, {
              name: this.getDate(1),
              data: this.getNumberOfConnexionPerDay(1, users),
            }, {
              name: this.getDate(2),
              data: this.getNumberOfConnexionPerDay(2, users),
            }, {
              name: this.getDate(3),
              data: this.getNumberOfConnexionPerDay(3, users),
            }, {
              name: this.getDate(4),
              data: this.getNumberOfConnexionPerDay(4, users),
            }, {
              name: this.getDate(5),
              data: this.getNumberOfConnexionPerDay(5, users),
            }, {
              name: this.getDate(6),
              data: this.getNumberOfConnexionPerDay(6, users),
            }]

this.setState({series: series});

var options = {
		  chart: {
			type: 'bar',
			height: 350,
			stacked: true,
			toolbar: {
			  show: true
			},
			zoom: {
			  enabled: true
			}
		  },
		  responsive: [{
			breakpoint: 480,
			options: {
			  legend: {
				position: 'bottom',
				offsetX: -10,
				offsetY: 0
			  }
			}
		  }],
		  plotOptions: {
			bar: {
			  horizontal: false,
			},
		  },
		  xaxis: {
			type: 'text',
			categories: users,
		  },
		  legend: {
			position: 'right',
			offsetY: 40
		  },
		  fill: {
			opacity: 1
		  }
		} ;
this.setState({options: options});
}


getNumberOfConnexionPerDay(numberOfDaysToRemove, users){
var numbers = [];
var dateForSearch = this.getEnglishDate(numberOfDaysToRemove); 

users.forEach( user1 =>
{
var nombre = 0;
this.state.historiqueConnexion.forEach(user =>
        {
                if ((user1 === user.prenom) && (user.date.includes(dateForSearch))) {
                        nombre++;
                }
        }
        );
numbers.push(nombre);
});
return numbers;
}




  componentDidMount = () => {
     this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          historiqueConnexion: "GET"
        }
      })
      .then(historiqueConnexion => {
         this.setState({historiqueConnexion: historiqueConnexion});
	 this.setTableOfUsers();
      })
      .catch(err => console.log(err));


  };


  render() {
    const { open, handleClose } = this.props;
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form >
          <DialogTitle id="form-dialog-title">Historique de Connexion</DialogTitle>
          <DialogContent>
            <Grid
              container
              justify="space-around"
              alignItems="flex-start"
              direction="row"
              spacing={16}
            >

<Grid item xs={12}>

<div id="chart">
  <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
</div>
{/*
                        <ReactTable
        data={this.state.historiqueConnexion.map((t, index) => {
          return { ...t};
        })}
        columns={[
          {
            Header: "Login",
            accessor: "login",
            width: 250,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          },
          {
            Header: "Nom",
            accessor: "nom",
            width: 200,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          },
		  {
            Header: "Prénom",
            accessor: "prenom",
            width: 200,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          },
		  {
            Header: "Date",
            accessor: "date",
            width: 260,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          },
        ]}
        noDataText="Aucune donnée"
        showPagination={false}
        pageSize={this.state.historiqueConnexion.length}
        className="-striped -highlight noScroll"
      />

*/}
                </Grid>

	  </Grid>
            <DialogActions>

<ExcelFile filename="Historique de Connexion MADAR" element={<Button
  variant="contained"
  color="primary"
  fullWidth
>
Télécharger l'historique de connexion
</Button>}>
		<ExcelSheet data={this.state.historiqueConnexion} name="Historique de Connexion MADAR">
				<ExcelColumn label="Login" value="login"/>
				<ExcelColumn label="Prénom" value="prenom"/>
				<ExcelColumn label="Nom" value="nom"/>
				<ExcelColumn label="Date" value="date"/>
		</ExcelSheet>
</ExcelFile>
              <Button onClick={this.handleCloseDialog} color="textSecondary">
                Annuler
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withGlobals(HistoriqueConnexion);

