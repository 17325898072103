import Yup from "yup";

const schema = {
  // idactifpatrimonial: Yup.number()
  //   .required("Id actif requis.")
  //   .typeError("Ça doit être un nombre."),
  // surface_totale: Yup.number().typeError("Ça doit être un nombre."),
  // etage: Yup.number()
  //   .required("Etage requis.")
  //   .typeError("Ça doit être un nombre."),
  montant_financement: Yup.number()
    .min(1, "Le montant doit être d'au moins 1 €")
    .required("Vous devez donner ce montant"),
  duree_financement: Yup.number().min(1, "La durée doit être d'au moins 1 mois"),
  nombre_echeances: Yup.number().min(1, "Il doit y avoir au moins une échéance"),
  //taux: Yup.number().min(0.0099, "Le taux doit être d'au moins 0.01%"),
  //   .required("Nombre parking ext requis.")
  //   .typeError("Ça doit être un nombre."),
  // nombre_parking_int: Yup.number()
  //   .default(0)
  //   .required("Nombre parking int requis.")
  //   .typeError("Ça doit être un nombre."),
  // surface_etage: Yup.number()
  //   .default(0)
  //   .required("Surface étage requise.")
  //   .typeError("Ça doit être un nombre."),
  // surface_rdc: Yup.number()
  //   .default(0)
  //   .required("Surface rdc requise.")
  //   .typeError("Ça doit être un nombre."),
  // surface_sous_sol: Yup.number()
  //   .default(0)
  //   .required("Surface sous-sol requise.")
  //   .typeError("Ça doit être un nombre."),
};

export default Yup.object().shape(schema);
