import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import NumberFormat from "react-number-format";
import moment from "moment";

const styles = theme => ({
  adresse: {
    padding: theme.spacing.unit * 2,
    fontWeight: "bold",
  },
  actions: {
    display: "flex",
  },
  label: {
    textAlign: "left",
    paddingLeft: theme.spacing.unit,
  },
  value: {
    textAlign: "right",
    padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
    fontWeight: "bold",
  },
  separation: {
    color: "silver",
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    paddingBottom: 0,
    textAlign: "left",
  },
  financement: {
    marginTop: theme.spacing.unit * 2,
  },
  details: {
    marginBottom: theme.spacing.unit * 2,
  },
});
//
// const diviseurFrequence = {
//   mensuelle: 1,
//   trimestrielle: 3,
//   semestrielle: 6,
//   annuelle: 12,
// };

class TranchesEmpruntMobile extends Component {
  navigateToTranche = no =>
    this.props.history.push(`/emprunts_mobile/${this.props.idInvestissement}?noTranche=${no}`);

  render() {
    const { banques, tranches, classes } = this.props;
    return tranches.map((tranche, index) => {
      const {
        montant_financement,
        date_premiere_echeance,
        duree_financement,
        frequence,
        taux,
        valeur_residuelle_emprunt,
        type_emprunt,
        marge_banque,
        couverture_taux,
        montant_cap,
        date_debut_cap,
        strike_cap,
        montant_swap,
        date_debut_swap,
        taux_swap,
        idbanque,
        duree_swap,
        duree_cap,
        CRD,
        duree_residuelle,
      } = tranche;

      //const nombre_echeances = parseInt(parseInt(duree_financement, 10) / diviseurFrequence[frequence], 10);

      return (
        <Paper elevation={2} className={classes.financement} >
          <Grid item xs={12} className={classes.label}>
            Tranche <strong>{index + 1}</strong>
          </Grid>
          {idbanque && (
            <Grid item xs={12} className={classes.label}>
              Banque <strong>{banques[(idbanque-1)].nom_banque}</strong>
            </Grid>
          )}
          <Grid item xs={12} className={classes.label}>
            Première échéance le <strong>{moment(date_premiere_echeance).format("DD/MM/YYYY")}</strong>
          </Grid>
          <Grid item xs={12} className={classes.label}>
            Montant emprunté{" "}
            <strong>
              {" "}
              <NumberFormat
                value={montant_financement}
                displayType={"text"}
                thousandSeparator=" "
                suffix={" €"}
              />
            </strong>
            {" sur "}
            <strong>{duree_financement}</strong> mois
          </Grid>
          <Grid item xs={12} className={classes.label}>
            Fréquence de remboursement <strong>{frequence}</strong>
          </Grid>
          {type_emprunt !== "taux_variable" && (
            <Grid item xs={12} className={classes.label}>
              Taux de <strong>{taux} %</strong>
            </Grid>
          )}
          {type_emprunt === "taux_variable" && (
            <Grid item xs={12} className={classes.label}>
              Euribor 3M <strong>{marge_banque} %</strong>
            </Grid>
          )}
          {!!valeur_residuelle_emprunt && (
            <Grid item xs={12} className={classes.label}>
              <span style={{display:"flex", justifyContent:"space-between", marginRight:"10px"}}>
                Valeur résiduelle :{" "}
                <strong>
                  {" "}
                  <NumberFormat
                    value={valeur_residuelle_emprunt}
                    displayType={"text"}
                    thousandSeparator=" "
                    suffix={" €"}
                  />
                </strong>
              </span>
            </Grid>
          )}
          {duree_residuelle && (
            <Grid item xs={12} className={classes.label}>
              <span style={{display:"flex", justifyContent:"space-between",  marginRight:"10px"}}>
                durée résiduelle :{" "}
                <strong>
                  {" "}
                  {duree_residuelle}
                  {" mois"}
                </strong>
              </span>
            </Grid>
          )}
          {CRD && (
            <Grid item xs={12} className={classes.label} >
              <span style={{display:"table", margin: "0 auto", justifyContent:"space-between"}} >
                CRD :{" "}
                <strong>
                  {" "}
                  <NumberFormat value={CRD} displayType={"text"} thousandSeparator=" " suffix={" €"} />
                </strong>
              </span>
            </Grid>
          )}
          {type_emprunt === "taux_variable" &&
            couverture_taux && [
              <Grid item xs={12} className={classes.label}>
                Couverture de taux : <strong>{couverture_taux.toUpperCase()}</strong>
                <div style={{ paddingLeft: "20px" }}>
                  <strong>
                    {!!montant_cap && (
                      <NumberFormat
                        value={montant_cap}
                        displayType={"text"}
                        thousandSeparator=" "
                        suffix={" €"}
                      />
                    )}
                    {!!montant_swap && (
                      <NumberFormat
                        value={montant_swap}
                        displayType={"text"}
                        thousandSeparator=" "
                        suffix={" €"}
                      />
                    )}
                  </strong>
                  <br />
                  À partir du{" "}
                  <strong>
                    {!!date_debut_cap && moment(date_debut_cap).format("DD/MM/YYYY")}
                    {!!date_debut_swap && moment(date_debut_swap).format("DD/MM/YYYY")}
                  </strong>
                  <br />
                  {!!duree_swap && (
                    <span>
                      Durée swap : <strong>{duree_swap} année(s)</strong>
                    </span>
                  )}
                  {!!duree_cap && (
                    <span>
                      Durée cap : <strong>{duree_cap} année(s)</strong>
                    </span>
                  )}
                  <br />
                  {!!strike_cap && (
                    <span>
                      Strike <strong>{strike_cap} %</strong>
                    </span>
                  )}
                  {!!taux_swap && (
                    <span>
                      Taux <strong>{taux_swap} %</strong>
                    </span>
                  )}
                </div>
              </Grid>,
            ]}
          <Grid item  style={{paddingTop: "20px"}} >
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={6}>
                <Button
                  onClick={() => this.navigateToTranche(index + 1)}
                  variant="raised"
                  fullWidth
                  color="primary"
                  className={classes.details}
                >
                  Détails de la tranche
                </Button>
              </Grid>
            </Grid>
          </Grid>
<Grid item>

            <Grid container direction="row" justify="center" alignItems="center">
<Grid item xs={6}>
            </Grid>
 </Grid>
          </Grid>

        </Paper>
      );
    });
  }
}

export default withStyles(styles)(withRouter(TranchesEmpruntMobile));
