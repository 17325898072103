import Yup from "yup";

const schema = {
  idactifpatrimonial: Yup.number()
    .required("Id actif requis.")
    .typeError("Ça doit être un nombre."),
  surface_totale: Yup.number().typeError("Ça doit être un nombre."),
//  etage: Yup.number()
//    .required("Etage requis.")
//    .typeError("Ça doit être un nombre."),
//  nombre_parking_ext: Yup.number()
//    .min(0, "Minimum 0")
//    .required("Nombre parking ext requis.")
//    .typeError("Ça doit être un nombre."),
//  nombre_parking_int: Yup.number()
//    .min(0, "Minimum 0")
//    .required("Nombre parking int requis.")
//    .typeError("Ça doit être un nombre."),
  surface_etage: Yup.number()
    .required("Surface étage requise.")
    .typeError("Ça doit être un nombre."),
  surface_rdc: Yup.number()
    .required("Surface rdc requise.")
    .typeError("Ça doit être un nombre."),
  surface_sous_sol: Yup.number()
    .required("Surface sous-sol requise.")
    .typeError("Ça doit être un nombre."),
};

export default Yup.object().shape(schema);
