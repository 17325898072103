import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Prompt } from "react-router-dom";
import { Route } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Actif from "./Actif";
import DatasLoader from "./DatasLoader";
import Centered from "../../layout/Centered";
import ListeActifs from "../../common/ListeActifs";
import withGlobals from "../withGlobals";
import columnData from "./columnData";
import PrivateRoute from "../../Route/PrivateRoute";

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: 0,
      paddingRight: 0,
      width: "500px",
    },
    zoneAjout: {
      maxHeight: "calc(100vh - 255px)",
      minHeight: "calc(100vh - 255px)",
    },
  };
};

class Actifs extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    openNotification: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { location: { pathname } } = props;
    const actifId = parseInt(pathname.split("/")[2], 10);
    this.state = {
      loading: actifId ? true : false,
      actifs: [],
      actifsFiltred: [],
      banques: [],
      typesFinancement: [],
      societes: [],
      creationMode: false,
      formDataChanged: false,
      textFiltered: false,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.handleResetDataChanged();
    }
  };

  componentDidMount = () => {
    this.props.fetch({ url: "/banques/" }).then(banques => {
      this.props.fetch({ url: "/typefinancements/" }).then(typesFinancement => {
        this.setState({
          // actifs: actifs.data,
          banques,
          typesFinancement,
        });
      });
    });
  };

  handleSocietesLoaded = societes => this.setState({ societes, loading: false });

  handleSocietesAdded = societe => {
    this.props.openNotification("success", `Société ${societe.nom_societe} ajoutée`, undefined, 4000);
    this.setState({
      societes: this.state.societes.concat([societe]),
      loading: false,
    });
  };

  handleActifsLoaded = actifs => this.setState({ actifs });

  handleActifFiltred = (actifsFiltred, textFiltered) => this.setState({ actifsFiltred, textFiltered });

  navigateToCreationMode = () => this.props.history.push("/actifs/nouveau");

  handleDataChanged = () => this.setState({ formDataChanged: true });

  handleResetDataChanged = () => this.setState({ formDataChanged: false });

  handleDelete = id => {
    this.setState({
      actifs: this.state.actifs.filter(a => a.idactifpatrimonial !== id),
      formDataChanged: false,
    });
  };

  handleUpdate = values => {
    this.setState({
      actifs: this.state.actifs.map(
        a => (a.idactifpatrimonial !== values.idactifpatrimonial ? a : { ...a, ...values }),
      ),
      formDataChanged: false,
    });
  };

  handleCreate = values =>
    this.setState({
      actifs: this.state.actifs.concat([values]),
      formDataChanged: false,
    });

  handleClick = (e, selected) => {
    const { history, location: { pathname, search } } = this.props;
    this.setState({ formDataChanged: false });

    if (pathname !== "/actifs" && pathname !== `/actifs/${selected}`) {
      history.push(`/actifs/${selected}${search}`);
      return;
    }
    history.push(
      pathname !== "/actifs" || pathname === `/actifs/${selected}`
        ? "/actifs"
        : `/actifs/${selected}${search}`,
    );
  };

  render() {
    const { match, location: { pathname }, openNotification, user } = this.props;
    if (false && (!user || !user.login)) return <h1>Acces denied</h1>;

    const {
      actifs,
      banques,
      societes,
      loading,
      formDataChanged,
      typesFinancement,
      actifsFiltred,
      textFiltered,
    } = this.state;

    const idActifUrl = pathname.split("/")[2];
    const actifId = parseInt(idActifUrl, 10);

    return (
      <Centered alignItems="flex-start" justify="center">
        {!actifId &&
          idActifUrl !== "nouveau" && (
            <Grid item>
              <ListeActifs
                onSelectRow={this.handleClick}
                datas={actifs}
                onDatasLoaded={this.handleActifsLoaded}
                onDatasFiltred={this.handleActifFiltred}
                onSocietesLoaded={this.handleSocietesLoaded}
                onSocieteAdded={this.handleSocietesAdded}
                onAddClicked={this.navigateToCreationMode}
                apiPathname="/actifs/"
                datasName="actif(s)"
                idField="idactifpatrimonial"
                addButtonTitle="Créer un nouvel actif"
                columnData={columnData}
                showIdActif={true}
              />
            </Grid>
          )}
        <Prompt
          when={formDataChanged}
          message={location => `Si vous cliquez sur OK, les données ne seront pas sauvegardées`}
        />
        {/* <PrivateRoute path={`${match.url}/nouveau`}
        exact
        component={Actif}
        actif={{}}
                actifs={actifsFiltred}
                banques={banques}
                societes={societes}
                typesFinancement={typesFinancement}
                onFormDataChanged={this.handleDataChanged}
                onResetDataChanged={this.handleResetDataChanged}
                formDataChanged={formDataChanged}
                onDelete={this.handleDelete}
                onUpdate={this.handleUpdate}
                onCreate={this.handleCreate}
                openNotification={openNotification}  /> */}
        <Route
          path={`${match.url}/nouveau`}
          exact
          render={() => (
            <Grid item sm={11}>
              <Actif
                actif={{}}
                actifs={actifsFiltred}
                banques={banques}
                societes={societes}
                typesFinancement={typesFinancement}
                onFormDataChanged={this.handleDataChanged}
                onResetDataChanged={this.handleResetDataChanged}
                formDataChanged={formDataChanged}
                onDelete={this.handleDelete}
                onUpdate={this.handleUpdate}
                onCreate={this.handleCreate}
                openNotification={openNotification}
              />
            </Grid>
          )}
        />
        <Route
          path={`${match.url}/:actifId(\\d+)`}
          exact
          render={() =>
            !loading && actifs.length === 0 ? null : (
              <Grid item sm={11}>
                <DatasLoader actifs={actifsFiltred} actifId={actifId} societes={societes}>
                  <Actif
                    banques={banques}
                    filtred={textFiltered}
                    typesFinancement={typesFinancement}
                    societes={societes}
                    onFormDataChanged={this.handleDataChanged}
                    onResetDataChanged={this.handleResetDataChanged}
                    formDataChanged={formDataChanged}
                    onDelete={this.handleDelete}
                    onUpdate={this.handleUpdate}
                    openNotification={openNotification}
                  />
                </DatasLoader>
              </Grid>
            )
          }
        />
      </Centered>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Actifs)));
