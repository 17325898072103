import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import DownloadIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import ListeSocietes from "./ListeSocietes";
import ListeBanques from "./ListeBanques";
import withGlobals from "../pages/withGlobals";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = theme => ({
  container: {
    backgroundColor: "transparent",
    border: `solid 1px ${theme.palette.primary.main}`,
    marginTop: 3,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "312px",
  },
  button: {
    margin: theme.spacing.unit / 2,
    height: 40,
    minHeight: 40,
    width: 40,
    minWidth: 40,
  },
  buttonActif: {
    margin: theme.spacing.unit,
  },
  listeSocietes: {
    minWidth: 246,
  },
});

class ZoneRechercheTable extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    texteSearch: PropTypes.string,
    societeSelected: PropTypes.number,
    inDialog: PropTypes.bool.isRequired,
    showIdActif: PropTypes.bool.isRequired,
    hideSocietes: PropTypes.bool.isRequired,
    hideBanques: PropTypes.bool.isRequired,
    showLocataireTextField: PropTypes.bool.isRequired,
    addButtonTitle: PropTypes.string.isRequired,
    onSocieteChange: PropTypes.func.isRequired,
    onSocieteAjoutee: PropTypes.func.isRequired,
    onBanqueChange: PropTypes.func.isRequired,
    onBanqueAjoutee: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onAddClicked: PropTypes.func.isRequired,
    onChangeFiltreAcquisition: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showLocataireTextField: false,
    societeSelected: 0,
    banqueSelected:0,
    inDialog: false,
    hideSocietes: false,
    hideBanques: true,
    showIdActif: false,
    searchFields: [
      {
        label: "Id Local",
        name: "id",
      },
      {
        label: "Id Actif",
        name: "idactifpatrimonial",
      },
      {
        label: "Code postal",
        name: "codePostal",
      },
      {
        label: "Ville",
        name: "ville",
      },
      {
        label: "Adresse",
        name: "adresse",
      },
      {
        label: "Surface",
        name: "surfaceTotale",
      },
      {
        label: "Nom du financement",
        name: "nom_portefeuille",
      },
    ],
  };

  state = {
    societes: [],
    banques: [],
    actifsAcquis: false,
  };

  componentDidMount = () => {
    if (!this.isCancelled) {
      this.props.fetch({ url: "/societes/" }).then(societes => {
        var array = [];
        array.push(societes[0]);
        array.push(societes[1]);
        if (this.props.onLoaded) {
          this.props.onLoaded(societes);
        }
        this.setState({ societes: array });
      }); 
      this.props.fetch({method: "GET", url: `/banques/` }).then(banques => {
banques.sort(function(a, b){
    if(a.idbanque === 38) { return -1; }
    if(a.nom_banque < b.nom_banque) { return -1; }
    if(a.nom_banque > b.nom_banque) { return 1; }
    return 0;
})
        if (this.props.onLoaded) {
          this.props.onLoaded(banques);
        }
        this.setState({ banques: banques });
      });
    }
  };

  componentWillUnmount() {
    this.isCancelled = true;
  }

  handleClose = () => {
    this.setState({ dialogSocieteOpen: false });
  };

  handleOpen = () => {
    this.setState({ dialogSocieteOpen: true });
  };

  handleSocieteAjoutee = societe => {
    this.setState({ societes: this.state.societes.concat([societe]) });
    this.props.onSocieteAjoutee(societe);
  };

  render() {
    const {
      classes,
      onSocieteChange,
      onBanqueChange,
      onSearchChange,
      societeSelected,
      banqueSelected,
      onAddClicked,
      tableauAmortissements,
      addButtonTitle,
      uniquementBiensAcquis,
      uniquementLocauxSansBail,
      onChangeFiltreAcquisition,
      onChangeFiltreBail,
      location: { pathname },
      inDialog,
      showIdActif,
      showLocataireTextField,
      hideSocietes,
      hideBanques,
      searchFields,
      readOnly,
      addDownloadAmortissements,
      disabledDownloadAmortissements,
    } = this.props;
    const { societes, banques } = this.state;

    return (
      <Grid>
      <Grid
        container
        alignItems="center"
        direction="column"
        justify="flex-start"
        className={classes.container}
        spacing={0}
      >
        {searchFields
          .filter(s => {
            if (showIdActif) return true;
            if (!showIdActif && s.name === "idactifpatrimonial") return false;
            return true;
          })
          .map((sF, index) => (
            <Grid item key={sF.name}>
              <TextField
                label={sF.label}
                name={sF.name}
                className={classes.textField}
                onChange={onSearchChange}
                autoFocus={index === 0}
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          ))}
        {showLocataireTextField && (
          <Grid item>
            <TextField
              label="Nom du locataire"
              name="nom_du_locataire"
              className={classes.textField}
              onChange={onSearchChange}
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {!hideSocietes && (
          <Grid item>
            <ListeSocietes
              onChange={onSocieteChange}
              societes={societes}
              societeSelected={societeSelected}
              className={classes.listeSocietes}
            />
          </Grid>
        )} 
        {!hideBanques && (
          <Grid item>
            <ListeBanques
              onChange={onBanqueChange}
              banques={banques}
              banqueSelected={banqueSelected}
              className={classes.listeSocietes}
            />
          </Grid>
        )}
        {pathname.indexOf("actifs") !== -1 && (
          <Grid item>
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={uniquementBiensAcquis}
                      onChange={onChangeFiltreAcquisition}
                      value="simple"
                      color="primary"
                    />
                  }
                  label="Uniquement les actifs non acquis"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {pathname.indexOf("locaux") !== -1 && (
          <Grid item>
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={uniquementLocauxSansBail}
                      onChange={onChangeFiltreBail}
                      value="simple"
                      color="primary"
                    />
                  }
                  label="Uniquement les locaux sans bail"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!inDialog &&
          !readOnly && (
            <Grid item>
              <Button
                onClick={onAddClicked}
                size="small"
                variant="raised"
                color="primary"
                aria-label="Add"
                fullWidth
                className={classes.buttonActif}
              >
                <AddIcon />
                {addButtonTitle}
              </Button>
            </Grid>
          )}
          
          
      </Grid>
      <Grid
        container
        alignItems="center"
        direction="column"
        justify="flex-start"
        spacing={0}
      >
        {addDownloadAmortissements && (
            <Grid item style={{width: "100%"}}>
              <ExcelFile filename="Tableau d'amortissements MADAR" element={<Button
              variant="contained"
              color="primary"
              fullWidth
              disabled = {disabledDownloadAmortissements}
              style={{ marginTop: "10px"}}
            >
              <DownloadIcon />
            Tableau d'amortissements
            </Button>}>
                <ExcelSheet data={tableauAmortissements} name="Tableau d'amortissements MADAR">
                    <ExcelColumn label="ID Investissement" value="idemprunt"/>
                    <ExcelColumn label="ID Echéance" value="echeances_numero"/>
                    <ExcelColumn label="Date de l’échéance" value="date_echeance"/>
                    <ExcelColumn label="Capital restant dû début période" value="valeur_residuelle"/>
                    <ExcelColumn label="Capital amorti" value="capital_rembourse"/>
                    <ExcelColumn label="Intérêts Versés" value="montant_interets"/>
                    <ExcelColumn label="Montant de l’échéance " value="montant_echeance"/>
                    <ExcelColumn label="Capital Fin de période" value="capital"/>
                </ExcelSheet>
            </ExcelFile>
            </Grid>
          )}
      </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withGlobals(ZoneRechercheTable)));
