import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../../pages/withGlobals";

class DialogProjectionIndexationLoyersOld extends React.Component {
  state = {
    dialogOpen: true,
    vr_lissee_mode: "false",
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
    window.location = "/";
  };


  componentDidMount = () => {
     this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          parametrage: "GET"
        }
      })
      .then(parametrage => {
document.getElementById("charge_forum").value = Number.parseFloat(parametrage[0]["charge_forum"]).toFixed(2);
document.getElementById("charge_padre").value = Number.parseFloat(parametrage[0]["charge_padre"]).toFixed(2);
this.setState({vr_lissee_mode: parametrage[0]["vr_lissee_mode"] == true ? "true" : "false"});
      })
      .catch(err => console.log(err));
  };

  handleSave = () => {
    this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          charge_forum: document.getElementById("charge_forum").value,
          charge_padre: document.getElementById("charge_padre").value,
          vr_lissee_mode: this.state.vr_lissee_mode == "true" ? true : false,
          parametrage: "charges"
        }
      })
      .then(res => {
        this.props.handleCloseDialog();
      })
      .catch(err =>  this.props.openNotification("success", "Paramétrage modifié", false));
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">Paramètrage</DialogTitle>
          <DialogContent>
            <Grid
              container
              justify="space-around"
              alignItems="flex-start"
              direction="row"
              spacing={16}
            >
              {(
                <Grid item xs={12}>

                </Grid>
              )}
              <Grid item xs={12}>
              <Grid
                  container
                  alignItems="flex-start"
                  direction="row"
                  justify="center"
                  style={{ paddingLeft: "20px" }}
                >
              <Grid item xs={6}>
                Modification des charges emprunts
                </Grid>
                </Grid>
                </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  direction="row"
                  style={{ paddingLeft: "20px" }}
                >
                  <Grid item xs={6} style={{ minHeight: "50px" }}>
                    <TextField
                      margin="dense"
                      defaultValue="0"
                      id="charge_padre"
                      label="Charge d'emprunt pardes"
                      type="text"
		                  defaultValue="0"
                      fullWidth
                      spacing={4}
                    />
                    <TextField
                      margin="dense"
                      defaultValue="0"
                      id="charge_forum"
                      label="Charge d'emprunt forum"
                      type="text"
                      defaultValue="0"
                      fullWidth
                      spacing={4}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <Button onClick={this.handleCloseDialog}>
                Annuler
              </Button>
              <Button
                onClick={this.handleSave}
                variant="raised"
                color="primary"
              >
                Valider ce paramètrage
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withGlobals(DialogProjectionIndexationLoyersOld);

