import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const CouvertureSwitch = ({ checked, handleToggle }) => (
  <FormControlLabel
    control={<Switch checked={checked} onChange={handleToggle} value="checkedA" />}
    label={checked ? "avec couverture" : "sans couverture"}
  />
);

export default CouvertureSwitch;
