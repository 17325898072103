import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#af0000",
      main: "#a60000",
      dark: "#6d0000"
    },
    secondary: {
      light: "#fff",
      main: "#c5c5c5",
      dark: "#b59b9b"
    }
    // action: {
    //   hover: '#f05545',
    // },
  },
  overrides: {
    MuiInput: {
      disabled: {
        color: "red"
      }
    }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
