import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import withGlobals from "../withGlobals";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LogoArrow from "../arrowleft.png";

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 1,
      paddingBottom: theme.spacing.unit * 2
    },
    info: {
      textAlign: "left",
      fontSize: "0.9em"
    },
    pA: {
      textAlign: "center",
      borderTop: "solid 1px silver",
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit
    },
    commentaire: {
      textAlign: "center",
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      backgroundColor: "silver"
    },
    textR: {
      textAlign: "right"
    },
    textC: {
      textAlign: "center"
    }
  };
};

class Local extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    actif: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      oneItem: false,
      local: props.local,
      actif: null,
      societe: null,
      typefinancements: []
    };
  }

  componentWillReceiveProps = nextProps => {
    if (!this.props.localId !== nextProps.localId && nextProps.localId) {
var idForSearch = nextProps.localId;
    if (nextProps.localId.includes('+')) {
      idForSearch = idForSearch.substr(1);
      this.state.oneItem = true;
     }  else {
      this.state.oneItem = false;
}

      this.props
        .fetch({ url: `/locaux/${idForSearch}?bailactif=true` })
        .then(local => {
		this.setState({ local });
		 this.props.fetch({ url: `/actifs/${local.idactifpatrimonial}` }).then(actif => {
        		this.setState({ actif });
		});
	}
	);
    }
  };

  handleFinancer = index => {
    const { history } = this.props;
    const {actif} = this.state;
    if(!actif.financement){
    history.push(`/emprunts/nouveau?actifId=${actif.idactifpatrimonial}`);
} else {
    history.push(`/emprunts_mobile/${actif.idinvestissement}`);
}
  };


  navigateToLocaux = () => {
    const { actif, history } = this.props;
    history.push(`/locaux_mobile?actifId=${actif.idactifpatrimonial}`);
  };

  render() {
    const { classes } = this.props;
    const { local } = this.state;

    if (!local) {
      return (
        <Grid container alignItems="center" direction="row" justify="center">
          <Grid item>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      );
    }

    const surfaceTotaleLocal =
      parseInt(local.surface_etage, 10) +
      parseInt(local.surface_sous_sol, 10) +
      parseInt(local.surface_rdc, 10);

    return (
      <Grid container alignItems="stretch" direction="column" justify="center">
        <Paper elevation={3} className={classes.root}>
          <Grid item xs={12}>
            <div style={{ marginBottom: "8px" }}>
<div className={classes.info}  style={{marginTop: "-8px", float:"left"}} >
<Button color="primary" title="Précédent" onClick={() => { if (this.state.oneItem == true) { this.props.history.push(`/locaux_mobile`); } else { this.props.history.goBack(); }
 }}>
        <img src={LogoArrow} alt="Précédent" width="30px" />
</Button>
</div>

              <Typography variant="body1">
                {local.adresse}
                <br />
                {local.code_postal} {local.ville}
              </Typography>
            </div>
            <div className={classes.info}>
              Id de l'actif{" : "}
              <strong>{local.idactifpatrimonial}</strong>
              <br />
              <hr />
              Surface de l'étage{" : "}
              <strong>{local.surface_etage} m²</strong>
              <br />
              Surface du RDC{" : "}
              <strong>{local.surface_rdc} m²</strong>
              <br />
              Surface du sous-sol{" : "}
              <strong>{local.surface_sous_sol} m²</strong>
              <br />
              <hr />
              <div className={classes.textC}>
                Surface totale: <strong>{surfaceTotaleLocal} m²</strong>
              </div>
              Étage{" : "}
              <strong>{local.etage}</strong>
              <br />
              Nombre de parking extérieur{" : "}
              <strong>{local.nombre_parking_ext}</strong>
              <br />
              Nombre de parking intérieur{" : "}
              <strong>{local.nombre_parking_int}</strong>
              <br />
              Licence 4{" : "}
              <strong>{local.licence4 ? "oui" : "non"}</strong>
              <br />
              Extraction{" : "}
              <strong>{local.extraction ? "oui" : "non"}</strong>
              <br />
              Restauration{" : "}
              <strong>{local.restauration ? "oui" : "non"}</strong>
              <br />
              {local.activite_interdite && (
                <span>
                  Activité interdite{" : "}
                  <strong>{local.activite_interdite}</strong>
                </span>
              )}
              <br />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{marginRight: "5px"}}
              variant="raised"
              color="primary"
              component={Link}
              to={`/baux_mobile?idlocal=${local.idlocal}&fromLocal=${local.idlocal}`}
            >
              Baux de ce local
            </Button>
		<Button
		variant="raised"
                color="primary"
		onClick={this.handleFinancer}>
              Financement
            </Button>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Local)));
