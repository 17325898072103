import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Prompt } from "react-router-dom";
import { Route } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Bail from "./Bail";
import DatasLoader from "./DatasLoader";
import Centered from "../../layout/Centered";
import ListeActifs from "../../common/ListeActifs";
import withGlobals from "../withGlobals";
import columnData from "./columnData";
import customFilter from "./customFilter";

const styles = theme => {
  return {
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: 0,
      paddingRight: 0,
      width: "500px",
    },
    zoneAjout: {
      maxHeight: "calc(100vh - 255px)",
      minHeight: "calc(100vh - 255px)",
    },
  };
};

class Baux extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    openNotification: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { location: { pathname } } = props;
    const bailId = parseInt(pathname.split("/")[2], 10);
    this.state = {
      loading: bailId ? true : false,
      baux: [],
      typesBaux: [],
      bauxFiltred: [],
      typesActivites: [],
      creationMode: false,
      formDataChanged: false,
      textFiltered: false,
    };
  }
  componentWillReceiveProps = nextProps => {
    if (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.location.search !== nextProps.location.search
    ) {
      this.handleResetDataChanged();
    }
  };

  componentDidMount = () => {
    this.props.fetch({ url: "/typesbaux/" }).then(typesBaux => {
      this.props
        .fetch({ url: "/activites/" })
        .then(typesActivites => this.setState({ typesActivites, typesBaux }));
    });
  };

  handleBauxLoaded = baux =>
    this.setState({
      baux,
      loading: false,
    });

  handleBauxFiltred = (bauxFiltred, textFiltered) => this.setState({ bauxFiltred, textFiltered });

  handleSocietesAdded = societe =>
    this.props.openNotification("success", `Société ${societe.nom_societe} ajoutée`, undefined, 4000);

  navigateToCreationMode = () =>
    this.props.history.push(
      `/baux/nouveau${this.props.location.search !== "" ? `/${this.props.location.search}` : ""}`,
    );

  handleDataChanged = () => this.setState({ formDataChanged: true });

  handleResetDataChanged = () => this.setState({ formDataChanged: false });

  handleDelete = id => {
    this.setState({
      baux: this.state.baux.filter(a => a.idbail !== id),
      formDataChanged: false,
    });
  };

  handleUpdate = values => {
    this.setState({
      baux: this.state.baux.map(a => (a.idbail !== values.idbail ? a : { ...a, ...values })),
      formDataChanged: false,
    });
  };

  handleCreate = values =>
    this.setState({
      baux: this.state.baux.concat([values]),
      formDataChanged: false,
    });

  handleClick = (e, selected) => {
    const { history, location: { pathname, search } } = this.props;
    this.setState({ creationMode: false, formDataChanged: false });

    if (pathname !== "/baux" && pathname !== `/baux/${selected}`) {
      history.push(`/baux/${selected}${search}`);
      return;
    }
    history.push(
      pathname !== "/baux" || pathname === `/baux/${selected}` ? "/baux" : `/baux/${selected}${search}`,
    );
  };

  getLocalIdQuery = () => {
    return this.props.location.search.split("localId=")[1]
      ? parseInt(this.props.location.search.split("localId=")[1], 10)
      : undefined;
  };

  render() {
    const { match, location: { pathname }, openNotification, user } = this.props;

    if (false && (!user || !user.login)) return <h1>Acces denied</h1>;

    const {
      baux,
      typesBaux,
      typesActivites,
      loading,
      formDataChanged,
      bauxFiltred,
      textFiltered,
    } = this.state;

    const localId = this.getLocalIdQuery();
    const idLocalUrl = pathname.split("/")[2];
    const bailId = parseInt(idLocalUrl, 10);
    const apiPathname = `/baux/?bailactif=true${localId ? `&idlocal=${localId}` : ""}`;

    return (
      <Centered alignItems="flex-start" justify="center">
        {!bailId &&
          idLocalUrl !== "nouveau" && (
            <Grid item>
              <ListeActifs
                onSelectRow={this.handleClick}
                datas={baux.filter(b => (localId ? b.idlocal === localId : true))}
                onDatasLoaded={this.handleBauxLoaded}
                onDatasFiltred={this.handleBauxFiltred}
                onSocieteAdded={this.handleSocietesAdded}
                onAddClicked={this.navigateToCreationMode}
                apiPathname={apiPathname}
                datasName="baux"
                idField="idbail"
                addButtonTitle="Créer un nouveau bail"
                columnData={columnData}
                showLocataireTextField
                showIdActif
                filter={customFilter}
                searchFields={[
                  {
                    label: "Id Bail",
                    name: "idbail",
                  },
                  {
                    label: "Id Actif",
                    name: "idactifpatrimonial",
                  },
                  {
                    label: "Code postal",
                    name: "codePostal",
                  },
                  {
                    label: "Ville",
                    name: "ville",
                  },
                  {
                    label: "Adresse",
                    name: "adresse",
                  },
                  {
                    label: "Surface",
                    name: "surfaceTotale",
                  },
                  {
                    label: "Nom du locataire",
                    name: "nom_du_locataire",
                  },
                ]}
              />
            </Grid>
          )}
        <Prompt
          when={formDataChanged}
          message={location => `Si vous cliquez sur OK, les données ne seront pas sauvegardées`}
        />
        <Route
          path={`${match.url}/nouveau`}
          exact
          render={() => (
            <Grid item sm={11}>
              <Bail
                bail={
                  localId
                    ? { idlocal: localId, paliers: [], type_de_bail: "" }
                    : { paliers: [], type_de_bail: "" }
                }
                baux={bauxFiltred}
                typesBaux={typesBaux}
                typesActivites={typesActivites}
                loading={loading}
                onFormDataChanged={this.handleDataChanged}
                onResetDataChanged={this.handleResetDataChanged}
                formDataChanged={formDataChanged}
                onDelete={this.handleDelete}
                onUpdate={this.handleUpdate}
                onCreate={this.handleCreate}
                openNotification={openNotification}
              />
            </Grid>
          )}
        />
        <Route
          path={`${match.url}/:bailId(\\d+)`}
          exact
          render={() =>
            !loading && baux.length === 0 ? null : (
              <Grid item sm={11}>
                <DatasLoader
                  baux={localId ? bauxFiltred.filter(b => b.localId === localId) : bauxFiltred}
                  bailId={bailId}
                >
                  <Bail
                    filtred={textFiltered || localId}
                    loading={loading}
                    typesBaux={typesBaux}
                    typesActivites={typesActivites}
                    onFormDataChanged={this.handleDataChanged}
                    onResetDataChanged={this.handleResetDataChanged}
                    formDataChanged={formDataChanged}
                    onDelete={this.handleDelete}
                    onUpdate={this.handleUpdate}
                    openNotification={openNotification}
                  />
                </DatasLoader>
              </Grid>
            )
          }
        />
      </Centered>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Baux)));
