import React from "react";
import ReactTable from "react-table";
import { withStyles } from "@material-ui/core/styles";
import headerStyle from "../../common/tableHeaderStyle";
import NumberFormat from "react-number-format";
import moment from "moment";

const styles = theme => {
  return {
    list: {
      paddingBottom: theme.spacing.unit,
      maxHeight: "calc(100vh - 155px)",
      minHeight: "calc(100vh - 155px)",
      overflowY: "auto",
      marginLeft: theme.spacing.unit * 2,
      width: 750
    }
  };
};

const freqHeaders = {
  mensuelle: "Mensualité",
  trimestrielle: "Trimestrialité",
  annuelle: "Annualité"
};

const Couverture = ({ couverture, frequence, classes }) => (
  <ReactTable
    data={couverture}
    columns={[
      {
        Header: "Éch.",
        accessor: "echeances_numero",
        width: 40,
        Cell: row => <span>{row.value}</span>,
        headerStyle: { ...headerStyle, textAlign: "right" }
      },
      {
        Header: "Cap. début période",
        accessor: "capital_debut",
        Cell: row => (
          <NumberFormat
            value={parseFloat(row.value).toFixed(2)}
            displayType={"text"}
            thousandSeparator=" "
            suffix={" €"}
          />
        ),
        headerStyle: { ...headerStyle, textAlign: "right" }
      },
      {
        Header: "Pâte payeuse",
        accessor: "pate_payeuse",
        Cell: row => (
          <NumberFormat
            value={parseFloat(row.value).toFixed(2)}
            displayType={"text"}
            thousandSeparator=" "
            suffix={" €"}
          />
        ),
        headerStyle: { ...headerStyle, textAlign: "right" }
      },
      {
        Header: "Pâte réception",
        accessor: "pate_reception",
        Cell: row => (
          <NumberFormat
            value={parseFloat(row.value).toFixed(2)}
            displayType={"text"}
            thousandSeparator=" "
            suffix={" €"}
            style={{ paddingRight: "10px" }}
          />
        ),
        headerStyle: {
          ...headerStyle,
          textAlign: "right",
          paddingRight: "10px"
        }
      },
      {
        Header: "Écheance",
        accessor: "date_echeance",
        Cell: row =>
          row.value !== "" ? moment(row.value).format("DD/MM/YYYY") : "",
        headerStyle: {
          ...headerStyle,
          textAlign: "right",
          paddingRight: "10px"
        }
      }
    ]}
    noDataText="Aucune donnée"
    showPagination={false}
    pageSize={couverture.length}
    className="-striped -highlight"
  />
);

export default withStyles(styles)(Couverture);
