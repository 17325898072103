import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withGlobals from "../../pages/withGlobals";
import headerStyle from "../../common/tableHeaderStyle";
import ReactTable from "react-table";

class DialogParametrage extends React.Component {
  state = {
    dialogOpen: true,
    taux_parametrage: [],
    pct_relocation: []
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
    window.location = "/";
  };

  componentDidMount = () => {
     this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          parametrage: "GET"
        }
      })
      .then(parametrage => {
        this.setState({taux_parametrage: JSON.parse(parametrage[0].taux_parametrage)});
        this.setState({pct_relocation: JSON.parse(parametrage[0].pct_relocation)});
      })
      .catch(err => console.log(err));
  };

  handleSave = () => {
    this.props
      .fetch({
        url: "/banques/",
        method: "POST",
        body: {
          taux_parametrage: this.state.taux_parametrage,
          pct_relocation: this.state.pct_relocation,
          parametrage: "oui"
        }
      })
      .then(res => {
        this.handleCloseDialog();
        this.props.openNotification("success", "Paramétrage modifié avec succès!", false)
      })
      .catch(err =>  {
        this.props.openNotification("success", "Paramétrage modifié", false)
        this.handleCloseDialog();
      });
  };

  handleChangeTauxParametrage = (e, index) => {
    this.state.taux_parametrage[index][e.target.name] = parseFloat(e.target.value.replace('%', '').trim());
  };
  handleChangePctRelocation = (e, index) => {
    this.state.pct_relocation[index][e.target.name] = parseFloat(e.target.value.replace('%', '').trim());
    var somme = 0;
    this.state.pct_relocation.forEach(pct => somme += parseFloat(pct.pct_relocation));
    if (somme > 100) {
      this.state.pct_relocation[index][e.target.name] = 0;
      this.setState({taux_parametrage: this.state.taux_parametrage});
      alert('La somme des pourcentages de relocation ne peut dépasser 100%\nVeuillez renseigner un autre % de relocation');
    }
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"md"}
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">Paramétrage</DialogTitle>
          <DialogContent>
          <Grid item xs={12}>
          <h4>Hypothèses sur le DSCR projeté</h4>
			<ReactTable
        data={this.state.taux_parametrage.map((t, index) => {
          return { ...t, pct_relocation: this.state.pct_relocation[index] ? this.state.pct_relocation[index].pct_relocation : ''};
        })}
        columns={[
          {
            Header: "Année",
            accessor: "annee",
            width: 150,
            Cell: row => <span style={{ lineHeight: "40px" }}>{row.value}</span>,
            headerStyle: { ...headerStyle, textAlign: "center" },
          },
          {
            Header: "Evolution Loyer Actuel et Frais de Gestion",
            accessor: "taux_parametrage",
            width: 430,
            headerStyle: { ...headerStyle, textAlign: "center" },
            Cell: row => { return ( <span>
             <TextField
              type="text"
              style={{ lineHeight: "40px" , border: "5px solid transparent"}}
              defaultValue={row.value? parseFloat(row.value).toFixed(2) + " %" : '1.00 %'}
              name="taux_parametrage"
              onChange={e => this.handleChangeTauxParametrage(e, row.index)}
              suffix={" %"}
               />
            </span>
            );
            }
          },
          {
            Header: "% de relocation du stock vacant",
            accessor: "pct_relocation",
            width: 330,
            headerStyle: { ...headerStyle, textAlign: "center" },
            Cell: row => { return ( <span>
             <TextField
              style={{ lineHeight: "40px" , border: "5px solid transparent"}}
              type="text"
              defaultValue={row.value? parseFloat(row.value).toFixed(2) + " %" : '0.00 %'}
              name="pct_relocation"
              onChange={e => this.handleChangePctRelocation(e, row.index)}
               />
            </span>
            );
            }
          }
          
        ]}
        noDataText="Aucune donnée"
        showPagination={false}
        pageSize={this.state.taux_parametrage.length}
        className="-striped -highlight noScroll"
      />
		</Grid>

            <DialogActions>
              <Button onClick={this.handleCloseDialog} color="textSecondary">
                Annuler
              </Button>
              <Button
                onClick={this.handleSave}
                variant="raised"
                color="primary"
              >
                Valider ce paramètrage
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withGlobals(DialogParametrage);

