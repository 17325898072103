import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Centered from "../layout/Centered";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import UserIcon from "@material-ui/icons/Person";
import { withStyles } from "@material-ui/core/styles";
import withGlobals from "./withGlobals";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    width: 350
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  button: {
    marginTop: theme.spacing.unit * 4
  },
  icon: {
    height: "3em",
    width: "3em",
    fill: theme.palette.primary.dark
  },
  errorMessage: {
    border: `solid 1px ${theme.palette.primary.light}`,
    padding: theme.spacing.unit * 2,
    color: theme.palette.primary.dark
  }
});

class Login extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onLoginSuccess: PropTypes.func.isRequired
  };

  state = {
    error: false,
    submiting: false,
    login: undefined,
    password: undefined
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.error) {
      return this.setState({ error: false });
    }
    const { login, password } = this.state;
    const { history, onLoginSuccess } = this.props;
    this.props
      .fetch({
        url: "/login/",
        method: "POST",
        body: { login, password },
        silent: true
      })
      .then(user => {
        onLoginSuccess({
          ...user
        });
        history.push("/");
      })
      .catch(() =>
        this.setState({
          error: true
        })
      );
  };

  render() {
    const { classes } = this.props;
    const { error } = this.state;
    return (
      <Centered spacing={0}>
        <Grid item>
          <Paper elevation={10} className={classes.root}>
            <div>
              <UserIcon className={classes.icon} />
            </div>
            <form className={classes.container} onSubmit={this.handleSubmit}>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
              >
                <Grid item>
                  {!error && (
                    <TextField
                      label="Identifiant"
                      name="login"
                      className={classes.textField}
                      onChange={this.handleChange}
                      autoFocus
                      margin="normal"
                    />
                  )}
                  {error && (
                    <Typography className={classes.errorMessage}>
                      Identifiant ou mot de passe incorrect
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  {!error && (
                    <TextField
                      label="Mot de passe"
                      className={classes.textField}
                      name="password"
                      onChange={this.handleChange}
                      margin="normal"
                      type="password"
                    />
                  )}
                  {error && <div style={{ minHeight: "64px" }} />}
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
                type="submit"
              >
                {error ? "Réessayer" : "Se connecter"}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Centered>
    );
  }
}

export default withRouter(withStyles(styles)(withGlobals(Login)));
