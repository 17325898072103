import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import CenteredMobile from "../../layout/CenteredMobile";
import ListItemsMobile from "../../common/ListItemsMobile";
import SwipeableViews from "react-swipeable-views";
import withGlobals from "../withGlobals";
import Emprunt from "./Emprunt";
import DetailsTrancheEmpruntMobile from "./DetailsTrancheEmpruntMobile";
import NumberFormat from "react-number-format";
import RechercheForm from "./RechercheForm";
import filter from "./filter";
const recherche = {
  idinvestissement: "",
  idactifpatrimonial: "",
  nom_portefeuille: "",
  code_postal: "",
  ville: "",
  adresse: "",
  idbanque: "",
  idsociete: ""
};

class EmpruntMobile extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      index: this.findSwipeableViewsIndex(props),
      emprunts: [],
      banques: [],
      recherche
    };
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps = nextProps => {
    const {
      match: {
        params: { empruntId }
      },
      location: { search }
    } = this.props;
    if (
      search !== nextProps.location.search ||
      empruntId !== nextProps.match.params.empruntId
    ) {
      this.setState({ index: this.findSwipeableViewsIndex(nextProps) });
    }
  };

  componentWillMount = () => {
    this.props.fetch({ url: "/portefeuilles/" }).then(datas => {
      this.setState({
        emprunts: datas.map(d => {
          const portefeuille = JSON.parse(d.portefeuille);
          let capital_restant = `${portefeuille.emprunts.reduce(
            (m, e) => m + e.capital_restant,
            0
          )} €`;
          return {
            ...d,
            portefeuille,
            capital_restant
          };
        })
      });
    });

      this.props.fetch({method: "POST", url: `/banques/`, body: { ALL: "ALL2" } }).then(banques => {
      this.setState({
          banques : banques
    });
    }); 


  };

  findSwipeableViewsIndex = props => {
    if (props.match.params.empruntId) {
      if (props.location.search !== "") {
        return 3;
      }
      return 2;
    } else {
      if (props.location.search !== "") {
        return 1;
      } else {
        return 0;
      }
    }
  };

  handleChange = e =>
    this.setState({
      recherche: { ...this.state.recherche, [e.target.name]: e.target.value }
    });

  render() {
    const {
      match: {
        params: { empruntId }
      }
    } = this.props;

    const { index, emprunts, recherche, banques } = this.state;
    const emprunt = emprunts.find(e => `${e.idinvestissement}` === empruntId);
    let noTranche = 0;
    if (index === 3 && this.props.location.search !== "") {
      noTranche = parseInt(
        this.props.location.search.split("noTranche=")[1],
        10
      );
    }
    return (
      <CenteredMobile alignItems="center" justify="center">
        <Grid item xs={12}>
          <SwipeableViews index={index} animateHeight={false}>
            <RechercheForm
              moduleRoute="emprunts_mobile"
              onChange={this.handleChange}
              recherche={recherche}
            />
            <div>
              {emprunts.length > 0 &&
                index === 1 && (
                  <ListItemsMobile
                    sameFormSearch={false}
                    idField="idinvestissement"
                    buildPrimaryText={a => (
                      <span style={{ fontSize: "0.8em" }}>
                        ID {a.idinvestissement} / Capital restant dû{" "}
                        <NumberFormat
                          value={a.capital_restant}
                          displayType={"text"}
                          thousandSeparator=" "
                          suffix={" €"}
                        />
                      </span>
                    )}
                    buildSecondaryText={a => {
                      return `ID ${
                        a.portefeuille.actifs[0].idactifpatrimonial
                        } / ${a.portefeuille.actifs[0].adresse} ${
                        a.portefeuille.actifs[0].code_postal
                        } ${a.portefeuille.actifs[0].ville}`;
                    }}
                    moduleRoute="emprunts_mobile"
                    datas={filter(emprunts, recherche)}
                  />
                )}
            </div>
            <div style={{ padding: 8 }}>
              {emprunt && <Emprunt emprunt={emprunt} banques={banques} />}
            </div>
            <div style={{ padding: 8 }}>
              {emprunt &&
                noTranche && (
                  <DetailsTrancheEmpruntMobile
                    emprunt={emprunt.portefeuille.emprunts[noTranche - 1]} banques={banques}
                  />
                )}
            </div>
          </SwipeableViews>
        </Grid>
      </CenteredMobile>
    );
  }
}

export default withRouter(withGlobals(EmpruntMobile));
